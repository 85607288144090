import { call, put } from "redux-saga/effects";

import DefMembershipActions from "../actions/defMembership-actions";
import { getDefMembership } from "../../Service/api";

// attempts to fetch default Membership
export function* getDefMembershipData() {
	const response = yield call(getDefMembership);
	// success?
	if (response.ok) {
		yield put(DefMembershipActions.defMembershipSuccess(response.data));
	} else {
		yield put(DefMembershipActions.defMembershipFailure(response.data));
	}
}
