import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  generateCodeRequest: ['generationRequest'],
  verifyCodeRequest: ['verificationRequest'],

  generateCodeSuccess: ['phoneVerification'],
  verifyCodeSuccess: ['subscriber'],

  generateCodeFailure: ['error'],
  verifyCodeFailure: ['error']
})

export const PhoneVerificationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  generating: false,
  verifying: false,

  phoneVerification: null,
  subscriber: null,

  errorGenerating: null,
  errorVerifying: null,
})

/* ------------- Reducers ------------- */

// request the code generation from api
export const generateRequest = (state) => {
  return {
    ...state,
    generating: true,
    phoneVerification: null
  }
}

// update state after code generation successful
export const generateSuccess = (state, action) => {
  const { phoneVerification } = action
  return {
    ...state,
    generating: false,
    errorGenerating: null,
    phoneVerification
  }
}

// request verification of submitted code
export const verifyRequest = (state) => {
  return {
    ...state,
    verifying: true
  }
}
// successful api lookup for all entities
export const verifySuccess = (state, action) => {
  const { subscriber } = action
  return {
    ...state,
    verifying: false,
    errorVerifying: null,
    subscriber
  }
}

// Something went wrong fetching a single entity.
export const generateFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    phoneVerification: null,
    errorGenerating: error,
    generating: false,
  }
}
// Something went wrong fetching all entities.
export const verifyFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    verifying: false,
    errorVerifying: error,
    subscriber: null,
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GENERATE_CODE_REQUEST]: generateRequest,
  [Types.VERIFY_CODE_REQUEST]: verifyRequest,

  [Types.GENERATE_CODE_SUCCESS]: generateSuccess,
  [Types.VERIFY_CODE_SUCCESS]: verifySuccess,

  [Types.GENERATE_CODE_FAILURE]: generateFailure,
  [Types.VERIFY_CODE_FAILURE]: verifyFailure
})
