import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  walletRequest: ['walletId'],
  globalWalletRequest: ['businessId', "options"],
  walletStatisticsRequest: ['businessId'],
  favouriteWalletsRequest: ['businessId'],
  favouriteWalletRequest: ['id'],
  unfavouriteWalletRequest: ['id'],

  walletSuccess: ['wallet'],
  globalWalletSuccess: ['globalWallet'],
  walletStatisticsSuccess: ['walletStatistics'],
  favouriteWalletsSuccess: ['favouriteWallets'],
  favouriteWalletSuccess: ['favouriteWallet'],
  unfavouriteWalletSuccess: ['unFavouriteWallet'],

  walletFailure: ['error'],
  globalWalletFailure: ['error'],
  walletStatisticsFailure: ['error'],
  favouriteWalletsFailure: ['error'],
  favouriteWalletFailure: ['error'],
  unfavouriteWalletFailure: ['error'],

  setWalletSelections: ['selections'],
  setWalletSources: ["sources"],
  resetWalletSelections: [],
  resetWalletSources: []

})

export const WalletTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingOne: false,
  fetchingGlobalWallet: false,
  fetchingWalletStatistics: false,
  favouritingWallets: false,
  favouritingWallet: false,
  unfavouritingWallet: false,
  fetchingFavouriteWallets: false,
  fetchingFavouriteWallet: false,
  fetchingUnfavouriteWallet: false,

  wallet: null,
  globalWallet: null,
  walletStatistics: null,
  favouriteWallets: null,
  favouriteWallet: false,
  unfavouriteWallet: null,

  errorOne: null,
  errorGlobalWallet: null,
  errorWalletStatistics: null,
  errorFavouriteWallets: null,
  errorFavouriteWallet: null,
  errorUnfavouriteWallet: null,

  selections: null,
  selectionsUpdated: false,
  sources: null,
  sourcesUpdated: false,
})

/* ------------- Reducers ------------- */
export const request = (state) => {
  return { ...state, fetchingOne: true, wallet: null };
};
export const globalWalletRequest = (state) => {
  return { ...state, fetchingGlobalWallet: true, globalWallet: null, errorGlobalWallet: null };
};

export const walletStatisticsSuccess = (state, action) => {
  const { walletStatistics } = action;
  return { ...state, fetchingWalletStatistics: false, errorWalletStatistics: null, walletStatistics }
}

export const walletStatisticsRequest = (state) => {
  return { ...state, fetchingWalletStatistics: true, walletStatistics: null, errorWalletStatistics: null };
};

// request the data from an api
export const favouriteWalletsRequest = (state) => {
  return { ...state, favouritingWallets: true, favouriteWallets: null }
}

// request the data from an api
export const favouriteWalletRequest = (state) => {
  return { ...state, favouritingWallet: true, favouriteWallet: null }
}

// request the data from an api
export const unfavouriteWalletRequest = (state) => {
  return { ...state, unfavouritingWallet: true, unFavouriteWallet: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
  const { wallet } = action;
  return { ...state, fetchingOne: false, errorOne: null, wallet }
}
export const globalWalletSuccess = (state, action) => {
  const { globalWallet } = action;
  return { ...state, fetchingGlobalWallet: false, errorGlobalWallet: null, globalWallet }
}

// successful api lookup for single entity
export const favouriteWalletsSuccess = (state, action) => {
  const { favouriteWallets } = action;
  return { ...state, favouritingWallets: false, errorFavouriteWallets: null, favouriteWallets }
}

// successful api lookup for single entity
export const favouriteWalletSuccess = (state, action) => {
  // eslint-disable-next-line no-unused-vars
  const { favouriteWallet } = action;
  return { ...state, favouritingWallet: false, errorFavouriteWallet: null, favouriteWallet: true }
}

// successful api lookup for single entity
export const unfavouriteWalletSuccess = (state, action) => {
  // eslint-disable-next-line no-unused-vars
  const { unfavouriteWallet } = action;
  return { ...state, unfavouritingWallet: false, errorUnfavouriteWallet: null, unfavouriteWallet: true }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingOne: false, errorOne: error, wallet: null }
}

export const walletStatisticsFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingWalletStatistics: false, errorWalletStatistics: error, walletStatistics: null }
}

export const globalWalletFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingGlobalWallet: false, errorGlobalWallet: error, globalWallet: null }
}

// Something went wrong fetching a single entity.
export const favouriteWalletsFailure = (state, action) => {
  const { error } = action;
  return { ...state, favouritingWallets: false, errorFavouriteWallets: error, favouriteWallets: null }
}

// Something went wrong fetching a single entity.
export const favouriteWalletFailure = (state, action) => {
  const { error } = action;
  return { ...state, favouritingWallet: false, errorFavouriteWallet: error, favouriteWallet: null }
}

// Something went wrong fetching a single entity.
export const unfavouriteWalletFailure = (state, action) => {
  const { error } = action;
  return { ...state, unfavouritingWallet: false, errorUnFavouriteWallet: error, unFavouriteWallet: null }
}

export const setWalletSelections = (state, action) => {
  const { selections } = action;
  return { ...state, selections, selectionsUpdated: true }
}

export const resetWalletSelections = (state) => {
  return { ...state, selections: null, selectionsUpdated: false }
}

export const setWalletSources = (state, action) => {
  const { sources } = action;
  return { ...state, sourcesUpdated: true, sources }
}

export const resetWalletSources = (state) => {
  return { ...state, fetchingOne: false, sourcesUpdated: null, }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.WALLET_REQUEST]: request,
  [Types.GLOBAL_WALLET_REQUEST]: globalWalletRequest,
  [Types.WALLET_STATISTICS_REQUEST]: walletStatisticsRequest,
  [Types.FAVOURITE_WALLETS_REQUEST]: favouriteWalletsRequest,
  [Types.FAVOURITE_WALLET_REQUEST]: favouriteWalletRequest,
  [Types.UNFAVOURITE_WALLET_REQUEST]: unfavouriteWalletRequest,

  [Types.WALLET_SUCCESS]: success,
  [Types.GLOBAL_WALLET_SUCCESS]: globalWalletSuccess,
  [Types.WALLET_STATISTICS_SUCCESS]: walletStatisticsSuccess,
  [Types.FAVOURITE_WALLETS_SUCCESS]: favouriteWalletsSuccess,
  [Types.FAVOURITE_WALLET_SUCCESS]: favouriteWalletSuccess,
  [Types.UNFAVOURITE_WALLET_SUCCESS]: unfavouriteWalletSuccess,

  [Types.WALLET_FAILURE]: failure,
  [Types.GLOBAL_WALLET_FAILURE]: globalWalletFailure,
  [Types.WALLET_STATISTICS_FAILURE]: walletStatisticsFailure,
  [Types.FAVOURITE_WALLETS_FAILURE]: favouriteWalletsFailure,
  [Types.FAVOURITE_WALLET_FAILURE]: favouriteWalletFailure,
  [Types.UNFAVOURITE_WALLET_FAILURE]: unfavouriteWalletFailure,

  [Types.SET_WALLET_SELECTIONS]: setWalletSelections,
  [Types.SET_WALLET_SOURCES]: setWalletSources,
  [Types.RESET_WALLET_SELECTIONS]: resetWalletSelections,
  [Types.RESET_WALLET_SOURCES]: resetWalletSources,
})
