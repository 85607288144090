import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useTranslation } from "react-i18next";
import "../Styles/components/Notification.scss";
import DocumentActions from '../Redux/transition/document-upload/document-upload.reducer';

export default function OnboardingNotification(props) {
      const { t } = useTranslation("common");

      //Redux State from Store
      const defMembership = useSelector((state) => state.defMembership.defMembership);
      const business = useSelector((state) => state.businesses.business);

      // redux dispatch actions
      const dispatch = useDispatch()
      const getVerificationRecord = useCallback((businessId) => { dispatch(DocumentActions.businessVerificationStatusRequest(businessId)) }, [dispatch]);

      const [state, setState] = useState({
            copied: false,
      });

      useEffect(() => {
            if (props.defMembership) {
                  getVerificationRecord(defMembership.business.id);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      return (
            <Dialog
                  header={t('login.welcome')}
                  visible={props.visible}
                  style={{ width: "550px", height: "auto" }}
                  onHide={() => props.onHide()}
                  className="tier_dialog"
                  id="tier_dialog"
            >
                  <div className="merchant-onboarding-div">
                        <div className="hello_name_div">
                              <span className="hello_name">{t("login.hello")}{" "}{defMembership?.user?.firstName}.</span>
                        </div>
                        <div className="welcome_div"><span className="dark_texts">{t('login.welcome')}</span></div>
                        <span className="dark_texts">{t('login.you_can_begin')}{" "}</span>
                        <span className="blue_texts">{t('login.setup_products')}</span>
                        <span className="dark_texts">,{" "}</span>
                        <span className="blue_texts">{t('login.setup_plans')}{" "}</span>
                        <span className="dark_texts">{t('login.and')}{" "}</span>
                        <span className="blue_texts">{t('login.invite_your_team')}{" "}</span>
                        <span className="dark_texts">{t('login.join_account')}</span>
                        <div style={{ padding: "1rem 0rem" }}> <span className="dark_texts">{t('login.however')}{" "}</span>
                              <span className="notification_red_txt">{t('login.invite_subscribers')}{" "}</span>
                              <span className="dark_texts">{t('login.and')}{" "}</span>
                              <span className="notification_red_txt">{t('login.start_collecting')}</span>
                              <span className="dark_texts">{","}{" "}{t('login.need_a_verified')}{" "}</span>
                              <span className="purple_styles">{t('login.merchant_account')}</span>
                              <span className="dark_texts">{t('login.from_a')}{" "}</span>
                              <span className="purple_styles">{t('login.licensed_payment')}</span>
                              <span className="dark_texts">.</span>
                        </div>
                        <span className="dark_texts">{t('login.click')}{" "}</span>
                        <span className="notification_red_txt">{t('login.start_application')}{" "}</span>
                        <span className="dark_texts">{t('login.notification_prompt')}
                        </span>
                        <p className="notification_hr" />
                        <div className="apply_txt_header">
                              <span className="blue_big_texts">{t('login.apply_merchant_account')}</span>
                        </div>
                        <div className="highlight">
                              <p className="side_thin_hr" />
                              <div className="hightlight_txts">
                                    <span className="payswitch_txt">{t('login.payswitch_txt')}{" "}</span>
                                    <span className="brown_text_style">{t('login.payment_service_partner')}{" "}</span>
                                    <span className="the_teller">{t('login.the_teller')}</span><span className="brown_text_style">.</span>
                              </div>
                        </div>
                        <div className="grid business_id-copy">
                              <div className="col-5 md:col-5 lg:col-5"><span className="the_teller">{t('login.revkn_business_id')}</span></div>
                              <div className="col-7 md:col-7 lg:col-7"><span
                                    id="notification_copy_btn"
                                    onClick={() => {
                                          const textToCopy = `${business?.keyString}`;
                                          navigator.clipboard.writeText(textToCopy);
                                          setState((state) => ({ ...state, copied: true }));
                                    }}>{state.copied ? t("share.copied") : t("login.copy")}</span>
                              </div>
                        </div>
                        <div className="merchant-onboarding-footer">
                              <div className="businessId_div"><span className="businessId">{business?.keyString}</span></div>
                              <Button
                                    id="start_application_btn" onClick={() => { window.open("https://form.jotform.com/241795157972571") }}>
                                    {t("login.startApplication")}
                              </Button>
                        </div>
                  </div>
            </Dialog>
      )
}