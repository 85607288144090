import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
//Redux actions import
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import BusinessActions from "../../../Redux/actions/business-actions"
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer"
import SelfSignupActions from "../../../Redux/transition/self-signup/self-signup.reducer";
import DocumentActions from '../../../Redux/transition/document-upload/document-upload.reducer';
//primereact imports
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
//Other imports
import InviteSubscriber from "./InviteSubscriber";
import { formatPhoneNumber } from "react-phone-number-input";
import moment from "moment";
import "../../../Styles/pages/subscriptions.scss";
import "../../../Styles/pages/invitation.scss";
import "../../../Styles/pages/activities.scss";
import "../../../Styles/pages/planCreate.scss";
import "../../../Styles/pages/product.scss";


const Invitations = () => {
	//redux state and dispatch to props
	const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
	const verificationStatus = useSelector((state) => state.documents.verifStatus);
	const fetchingSelfSignup = useSelector((state) => state.selfSignups.fetchingAll);
	const filter = useSelector((state) => state.selfSignups.fetchingInvitation);
	const invitations = useSelector((state) => state.selfSignups.invitations);
	const fetchingAllInvitations = useSelector((state) => state.selfSignups.fetchingAllInvitations);
	const fetchingCounts = useSelector((state) => state.selfSignups.fetchingCount);
	const fetchingSearchCount = useSelector((state) => state.selfSignups.fetchingSearchCount);
	const allInvitations = useSelector((state) => state.selfSignups.allInvitations);
	const invitationCounts = useSelector((state) => state.selfSignups.searchCount);
	const invitationError = useSelector((state) => state.selfSignups.errorInvitation);
	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const planGroupsGraphed = useSelector((state) => state.planGroups.planGroupsGraphed)
	const resend = useSelector((state) => state.selfSignups.resending)
	const cancel = useSelector((state) => state.selfSignups.deleting)
	const resendSuccess = useSelector((state) => state.selfSignups.signUpResend)
	const resendFail = useSelector((state) => state.selfSignups.errorResend)
	const resendBulk = useSelector((state) => state.selfSignups.resendingAll)
	const resendBulkFail = useSelector((state) => state.selfSignups.errorResendBulk)
	const business = useSelector((state) => state.businesses.business)
	const generating = useSelector((state) => state.phoneVerifications.generating)
	const selfSignupCounts = useSelector((state) => state.selfSignups.count)
	const selfSignupFilter = useSelector((state) => state.selfSignups.filter)
	const selfSignupDeleteError = useSelector((state) => state.selfSignups.errorDeleting)

	// Redux dispatch actions
	const dispatch = useDispatch();
	const getBusinessVerificationStatus = useCallback((businessId) => dispatch(DocumentActions.businessVerificationStatusRequest(businessId)), [dispatch]);
	const getInvitations = useCallback((businessId, filter, options) => dispatch(SelfSignupActions.invitationSearchRequest(businessId, filter, options)), [dispatch]);
	const getInvitationsCount = useCallback((businessId, filter) => dispatch(SelfSignupActions.searchCountRequest(businessId, filter)), [dispatch]);
	const getSelfSignups = useCallback((businessId, options) => dispatch(SelfSignupActions.selfSignupAllRequest(businessId, options)), [dispatch])
	const resendSelfSignup = useCallback((id) => dispatch(SelfSignupActions.resendSelfSignupRequest(id)), [dispatch])
	const deleteSelfSignup = useCallback((id) => dispatch(SelfSignupActions.selfSignupDeleteRequest(id)), [dispatch])
	const resendBulkSelfSignup = useCallback((ids) => dispatch(SelfSignupActions.resendBulkSelfSignupRequest(ids)), [dispatch])
	const getSelfSignupsCount = useCallback((businessId) => dispatch(SelfSignupActions.selfSignupCountRequest(businessId)), [dispatch])
	const getMembership = useCallback(() => dispatch(DefMembershipActions.defMembershipRequest()), [dispatch])
	const getActivePlanGroups = useCallback((businessId) => dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)), [dispatch])
	const getBusiness = useCallback((businessId) => dispatch(BusinessActions.businessRequest(businessId)), [dispatch])

	const history = useHistory();
	const { t } = useTranslation("common");
	const toast = useRef(null);
	const toastWarn = useRef(null);
	const toastBulk = useRef(null);

	const [state, setState] = useState({
		layout: "list",
		planGroupsGraphed: null,
		inviteData: null,
		checking: false,
		success: false,
		resending: false,
		deleting: false,
		resendingBulk: false,
		resendingId: null,
		deletingId: null,
		requesting: false,
		sort: 'requestedOn,desc',
		count: null,
		searchCount: null,
		first: 0,
		size: 15,
		input: "",
		filtering: false,
		isFiltered: false,
		fetchingVerifRecord: true,
		verifStatus: null,
		invitationsFilter: null,
		visible: false,
		hide: true,
		defMembership: null,
		notOwnerVisible: false,
		verifPendingVisible: false,
		initialTier: null,
		filter: null,
		filterStatus: null,
		filterNum: null,
		filters: null,
		reset: false,
		number: null,
		inputCleared: "false"
	})
	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const [visible, setVisible] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState({ name: 'All Invitations', code: 'ALL' });

	const filters = [
		{ name: t('invitation.all_invitations'), code: 'ALL' },
		{ name: t('invitation.pending_invitations'), code: 'PENDING' },
		{ name: t('invitation.accepted_invitations'), code: 'ACCEPTED' },
		{ name: t('invitation.error_invitations'), code: 'ERROR' },
	];

	const ids = selectedCustomers.map(({ id }) => id)

	const fetchActive = () => {
		getActivePlanGroups(defMembership.business.id);
	}
	const fetchInvitesFilter = (page) => {
		let filterStatus = state.filterStatus
		let filterNum = state.input ? state.input : null
		getInvitationsCount(defMembership.business.id, { status: filterStatus, phoneNo: filterNum })
		getInvitations(defMembership.business.id, { status: filterStatus, phoneNo: filterNum, page, size: state.size, sort: state.sort })
	}
	const fetchInvites = (page) => {
		getSelfSignupsCount(defMembership.business.id);
		getSelfSignups(defMembership.business.id, { page, size: state.size, sort: state.sort })
	}
	const fetchAllInvites = useCallback(page => {
		fetchInvites(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSelfSignups, getInvitations, state.size, state.sort, selectedFilter])
	const fetchAllSearchInvites = useCallback(page => {
		fetchInvitesFilter(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getInvitations, state.size, fetchInvitesFilter, state.sort, selectedFilter])

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			getBusiness(defMembership.business.id)
			fetchActive();
			getSelfSignups(defMembership.business.id, { sort: state.sort, size: state.size, page: 0 });
			getSelfSignupsCount(defMembership.business.id);
			getBusinessVerificationStatus(defMembership.business.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let loading = fetchingVerifStatus;
		setState(state => { return { ...state, fetchingVerifRecord: loading } });
		if (selfSignupCounts) {
			setState((state) => { return { ...state, count: selfSignupCounts } })
		}
		if (planGroupsGraphed) {
			setState((state) => { return { ...state, planGroupsGraphed } })
		}
		if (verificationStatus) {
			setState(state => { return { ...state, verifStatus: verificationStatus } })
		}
		if (invitations) {
			setState(state => { return { ...state, invitationsFilter: invitations } })
		}
		if (!resend && state.resending && resendFail) {
			showError()
			setState((state) => { return { ...state, resending: false, resendingId: null } })
		}
		if (!cancel && state.deleting && selfSignupDeleteError) {
			showDeleteError()
			setState((state) => { return { ...state, deleting: false, deletingId: null } })
		}
		if (!resendBulk && state.resendingBulk && resendBulkFail) {
			showBulkError()
			setState((state) => { return { ...state, resendingBulk: false, } })
		}
		if (!resend && state.resending && !resendFail) {
			showSuccess()
			setState((state) => { return { ...state, resending: false, resendingId: null } })
		}
		if (!cancel && state.deleting && !selfSignupDeleteError) {
			showDeleteSuccess()
			setState((state) => { return { ...state, deleting: false, deletingId: null } })
		}
		if (!resendBulk && state.resendingBulk && !resendBulkFail) {
			showBulkSuccess()
			setState((state) => { return { ...state, resendingBulk: false, } })
		}
		if (!filter && state.filtering && invitationError) {
			showErrorFilter()
			setState((state) => ({ ...state, filtering: false }))
		}
		if (invitationCounts) {
			setState((state) => ({ ...state, searchCount: invitationCounts }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.checking, generating, state.requesting, state.searchCount, selfSignupFilter, verificationStatus, resend,
		resendBulk, state.resending, state.deleting, state.resendingBulk, resendSuccess, resendFail, cancel, selfSignupDeleteError, invitations, invitationError, state.filtering, state.isFiltered, filter])

	const data = state.isFiltered ? invitations : allInvitations;
	const count = state.isFiltered ? invitationCounts : selfSignupCounts
	const businessId = defMembership.business.id

	const onFilterChange = (e) => {
		setSelectedFilter(e.value);
		const filterSelected = () => {
			let paginationFilter = { page: state.first, size: state.size, sort: state.sort }
			if (e.value.code !== 'ALL' && (state.input === null || state.input === "" || state.input === " " || state.input === undefined)) {
				setState((state) => ({ ...state, isFiltered: true, filters: { status: e.value.code, ...paginationFilter }, filterStatus: e.value.code }))
				return { status: e.value.code, ...paginationFilter, }
			}
			if (e.value.code !== 'ALL' && state.input) {
				setState((state) => ({ ...state, isFiltered: true, filters: { phoneNo: state.input, status: e.value.code, ...paginationFilter }, filterStatus: e.value.code, filterNum: state.input }))
				return { status: e.value.code, phoneNo: state.input, ...paginationFilter }
			}
			if (e.value.code === 'ALL' && state.input !== null) {
				setState((state) => ({ ...state, isFiltered: true, filters: { phoneNo: state.input, ...paginationFilter }, filterNum: state.input }))
				return { phoneNo: state.input, ...paginationFilter }
			}
		}
		if (e.value.code === 'ALL' && state.input) {
			setState((state) => ({ ...state, isFiltered: true, }))
			getInvitationsCount(businessId, filterSelected())
			getInvitations(businessId, filterSelected(),)
		}
		else if (e.value.code === 'ALL') {
			setState((state) => ({ ...state, isFiltered: false, first: 0 }))
			getSelfSignups(businessId, { page: 0, size: state.size, sort: state.sort })
			getSelfSignupsCount(businessId);
		}
		else if (e.value.code === 'PENDING') {
			setState((state) => ({ ...state, isFiltered: true }))
			getInvitationsCount(businessId, filterSelected())
			getInvitations(businessId, filterSelected())
		}
		else if (e.value.code === 'ACCEPTED') {
			setState((state) => ({ ...state, isFiltered: true }))
			getInvitationsCount(businessId, filterSelected())
			getInvitations(businessId, filterSelected())
		}
		else {
			setState((state) => ({ ...state, isFiltered: true }))
			getInvitationsCount(businessId, filterSelected())
			getInvitations(businessId, filterSelected())
		}
	}

	//loaing loadingTable variable controls the spinner on the table
	const loadingTable = !defMembership || fetchingSelfSignup || filter || fetchingVerifStatus || !business || fetchingAllInvitations || fetchingSearchCount || fetchingCounts;

	const showSuccess = () => {
		toast.current.show({ severity: 'success', summary: t("subscriber.success"), detail: t("subscriber.success_message"), life: 3000 });
	}
	const showDeleteSuccess = () => {
		toast.current.show({ severity: 'success', code: 'deleted', summary: t("subscriber.success"), detail: t("invitation.delete_detail"), life: 3000 });
	}
	const showError = () => {
		toast.current.show({ severity: 'error', summary: t("subscriber.error_summary"), detail: t("subscriber.error_message"), life: 3000 });
	}
	const showDeleteError = () => {
		toast.current.show({ severity: 'error', summary: t("subscriber.error_summary"), detail: t("invitation.invitation_error"), life: 3000 });
	}
	const showBulkSuccess = () => {
		toast.current.show({ severity: 'success', code: 'bulkSuccess', summary: t("invitation.success_header"), detail: t("invitation.success_bulk_detail"), life: 3000 });
	}
	const showBulkError = () => {
		toast.current.show({ severity: 'error', summary: t("invitation.error_header"), detail: t("invitation.error_bulk_detail"), life: 3000 });
	}
	const showErrorFilter = () => {
		toast.current.show({ severity: 'error', summary: t("invitation.invitation_error_header"), detail: t("invitation.invitation_error"), life: 3000 });
	}
	const showWarn = () => {
		toast.current.show({ severity: 'warn', summary: t('invitation.invitation_bulk_warning_header'), detail: t('invitation.invitation_bulk_warning_message'), life: 3000 });
	}
	const formatTime = (time) => {
		if (moment(time).isValid()) {
			return moment(time).format("MMMM Do YYYY");
		}
	};

	//Toggle Invite Subscriber Dialogue Visibility
	const onHide = () => {
		setState(state => { return { ...state, visible: false } })
	}
	//Toggle not Owner dialogue visibility
	const toggleVerifPendingDialogue = () => {
		setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible } })
	}
	//Toggle not Owner dialogue visibility
	const toggleNotOwnerDialogue = () => {
		setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible } })
	}
	//Dialog Footer for verif alert**
	const renderVerifFooter = (component) => {
		return (
			<div>
				<Button label={t("common.ok")} style={{ backgroundColor: "#d0021b" }} icon="pi pi-check" autoFocus
					onClick={component === 'not_owner' ? toggleNotOwnerDialogue : toggleVerifPendingDialogue}
				/>
			</div>
		);
	}

	const onChange = (e) => {
		e.preventDefault();
		setState({ ...state, input: e.target.value, });
		let paginationFilter = { page: state.first, size: state.size, sort: state.sort }
		if (!e.target.value && selectedFilter.code === 'ALL') {
			setState((state) => ({ ...state, isFiltered: false, inputCleared: true }))
			getSelfSignupsCount(businessId);
			getSelfSignups(businessId, { page: 0, size: state.size, sort: state.sort })
		}
		else if ((!e.target.value && selectedFilter.code === 'PENDING') || (!e.target.value && selectedFilter.code === 'ACCEPTED')
			|| (!e.target.value && selectedFilter.code === 'ERROR')) {
			setState((state) => ({ ...state, isFiltered: true }))
			getInvitations(businessId, { status: selectedFilter.code, ...paginationFilter })
			getInvitationsCount(businessId, { status: selectedFilter.code, ...paginationFilter })
		}
	};

	//This makes the search for the phone input field
	const onSearch = (e) => {
		let paginationFilter = { page: 0, size: state.size, sort: state.sort }
		if (selectedFilter.code && (state.input === null || state.input === "" || state.input === " " || state.input === undefined)) {
			return;
		}
		else if (selectedFilter.code === "ALL" && state.input) {
			setState((state) => ({ ...state, isFiltered: true }))
			getInvitations(businessId, { phoneNo: state.input, ...paginationFilter })
			getInvitationsCount(businessId, { phoneNo: state.input, ...paginationFilter })
		}
		else if (selectedFilter.code === "PENDING" || selectedFilter.code === "ACCEPTED" || selectedFilter.code === "ERROR") {
			setState((state) => ({ ...state, isFiltered: true, }))
			getInvitations(businessId, { status: selectedFilter.code, phoneNo: state.input, ...paginationFilter })
			getInvitationsCount(businessId, { status: selectedFilter.code, phoneNo: state.input, ...paginationFilter })
		}
	}

	const handleKeyPress = e => {
		if (e.keyCode === 13) {
			onSearch(e);
		}
	}

	const accept = () => {
		const checks = selectedCustomers.map(({ status }) => status.includes('ACCEPTED'))
		if (checks.includes(true)) {
			showWarn()
		}
		else {
			let invitationIds = { ids }
			resendBulkSelfSignup(invitationIds)
			setVisible(false)
			setState((state) => ({ ...state, resendingBulk: true, }))
		}
	}

	const acceptDelete = () => {
		deleteSelfSignup(state.deletingId)
		setDeleteVisible(false)
		setState((state) => ({ ...state, deleting: true, }))
	}

	const renderHeader = () => {
		return (
			<>
				<ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={t('invitation.sms_confirmation')}
					header={t('invitation.confirmation_header')} accept={accept} reject={() => setVisible(false)} />
				<ConfirmDialog visible={deleteVisible} onHide={() => setDeleteVisible(false)} message={`${t('invitation.delete_confirmation')} ${state.number}`}
					header={t('invitation.confirmation_header')} accept={acceptDelete} reject={() => setDeleteVisible(false)} />
				<Toast ref={toast} />
				<Toast ref={toast} onRemove={(message) => {
					if (message.code === 'bulkSuccess' || message.code === 'deleted') {
						setSelectedCustomers([]);
						getSelfSignupsCount(businessId);
						getSelfSignups(businessId, { page: 0, size: state.size, sort: state.sort })
					}
				}} />
				<Toast ref={toastWarn} />
				<Toast ref={toastBulk} />
				<InviteSubscriber visible={state.visible} onHide={() => onHide()} initialTier={null} />
				<div className="p-grid p-nogutter" style={{ textAlign: 'justify', display: 'flex', justifyContent: 'space-between' }}>
					<Dialog
						className="tier_dialog"
						id="tier_dialog"
						style={{ width: "360px" }}
						header={t("idoc.id_verif_pending_header")} visible={state.verifPendingVisible}
						onHide={toggleVerifPendingDialogue} footer={renderVerifFooter('verif_pending')}>
						<span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#ffb304", fontWeight: "bolder" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
					</Dialog>
					<Dialog
						header={t("idoc.not_owner_header")} visible={state.notOwnerVisible}
						onHide={toggleNotOwnerDialogue} style={{ width: "360px" }} footer={renderVerifFooter('not_owner')}>
						<p>{t("idoc.not_owner_text")}</p>
					</Dialog>
				</div>
			</>
		);
	}

	const header = renderHeader();

	const onPageChange = (e) => {
		if (e.pageCount <= 1) {
			return;
		}
		if (selectedFilter.code === "ACCEPTED" || selectedFilter.code === "PENDING" || selectedFilter.code === 'ERROR' || state.input) {
			fetchAllSearchInvites(e.page)
			setState((state) => ({ ...state, first: e.first, isFiltered: true }));
		}
		else if (selectedFilter.code === "ALL") {
			fetchAllInvites(e.page)
			setState((state) => ({ ...state, first: e.first, isFiltered: false }))
		}
	}

	const dateFormatterBody = (rowData, e) => { return rowData[e] ? formatTime(rowData[e]) : "-" }

	const statusTemplate = (rowData) =>
		<div style={{ minWidth: "3rem" }}>
			{rowData.status === "ACCEPTED" && <div><span className="accepted_status">{rowData?.status}</span></div>}
			{rowData.status === "ERROR" && <div><span className="error_status">{rowData?.status}</span></div>}
			{rowData.status === "PENDING" && <div><span className="pending_status">{rowData?.status}</span></div>}
		</div>

	const subsInfoTemplate = (rowData) => <div style={{ minWidth: "12rem" }}>
		<p className={"planText tier"}
			onClick={() => {
				history.push({
					pathname: "/products/plan/detail/" + rowData.planId
				})
			}} >{rowData.planName}</p>
		<p className={"planText"}
			style={{ fontSize: "11px" }}
			onClick={() => { history.push({ pathname: "/products/detail/" + rowData.groupId }) }} >{rowData.groupName}</p>
	</div>

	const createdDateTemplate = (rowData, e) => <div style={{ minWidth: "3rem" }}>
		{rowData.startDate && <>
			{<p style={{ fontWeight: 'bold', marginBottom: "0", color: '#000', }}>
				{t('invitation.billing_start')}
			</p>}
			<p style={{ color: '#000', marginBottom: '3px', fontSize: "11px", }}>
				{dateFormatterBody(rowData, "startDate")}
			</p>
		</>}
		<p style={{ fontSize: "14px", color: rowData.startDate ? "#666678" : "#000", }}>
			{dateFormatterBody(rowData, "requestedOn")}
		</p>
	</div>
	const smsTemplate = (rowData) => {
		let status = rowData.latestDlr?.status.toLowerCase()
		status = status === "sent" || status === "delivered" ? "success" :
			status === "undelivered" ? "error" : "warning";
		return (
			<div className="sms" >
				<span className={status} id="delivered">{rowData.latestDlr?.status}</span>
				<div>
					{rowData.latestDlr?.status === "Undelivered" && <span style={{ display: "flex", textAlign: "left" }} >{t("invitation.undelivered_message")}</span>}
					{rowData.latestDlr?.status === "Delivered" && <span style={{ display: "flex", textAlign: "left" }} >{t("invitation.delivered_message")}</span>}
					{(rowData.status === "PENDING" || rowData.status === "ERROR") && <> <span id="resend-invitations-button"
						//Checking if the resend button is clicked and if the subscriber id is the same as the resending id.
						loading={resend && state.resendingId === rowData.id}
						disabled={resend && state.resendingId === rowData.id}
						onClick={() => {
							resendSelfSignup(rowData.id)
							// Setting the state of the resending to true and the resendingId to the id of the invitation that is being resent. 
							setState((state) => ({ ...state, resending: true, resendingId: rowData.id }))
						}} >{t('subscriber.resend')}</span>
					</>}
				</div>
			</div>
		)
	}

	const planAmountColumn = (rowData) =>
		<div style={{ minWidth: "3rem" }}><p className="invitationsPlanAmount" >{rowData.amount ? `GHS ${rowData.amount?.toFixed(2)}` : " "}
			{rowData.planFlex === true && <p className="invitationsPlanAmount">{t("products.flexible_amount")}</p>}</p>
		</div >

	const columns = [
		{ field: 'status', body: statusTemplate, header: t("invitation.status"), headerClassName: "status-invitations", },
		{
			field: "phoneNo", header: t("invitation.phoneNo"),
			body: (rowData) => <span style={{ minWidth: "3rem" }}>{rowData.phoneNo && rowData.phoneNo[0] === '+' ? formatPhoneNumber(rowData?.phoneNo) : rowData?.phoneNo}</span>
		},
		{
			field: "groupName", body: subsInfoTemplate,
			header: () => { return <p>{t("invitation.plan_header")} </p> }
		},
		{ field: "price", header: t("invitation.amount"), body: planAmountColumn },
		{ field: "requestedOn", body: createdDateTemplate, header: t("invitation.created_on"), style: { color: "#666678" } },
		{ field: "latestDlr.status", body: smsTemplate, header: t("invitation.sms_status") }
	]
	const defaultCellStyles = { border: "0px", borderBottom: "1px solid #e0e0e0", padding: "auto" }
	const defaultHederStyles = {
		fontFamily: "Roboto",
		fontSize: "12px",
		color: "#666678",
		textTransform: "capitalize",
	}

	return (
		<>
			<Card id="invitations-card" className="action_bar_cards">
				<>
					{(selectedCustomers === null || selectedCustomers.length < 2) ?
						<div className="filter-group invitations_search_group">
							<span style={{ color: "black", marginBottom: "2rem" }}><span id="invitations-count">{count > 0 ? count : 0}</span>
								<span className="invitations-text">{t('invitation.count')}</span></span>
							<div className="p-col-12 p-md-3 input-div">
								<div className="p-inputgroup" style={{ marginBottom: "2rem" }}>
									<InputText
										placeholder={t('invitation.search_phone')}
										value={state.input.trim()}
										onInput={onChange}
										onKeyDown={handleKeyPress}
										id="invitations_search_input"
									/>
									{state.input && <Button icon={"pi pi-times"} type="submit" className="x-button"
										onClick={(e) => {
											if (selectedFilter.code === 'ALL') {
												setState((state) => ({ ...state, input: "", filtering: false, isFiltered: false }))
												getSelfSignups(businessId, { sort: state.sort, size: state.size, page: 0 });
												getSelfSignupsCount(businessId);
											}
											else {
												let pagination = { sort: state.sort, size: state.size, page: 0 }
												setState((state) => ({ ...state, isFiltered: true, input: "" }))
												getInvitations(businessId, { status: selectedFilter.code, ...pagination })
												getInvitationsCount(businessId, { status: selectedFilter.code, ...pagination })
											}
										}}
									/>}
									<Button id="cancel-button" icon={"pi pi-search"} type="submit"
										onClick={(e) => {
											if (state.input) {
												onSearch();
												setState((state) => ({ ...state, filtering: true }))
											}
										}}
									/>
								</div>
							</div>
							<Dropdown style={{ padding: "0.4rem", backgroundColor: "transparent", marginBottom: "2rem" }} value={selectedFilter} options={filters} onChange={(e) => { onFilterChange(e) }} optionLabel="name" placeholder={t('invitation.all_invitations')} />
						</div> :
						<div className="action-group">
							<span style={{ color: "black" }}><span id="invitations_length">{selectedCustomers.length > 1 ? <span id="invitations_selected">
								{selectedCustomers.length} {t('invitation.invitations_selected')}</span> : <span style={{ fontFamily: "Roboto", fontSize: "14px", color: "#495057" }}>{selectedCustomers.length} {t('invitation.invitation_selected')}</span>} </span>
							</span>
							<span className="p-buttonset" style={{ marginLeft: "0.4rem" }}>
								<Button className=" p-button-secondary p-button-outlined send-sms" loading={resendBulk}
									label={t('invitation.send_sms')}
									onClick={() => {
										const checks = selectedCustomers.map(({ status }) => status.includes('ACCEPTED'))
										if (checks.includes(true)) {
											showWarn()
										}
										else {
											setVisible(true)
										}
									}}
								/>
								<Button className="p-button-info p-button-text clear-button" label={t('invitation.clear')} icon="pi pi-times" onClick={() => setSelectedCustomers([])} />
							</span>
						</div>
					}
				</>
			</Card>
			<DataTable header={header} value={data} lazy
				id="invitations_dataTable"
				selection={selectedCustomers}
				loading={loadingTable}
				onSelectionChange={e => setSelectedCustomers(e.value)}
				className="p-datatable-responsive-table"
				responsiveLayout="stack" breakpoint="1050px">
				<Column selectionMode="multiple"
					style={defaultCellStyles}
					bodyClassName="table-checkbox-header"
					headerStyle={defaultHederStyles} ></Column>
				{columns.map(({ field, body, header, sort, style, className }, i) =>
					<Column className={className} key={i} field={field} body={body} header={header}
						alignHeader="center"
						style={{ ...defaultCellStyles, ...style }}
						headerStyle={defaultHederStyles} sortable={sort} />)}
			</DataTable>
			<Paginator rows={data?.length === 0 ? 0 : state.size} totalRecords={!state.isFiltered ? selfSignupCounts : invitationCounts} first={state.first} onPageChange={onPageChange} ></Paginator>
		</>
	)
}
export default (Invitations);