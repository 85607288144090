import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import "../Styles/components/AppTopbar.scss";
import DefMembershipActions from "../Redux/actions/defMembership-actions";
import ProfileActions from "../Redux/actions/profile-actions";
import BusinessActions from "../Redux/actions/business-actions"
import getMeta from "../getMeta";
import { useAuth0 } from "@auth0/auth0-react";
import "../Styles/pages/profile.scss";
import logo from "../assets/images/Reveknew_Logo_All_White.png"


let mounted = true;
const AppTopbar = ({
	onMenuButtonClick,
	onTopbarMenuButtonClick,
	onTopbarItemClick,
	topbarMenuActive,
	activeTopbarItem,
}) => {

	
	const dispatch = useDispatch();

	const { isAuthenticated,  getAccessTokenSilently } = useAuth0();

	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const profile = useSelector((state) => state.profile.profile);

	const getMembership = useCallback(() => dispatch(DefMembershipActions.defMembershipRequest()), [dispatch]);
	const getProfile = useCallback(() => dispatch(ProfileActions.profileRequest()), [dispatch]);
	const getBusiness = useCallback((businessId) => { dispatch(BusinessActions.businessRequest(businessId)) }, [dispatch])

	useEffect(() => {
		getMeta(getAccessTokenSilently, () => {
			if (mounted) {
				getProfile();
				getMembership();
			}
			if (!defMembership) {
				getMembership();
			}
			if (!profile) {
				getProfile();
			}
			if (defMembership) {
				getBusiness(defMembership.business.id)
			}
		})
		return function cleanup() {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAccessTokenSilently, getMembership, isAuthenticated, defMembership, profile]);


	return (
		<div className="layout-topbar clearfix" style={{ backgroundColor: "#D0021B", padding:'0.7rem' }}>

			<button className="layout-topbar-logo p-link">
				<img id="layout-topbar-logo" alt="babylon-layout" width='270px' src={logo} />
			</button>

		</div>
	);
};

AppTopbar.defaultProps = {
	onMenuButtonClick: null,
	onTopbarMenuButtonClick: null,
	onTopbarItemClick: null,
	topbarMenuActive: false,
	activeTopbarItem: null,
	profileMode: null,
	horizontal: null,
};

AppTopbar.prototype = {
	onMenuButtonClick: PropTypes.func.isRequired,
	onTopbarMenuButtonClick: PropTypes.func.isRequired,
	onTopbarItemClick: PropTypes.func.isRequired,
	topbarMenuActive: PropTypes.bool.isRequired,
	activeTopbarItem: PropTypes.string,
	profileMode: PropTypes.string,
	horizontal: PropTypes.bool,
};

export default AppTopbar;
