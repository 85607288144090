import { call, put } from "redux-saga/effects";
import SettlementActions from "./settlement.reducer";
import * as api from "../../../Service/api";

export function* getSettlement(action) {
  const { settlementId } = action;
  const response = yield call(api.getSettlement, settlementId);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementFailure(response.data));
  }
}

export function* getSettlements(action) {
  const { businessId, filter } = action;
  // make the call to the api

  const response = yield call(api.getAllSettlements, businessId, filter);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementAllSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementAllFailure(response.data));
  }
}

export function* getSettlementsCount(action) {
  const { businessId, filter } = action;
  // make the call to the api

  const response = yield call(api.getAllSettlementsCount, businessId, filter);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementCountSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementCountFailure(response.data));
  }
}

export function* getSettlementsStatusCount(action) {
  const { businessId, status } = action;
  // make the call to the api

  const response = yield call(api.getSettlementsStatusCount, businessId, status);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementStatusCountSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementStatusCountFailure(response.data));
  }
}

export function* getSettlementSources(action) {
  const { settlementId } = action;
  // make the call to the api

  const response = yield call(api.getSettlementSources, settlementId);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementSourcesSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementSourcesFailure(response.data));
  }
}

export function* getSettlementSourcesGroup(action) {
  const { planGroupId } = action;
  // make the call to the api

  const response = yield call(api.getSettlementSourcesGroup, planGroupId);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementSourcesGroupSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementSourcesGroupFailure(response.data));
  }
}

export function* updateSettlement(action) {
  const { settlement } = action;
  // make the call to the api

  const response = yield call(api.updateSettlement, settlement);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementUpdateSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementUpdateFailure(response.data));
  }
}

export function* createSettlement(action) {
  const { settlementRequest } = action;
  // make the call to the api

  const response = yield call(api.createSettlement, settlementRequest);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementCreateSuccess(response.data));
  } else {
    yield put(SettlementActions.settlementCreateFailure(response.data));
  }
}

export function* deleteSettlement(action) {
  const { settlementId } = action;
  // make the call to the api

  const response = yield call(api.deleteSettlement, settlementId);

  // success?
  if (response.ok) {
    yield put(SettlementActions.settlementDeleteSuccess());
  } else {
    yield put(SettlementActions.settlementDeleteFailure(response.data));
  }
}

export function* getListOfWithdrawals(action) {
  const { id } = action;
  // make the call to the api

  const response = yield call(api.getPlanSettlements, id);

  // success?
  if (response.ok) {
    yield put(SettlementActions.planSettlementsSuccess(response.data));
  } else {
    yield put(SettlementActions.planSettlementsFailure(response.data));
  }
}

export function* getStructureSettlement(action) {
  const { countryId } = action;

  //make call to api
  const response = yield call(api.getStructureSettlement, countryId);

  //success?
  if (response.ok) {
    yield put(SettlementActions.structureSettlementSuccess(response.data));
  } else {
    yield put(SettlementActions.structureSettlementFailure(response.data));
  }
}