/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Card } from "primereact/card";
import moment from "moment";
import Loading from "../../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import { useTranslation } from 'react-i18next';

function TransactionDetailScreen(props) {

  const { t } = useTranslation();

  const settlement = useSelector((state) => state.settlements.settlement);
  const defMembership = useSelector(
    (state) => state.defMembership.defMembership
  );

  const dispatch = useDispatch();
  const getMembership = useCallback(() => {
    dispatch(DefMembershipActions.defMembershipRequest());
  }, [dispatch]);

  const getSettlement = useCallback(
    (settlementId) => {
      dispatch(SettlementActions.settlementRequest(settlementId));
    },
    [dispatch]
  );

  const [state, setState] = useState({
    settlement: {},
  });

  const { id } = useParams();

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }
    getSettlement(id)
  }, [])

  useEffect(() => {
    if (settlement) {
      setState(() => {
        return { ...state, settlement: settlement };
      });
    }
  }, [settlement, getSettlement, state.settlement]);

  if (!state.settlement) {
    return <Loading />;
  }

  return (
    <div>
      {state.settlement.channel && (
        <Card>
          <h3>{t("common.name")}</h3>
          <span>{state.settlement.channel.name}</span>
          <h4>{t("common.number")}</h4>
          <span>{state.settlement.channel.identifier}</span>
          <h4>{t("common.requested_by")}</h4>
          <span>{state.settlement.channel.addedBy.name}</span>
          <h4>{t("common.channel_type")}</h4>
          <span>{state.settlement.channel.type}</span>
          <h4>{t("common.amount")}:</h4>
          <span>
            {state.settlement.channel.business.currency}{" "}
            {state.settlement.requested}
          </span>
          <h4>{t("common.status")}:</h4>
          <span>{state.settlement.status}</span>
          <h4>{t("common.date")}</h4>
          <span>{moment(state.settlement.requestedOn).format("LLLL")}</span>
        </Card>
      )}
    </div>
  );
}

export default TransactionDetailScreen;
