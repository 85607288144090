import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { PayoutChannelTypes } from '../actions/payoutchannel-actions'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOne: null,
    fetchingAll: null,
    fetchingSaved: null,
    fetchingFavourite: null,
    fetchingFiltered: null,
    updating: null,
    settingFavourite: null,
    settingUnfavourite: null,
    archiving: null,
    payoutChannel: null,
    payoutChannels: null,
    payoutChannelsSaved: null,
    payoutChannelsFavourite: null,
    filterdPayoutChannels: null,
    errorOne: null,
    errorAll: null,
    errorSaved: null,
    errorFavourite: null,
    errorSettingFavourite: null,
    errorSettingUnfavourite: null,
    errorUpdating: null,
    errorDeleting: null,
    errorArchiving: null,
    errorFetchingFiltered: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => { return { ...state, fetchingOne: true, payoutChannel: null } }

// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, payoutChannels: null } }

// request the data from an api
export const savedRequest = (state) => { return { ...state, fetchingSaved: true, payoutChannelsSaved: null } };
// request the data from an api
export const favouriteRequest = (state) => { return { ...state, fetchingFavourite: true, payoutChannelsFavourite: null } };
// request to set from an api
export const setFavouriteRequest = (state) => { return { ...state, settingFavourite: true, payoutChannelsFavourite: null } };
// request to set from an api
export const setUnfavouriteRequest = (state) => { return { ...state, settingUnfavourite: true, payoutChannelsFavourite: null } };

// request to update from an api
export const updateRequest = (state) => { return { ...state, updating: true } }
// request to delete from an api
export const deleteRequest = (state) => { return { ...state, deleting: true } }
// request to archive from an api
export const archiveRequest = (state) => { return { ...state, archiving: true } }
// request to fetch filtered payout channels from an api
export const filteredPayoutChannelsRequest = (state) => { return { ...state, fetchingFiltered: true } }

// successful api lookup for single entity
export const success = (state, action) => {
    const { payoutChannel } = action
    return { ...state, fetchingOne: false, errorOne: null, payoutChannel }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { payoutChannels } = action
    return { ...state, fetchingAll: false, errorAll: null, payoutChannels }
}
// successful api lookup for all entities
export const savedSuccess = (state, action) => {
    const { payoutChannelsSaved } = action;
    return { ...state, fetchingSaved: false, errorSaved: null, payoutChannelsSaved };
};
// successful api lookup for all entities
export const favouriteSuccess = (state, action) => {
    const { payoutChannelsFavourite } = action;
    return { ...state, fetchingFavourite: false, errorFavourite: null, payoutChannelsFavourite };
};
// successful api update
export const setFavouriteSuccess = (state, action) => {
    return { ...state, settingFavourite: false, errorSettingFavourite: null };
};
// successful api update
export const setUnfavouriteSuccess = (state, action) => {
    return { ...state, settingUnfavourite: false, errorSettingUnfavourite: null };
};
// successful api update
export const updateSuccess = (state, action) => {
    const { payoutChannel } = action
    return { ...state, updating: false, errorUpdating: null, payoutChannel }
}
// successful api delete
export const deleteSuccess = (state) => {
    return { ...state, deleting: false, errorDeleting: null, payoutChannel: null }
}
// successful api archive
export const archiveSuccess = (state) => {
    return { ...state, archiving: false, errorArchiving: null, payoutChannel: null }
}
// successful fetching filtered payout channels
export const filteredPayoutChannelsSuccess = (state, action) => {
    const { filteredPayoutChannels } = action
    return { ...state, fetchingFiltered: false, errorFetchingFiltered: null, filteredPayoutChannels }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOne: false, errorOne: error, payoutChannel: null }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, errorAll: error, payoutChannels: null }
}
export const savedFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingSaved: false, errorSaved: error, payoutChannelsSaved: null };
};
// successful api lookup for all entities
export const favouriteFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingFavourite: false, errorFavourite: error, payoutChannelsFavourite: null };
};
// successful api update
export const setFavouriteFailure = (state, action) => {
    const { error } = action;
    return { ...state, settingFavourite: false, errorSettingFavourite: error, payoutChannelFavourite: null };
};
// successful api update
export const setUnfavouriteFailure = (state, action) => {
    const { error } = action;
    return { ...state, settingUnfavourite: false, errorSettingUnfavourite: error, payoutChannelUnFavourite: null };
};
// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action
    return { ...state, updating: false, errorUpdating: error }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
    const { error } = action
    return { ...state, deleting: false, errorDeleting: error }
}

// Something went wrong deleting.
export const archiveFailure = (state, action) => {
    const { error } = action
    return { ...state, archiving: false, errorArchiving: error }
}
// Something went wrong fetching filtered payout channels.
export const filteredPayoutChannelsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingFiltered: false, errorFetchingFiltered: error, filteredPayoutChannels: null }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [PayoutChannelTypes.PAYOUT_CHANNEL_REQUEST]: request,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ALL_REQUEST]: allRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SAVED_REQUEST]: savedRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FAVOURITE_REQUEST]: favouriteRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_FAVOURITE_REQUEST]: setFavouriteRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_UNFAVOURITE_REQUEST]: setUnfavouriteRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_UPDATE_REQUEST]: updateRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_DELETE_REQUEST]: deleteRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ARCHIVE_REQUEST]: archiveRequest,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FILTERED_REQUEST]: filteredPayoutChannelsRequest,

    [PayoutChannelTypes.PAYOUT_CHANNEL_SUCCESS]: success,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ALL_SUCCESS]: allSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SAVED_SUCCESS]: savedSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FAVOURITE_SUCCESS]: favouriteSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_FAVOURITE_SUCCESS]: setFavouriteSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_UNFAVOURITE_SUCCESS]: setUnfavouriteSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_UPDATE_SUCCESS]: updateSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_DELETE_SUCCESS]: deleteSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ARCHIVE_SUCCESS]: archiveSuccess,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FILTERED_SUCCESS]: filteredPayoutChannelsSuccess,


    [PayoutChannelTypes.PAYOUT_CHANNEL_FAILURE]: failure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ALL_FAILURE]: allFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SAVED_FAILURE]: savedFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FAVOURITE_FAILURE]: favouriteFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_FAVOURITE_FAILURE]: setFavouriteFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_SET_UNFAVOURITE_FAILURE]: setUnfavouriteFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_UPDATE_FAILURE]: updateFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_DELETE_FAILURE]: deleteFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_ARCHIVE_FAILURE]: archiveFailure,
    [PayoutChannelTypes.PAYOUT_CHANNEL_FILTERED_FAILURE]: filteredPayoutChannelsFailure,

})
