import React from 'react';
import { useSelector } from 'react-redux';

const RoleAccess = ({ roles, children, allRoles = false }) => {
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const userRoleFound = roles.includes(defMembership?.role);
    if (userRoleFound || allRoles) {
        return <>{children}</>;
    } else {
        return <></>;
    }
};

export default RoleAccess;
