import React from 'react'
import { useTranslation } from "react-i18next";
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import "../../Styles/pages/report.scss"
function ChartsReport({ invitationData, paymentsData, subscriptionsData, duration, loading }) {
  const { t } = useTranslation("common");
  const RenderChart = ({ chartType, chartName, chartData }) => {
    return (
      <div className="chart card sm:col-12 lg:col-5" style={{ width: "45%", margin: "1.5%", marginLeft: "0", minWidth: "40rem" }}>
        <div className="chart-info">
          <span className="centerText" style={{ fontFamily: "RobotoCondensed-Bold", fontSize: "12px" }}> {chartName}</span>
          <span className="chart-duration">{duration}</span>
        </div>
        <div>{loading ? <div className="card flex justify-content-center">
          <ProgressSpinner style={{ width: '80px', height: '80px' }} />
        </div> :
          <Chart className="p-col-6 p-col-6" type={chartType} data={chartData} />
        }
        </div>
      </div>
    )
  }
  const data = [
    { type: "bar", title: t("subscriber.invitations"), data: invitationData },
    { type: "bar", title: t("subscriber.payments"), data: paymentsData },
    { type: "bar", title: t("subscriber.title"), data: subscriptionsData },
  ]
  return (
    <div>
      <h6 className="wallet-titles" style={{ marginBottom: "15px", marginLeft: "1.5em" }} >{t("subscriber.snapshot")}</h6>
      <div className="grid p-fluid" style={{ marginLeft: "5px" }}> </div >
      <div className="grid  p-fluid " style={{ marginLeft: "5px", }} >
        <div className='col-12' style={{ display: "flex", flexWrap: "wrap", }}>
          {data.map((item, i) => {
            return <RenderChart chartType={item.type} chartName={item.title} chartData={item.data} key={i} />
          })}
        </div>
      </div>
    </div>
  )
}
export default ChartsReport