import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../Components/Loading";

export default function Authenticate() {
	const { loginWithRedirect} = useAuth0();
	const login = () => loginWithRedirect('/');

    login();

    return <div style={{height: "100vh"}}><Loading/></div>
}
