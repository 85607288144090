import { call, put } from "redux-saga/effects";
import SelfSignupActions from "./self-signup.reducer";
import * as api from "../../../Service/api";

export function* getSelfSignup(action) {
	const { selfSignupId } = action;
	// make the call to the api
	const response = yield call(api.getSelfSignup, selfSignupId);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.selfSignupSuccess(response.data));
	} else {
		yield put(SelfSignupActions.selfSignupFailure(response.data));
	}
}

export function* resendSelfSignup(action) {
	const { id } = action;
	// make the call to the api
	const response = yield call(api.resendSelfSignup, id);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.resendSelfSignupSuccess(response.data));
	} else {
		yield put(SelfSignupActions.resendSelfSignupFailure(response.data));
	}
}

export function* resendBulkSelfSignup(action) {
	const { invitations } = action;
	// make the call to the api
	const response = yield call(api.resendBulkSelfSignup, invitations);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.resendBulkSelfSignupSuccess(response.data));
	} else {
		yield put(SelfSignupActions.resendBulkSelfSignupFailure(response.data));
	}
}

export function* getSelfSignups(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.getSelfSignups, businessId, options);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.selfSignupAllSuccess(response.data));
	} else {
		yield put(SelfSignupActions.selfSignupAllFailure(response.data));
	}
}

export function* searchInvitations(action) {
	const { businessId, filter, options } = action;
	// make the call to the api

	const response = yield call(api.searchInvitations, businessId, filter, options);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.invitationSearchSuccess(response.data));
	} else {
		yield put(SelfSignupActions.invitationSearchFailure(response.data));
	}
}

export function* getSelfSignupsCount(action) {
	const { businessId } = action;
	// make the call to the api
  
	const response = yield call(api.getCountSelfSignups, businessId);
  
	// success?
	if (response.ok) {
	  yield put(SelfSignupActions.selfSignupCountSuccess(response.data));
	} else {
	  yield put(SelfSignupActions.selfSignupCountFailure(response.data));
	}
  }

export function* getSearchCount(action) {
	const { businessId, filter } = action;
	// make the call to the api
  
	const response = yield call(api.searchInvitationsCount, businessId, filter);
  
	// success?
	if (response.ok) {
	  yield put(SelfSignupActions.searchCountSuccess(response.data));
	} else {
	  yield put(SelfSignupActions.searchCountFailure(response.data));
	}
  }

export function* getSelfSignupsFilter(action) {
	const { businessId, filter, options } = action;
	// make the call to the api
  
	const response = yield call(api.getSelfSignupsFilter, businessId, filter, options);
  
	// success?
	if (response.ok) {
	  yield put(SelfSignupActions.selfSignupFilterSuccess(response.data));
	} else {
	  yield put(SelfSignupActions.selfSignupFilterFailure(response.data));
	}
  }

export function* deleteSelfSignup(action) {
	const { selfSignupId } = action;
	// make the call to the api
	const response = yield call(api.deleteSelfSignup, selfSignupId);

	// success?
	if (response.ok) {
		yield put(SelfSignupActions.selfSignupDeleteSuccess());
	} else {
		yield put(SelfSignupActions.selfSignupDeleteFailure(response.data));
	}
}