import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../Styles/components/Share.scss";

const ShareUrl = (props) => {
  const { t } = useTranslation("common");

  const {
    visible,
    setShareDialog,
    sharedTier: { plan, shortUrl },
  } = props;

  const [state, setState] = useState({
    copied: false,
  });

  useEffect(() => {
    if (!visible) {
      setState((state) => ({ ...state, copied: false }));
    }
  }, [visible]);

  return (
    <>
      <Dialog
        header={t("share.plan_title")}
        visible={visible}
        style={{ width: "40vw", height: "14rem" }}
        onHide={setShareDialog}
        className="tier_dialog"
        id="tier_dialog"
      >
        <div style={{ padding: "15px" }}>
          <p style={{ marginLeft: "0px" }} className="share-title">
            {t("share.plan_hint_box")}
          </p>
          <div className="planShare-box" style={{ padding: "4px" }}>
            <span>
              <span>{plan}</span>
              <p>{shortUrl}</p>
            </span>
            <Button
              label={state.copied ? "Copied" : "Copy"}
              className="copy_btn"
              onClick={() => {
                const textToCopy = `${plan}: ${shortUrl}`;
                navigator.clipboard.writeText(textToCopy);
                setState((state) => ({ ...state, copied: true }));
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ShareUrl;
