import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
	planReportRequest: ["businessId", "options"],
	planReportChartRequest: ["businessId", 'planGroupType', 'options'],
	transactionReportRequest: ["businessId", "options"],
	transactionReportChartRequest: ["businessId", 'planGroupType', 'options'],

	planReportSuccess: ["planReport"],
	planReportChartSuccess: ["planReportChart"],
	transactionReportSuccess: ["transactionReport"],
	transactionReportChartSuccess: ["transactionReportChart"],

	planReportFailure: ["error"],
	planReportChartFailure: ["error"],
	transactionReportFailure: ["error"],
	transactionReportChartFailure: ["error"]
});
export const PlanReportTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
	fetching: false,
	fetchingChart: false,
	fetchingTransactionReport: false,
	fetchingTransactionChartReport: false,

	planReport: null,
	planReportChart: null,
	transactionReport: null,
	transactionReportChart: null,

	error: null,
	errorChart: null,
	errorFetchingTransactionReport: null,
	errorFetchingTransactionReportChart: null,

});
/* ------------- Reducers ------------- */
// request the data from an api
export const request = (state) => {
	return { ...state, fetching: true, planReport: null, error: null };
};
// request the data from an api
export const chartRequest = (state) => {
	return { ...state, fetchingChart: true, planReportChart: null, errorChart: null };
};
// request the data from an api
export const transactionReportRequest = (state) => {
	return { ...state, fetchingTransactionReport: true, transactionReport: null, errorFetchingTransactionReport: null };
};
// request the data from an api
export const transactionReportChartRequest = (state) => {
	return { ...state, fetchingTransactionChartReport: true, transactionReportChart: null, errorFetchingTransactionReportChart: null };
};
// successful api lookup for single entity
export const success = (state, action) => {
	const { planReport } = action;
	return { ...state, fetching: false, error: null, planReport };
};
// successful api planChart
export const chartSuccess = (state, action) => {
	const { planReportChart } = action;
	return { ...state, fetchingChart: false, errorChart: null, planReportChart };
};
// successful api lookup for single entity
export const transactionReportSuccess = (state, action) => {
	const { transactionReport } = action;
	return { ...state, fetchingTransactionReport: false, errorFetchingTransactionReport: null, transactionReport };
};
// successful api planChart
export const transactionReportChartSuccess = (state, action) => {
	const { transactionReportChart } = action;
	return { ...state, fetchingTransactionChartReport: false, errorFetchingTransactionReportChart: null, transactionReportChart };
};
// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetching: false, error: error, planReport: null };
};
export const chartFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingChart: false, errorChart: error, planReportChart: null };
};
export const transactionReportFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingTransactionReport: false, errorFetchingTransactionReport: error, transactionReport: null };
};
// successful api planChart
export const transactionReportChartFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingTransactionChartReport: false, errorFetchingTransactionReportChart: error, transactionReportChart: null };
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
	[Types.PLAN_REPORT_REQUEST]: request,
	[Types.PLAN_REPORT_CHART_REQUEST]: chartRequest,
	[Types.TRANSACTION_REPORT_CHART_REQUEST]: transactionReportChartRequest,
	[Types.TRANSACTION_REPORT_REQUEST]: transactionReportRequest,

	[Types.PLAN_REPORT_SUCCESS]: success,
	[Types.PLAN_REPORT_CHART_SUCCESS]: chartSuccess,
	[Types.TRANSACTION_REPORT_CHART_SUCCESS]: transactionReportChartSuccess,
	[Types.TRANSACTION_REPORT_SUCCESS]: transactionReportSuccess,

	[Types.PLAN_REPORT_FAILURE]: failure,
	[Types.PLAN_REPORT_CHART_FAILURE]: chartFailure,
	[Types.TRANSACTION_REPORT_CHART_FAILURE]: transactionReportChartFailure,
	[Types.TRANSACTION_REPORT_FAILURE]: transactionReportFailure,
});