import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
//primereact imports
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
// imports from redux
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import MembershipInviteActions from "../../Redux/transition/membership-invite/membership-invite.reducer"
//imports from other libraries and styles
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../Styles/pages/colleagues.scss";
import CustomDataTable from "../../Components/CustomDataTable";

const ColleaguesInvited = () => {

  const defMembership = useSelector((state) => state.defMembership.defMembership)
  const fetchingAllMembershipInvites = useSelector((state) => state.membershipInvites.fetchingAll)
  const allInvites = useSelector((state) => state.membershipInvites.membershipInvites)
  const fetchingAllMembership = useSelector((state) => state.memberships.fetchingAll)
  const cancel = useSelector((state) => state.membershipInvites.deleting)
  const cancelError = useSelector((state) => state.membershipInvites.errorDeleting);
  const resending = useSelector((state) => state.membershipInvites.resending);
  const errorResending = useSelector((state) => state.membershipInvites.errorResending);

  const dispatch = useDispatch()
  const getDefMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
  const getMembershipInvites = useCallback((businessId, options) => { dispatch(MembershipInviteActions.membershipInviteAllRequest(businessId, options)) }, [dispatch])
  const deleteMembership = useCallback((membershipId) => dispatch(MembershipInviteActions.membershipInviteDeleteRequest(membershipId)), [dispatch]);
  const resendMembershipInvite = useCallback((membershipInviteId) => dispatch(MembershipInviteActions.membershipInviteResendRequest(membershipInviteId)), [dispatch])

  const [state, setState] = useState({
    size: 15,
    first: 0,
    resendingId: null,
    resending: null,
    deletingId: null,
    deleting: false,
    visible: false,
    success: false,
    count: null,
    data: null
  })

  const { t } = useTranslation("common");
  const [invitedMembers, setInvitedMembers] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    if (!defMembership) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (allInvites) {
      setInvitedMembers(allInvites);
    }
    if (!cancel && state.deleting && cancelError) {
      showDeleteError()
      setState((state) => { return { ...state, deleting: false, deletingId: null } })
    }
    if (!cancel && state.deleting && !cancelError) {
      showDeleteSuccess()
      setState((state) => { return { ...state, deleting: false, deletingId: null } })
    }
    if (!resending && state.resending && errorResending) {
      showResendError()
      setState((state) => { return { ...state, resending: false, resendingId: null } })
    }
    if (!resending && state.resending && !errorResending) {
      showResendSuccess()
      setState((state) => { return { ...state, resending: false, resendingId: null } })
    }
    function cleanup() { }
    return cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defMembership, getDefMembership, allInvites, cancel, cancelError, resending, errorResending,
    getMembershipInvites, state.deleting, state.resending]);

  //the loadingTable variable here controls the spinner in the table
  const loadingTable = fetchingAllMembership || !defMembership || fetchingAllMembershipInvites

  const showWarn = () => {
    toast.current.show({ severity: 'warn', summary: t('colleague.warning_header'), detail: t('colleague.warning_message'), life: 3000 });
  }
  const showDeleteError = () => {
    toast.current.show({ severity: 'error', summary: t("colleague.error_summary"), detail: t("colleague.error_message"), life: 3000 });
  }
  const showDeleteSuccess = () => {
    toast.current.show({ severity: 'success', code: 'deleted', detail: t("invitation.delete_detail"), life: 3000 });
  }
  const showResendError = () => {
    toast.current.show({ severity: 'error', summary: t("colleague.error_summary"), detail: t("colleague.error_message"), life: 3000 });
  }
  const showResendSuccess = () => {
    toast.current.show({ severity: 'success', detail: t("colleague.success_message"), life: 3000 });
  }

  const accept = () => {
    const checks = invitedMembers.map(({ status }) => status.includes('ACCEPTED'))
    if (checks.includes(true)) {
      showWarn()
    }
    else {
      resendMembershipInvite(state.resendingId)
      setVisible(false)
      setState((state) => ({ ...state, resending: true, }))
    }
  }

  const acceptDelete = () => {
    deleteMembership(state.deletingId)
    setDeleteVisible(false)
    setState((state) => ({ ...state, deleting: true, }))
  }

  const renderHeader = () => {
    return (
      <>
        <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={t('colleague.resend_confirmation')}
          header={t('colleague.confirmation_header')} accept={accept} reject={() => setVisible(false)} />
        <ConfirmDialog visible={deleteVisible} onHide={() => setDeleteVisible(false)} message={t('colleague.delete_confirmation')}
          header={t('colleague.confirmation_header')} accept={acceptDelete} reject={() => setDeleteVisible(false)} />
        <Toast ref={toast} />
        <Toast ref={toast} onRemove={(message) => {
          if (message.code === 'deleted') {
            setInvitedMembers([])
            getMembershipInvites(defMembership.business.id, { page: 0, size: state.size, sort: state.sort })
          }
        }} />
      </>
    );
  }

  const header = renderHeader();

  const nameBodyTemplate = (data) => {
    if (data.firstName + "  " + data.lastName) {
      return <p className="table_data">{data.firstName + "  " + data.lastName}</p>
    } else {
      return "--"
    }
  };

  const emailBodyTemplate = (data) => {
    if (data.email) {
      return <p className="table_data">{data.email}</p>
    } else {
      return "--"
    }
  };

  const roleBodyTemplate = (data) => {
    if (data.inviteRole) {
      return <p className="table_data">{data.inviteRole}</p>
    } else {
      return "--"
    }
  };

  const invitedOnBodyTemplate = (data) => {
    if (data.invitedOn) {
      return <p className="table_data"> {moment(data.invitedOn).format("ll")}</p>
    } else {
      return "--"
    }
  }

  const actionTemplate = (data) => {
    return (
      <div className="colleagues-action">
        <Button label={t("colleague.resend_invitation")}
          id="colleague-resend-button"
          className="p-button-outlined p-button-secondary"
          /*A check for if resendingId and element id are the same to control loading(spinner) and disable the resend button*/
          loading={resending && state.resendingId === data.id}
          disabled={resending && state.resendingId === data.id}
          onClick={() => {
            resendMembershipInvite(data.id)
            /*save resendingId to state to be used as check for resend button spinner and disable*/
            setState((state) => ({ ...state, resending: true, resendingId: data.id }))
          }}
        />

        <Button label={t("colleague.cancel_invitation")} id="colleague-cancel-button"
          className="p-button-outlined p-button-secondary"
          /*A check for if deletingId and element id are the same to control loading(spinner) and disable the cancel button*/
          loading={cancel && state.deletingId === data.id}
          disabled={cancel && state.deletingId === data.id}
          onClick={() => {
            setDeleteVisible(true)
            setState((state) => ({ ...state, deletingId: data.id }))
          }} />
      </div>
    )
  }

  const columns = [
    { field: 'name', body: nameBodyTemplate, header: () => { return <p className="table_header">{t("colleague.name")}</p> }, sort: true },
    { field: "email", header: () => { return <p className="table_header">{t("colleague.email")}</p> }, body: emailBodyTemplate, sort: true },
    { field: "proposed_role", body: roleBodyTemplate, header: () => { return <p className="table_header">{t("colleague.proposed_role")}</p> }, sort: true },
    { field: "invite_on", body: invitedOnBodyTemplate, header: () => { return <p className="table_header">{t("colleague.invite_on")}</p> }, sort: true },
    { field: "actions", body: actionTemplate },
  ]

  return (
    <>
     
      <CustomDataTable columns={columns} value={invitedMembers ? invitedMembers : []} header={header} loading={loadingTable} rows={10} paginator>
      </CustomDataTable>
    </>
  );
};
export default (ColleaguesInvited);
