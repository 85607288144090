import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Paginator } from "primereact/paginator";
import CustomDataTable from "../../Components/CustomDataTable";
import { Card } from "primereact/card";

function Import() {
    const { t } = useTranslation("common");

    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [state, setState] = useState({
        enterpriseCustomers: null,
        importedCustomers: [],
        uploadedCustomers: null,
        entries: null,
        file: {},
        count: null,
        page: 0,
        sort: "importedOn,desc",
        size: 20,
        loading: true,
        uploading: false,
        applying: false,
        selectedId: null,
        openDailog: false,
        appliedOn: null,
    });

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessCustomers = useSelector((state) => state.businessCustomers.businessCustomers);
    const uploadedCustomers = useSelector((state) => state.businessCustomers.uploadCustomer);
    const uploadedCustomersError = useSelector((state) => state.businessCustomers.uploadError);
    const uploadingCustomers = useSelector((state) => state.businessCustomers.uploading);
    const importedCustomers = useSelector((state) => state.businessCustomers.importedCustomer);
    const importedCustomersCount = useSelector((state) => state.businessCustomers.importedCustomerCount);
    const entries = useSelector((state) => state.businessCustomers.entries);
    const fetchingEntries = useSelector((state) => state.businessCustomers.fetchingEntries);
    const applying = useSelector((state) => state.businessCustomers.applying);
    const applySuccess = useSelector((state) => state.businessCustomers.appliedImport);
    const applyError = useSelector((state) => state.businessCustomers.appliedImportError);
    const fetchingImportedCustomers = useSelector((state) => state.businessCustomers.fetchingImportedCustomers);

    const dispatch = useDispatch();
    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    const getBusinessCustomers = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerAllRequest(businessId)), [dispatch]);
    const getImportedCustomers = useCallback((businessId, options) => dispatch(BusinessCustomersActions.businessCustomerImportRequest(businessId, options)), [dispatch]);
    const getImportedCustomersCount = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerImportCountRequest(businessId)), [dispatch]);
    const getEntries = useCallback((importId) => dispatch(BusinessCustomersActions.businessCustomerEntryRequest(importId)), [dispatch]);
    const applyImport = useCallback((id) => dispatch(BusinessCustomersActions.businessCustomerApplyRequest(id)), [dispatch]);
    const importBusinessCustomers = useCallback((businessId, membershipId, file) => dispatch(BusinessCustomersActions.businessCustomerUploadRequest(businessId, membershipId, file)), [dispatch]);

    const fetchAllImportedCustomers = useCallback(
        (page) => {
            getImportedCustomers(defMembership.business.id, { page, size: state.size, sort: state.sort });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [defMembership.business.id, getImportedCustomers, state.size, state.sort]
    );

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getImportedCustomersCount(defMembership.business.id);
            getBusinessCustomers(defMembership.business.id);
            fetchAllImportedCustomers(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (businessCustomers) {
            setState((state) => ({ ...state, enterpriseCustomers: businessCustomers }));
        }
        if (importedCustomers) {
            setState((state) => ({ ...state, importedCustomers: importedCustomers }));
        }
        if (importedCustomersCount) {
            setState((state) => ({ ...state, count: importedCustomersCount }));
        }
        if (uploadedCustomers) {
            setState((state) => ({ ...state, uploadedCustomers: uploadedCustomers }));
        }
        if (entries) {
            setState((state) => ({ ...state, entries: entries }));
        }
    }, [businessCustomers, importedCustomers, uploadedCustomers, entries, importedCustomersCount]);

    useEffect(() => {
        if (!uploadingCustomers && uploadedCustomers && state.uploading) {
            setDisplayBasic(true);
            setState((state) => ({ ...state, uploading: false }));
            fileUploadRef.current = null;
        }

        if (!uploadingCustomers && uploadedCustomersError?.message === "Cannot process CSV file supplied" && state.uploading) {
            showError(t("business_customers.user_upload_error"), "user");
        } else if (!uploadingCustomers && uploadedCustomersError && state.uploading) {
            showError(t("business_customers.upload_error"));
        }

        if (!applying && applySuccess && state.applying) {
            showSuccess();
        }
        if (!applying && applyError && state.applying) {
            showError(t("business_customers.apply_error"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadingCustomers, uploadedCustomers, state.uploading, applying, state.applying, applySuccess, applyError, uploadedCustomersError]);

    if (fetchingImportedCustomers) {
        return <Loading />;
    }

    const showSuccess = () => {
        toast.current.show({ severity: "success", summary: t("business_customers.success_summary"), detail: t("business_customers.apply_success"), life: 3000 });
    };

    const showError = (message, user) => {
        toast.current.show({ severity: "error", user: user, summary: t("business_customers.error_summary"), detail: message, life: 3000 });
    };

    const handleUpload = () => {
        let file = state.file;

        let formData = new FormData();

        formData.append("file", file, file.name);

        importBusinessCustomers(defMembership.business.id, defMembership.id, formData);

        setState((state) => ({ ...state, uploading: true }));
    };

    const handleSelect = (e) => {
        getEntries(e.value.id);
        setDisplayBasic(true);
        setState((state) => ({ ...state, openDailog: true, appliedOn: e.value.appliedOn, selectedId: e.value.id }));
    };

    const applyId = state.openDailog ? state.selectedId : state.uploadedCustomers?.id;

    const handleApply = () => {
        if (state.uploadedCustomers?.appliedOn === null || state.appliedOn === null) {
            applyImport(applyId);
            setState((state) => ({ ...state, applying: true, openDailog: false }));
        } else {
            setDisplayBasic(false);
            setState((state) => ({ ...state, openDailog: true }));
        }
    };

    const onHide = () => {
        setDisplayBasic(false);
        setState((state) => ({ ...state, openDailog: false }));
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label={state.uploadedCustomers?.appliedOn === null || state.appliedOn === null ? t("business_customers.apply") : t("business_customers.close")}
                    loading={applying}
                    disabled={!entryData}
                    onClick={() => {
                        handleApply();
                    }}
                    autoFocus
                />
            </div>
        );
    };

    const boolenConvert = (data) => {
        if (data) {
            return t("common.true");
        } else {
            return t("common.false");
        }
    };

    const onPageChange = (e) => {
        fetchAllImportedCustomers(e.page);
        setState({ ...state, first: e.first });
    };

    const entryData = state.openDailog ? state.entries : state.uploadedCustomers?.entries;
    let columnsEntries = [
        { field: "firstName", header: t("common.first_name"), },
        { field: "lastName", header: t("common.last_name"), },
        { field: "phoneNo", header: t("common.phone_no"), },
        { field: "customerNum", header: t("business_customers.customer_num"), },
        {
            field: "phoneNoValid",
            header: t("business_customers.phone_valid"),
            body: (data) => (boolenConvert(data.phoneNoValid)),
            sort: true,
        }, {
            field: "imported",
            header: t("business_customers.imported"),
            body: (data) => (boolenConvert(data.imported)),
            sort: true,
        },
    ]
    let columnsImports = [
        { field: "externalId", header: t("business_customers.id"), },
        {
            field: "importedOn", header: t("business_customers.imported_on"), body: (data) => moment(data.importedOn).format("MMMM Do YYYY")
        },
        { field: "importedCount", header: t("business_customers.import_count"), body: (data) => (data.importedCount ? data.importedCount : "--"), },
        { field: "importedBy", header: t("business_customers.imported_by"), body: (data) => (data.importedBy ? t(`business_customers.${data.importedBy.role.toLowerCase()}`) : "--"), },
        {
            field: "appliedOn", header: t("business_customers.applied_on"), body: (data) => data.appliedOn ? moment(data.appliedOn).format("MMMM Do YYYY") : "--"
        },

    ]
    return (
        <>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        setDisplayBasic(false);
                        setState((state) => ({ ...state, applying: false }));
                        getImportedCustomers(defMembership.business.id, { page: state.page, size: state.size, sort: state.sort });
                    }

                    if (message.user === "user") {
                        fileUploadRef.current.clear();
                    }
                }}
            />
            <Dialog header={t("business_customers.import_entries")} visible={displayBasic} style={{ width: "50vw" }} footer={renderFooter("displayBasic")} onHide={() => onHide("displayBasic")}>
                {entryData && !fetchingEntries ? (
                    <CustomDataTable columns={columnsEntries} value={entryData}>
                    </CustomDataTable>
                ) : (
                    <>{<Loading />}</>
                )}
            </Dialog>
            <Card className="activity-actions p-grid p-fluid activity-actions-container search">
                {uploadingCustomers && <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>}
                {defMembership?.role === ("OWNER" || "MANAGER") && (
                    <FileUpload
                        ref={fileUploadRef}
                        name="demo[]"
                        onSelect={(e) => {
                            setState((state) => ({ ...state, file: e.files[0] }));
                        }}
                        customUpload
                        uploadHandler={handleUpload}
                        accept=".csv"
                        emptyTemplate={<p className="m-0"></p>}
                    />
                )}
            </Card>

            {state.importedCustomers && (
                <div>
                    <CustomDataTable
                        value={state.importedCustomers}
                        sortField="importedOn"
                        sortOrder={-1}
                        paginator={false}
                        selectionMode="single"
                        columns={columnsImports}
                        onSelectionChange={(e) => {
                            handleSelect(e);
                        }}
                    >
                    </CustomDataTable>
                    <Paginator rows={state.size} totalRecords={state.count} first={state.first} onPageChange={onPageChange}></Paginator>
                </div>
            )}
        </>
    );
}
export default Import;
