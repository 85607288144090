import { call, put } from 'redux-saga/effects'
import * as api from "../../../Service/api";
import MembershipActions from './membership.reducer'

export function* getMembership(action) {
  const { membershipId } = action
  // make the call to the api
  const response = yield call(api.getMembership, membershipId)

  // success?
  if (response.ok) {
    response.data = mapDateFields(response.data)
    yield put(MembershipActions.membershipSuccess(response.data))
  } else {
    yield put(MembershipActions.membershipFailure(response.data))
  }
}

export function* getMemberships(action) {
  const { businessId, options } = action
  // make the call to the api
  const response = yield call(api.getMemberships, businessId, options)

  // success?
  if (response.ok) {
    yield put(MembershipActions.membershipAllSuccess(response.data))
  } else {
    yield put(MembershipActions.membershipAllFailure(response.data))
  }
}

export function* updateMembership(action) {
  const { membership } = action
  // make the call to the api
  const idIsNotNull = !!membership.id
  const response = yield call(idIsNotNull ? api.updateMembership : api.createMembership, membership)

  // success?
  if (response.ok) {
    response.data = mapDateFields(response.data)
    yield put(MembershipActions.membershipUpdateSuccess(response.data))
  } else {
    yield put(MembershipActions.membershipUpdateFailure(response.data))
  }
}

export function* deleteMembership(action) {
  const { membershipId } = action
  // make the call to the api

  const response = yield call(api.deleteMembership, membershipId)

  // success?
  if (response.ok) {
    yield put(MembershipActions.membershipDeleteSuccess())
  } else {
    yield put(MembershipActions.membershipDeleteFailure(response.data))
  }
}
function mapDateFields(data) {
  if (data.joinedOn) {
    data.joinedOn = new Date(data.joinedOn)
  }
  if (data.exitedOn) {
    data.exitedOn = new Date(data.exitedOn)
  }
  return data
}
