import React from "react";
import { Button } from "primereact/button";
import { useAuth0 } from "@auth0/auth0-react";
import ImplicitCallback from "./Authentication";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/Reveknew_Logo_Colour_and_Black.png";

export default function Login() {
	const { t } = useTranslation("common");
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
	const login = () => loginWithRedirect('/');;

	if (isLoading) {
		return <div>{t("login.loading_auth")}</div>;
	}
	if (!isAuthenticated) {
		return (
			<div className="login-body">
				<div className="login-panel"></div>

				<div className="login-content" style={{ marginTop: "5rem" }}>
					<img src={logo} alt="babylon-layout" />
					<h1>
						<span>{t("login.login_text")} </span>{t("login.reveknew_text")}
					</h1>
					<p>{t("login.welcome_text")}</p>

					<Button label={t("login.login_label")} onClick={login} />
				</div>
			</div>
		);
	} else if (isAuthenticated) {
		return (
			<ImplicitCallback />
		);
	}
}