import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	selfSignupRequest: ["selfSignupId"],
	resendSelfSignupRequest: ["id"],
	resendBulkSelfSignupRequest: ["invitations"],
	selfSignupAllRequest: ["businessId", "options"],
	selfSignupUpdateRequest: ["selfSignup"],
	invitationSearchRequest: ["businessId", "filter", "options"],
	selfSignupCountRequest: ["businessId"],
	searchCountRequest: ["businessId", "filter"],
	selfSignupFilterRequest: ["businessId", "filter", "options"],
	selfSignupDeleteRequest: ["selfSignupId"],

	selfSignupSuccess: ["selfSignup"],
	resendSelfSignupSuccess: ["signUpResend"],
	resendBulkSelfSignupSuccess: ["bulkSignUpResend"],
	selfSignupAllSuccess: ["selfSignups"],
	selfSignupUpdateSuccess: ["selfSignup"],
	invitationSearchSuccess: ["invitations"],
	selfSignupCountSuccess: ["count"],
	searchCountSuccess: ["searchCount"],
	selfSignupFilterSuccess: ["filter"],
	selfSignupDeleteSuccess: [],

	selfSignupFailure: ["error"],
	resendSelfSignupFailure: ["error"],
	resendBulkSelfSignupFailure: ["error"],
	selfSignupAllFailure: ["error"],
	selfSignupUpdateFailure: ["error"],
	invitationSearchFailure: ["error"],
	selfSignupCountFailure: ["error"],
	searchCountFailure: ["error"],
	selfSignupFilterFailure: ["error"],
	selfSignupDeleteFailure: ["error"],
});

export const SelfSignupTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingOne: null,
	resending: false,
	resendingAll: false,
	fetchingAll: false,
	fetchingCount: false,
	fetchingSearchCount: false,
	fetchingFilter: null,
	updating: null,
	deleting: false,
	fetchingInvitation: false,
	fetchingAllInvitations:false,

	count:null,
	searchCount:null,
	filter:null,
	// deleting: null,
	selfSignup: null,
	signUpResend: null,
	bulkSignUpResend: null,
	invitations: null,
	selfSignups: null,
	allInvitations:null,

	errorOne: null,
	errorResend: null,
	errorResendBulk: null,
	errorAll: null,
	errorUpdating: null,
	errorInvitation: null,
	errorCount: null,
	errorSearchCount: null,
	errorFilter: null,
	errorDeleting: null,
	errorAllInvitations:null,
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
	return { ...state, fetchingOne: true, selfSignup: null };
};

// request the data from an api
export const resendRequest = (state) => {
	return { ...state, resending: true, signUpResend: null };
};

// request the data from an api
export const bulkResendRequest = (state) => {
	return { ...state, resendingAll: true, bulkSignUpResend: null };
};

// request the data from an api
export const allRequest = (state) => {
	return { ...state, fetchingAll: true, selfSignups: null };
};
export const allInvitationRequest = (state) => {
	return { ...state, fetchingAllInvitations: true, allInvitations: null };
};
// request to update from an api
export const updateRequest = (state) => {
	return { ...state, updating: true };
};

export const invitationRequest = (state) => {
	return {
		fetchingInvitation: true,
		invitations: null,
	};
};

//request to get count from api
export const countRequest = (state) => {
	return {...state, fetchingCount:true, count: null}
}
//request to get count from api
export const searchCountRequest = (state) => {
	return {...state, fetchingSearchCount:true, searchCount: null}
}

//request to get filter from api
export const filterRequest = (state) => {
	return {...state, fetchingFilter:true, filter: null}
}

// request to delete from an api
export const deleteRequest = (state) => {
	return { ...state, deleting: true, errorDeleting: null };
};

// successful api lookup for single entity
export const success = (state, action) => {
	const { selfSignup } = action;
	return { ...state, fetchingOne: false, errorOne: null, selfSignup };
};

// successful api lookup for single entity
export const resendSuccess = (state, action) => {
	const { signUpResend } = action;
	return { ...state, resending: false, errorResend: null, signUpResend };
};
export const bulkResendSuccess = (state, action) => {
	const { bulkSignUpResend } = action;
	return { ...state, resendingAll: false, errorResendBulk: null, bulkSignUpResend };
};


// successful api lookup for all entities
export const allSuccess = (state, action) => {
	const { selfSignups } = action;
	return { ...state, fetchingAll: false, errorAll: null, selfSignups };
};
export const allInvitationsSuccess = (state, action) => {
	const { selfSignups } = action;
	return { ...state, fetchingAllInvitations: false, errorAllInvitations: null, allInvitations:selfSignups };
};
// successful api update
export const updateSuccess = (state, action) => {
	const { selfSignup } = action;
	return { ...state, updating: false, errorUpdating: null, selfSignup };
};

export const invitationSuccess = (state, action) => {
	const { invitations } = action;
	return {
		...state,
		fetchingInvitation: false,
		errorInvitation: null,
		invitations,
	};
};

// successful api count
export const countSuccess = (state, action) => {
	const { count } = action;
	return { ...state, fetchingCount: false, errorCount: null, count }
  }
export const searchCountSuccess = (state, action) => {
	const { searchCount } = action;
	return { ...state, fetchingSearchCount: false, errorSearchCount: null, searchCount }
  }

// successful api filter
export const filterSuccess = (state, action) => {
	const { filter } = action;
	return { ...state, fetchingFilter: false, errorFilter: null, filter }
  }

// successful api delete
export const deleteSuccess = (state) => {
	return { ...state, deleting: false, errorDeleting: null, selfSignup: null };
};

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingOne: false, errorOne: error, selfSignup: null };
};

// Something went wrong fetching a single entity.
export const resendFailure = (state, action) => {
	const { error } = action;
	return { ...state, resending: false, errorResend: error, signUpResend: null };
};

// Something went wrong fetching a single entity.
export const bulkResendFailure = (state, action) => {
	const { error } = action;
	return { ...state, resendingAll: false, errorResendBulk: error, bulkSignUpResend: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingAll: false, errorAll: error, selfSignups: null }; 
};
export const allInvitationsFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingAllInvitations: false, errorAllInvitations: error, allInvitations: null }; 
};
// Something went wrong updating.
export const updateFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		updating: false,
		errorUpdating: error,
		selfSignup: state.selfSignup,
	};
};

export const invitationFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingInvitation: false,
		errorInvitation: error,
		invitations: null,
	};
};


// Something went wrong counting
export const countFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingCount: false, errorCount: error, count: null }
  }
export const searchCountFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingSearchCount: false, errorSearchCount: error, searchCount: null }
  }

// Something went wrong filtering
export const filterFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingFilter: false, errorFilter: error, filter: null }
  }


// Something went wrong deleting.
export const deleteFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		deleting: false,
		errorDeleting: error,
		selfSignup: state.selfSignup,
	};
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SELF_SIGNUP_REQUEST]: request,
	[Types.RESEND_SELF_SIGNUP_REQUEST]: resendRequest,
	[Types.RESEND_BULK_SELF_SIGNUP_REQUEST]: bulkResendRequest,
	[Types.SELF_SIGNUP_ALL_REQUEST]: allRequest,
	[Types.SELF_SIGNUP_ALL_REQUEST]: allInvitationRequest,
	[Types.SELF_SIGNUP_UPDATE_REQUEST]: updateRequest,
	[Types.INVITATION_SEARCH_REQUEST]: invitationRequest,
	[Types.SELF_SIGNUP_COUNT_REQUEST]: countRequest,
	[Types.SEARCH_COUNT_REQUEST]: searchCountRequest,
	[Types.SELF_SIGNUP_FILTER_REQUEST]: filterRequest,
	[Types.SELF_SIGNUP_DELETE_REQUEST]: deleteRequest,

	[Types.SELF_SIGNUP_SUCCESS]: success,
	[Types.RESEND_SELF_SIGNUP_SUCCESS]: resendSuccess,
	[Types.RESEND_BULK_SELF_SIGNUP_SUCCESS]: bulkResendSuccess,
	[Types.SELF_SIGNUP_ALL_SUCCESS]: allSuccess,
	[Types.SELF_SIGNUP_ALL_SUCCESS]: allInvitationsSuccess,
	[Types.SELF_SIGNUP_UPDATE_SUCCESS]: updateSuccess,
	[Types.INVITATION_SEARCH_SUCCESS]: invitationSuccess,
	[Types.SELF_SIGNUP_COUNT_SUCCESS]: countSuccess,
	[Types.SEARCH_COUNT_SUCCESS]: searchCountSuccess,
	[Types.SELF_SIGNUP_FILTER_SUCCESS]: filterSuccess,
	[Types.SELF_SIGNUP_DELETE_SUCCESS]: deleteSuccess,

	[Types.SELF_SIGNUP_FAILURE]: failure,
	[Types.RESEND_SELF_SIGNUP_FAILURE]: resendFailure,
	[Types.RESEND_BULK_SELF_SIGNUP_FAILURE]: bulkResendFailure,
	[Types.SELF_SIGNUP_ALL_FAILURE]: allFailure,
	[Types.SELF_SIGNUP_ALL_FAILURE]: allInvitationsFailure,
	[Types.SELF_SIGNUP_UPDATE_FAILURE]: updateFailure,
	[Types.INVITATION_SEARCH_FAILURE]: invitationFailure,
	[Types.SELF_SIGNUP_COUNT_FAILURE]: countFailure,
	[Types.SEARCH_COUNT_FAILURE]: searchCountFailure,
	[Types.SELF_SIGNUP_FILTER_FAILURE]: filterFailure,
	[Types.SELF_SIGNUP_DELETE_FAILURE]: deleteFailure,
});