import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import validator from 'validator';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from 'primereact/inputswitch';
import PhoneInput from "react-phone-number-input";
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import "./../../Styles/pages/transactions.scss";
import { useSelector } from "react-redux";

export const NewRecipient = ({ setFieldValue, values, state, setState, touched, errors }) => {

    const { t } = useTranslation("common");
    const [error, setError] = useState(false)
    const [wrongChar, setWrongChar] = useState(false)
    const [field, setField] = useState(0)

    const defMembership = useSelector((state) => state.defMembership.defMembership);

    const isNonNumeric = (input) => {
        const numericChars = '0123456789';
        for (let char of input) {
            if (numericChars.indexOf(char) === -1) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const inputValue = values.newChannel.identifier;
        if (values.newChannel.systemBank && values.newChannel.systemBank.type === "BANK") {
            setWrongChar(isNonNumeric(inputValue));
            setError(defMembership.business.country.name === "Ghana" && inputValue.length > 13);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.newChannel.systemBank, values.newChannel.identifier]);

    const handleChange = (e, value) => {
        setFieldValue("newChannel.identifier", e.target.value);
    };

    return (
        <div className="p-col payout_channel-new">
            <h2 className="payout_channel-title">{t("recipient.create")}</h2>
            <div className="payout_channel-new-input" style={{ display: "flex" }}>
                <label className="payout_channel-new-input-label">{t("recipient.payment_channel")}</label>
                <div className="payout_channel-new-input-container">
                    <Dropdown
                        value={values.newChannel.systemBank}
                        options={[...state.mobileBanks, ...state.banks]}
                        onChange={(e) => {
                            setField(1)
                            setFieldValue("newChannel.systemBank", e.value);
                            setFieldValue("newChannel.isMomo", e.value.type === "MOBILE");
                            setFieldValue("newChannel.isBank", e.value.type === "BANK");
                            setFieldValue("newChannel.identifier", "");
                        }}
                        optionLabel="name"
                        id="systemBank"
                        filter
                        filterInputAutoFocus
                        placeholder={t("recipient.select_type")}
                        style={{ border: (errors.newChannel && touched.newChannel && errors.newChannel.systemBank && errors.newChannel.systemBank.type && field === 0) ? '1px solid #EF5350' : '' }}
                        className="payout_channel-dropdown payout-width" />
                </div>
                <div style={{ marginTop: '0.17rem', marginLeft: '0.4rem' }}>
                    {errors.newChannel && touched.newChannel ? (
                        errors.newChannel.systemBank && errors.newChannel.systemBank.type && field === 0 &&
                        <Message severity="error" className="error_message" text={errors.newChannel.systemBank.type} />
                    ) : null}
                </div>

            </div>

            {values.newChannel.isMomo ?
                <div className="field " style={{ display: "flex" }}>
                    <div className="payout_channel-new-input phone-input">
                        <label htmlFor="accNum" className="payout_channel-new-input-label">
                            {t("recipient.phone_no")}:
                        </label>
                        <PhoneInput
                            className="payout_channel-new-input-field payout-width"
                            style={{ border: (errors.newChannel && touched.newChannel && errors.newChannel.identifier) ? '1px solid #EF5350' : '' }}
                            placeholder={t("recipient.phone_no")}
                            addInternationalOption={false}
                            countries={[state.country.code]}
                            value={values.newChannel.identifier}
                            limitMaxLength
                            international={false}
                            countryCallingCodeEditable={false}
                            onChange={(val) => setFieldValue("newChannel.identifier", val)}
                            error={values.newChannel.identifier && validator.isMobilePhone(`${values.newChannel.identifier}`, 'en-GH') ? 'false' : 'true'} />
                    </div>
                    <div style={{ marginTop: '1.2rem', width: "247%", marginLeft: "0.4rem" }}>
                        {errors.newChannel && touched.newChannel ? (
                            errors.newChannel.identifier &&
                            <Message severity="error" className="error_message" text={errors.newChannel.identifier} />
                        ) : null}
                    </div>

                </div>
                :
                <div style={{ display: "flex" }}>
                    <TextField
                        label={t("recipient.recipient_no")} name="newChannel.identifier"
                        placeholder={t("recipient.account")} 
                        value={values.newChannel.identifier} 
                        keyfilter="int" 
                        maxLength={13}
                        className={`payout-width recipient_text_field`}
                        style={{ border: (error || wrongChar) ? '1px solid #EF5350' : '' }}
                        onInput={handleChange} 
                    />
                    <div style={{ marginTop: '1rem', width: "50%", marginLeft: "0.5rem" }}>
                        {(wrongChar && error) ?
                            <Message severity="error" className="error_message" text={t('recipient.recipient_account_both_error')} />
                            : (error ?
                                <Message severity="error" className="error_message" text={t('recipient.recipient_account_error')} />
                                : (wrongChar &&
                                    <Message severity="error" className="error_message" text={t('recipient.recipient_account_char_error')} />
                                )
                            )
                        }
                    </div>
                </div>
            }

            {values.newChannel.isSaved && <div style={{ display: "flex" }}>
                <TextField label={t("recipient.name")} name="newChannel.name" placeholder={t("recipient.name")} 
                style={{ border: (errors.newChannel && touched.newChannel && errors.newChannel.name) ? '1px solid #EF5350' : '' }}
                maxLength={50}
                />
                <div style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                    {errors.newChannel && touched.newChannel ? (
                        errors.newChannel.name &&
                        <Message severity="error" className="error_message" text={t('recipient.name_error')} />
                    ) : null}
                </div>
            </div>}

            <div className="payout_channel-new-input">
                <label className="payout_channel-new-input-label">{t("recipient.temporary")}</label>
                <InputSwitch
                    name="newChannel.temporary"
                    checked={values.newChannel.isSaved}
                    onChange={(e) => {
                        setFieldValue("newChannel.isSaved", e.value);
                        setFieldValue("newChannel.temporary", !e.value);
                    }} />
            </div>
            <div className="payout_channel-buttonsBar">

                <Button
                    className="payout_channel-buttonsBar-cancel-button"
                    type="reset"
                    label={t("cancel")}
                    onClick={(e) => {
                        setState((prev) => { return { ...prev, isNewChannel: false }; });
                        setFieldValue("newChannel.isSaved", false);
                        setFieldValue("newChannel.identifier", "");
                        setFieldValue("newChannel.name", "");
                        setFieldValue("newChannel.systemBank", { name: "" });
                    }} />
                <Button
                    className=" p-button-info payout_channel-buttonsBar-continue-button"
                    type="submit"
                    disabled={Object.keys(values.newChannel.systemBank).length === 0 || wrongChar || error}
                    label={t("continue")} />
            </div>
        </div>);
};
const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="field">
            <div className="payout_channel-new-input">
                {label && <label className="payout_channel-new-input-label">{label}: </label>}
                <InputText
                    className={`payout_channel-new-input-field ${meta.touched && meta.error && 'p-error'}`}
                    {...field} {...props}
                    autoComplete="on"
                     />
            </div>

        </div>
    );
};