import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
    documentRequest: ['countryId'],
    organisationRequest: ['countryId'],
    businessVerificationRequest: ['business'],
    documentVerificationRequest: ['requestId', 'membershipId', 'documentId', 'refrence', 'file'],
    documentReviewRequest: ['requestId'],
    documentPreviewRequest: ['proofId'],
    businessVerificationStatusRequest: ['businessId'],
    businessLogoUploading:['businessId', 'file'],

    documentSuccess: ["documents"],
    organisationSuccess: ["organisationDocuments"],
    businessVerificationSuccess: ['record'],
    documentVerificationSuccess: ['document'],
    documentReviewSuccess: ['reviewResponse'],
    documentPreviewSuccess: ['image'],
    businessVerificationStatusSuccess: ['record'],
    businessLogoUploadSuccess:['file'],

    documentFailure: ["error"],
    organisationFailure: ["error"],
    businessVerificationFailure: ['error'],
    documentVerificationFailure: ['error'],
    documentReviewFailure: ['error'],
    documentPreviewFailure: ['error'],
    businessVerificationStatusFailure: ['error'],
    businessLogoUploadFailure:['error'],
});

export const DocumentTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    fetchingDocument: false,
    fetchingOrganisationDocument: false,
    creating: false,
    verifyingDocument: false,
    reviewingDocument: false,
    fetchingImage: false,
    fetchingVerifStatus: false,
    uploadingBusinessLogo:false,

    documents: null,
    organisationDocuments: null,
    creatingSuccess: null,
    verificationSuccess: null,
    reviewSuccess: null,
    imageSuccess: null,
    verifStatus: null,
    uploadedBusinessLogo:null,

    errorFetching: null,
    errorOrganisationFetching: null,
    creatingError: null,
    verificationError: null,
    reviewError: null,
    imageError: null,
    verifStatusError: null,
    errorUploadingBusinessLogo:null,
})


export const request = (state) => {
    return { ...state, fetchingDocument: true }
}

export const organisationRequest = (state) => {
    return { ...state, fetchingOrganisationDocument: true }
}

export const creatingRequest = (state) => {
    return { ...state, creating: true }
}

export const verificationRequest = (state) => {
    return { ...state, verifyingDocument: true, verificationSuccess: null }
}

export const reviewingRequest = (state) => {
    return { ...state, reviewingDocument: true, reviewSuccess:null, reviewError:null }
}

export const imageRequest = (state) => {
    return { ...state, fetchingImage: true }
}

export const verifStatusRequest = (state) => {
    return { ...state, fetchingVerifStatus: true }
}

export const uploadingBusinessLogo =(state)=> {
    return {...state, uploadingBusinessLogo:true, uploadedBusinessLogo:null}
}

export const success = (state, action) => {
    const { documents } = action
    return { ...state, fetchingDocument: false, documents }
}

export const organisationSuccess = (state, action) => {
    const { organisationDocuments } = action
    return { ...state, fetchingOrganisationDocument: false, organisationDocuments }
}

export const creationSuccess = (state, action) => {
    const { record } = action
    return { ...state, creating: false, creatingSuccess: record }
}

export const verificationSuccess = (state, action) => {
    const { document } = action
    return { ...state, verifyingDocument: false, verificationSuccess: document }
}

export const reviewingSuccess = (state, action) => {
    const { reviewResponse } = action
    return { ...state, reviewingDocument: false, reviewSuccess: reviewResponse, reviewError: null }
}

export const imageSuccess = (state, action) => {
    const { image } = action
    return { ...state, fetchingImage: false, imageSuccess: image }
}

export const verifStatusSuccess = (state, action) => {
    const { record } = action
    return { ...state, fetchingVerifStatus: false, verifStatus: record }
}

export const logoUploadSuccess =(state, action)=>{
    const {file} = action
    return{...state, uploadingBusinessLogo:false, uploadedBusinessLogo:file }
}

export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingDocument: false, errorFetching: error, documents: null }
}

export const organisationFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOrganisationDocument: false, errorOrganisationFetching: error, organisationDocuments: null }
}

export const creationFailure = (state, action) => {
    const { error } = action
    return { ...state, creating: false, creatingError: error, creatingSuccess: null }
}

export const verificationFailure = (state, action) => {
    const { error } = action
    return { ...state, verifyingDocument: false, verificationError: error, verificationSuccess: null }
}

export const reviewingFailure = (state, action) => {
    const { error } = action
    return { ...state, reviewError: error, reviewingDocument: false,  reviewSuccess: null }
}

export const imageFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingImage: false, imageError: error, imageSuccess: null }
}

export const verifStatusFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingVerifStatus: false, verifStatusError: error, verifStatus: null }
}

export const logoUploadFailure = (state, action) => {
    const { error } = action
    return { ...state,  uploadingBusinessLogo: false, errorUploadingBusinessLogo: error }
}


export const reducer = createReducer(INITIAL_STATE, {
    [Types.DOCUMENT_REQUEST]: request,
    [Types.ORGANISATION_REQUEST]: organisationRequest,
    [Types.BUSINESS_VERIFICATION_REQUEST]: creatingRequest,
    [Types.DOCUMENT_VERIFICATION_REQUEST]: verificationRequest,
    [Types.DOCUMENT_REVIEW_REQUEST]: reviewingRequest,
    [Types.DOCUMENT_PREVIEW_REQUEST]: imageRequest,
    [Types.BUSINESS_VERIFICATION_STATUS_REQUEST]: verifStatusRequest,
    [Types.BUSINESS_LOGO_UPLOADING]: uploadingBusinessLogo,

    [Types.DOCUMENT_SUCCESS]: success,
    [Types.ORGANISATION_SUCCESS]: organisationSuccess,
    [Types.BUSINESS_VERIFICATION_SUCCESS]: creationSuccess,
    [Types.DOCUMENT_VERIFICATION_SUCCESS]: verificationSuccess,
    [Types.DOCUMENT_REVIEW_SUCCESS]: reviewingSuccess,
    [Types.DOCUMENT_PREVIEW_SUCCESS]: reviewingSuccess,
    [Types.DOCUMENT_PREVIEW_SUCCESS]: imageSuccess,
    [Types.BUSINESS_VERIFICATION_STATUS_SUCCESS]: verifStatusSuccess,
    [Types.BUSINESS_LOGO_UPLOAD_SUCCESS]: logoUploadSuccess,

    [Types.DOCUMENT_FAILURE]: failure,
    [Types.ORGANISATION_FAILURE]: organisationFailure,
    [Types.DOCUMENT_VERIFICATION_FAILURE]: verificationFailure,
    [Types.DOCUMENT_REVIEW_FAILURE]: reviewingFailure,
    [Types.DOCUMENT_PREVIEW_FAILURE]: imageFailure,
    [Types.BUSINESS_VERIFICATION_STATUS_FAILURE]: verifStatusFailure,
    [Types.BUSINESS_LOGO_UPLOAD_FAILURE]: logoUploadFailure,
})