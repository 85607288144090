import { Button } from "primereact/button";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";

const ProductEdit = ({ onHide, product, setShowProductEdit, showProductEdit }) => {

  const toastProduct = useRef(null);
  const { t } = useTranslation("common");
  const [exclusive, setExclusive] = useState('')

  const [state, setState] = useState({
    submit: false,
  });

  useEffect(() => {
    if (product.exclusive) {
      setExclusive(product.exclusive)
    }
  }, [product.exclusive])

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: product?.name,
      description: product?.description,
      exclusive: product.exclusive,
    }
  });

  const errorUpdatingPlanGroup = useSelector((state) => state.planGroups.errorUpdating)
  const updatingPlanGroup = useSelector((state) => state.planGroups.updating)
  const updatedPlanGroup = useSelector((state) => state.planGroups.updatedPlanGroup)
  const defMembership = useSelector((state) => state.defMembership.defMembership);

  const dispatch = useDispatch()
  const updatePlanGroup = useCallback((planGroupId, planGroup) => { dispatch(PlanGroupActions.planGroupUpdateRequest(planGroupId, planGroup)) }, [dispatch])
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch]);

  useEffect(() => {
    if (state.submit && updatedPlanGroup && !updatingPlanGroup && errorUpdatingPlanGroup === null) {
      showSuccess()
    }
    if (state.submit && !updatingPlanGroup && errorUpdatingPlanGroup) {
      showError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPlanGroup, errorUpdatingPlanGroup, state.submit]);

  const showSuccess = () => { toastProduct.current.show({ severity: 'success', summary: t("products.edit_save_success"), life: 2000 }); }
  const showError = () => { toastProduct.current.show({ severity: 'error', summary: t("products.generic_error"), life: 3000 }); }

  const onSubmit = (data) => {
    product.name = data?.name ? data.name : product.name
    product.description = data.description ? data.description : product.description
    product.exclusive = exclusive !== " " ? exclusive : product.exclusive
    updatePlanGroup(product.id, product);
    setState({
      ...state,
      submit: true,
    });
  };

  const fetchActive = () => {
    getActivePlanGroups(defMembership.business.id);
  };

  return (
    <>
      <Dialog
        header={t("products.product_edit_info")}
        visible={!!product?.id}
        style={{ width: "355px" }}
        onHide={onHide}
        className="tier_dialog" id="tier_dialog"
      >
        <Toast
          className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
          ref={toastProduct}
          onRemove={({ severity }) => {
            if (severity === "success") {
              reset()
              onHide({})
              setShowProductEdit(!showProductEdit)
              fetchActive()
            }
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-5">
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <div className="flex flex-column gap-2 mb-4" style={{ padding: "0px 10px 0px 10px" }}>
                  <label className="plan_edit_labels" >{t("product.name")}</label>
                  <InputText style={{ height: "3rem" }} defaultValue={product.name} value={field.value} maxLength={50}
                    className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                </div>
              </>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <div className="flex flex-column gap-2 mb-4" style={{ padding: "0px 10px 0px 10px" }}>
                  <label className="plan_edit_labels" >{t("product.description")}</label>
                  <InputText style={{ height: "3rem" }} defaultValue={product.description} value={field.value} maxLength={100}
                    className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                </div>
              </>
            )}
          />
          <Controller
            name="exclusive"
            control={control}
            render={({ field, fieldState }) => (
              <div className="car" style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                <span className='labels' >{t("products.exclusive")}</span> &nbsp;
                <InputSwitch
                  id="exclusive"
                  name="exclusive"
                  value={exclusive}
                  checked={exclusive}
                  onChange={(e) => { setExclusive(!exclusive) }}
                />
                {exclusive ? (
                  <div className='labels' style={{ marginTop: "0.5rem" }}>{t("products.exclusivity_hint_start")} <span className="exclusivity_hint_on_middle"
                  >{t("products.exclusivity_hint_on_middle")} </span> {t("products.exclusivity_hint_end")} </div>
                ) : (
                  <div className='labels' style={{ marginTop: "0.5rem" }}>{t("products.exclusivity_hint_start")}
                    <span className="exclusivity_hint_off_middle">{t("products.exclusivity_hint_off_middle")} </span>{t("products.exclusivity_hint_end")} </div>
                )}{" "}
                <br />
              </div>
            )}
          />

          <div>
            <div className="edit_plan_footer">
              <Button id="footer-btn-cancel"
                type="reset"
                label={t("cancel")}
                icon="pi pi-times"
                onClick={onHide}
              />
              <Button
                type="submit"
                disabled={updatingPlanGroup}
                loading={updatingPlanGroup}
                label={t("common.save")}
                id="rename-btn"
              />
            </div>
          </div>
        </form>
      </Dialog >
    </>
  );
};
export default ProductEdit;