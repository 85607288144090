import React from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

const AddProductAccountDialog = (props) => {
    const { t } = useTranslation("common");
    const channelOptionTemplate = (option) => (<>{option.name}</>);

    const selectedChannelTemplate = (option, props) => {
        if (option) { return (<div>{option.name}</div>); }
        return <span>{props.placeholder}</span>;
    };

    const add = () => {
        if (props.state.newFav) {
            props.getFavouriteWallet(props.state.newFav.id);
            props.showAddingFav(props.state.newFav.name);
            props.setState((state) => {
                return {
                    ...state, callFav: true, addNewFavVisible: false,
                    favouritingId: props.state.newFav.id, unfavouritingWallet: false, favouritingWallet: true
                };
            });
        }
    };
    const onHide = () => {
        props.setState((prev) => { return { ...prev, newFav: null, addNewFavVisible: false }; });
    };
    const footer = (
        <div className="payout_channel-buttonsBar">
            <Button label="Cancel" icon="pi pi-times" severity="info" text className="payout_channel-buttonsBar-cancel-button" onClick={onHide} />
            <Button label={t("product_account.add_favourites")} icon="pi pi-plus" style={{ backgroundColor: "#D0021B" }} className="payout_channel-buttonsBar-continue-button" onClick={add} />
        </div>
    );
    const onChange = (e) => {
        props.setState((state) => ({ ...state, newFav: e.value }))
    }
    return (
        <Dialog footer={footer} className="addWallet" header={t("product_account.add_favourites")} headerStyle={{ backgroundColor: "#D0021B", color: "#fff", height: "4rem", padding: "1rem" }}
            visible={props.state.addNewFavVisible} onHide={onHide}>
            <h6 className="mt-4 choose_accounts">{t("product_account.choose_accounts")}</h6>
            <hr style={{ backgroundColor: "#495057", height: "1.7px", margin: "1rem 0rem 1.5rem 0rem" }} />
            <label style={{ color: "#495057", fontFamily: "Roboto", padding: "0rem 1rem 1rem 1rem" }} htmlFor="dd-city">{t("product_account.saved_recipients_label")}</label>
            <div className="p-col mt-2 md-6 ml-3">
                <Dropdown
                    value={props.state.newFav}
                    options={props.state.planGroups?.filter(item => item.favourite === false)}
                    itemTemplate={channelOptionTemplate}
                    onChange={onChange}
                    optionLabel="name"
                    id="payoutChannel"
                    placeholder={t("product_account.choose_saved")}
                    valueTemplate={selectedChannelTemplate}
                    className="payout_channel-dropdown" />
            </div>
        </Dialog>
    );
};

export default AddProductAccountDialog;