import React, { useEffect, useState, useCallback, useRef } from "react";
import { Formik, Form } from "formik";
import { Toast } from "primereact/toast";
import "react-phone-number-input/style.css";
import { formatPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import PayoutChannelActions from "../../../../Redux/actions/payoutchannel-actions";
import PlanGroupActions from "../../../../Redux/transition/plan-group/plan-group.reducer";
import BankActions from "../../../../Redux/actions/bank-actions";
import { defMembershipCall } from "../../calls"
import { SavedPayout } from "../../../../Components/sendMoney/SavedPayout";
import { NewRecipient } from "../../../../Components/sendMoney/NewRecipient";

export const RecipientDetails = ({ setRecipient, handleNext, setWalletExist }) => {
	const { t } = useTranslation("common");
	//Redux State from Store
	const payoutChannelsSaved = useSelector((state) => state.payoutChannels.payoutChannelsSaved);
	const payoutChannelsFavourite = useSelector((state) => state.payoutChannels.payoutChannelsFavourite);
	const errorSettingFavourite = useSelector((state) => state.payoutChannels.errorSettingFavourite);
	const settingFavourite = useSelector((state) => state.payoutChannels.settingFavourite);
	const settingUnfavourite = useSelector((state) => state.payoutChannels.settingUnfavourite);
	const errorSettingUnfavourite = useSelector((state) => state.payoutChannels.errorSettingUnfavourite);
	const banks = useSelector((state) => state.banks.banks);
	const planGroups = useSelector((state) => state.planGroups.planGroups);

	//Redux Dispatch Actions
	const dispatch = useDispatch();
	const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
	const getFavouriteChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelFavouriteRequest(businessId, options)), [dispatch]);
	const getAllPlanGroups = useCallback((businessId, options) => dispatch(PlanGroupActions.planGroupAllRequest(businessId, options)), [dispatch]);
	const getAllBanks = useCallback((countryId) => dispatch(BankActions.bankAllRequest(countryId)), [dispatch]);
	const { defMembership, getMembership } = defMembershipCall(dispatch, useSelector, useCallback);
	const business = defMembership ? defMembership.business : {};
	const toast = useRef(null);

	const [state, setState] = useState({
		loading: false,
		success: false,
		requesting: null,
		creating: null,
		error: null,
		payoutChannels: [],
		payoutChannel: null,
		banks: [],
		mobileBanks: [],
		allPlanGroups: [],
		isNewChannel: false,
		newChannel: {},
		favouritePayoutChannels: [],
		newFav: null,
		addNewFavVisible: false,
		callFav: false
	});

	const showSuccess = () => {
		toast.current.show({
			severity: "success",
			summary: t("successful"),
			detail: state.isNewChannel ? t("recipient.save_success") : t("recipient.select_success"),
			life: 3000,
		});
	};
	const showError = () => {
		toast.current.show({
			severity: "error",
			summary: t("error_dashboard"),
			detail: t("recipient.generic_error"),
			life: 3000,
		});
	};
	const showRecipientDuplicateError = () => {
		toast.current.show({
			severity: "error",
			summary: t("error_dashboard"),
			detail: t("recipient.duplicate_error"),
			life: 3000,
		});
	};
	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			getAllPlanGroups(business.id);
			getAllBanks(business.country.id);
			getSavedPayoutChannels(business.id);
			getFavouriteChannels(business.id);
			setState((s) => {
				return { ...s, loading: true, country: business.country };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defMembership]);

	useEffect(() => {
		if (!settingFavourite && state.callFav) {
			getFavouriteChannels(business.id);
			if (errorSettingFavourite) {
				toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_add_fav") });
			} else {
				toast.current.show({
					severity: "success",
					summary: t("successful"),
					detail: `${state.newFav.name} ${t("recipient.save_fav")}`,
					life: 3000,
				});
			}
			setState((prev) => { return { ...prev, callFav: false, newFav: null } });
		}
		if (!settingUnfavourite && state.loadUnFav) {
			getFavouriteChannels(business.id);
			if (errorSettingUnfavourite) {
				toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_removed_fav") });

			} else {
				toast.current.show({
					severity: "success",
					summary: t("successful"),
					detail: t("recipient.err_removed_fav"),
					life: 3000,
				});
			}
			setState((prev) => { return { ...prev, loadUnFav: false } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [business.id, errorSettingFavourite, errorSettingUnfavourite, getFavouriteChannels, settingFavourite, settingUnfavourite, state.callFav, state.loadUnFav]);


	useEffect(() => {
		if (payoutChannelsSaved) {
			setState((state) => {
				return { ...state, payoutChannels: payoutChannelsSaved, isNewChannel: payoutChannelsSaved.length === 0 };
			});
		}
		if (banks) {
			const mobileBankList = banks.filter((bank) => bank.type === "MOBILE");
			const bankList = banks.filter((bank) => bank.type === "BANK");
			setState((s) => {
				return {
					...s,
					banks: bankList,
					mobileBanks: mobileBankList,
					loading: false,
				};
			});
		}
		if (planGroups) {
			setState((s) => ({ ...s, allPlanGroups: planGroups }));
		}
		if (payoutChannelsFavourite) {
			setState((prev) => { return { ...prev, favouritePayoutChannels: payoutChannelsFavourite, } });
		}
	}, [banks, payoutChannelsFavourite, payoutChannelsSaved, planGroups])

	Yup.addMethod(Yup.number, "validatePhoneNumber", function (errorMessage) {
		return this.test(`test-mobile-number-require`, errorMessage, function (value) {
			const { path, createError } = this;
			const valid = validator.isMobilePhone(value ? value.toString() : "") ? "true" : "false";
			return (value && valid === "false") || createError({ path, message: errorMessage });
		});
	});
	Yup.addMethod(Yup.number, "validateBankNumber", function (errorMessage) {
		return this.test(`test-mobile-number-require`, errorMessage, function (value) {
			const { path, createError } = this;
			const valid = validator.isIBAN(value ? value.toString() : "") ? "true" : "false";
			return (value && valid === "false") || createError({ path, message: errorMessage });
		});
	});
	const savedValid = Yup.object().shape({
		payoutChannel: Yup.object().shape({
			name: Yup.string().min(2, t("recipient.minChar")).required(t("recipient.error_name_required")),
			identifier: Yup.number().required(t("recipient.error_phoneno_required")),
			channelName: Yup.string().required(t("recipient.required"))
		}).required(t("recipient.required"))
	})

	const newChannelValid = Yup.object().shape({
		newChannel: Yup.object().shape({
			name: Yup.string().when(["isSaved"], {
				is: (isSaved) => isSaved,
				then: Yup.string().min(2, t("recipient.minChar")).required(t("recipient.error_name_required"))
			}),
			systemBank: Yup.object().shape({
				type: Yup.string().required(t("recipient.error_accountType")),
				name: Yup.string().min(2, t("recipient.minChar")).required(t("recipient.error_bank_required")),
			}).required(t("recipient.required")),
			identifier: Yup.number()
				.when(['isMomo', 'isBank'], {
					is: (isMomo, isBank) => !isBank && isMomo,
					then: Yup.number().validatePhoneNumber(t("recipient.error_phoneno_invalid")).required(t("recipient.error_phoneno_required"))
				})
				.when(['isMomo', 'isBank'], {
					is: (isMomo, isBank) => isBank && !isMomo,
					then: Yup.number().validateBankNumber(t("recipient.error_accountno_invalid")).required(t("recipient.error_accountno_required"))
				}),
			isSaved: Yup.bool().required()
		})
	})

	const formik = {
		initialValues: {
			payoutChannel: {
				name: "",
				identifier: "",
				channelName: "",
				temporary: "",
				type: "BANK",
				systemBank: {
					name: ""
				},
			},
			newChannel: {
				name: "",
				identifier: "",
				channelName: "",
				temporary: false,
				systemBank: {},
				isSaved: true
			}
		},
		validationSchema: state.isNewChannel ? newChannelValid : savedValid,
		onSubmit: (data, actions) => {
			const { name, identifier, isMomo } = data.newChannel
			const formattedIdentifier = isMomo ? identifier.replace(/^.{4}/, "0") : identifier

			//check if the data from the form matches an already existing recipient in our payout channels
			const isRecipientDuplicate = state.payoutChannels.some(recipient => recipient.name.toLowerCase() === name.toLowerCase() && recipient.identifier === formattedIdentifier);
			if (isRecipientDuplicate) {
				showRecipientDuplicateError()
			} else {
				if (state.isNewChannel) {
					setRecipient({ ...data.newChannel, identifier: data.newChannel.systemBank.type === "MOBILE" ? formatPhoneNumber(data.newChannel.identifier).replace(/\s/g, '') : data.newChannel.identifier })
				} else {
					setRecipient(data.payoutChannel)
				}
				setState({ ...state, requesting: true, success: false });
				if (state.error) {
					showError();
				} else {
					showSuccess();
				}
				handleNext()
			}
		},
	};

	return (
		<div className="p-d-flex">
			<Toast ref={toast} />
			<Formik
				initialValues={formik.initialValues}
				onSubmit={formik.onSubmit}
				validationSchema={formik.validationSchema}>
				{({ values, errors, touched, setFieldValue }) => {

					return (
						<Form className="p-ml-4" style={{ width: "100%" }}>
							{!state.isNewChannel && <SavedPayout {...{ setFieldValue, values, state, setState }} />}
							{state.isNewChannel && <NewRecipient {...{ setFieldValue, values, state, setState, touched, errors, }} />}
						</Form>
					)
				}}
			</Formik>
		</div>
	);
};