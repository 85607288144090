import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { BankTypes } from '../actions/bank-actions'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOne: null,
    fetchingAll: null,
    fetchingFilteredBanks: null,
    filteredBanks: null,
    bank: null,
    banks: null,
    errorOne: null,
    errorAll: null,
    errorFetchingFilteredBanks: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => { return { ...state, fetchingOne: true, bank: null } }
// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, banks: null } }
// request the data from an api
export const filterBankRequest = (state) => { return { ...state, fetchingFilteredBanks: true, filteredBanks: null } }

// successful api lookup for single entity
export const success = (state, action) => {
    const { bank } = action
    return { ...state, fetchingOne: false, errorOne: null, bank }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { banks } = action
    return { ...state, fetchingAll: false, errorAll: null, banks }
}
// successful api lookup for all entities
export const filterBankSuccess = (state, action) => {
    const { filteredBanks } = action
    return { ...state, fetchingFilteredBanks: false, errorFetchingFilteredBanks: null, filteredBanks }
}


// Something went wrong fetching a single entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOne: false, errorOne: error, bank: null }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, errorAll: error, banks: null }
}
export const filterBankFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingFilteredBanks: false, errorFetchingFilteredBanks: error, filteredBanks: null }
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [BankTypes.BANK_REQUEST]: request,
    [BankTypes.BANK_ALL_REQUEST]: allRequest,
    [BankTypes.BANK_FILTER_REQUEST]: filterBankRequest,

    [BankTypes.BANK_SUCCESS]: success,
    [BankTypes.BANK_ALL_SUCCESS]: allSuccess,
    [BankTypes.BANK_FILTER_SUCCESS]: filterBankSuccess,
    
    [BankTypes.BANK_FAILURE]: failure,
    [BankTypes.BANK_ALL_FAILURE]: allFailure,
    [BankTypes.BANK_FILTER_FAILURE]: filterBankFailure,

})
