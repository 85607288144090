import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  membershipRequest: ['membershipId'],
  membershipAllRequest: ['businessId', 'options'],
  membershipUpdateRequest: ['membership'],
  membershipDeleteRequest: ['membershipId'],
  
  membershipSuccess: ['membership'],
  membershipAllSuccess: ['memberships'],
  membershipUpdateSuccess: ['membership'],
  membershipDeleteSuccess: [],

  membershipFailure: ['error'],
  membershipAllFailure: ['error'],
  membershipUpdateFailure: ['error'],
  membershipDeleteFailure: ['error']
})

export const MembershipTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingOne: false,
  fetchingAll: false,
  updating: null,
  deleting: null,
  membership: null,
  memberships: null,
  errorOne: null,
  errorAll: null,
  errorUpdating: null,
  errorDeleting: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
  return {
    ...state,
    fetchingOne: true,
    membership: null
  }
}

// request the data from an api
export const allRequest = (state) => {
  return {
    ...state,
    fetchingAll: true,
    memberships: null
  }
}

// request to update from an api
export const updateRequest = (state) => {
  return {
    ...state,
    updating: true
  }
}
// request to delete from an api
export const deleteRequest = (state) => {
  return {
    ...state,
    deleting: true
  }
}

// successful api lookup for single entity
export const success = (state, action) => {
  const { membership } = action
  return {
    ...state,
    fetchingOne: false,
    errorOne: null,
    membership
  }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
  const { memberships } = action
  return {
    ...state,
    fetchingAll: false,
    errorAll: null,
    memberships
  }
}
// successful api update
export const updateSuccess = (state, action) => {
  const { membership } = action
  return {
    ...state,
    updating: false,
    errorUpdating: null,
    membership
  }
}
// successful api delete
export const deleteSuccess = (state) => {
  return {
    ...state,
    deleting: false,
    errorDeleting: null,
    membership: null
  }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action
  return {
    ...state,
    fetchingOne: false,
    errorOne: error,
    membership: null
  }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    fetchingAll: false,
    errorAll: error,
    memberships: null
  }
}
// Something went wrong updating.
export const updateFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    updating: false,
    errorUpdating: error,
    membership: state.membership
  }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    deleting: false,
    errorDeleting: error,
    membership: state.membership
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MEMBERSHIP_REQUEST]: request,
  [Types.MEMBERSHIP_ALL_REQUEST]: allRequest,
  [Types.MEMBERSHIP_UPDATE_REQUEST]: updateRequest,
  [Types.MEMBERSHIP_DELETE_REQUEST]: deleteRequest,

  [Types.MEMBERSHIP_SUCCESS]: success,
  [Types.MEMBERSHIP_ALL_SUCCESS]: allSuccess,
  [Types.MEMBERSHIP_UPDATE_SUCCESS]: updateSuccess,
  [Types.MEMBERSHIP_DELETE_SUCCESS]: deleteSuccess,

  [Types.MEMBERSHIP_FAILURE]: failure,
  [Types.MEMBERSHIP_ALL_FAILURE]: allFailure,
  [Types.MEMBERSHIP_UPDATE_FAILURE]: updateFailure,
  [Types.MEMBERSHIP_DELETE_FAILURE]: deleteFailure
})
