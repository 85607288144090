import { call, put } from 'redux-saga/effects'
import * as api from "../../Service/api";
import BankBranchActions from '../actions/bankBranches-actions'

export function* getBankBranch(action) {
    const { bankBranchId } = action
    // make the call to the api
    const apiCall = call(api.getBankBranch, bankBranchId)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        yield put(BankBranchActions.bankBranchSuccess(response.data))
    } else {
        yield put(BankBranchActions.bankBranchFailure(response.data))
    }
}

export function* getBankBranches(action) {
    const { bankId, options } = action
    // make the call to the api
    const apiCall = call(api.getBankBranches, bankId, options)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        yield put(BankBranchActions.bankBranchAllSuccess(response.data))
    } else {
        yield put(BankBranchActions.bankBranchAllFailure(response.data))
    }
}

