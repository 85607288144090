/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import ReportActions from "../../Redux/transition/report/report.reducer";
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer";
import Loading from "../../Components/Loading";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";
import "../../Styles/dataTable.scss";
import "../../Styles/pages/subscriptionReport.scss";
import CustomDataTable from "../../Components/CustomDataTable";
import { Calendar } from "primereact/calendar";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { TreeSelect } from "primereact/treeselect";

const SubscriptionCustom = () => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const [state, setState] = useState({
        sort: "id,desc",
        count: null,
        size: 20,
        first: 0,
        page: 0,
        plan: {},
        subscriptionReport: [],
        planGroupsGraphedActive: [],
        planGroups: [],
        filteredPlanGroups: [],
        filteredPlans: [],
        filteredCustomers: [],
        customers: [],
        selectOptions:[],
    });
    const [filter, setFilter] = useState({
        planGroups: [],
        plans: [],
        periods: [],
        statuses: [],
        busCustomers: [],
    })
    const setFilterFun = (newBody) => {
        setFilter((body) => ({
            ...body,
            ...newBody,
        }));
    }
    const setFilterSubFun = (sub, newBody) => {
        setFilter((body) => ({
            ...body,
            [sub]: {
                ...body[sub], ...newBody,
            }
        }));
    }
    const [addedStatus, setAddedStatus] = useState(false)
    const [addedInfo, setAddedInfo] = useState(false)
    const [addedInfoBilling, setAddedInfoBilling] = useState(false)
    const [addedAmountOperator, setAddedAmountOperator] = useState(false)
    const [addedAmount, setAddedAmount] = useState(false)
    const [addedContact, setAddedContact] = useState(false)
    const [addedActivity, setAddedActivity] = useState(false)
    const [filters, setFilters] = useState({})
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
    const [makeProductSelectable, setMakeProductSelectable] = useState(true);
    const [makePlanSelectable, setMakePlanSelectable] = useState(true);

    const comparisonOperators = [
        { value: "EQUALS", label: t('report.equals') },
        { value: "NOT_EQUALS", label: t('report.not_equals') },
        { value: "LESS_THAN", label: t('report.less') },
        { value: "GREATER_THAN", label: t('report.greater') },
        { value: "LESS_THAN_EQUAL_TO", label: t('report.less_or') },
        { value: "GREATER_THAN_EQUAL_TO", label: t('report.greater_or') },
    ];
    const dateComparisonOperators = [
        { value: "EQUALS", label: t('report.date_on') },
        { value: "LESS_THAN", label: t('report.date_before') },
        { value: "GREATER_THAN", label: t('report.date_after') },
    ];
    const dateTypes = [
        { label: t("report.startedOn"), value: "startedOn" },
        { label: t("report.renewsOn"), value: "renewsOn" },
        { label: t("report.cancelledOn"), value: "cancelledOn" },
        { label: t("report.pausedOn"), value: "pausedOn" },
        { label: t("report.completedOn"), value: "completedOn" },
    ];
    const setBodyFun = (newBody = {}) => {
        const { busCustomers = [], planGroups = [], plans = [], periods = [] } = filter
        setBody((body) => ({
            subscription: {
                ...body.subscription,
                ...filter,
                busCustomers: busCustomers.map(({ id }) => id),
                planGroups: planGroups.map(({ id }) => id),
                plans: plans.map(({ id }) => id),
                periods: periods,
                ...newBody,
            },
        }));
        
        if((filters?.planFilter) && (!filter.planGroups?.length && !filter.plans?.length && !filter.periods?.length)){
            removeKeyValuePair('planFilter')
        }
        setTrigger(!trigger);
    };
    const billingPeriods = [
        { label: t("plan.period_ONCE"), value: "ONCE" },
        { label: t("plan.period_DAILY"), value: "DAILY" },
        { label: t("plan.period_DAILY_WITH_SATURDAYS"), value: "DAILY_WITH_SATURDAYS" },
        { label: t("plan.period_DAILY_WITH_WEEKENDS"), value: "DAILY_WITH_WEEKENDS" },
        { label: t("plan.period_WEEKLY"), value: "WEEKLY" },
        { label: t("plan.period_BIWEEKLY"), value: "BIWEEKLY" },
        { label: t("plan.period_MONTHLY"), value: "MONTHLY" },
        { label: t("plan.period_QUARTERLY"), value: "QUARTERLY" },
        { label: t("plan.period_HALFYEARLY"), value: "HALFYEARLY" },
        { label: t("plan.period_YEARLY"), value: "YEARLY" }
    ]

    const [body, setBody] = useState({
        subscription: {
            planGroups: [],
            plans: [],
            periods: [],
            statuses: [],
            busCustomers: [],
        },
    });
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            filters: [{}],
        },
    });
    const filterSubscribersItems = [
        { label: t('report.active'), value: "ACTIVE" },
        { label: t('report.pause'), value: "PAUSED" },
        { label: t('report.cancel'), value: "CANCELLED" },
        { label: t('report.complete'), value: "COMPLETE" },
    ];
    const [trigger, setTrigger] = useState(false);
    const toast = useRef(null);
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessId = defMembership.business.id;
    const { fetchingSubscriptionReport, subscriptionReport } = useSelector((state) => state.report);
    const { planGroupsGraphedActive, fetchingPlanGroupsGraphed } = useSelector((state) => state.planGroups);
    const { filteredCustomers } = useSelector((state) => state.businessCustomers);

    const dispatch = useDispatch();
    const getSubscriptionReport = useCallback((body) => dispatch(ReportActions.subscriptionReportRequest(businessId, body)), [dispatch]);
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)); }, [dispatch]);
    const getFilteredCustomers = useCallback((businessId, query) => dispatch(BusinessCustomersActions.businessCustomerSearchRequest(businessId, query)), [dispatch]);

    useEffect(() => {
        getActivePlanGroups(businessId);
        getSubscriptionReport(body);
    }, [trigger]);

    useEffect(() => {
        if (subscriptionReport) {
            setState((state) => {
                return { ...state, subscriptionReport: subscriptionReport };
            });
        }
        if (filteredCustomers) {
            setState((state) => {
                return { ...state, filteredCustomers: filteredCustomers };
            });
        }

        const filteredGroup = planGroupsGraphedActive?.groups?.map((group) => {
			return {
				...group,
				plans: group.plans,
			};
		})

        if (filteredGroup) {
			let planGroupAssigned = [];
			filteredGroup.map((item, ind) => {
				const {	group: { id }, group, plans} = item;
				planGroupAssigned.push({
                    key: id,
                    nodeId:id,
					label: group.name,
					selectable: makeProductSelectable? true : false,
					data: group,
					children: plans? plans.map((item, idx, name, id ) => ({
								key: `${ind}-${idx}`,
								label: item.name,
                                nodeId:item.id,
								data: item,
                                selectable: makePlanSelectable? true : false,
							})):[]
				});
				
				return null;
			});
            setState((prev) => {
                return {
                    ...prev,
                    selectOptions: planGroupAssigned,
                    filteredPlanGroups: planGroupAssigned,
                    filteredPlans: getFilteredPlans(planGroupAssigned),
                }
            })
		}
    }, [subscriptionReport, planGroupsGraphedActive, filteredCustomers,makePlanSelectable, makeProductSelectable]);

    // Save object to local storage
  const saveObjectToLocal = (obj) => {
    localStorage.setItem('filterKey', JSON.stringify(obj));
  };

  // Retrieve object from local storage
  const getStoredObject = () => {
    const storedObject = localStorage.getItem('filterKey');
    return storedObject ? JSON.parse(storedObject) : null;
  };

  // Effect to save object to local storage when the object changes
  useEffect(() => {
    saveObjectToLocal(filters);
  }, [filters]);

  useEffect(() => {
    if(filter.statuses?.length === 0){
        setAddedStatus(false)
    }
  },[filter.statuses])

  // Effect to retrieve object from local storage during mount
  useEffect(() => {
    const storedObject = getStoredObject();
    if(fetchingSubscriptionReport){
    if (storedObject) {
      setFilters(storedObject);
    }
}
  }, [fetchingSubscriptionReport]);

 // Function to update a specific key-value pair
 const updateKeyValuePair = (key, value) => {
    setFilters((prevObject) => ({ ...prevObject, [key]: value }));
  };

  // Function to remove a specific key-value pair
  const removeKeyValuePair = (key) => {
    setFilters((prevObject) => {
      const updatedObject = { ...prevObject };
      delete updatedObject[key];
      return updatedObject;
    });
  };

    const getFilteredPlans = (planGroups = state.filteredPlanGroups) => {
        return planGroups.reduce((prev, cur) => {
            return [...prev, ...cur.children];
        }, []);
    };

    if (fetchingSubscriptionReport || fetchingPlanGroupsGraphed) {
        return <Loading />;
    }

    const formatTime = (time) => {
        if (moment(time).isValid()) {
            return moment(time).format("MMMM Do YYYY");
        }
    };
    const DateFormatterBody = ({ date, title, dateColor, titleColor }) => !!date && (
        <span className="trans-normal-text" id="" style={{display:'flex', flexDirection:'column', alignItems:"flex-start"}} >
            <strong style={{color: titleColor, fontFamily:"Roboto-Bold",fontSize:"14px"}}>{t(title)}</strong>
            <span style={{color: dateColor, fontFamily:"RobotoCondensed",fontSize:"14px", marginBottom:"7px"}}>{formatTime(date)}</span>
        </span>
    )

    const statusTemplate = (rowData, e) => (<div className="statusbodytemplate">{
         rowData[e.field] && <span className={`product-badge status-${rowData[e.field] ? rowData[e.field].toLowerCase() : ""}`}>{rowData[e.field]==="COMPLETE"? t("business_customers.completed"): rowData[e.field]}</span>
    }</div>);

    const amountTemplate = (tier, e) => {
        const formattedCost = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(tier.cost);
    return(
    <div className="amountbodytemplate">{(tier.plan.flex ?  `${t("products.flexible_amount")}` : tier.cost === null ? `${defMembership.business.country.currency} 0.00` : `${defMembership.business.country.currency} ${formattedCost}` )}</div>)};
    
    const subscriptionBodyTemplate = ({ plan }) => (
        <div className="subscriptionBodyTemplate">
            <span className="name-body" onClick={() => {
                history.push({ pathname: "/products/detail/" + plan.groupId });
            }}><strong>{plan.groupName}</strong></span>
            <span className="name-body" onClick={() => {
                history.push({ pathname: "/products/plan/detail/" + plan.id });
            }}>{plan.name}</span>
            {plan.billingPeriod && (<span className="billingPeriod">{t(`product.${plan.billingPeriod.toLowerCase()}`)}</span>)}
        </div>
    );
    const requestedOnBodyTemplate = (rowData) => (
        <div className="customerBodyTemplate">
            <DateFormatterBody title={t("report.renewsOn")} date={rowData.status === "ACTIVE" ? rowData.nextBillingDate : null} titleColor="#256029"  dateColor="#000"/>
            <DateFormatterBody title={t("report.cancelledOn")} date={rowData.status === "CANCELLED" ? rowData.cancelledOn : null} titleColor="#c63750"dateColor="#000"/>
            <DateFormatterBody title={t("report.pausedOn")} date={rowData.status === "PAUSED" ? rowData.pausedOn : null} titleColor="#FFB300"dateColor="#000"/>
            <DateFormatterBody title={t("report.completedOn")} date={rowData.status === "COMPLETE" ? rowData.completedOn : null} titleColor="#666678" dateColor="#000"/>
            <DateFormatterBody title={t("report.startedOn")} date={rowData.joinedOn} titleColor="#666678" dateColor="#495057" />
        </div>
    );
    const statusFilterTemplate = (options) => {
        return (
            <div className="field">
                <div style={{fontSize:'11.5px',margin:"-15px 0 2px 1px"}}>{t("status")}</div> 
                <span>
                    <MultiSelect
                        placeholder={t("statusHint")}
                        value={filter.statuses}
                        options={filterSubscribersItems}
                        itemTemplate={({ label, value }) => <span className={`product-badge status-${label.toLowerCase()}`}>{value}</span>}
                        selectedItemTemplate={(label) => label? 
                        <span className={`product-badge status-${label && label.toLowerCase()} stat-label`}>{label}</span>
                        : <span>{t("statusHint")}</span>
                        }
                        onChange={(e) => {
                            if(e.value){
                                setAddedStatus(true)
                            }
                            else{
                                setAddedStatus(false)
                            }
                            setFilterFun({ statuses: e.value });
                        }}
                        className="p-column-filter status-col"
                        display="chip"
                    />
                </span>
            </div>
        )
    };

    const planFilterTemplate = (options) => {
        const handleNodeSelect = (e) => {
            const selectedNodeKey = e.node.key;
            const keyContainsHyphen = selectedNodeKey.toString().includes('-');
            if(keyContainsHyphen){
                setMakeProductSelectable(false)
                setMakePlanSelectable(true)
            }
            else  {
                setMakePlanSelectable(false)
                setMakeProductSelectable(true)
            }
            const selectedData = e.node.data;
            const isChildEle = selectedData.groupId !== undefined;
                
            if (isChildEle) {
                setFilterFun({ plans: [...filter.plans, selectedData] });
            } else {
                setFilterFun({ planGroups: [...filter.planGroups, selectedData] });
            }
        };
    
        const handleNodeUnselect = (e) => {
             const unselectedData = e.node.data;
            setMakePlanSelectable(true)
            setMakeProductSelectable(true)
            const isChildEle = unselectedData.groupId !== undefined;
            if (isChildEle) {
                setFilterFun({ plans: filter.plans.filter(plan => plan.id !== unselectedData.id) });
            } else {
                setFilterFun({ planGroups: filter.planGroups.filter(group => group.id !== unselectedData.id) });
            }
        } 

        const handleChipClose = (nodeKey, nodeId) => {
            const newSelectedNodeKeys = { ...selectedNodeKeys };
            delete newSelectedNodeKeys[nodeKey];
            setSelectedNodeKeys(newSelectedNodeKeys);
            const isChildEle = nodeKey.toString().includes('-');
            if (isChildEle) {
            const newPlan = filter.plans.filter(plan => plan.id !== nodeId)
            setFilterFun({ plans: newPlan});
            } else {
            const newPlanGroup= filter.planGroups.filter(group => group.id !== nodeId)
            setFilterFun({ planGroups: newPlanGroup });
        }
        if (Object.keys(newSelectedNodeKeys).length === 0) {
            setMakePlanSelectable(true);
            setMakeProductSelectable(true);
        }
        };
        const valueTemplate = (selectedNodes) => {
            if (!selectedNodes || selectedNodes.length === 0) {
                return <span className="p-treeselect-placeholder">{t("report.product_plan_hint")}</span>;
            }
            return (
                <div className="p-treeselect-value-container">
                    {selectedNodes.map(node => (
                        <div key={node.key} className="custom-chip">
                            <span className="custom-chip-label">{node.label}</span>
                            <span
                                className="custom-chip-close-icon pi pi-times-circle"
                                onClick={() =>  handleChipClose(node.key, node.nodeId) }
                            ></span>
                        </div>
                    ))}
                </div>
            );
        };
        const nodeTemplate = (node) => {
            const keyContainsHyphen = node?.key.toString().includes('-');
            return (
                <span className={(keyContainsHyphen && !makePlanSelectable)?'disable-plan-mode'
                                :(!keyContainsHyphen && !makeProductSelectable)?'disable-product-mode'  
                                :'norm'}>
                    {node.label}
                </span>
            );
        };
        return (
            <div className="tree-container">
                <div className="field">
                <div style={{fontSize:'11.5px',margin:"0 0 2px 1px"}}>{t("report.product_plan")}</div>   
                <span className="tree-select">
                        <TreeSelect 
                            placeholder={t("report.product_plan")} showClear={true}
                            value={selectedNodeKeys}  metaKeySelection={false} display="chip" valueTemplate={valueTemplate} nodeTemplate={nodeTemplate}
                            options={state.selectOptions} onNodeSelect={handleNodeSelect} onNodeUnselect={handleNodeUnselect} filter  selectionMode="multiple"  
                            onChange={(e)=> {if (e.value) { setSelectedNodeKeys(e.value); setAddedInfo(true);} else  {setAddedInfo(false);}
                            }}
                        />
                    </span>
                </div>

                <div className="field">
                <div style={{fontSize:'11.5px',margin:"0 0 2px 1px"}}>{t("report.billing_period")}</div> 
                    <span>
                        <MultiSelect
                            options={billingPeriods}
                            showClear
                            display="chip"
                            value={filter.periods}
                            placeholder={t("report.billing_period_hint")}
                            style={{margin:"0"}}
                            onChange={(e) => {
                                if(e.value){
                                    setAddedInfo(true)
                                    setAddedInfoBilling(true)
                                }else{
                                    setAddedInfo(false)
                                    setAddedInfoBilling(false)
                                }
                                setFilterFun({ periods: e.value })
                            }}
                            className="p-column-filter"
                        />
                    </span>
                </div>
            </div>
        );
    };

    const customersFilterTemplate = (options) => {
        const searchCustomers = (event) => {
            getFilteredCustomers(defMembership.business.id, event.query);
        };

        return (
            <div className="field">
                <div style={{margin:' -1.8rem 0 0.2rem 0.2rem',fontSize:'11.5px'}}>
                    {t("report.customer")}</div>
                <span>
                    <AutoComplete
                        inputId="ac"
                        multiple
                        field="name"
                        value={filter.busCustomers}
                        suggestions={state.filteredCustomers}
                        completeMethod={searchCustomers}
                        placeholder={t("report.customer_hint")}
                        onChange={(e) => {
                            if(e.value){
                                setAddedContact(true)
                            }
                            else{
                                setAddedContact(false)
                            }
                            setFilterFun({ busCustomers: e.value })
                        }}
                    />
                </span>
            </div>
        );
    };

    const balanceFilterTemplate = () => {
        return (
            <form className="p-fluid">
                <div className="field">
                    <div style={{margin:" 0 0 2px 1px", fontSize:"11.5px"}}>{t("report.sub")}</div>
                    <span>
                        <Dropdown
                            value={filter?.collected?.operator || null}
                            onChange={(e) => {
                                if(e.value){
                                    setAddedAmountOperator(true)
                                }
                                else{
                                    setAddedAmountOperator(false)
                                }
                                setFilterSubFun("collected", { operator: e.value })
                            }}
                            options={comparisonOperators} placeholder={t("report.equals")} />
                    </span>
                </div>

                
                <div className="field">
                <div style={{margin:' 0 0 -2rem 0.2rem',fontSize:'11.5px'}}>{t("amount")}</div>
                    <span>
                        <InputNumber
                            value={filter?.collected?.amount || 0}
                            onChange={(e) =>{
                                if(e.value){
                                    setAddedAmount(true)
                                } 
                                else{
                                    setAddedAmount(false)
                                }
                                setFilterSubFun("collected", { amount: e.value })
                            }}
                            mode="currency" currency={defMembership.business.country.currency}
                            locale="en-US" placeholder={t("amount")} />
                    </span>
                </div>
            </form >

        );
    };
   
    const filterClearStatus = (options) => {
        return <Button type="button"  onClick={(e) => {
            if(addedStatus){
            setFilterFun({ statuses: [] });
            setBodyFun({ statuses: [] });
            removeKeyValuePair('statusFilter');
            setAddedStatus(false)
            }
    }} 
        severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span> </Button>;
    };

    const filterClearPlanName = (options) => {
        return <Button type="button"  onClick={() => {
            if(addedInfo){
            setFilterFun({
                planGroups: [],
                plans: [],
                periods: [],
            });
            setBodyFun({
                planGroups: [],
                plans: [],
                periods: [],
            });
            removeKeyValuePair('planFilter')
            setAddedInfo(false)
            setSelectedNodeKeys([]); 
            setMakePlanSelectable(true);
            setMakeProductSelectable(true);
        }
        }}
         severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearCollected = (options) => {
        return <Button type="button"  onClick={() => { 
            if(addedAmountOperator)
             setBodyFun({
            collected: undefined
        });
        setFilterFun({
            collected: undefined
        });
        removeKeyValuePair('amountFilter')
        setAddedAmount(false)
    }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearPhoneNo = (options) => {
        return <Button type="button"  onClick={() => {
            if(addedContact)
            setBodyFun({
                busCustomers: [],
            });
            removeKeyValuePair('contactFilter')
            setFilterFun({ busCustomers: [] })
            setAddedContact(false)
        }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearStartedOn = (options) => {
        return <Button type="button"  onClick={() => { 
            if(addedActivity){ 
            setBodyFun({
            startedOn: undefined,
            renewsOn: undefined,
            cancelledOn: undefined,
            pausedOn: undefined,
            completedOn: undefined,
        });
        removeKeyValuePair('activityFilter')
        setAddedActivity(false)
        setValue("filters", [{}])
    }
        
    }
    } 
    severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
        
    };
    
    const tabs = [
        {
            label: "subscriber.title",
            columns: [
                {
                    field: "status",
                    body: statusTemplate,
                    header: t("products.status"),
                    headerClassName: filters?.statusFilter && "statusFilter",
                    filterField: "status",
                    showFilterMenuOptions: false,
                    showFilterMatchModes: false,
                    filterMenuClassName: "filterStatusBox",
                    style: { minWidth: "14rem" },
                    filter: true,
                    filterElement: statusFilterTemplate,
                    onFilterApplyClick: () => {
                        if(addedStatus){
                            updateKeyValuePair('statusFilter', true)
                            setBodyFun();
                        } 
                        if(!addedStatus && filters?.statusFilter){
                            setFilterFun({ statuses: [] });
                            setBodyFun({ statuses: [] });
                            removeKeyValuePair('statusFilter');
                            setAddedStatus(false)
                        } 
                    },
                    filterClear:filterClearStatus,
                },
                {
                    field: "plan.name",
                    header: t("report.plan_header"),
                    body: subscriptionBodyTemplate,
                    headerClassName: filters?.planFilter && "planFilter",
                    showFilterMenuOptions: false,
                    showFilterMatchModes: false,
                    filterMenuClassName: "filterSubsBox",
                    style: { minWidth: "14rem" },
                    filter: true,
                    filterElement: planFilterTemplate,
                    onFilterApplyClick: (e) => {
                        if(addedInfo){
                            updateKeyValuePair('planFilter', true)
                            setBodyFun();
                        } 
                        if(!addedInfoBilling && filters?.planFilter){
                            setBodyFun()
                        }        
                    },
                    filterClear: filterClearPlanName
                },
                {
                    field: "collected",
                    header: t("subscriber.amount_paid"),
                    body: amountTemplate,
                    headerClassName: filters?.amountFilter && "amountFilter",
                    filter: true,
                    showFilterMenuOptions: false,
                    showFilterMatchModes: false,
                    filterElement: balanceFilterTemplate,
                    filterMenuClassName: "filterAmtBox",
                    onFilterApplyClick: (e) => {
                        if(addedAmount && addedAmountOperator){
                            updateKeyValuePair('amountFilter', true)
                        setBodyFun();
                        }
                    },
                    filterClear: filterClearCollected
                },
                {
                    field: "businessCustomer.phoneNoMasked",
                    header: t("products.customer_info"),
                    body: ({ businessCustomer = { name: "", phoneNo: "", email: "" } }) => {
                        if (!businessCustomer) return <>-</>
                        const { name, phoneNo, email, customerNum } = businessCustomer
                        return (
                            <div className="subInfobodytemplate">
                                {name && <p className="tier-name sub-info">{name}</p>}
                                {customerNum && <p className="tier-name sub-customerRef">{customerNum}</p>}
                                {phoneNo && <p className="tier-phone sub-info">{phoneNo}</p>}
                                {email && <p className="tier-email sub-info-email">{email}</p>}
                            </div>
                        )
                    },
                    headerClassName: filters?.contactFilter && "contactFilter",
                    showFilterMenuOptions: false,
                    showFilterMatchModes: false,
                    filterMenuClassName: "filterBox",
                    filter: true,
                    filterElement: customersFilterTemplate,
                    onFilterApplyClick: () => {
                        if(addedContact){
                            updateKeyValuePair('contactFilter', true)
                            setBodyFun({
                                busCustomers: filter.busCustomers.map(({ id }) => id),
                            });
                        }
                       
                    }, 
                    filterClear: filterClearPhoneNo

                },
                {
                    field: "startedOn",
                    body: requestedOnBodyTemplate,
                    header: t("transaction.activity"),
                    headerClassName: filters?.activityFilter && "activityFilter",
                    filter: true,
                    filterElement: () => <DateFilterTemplate {...{control, setBodyFun, dateTypes, t,handleSubmit,dateComparisonOperators, setAddedActivity, updateKeyValuePair}} />,
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    showFilterMenuOptions: false,
                    showApplyButton: false,
                    filterMenuClassName: "filterBox",
                    filterClear: filterClearStartedOn
                },
            ],
            value: state.subscriptionReport,
            count: state.subscriptionReport?.length,
        },
    ];

    return (
        <div className="subscriptionReport">
            <Toast ref={toast} />
            <h6 className="title-tile">{t("Custom Report (Subscription)")}</h6>
            <CustomDataTable value={state.subscriptionReport} paginator={tabs[0].count > state.size} columns={tabs[0].columns} setState={setState} tableClassName="tier_detail_data_table custom_report" />
        </div>
    );
};

export default SubscriptionCustom;

const DateFilterTemplate =({control, setBodyFun, dateTypes, t,handleSubmit,dateComparisonOperators, setAddedActivity,updateKeyValuePair})=> {
    const [dateTypeEmptyColor, setDateTypeEmptyColor] = useState(null);
    const [operatorEmptyColor, setOperatorEmptyColor] = useState(null);
    const [dateEmptyColor, setDateEmptyColor] = useState(null);
  
    const { fields } = useFieldArray({
        control,
        name: "filters",
    });
   
    const handleFormSubmit = (data) => {
       data.filters[0].dateType === undefined? setDateTypeEmptyColor("#d0021b") : setDateTypeEmptyColor("#495057");
       data.filters[0].operator === undefined? setOperatorEmptyColor("#d0021b") : setOperatorEmptyColor("#495057");
       data.filters[0].date === undefined?  setDateEmptyColor("#d0021b") : setDateEmptyColor("#495057");
          
        if(data.filters[0].dateType !== undefined && data.filters[0].operator !== undefined && data.filters[0].date !== undefined){
            updateKeyValuePair('activityFilter', true)
        setBodyFun(
            data.filters.reduce(
                (prv, { dateType, operator, date }) => ({
                    ...prv,
                    [dateType]: { operator, date },
                }),
                {}
            )
        );
        setAddedActivity(true)
            }
           
    };

    return (
        <>
            {fields.map((fieldD, index) => (

                <form className="p-fluid" key={fieldD.id}>
                    <div className="field">
                    <div style={{fontSize:"11.5px", margin:"-1.5rem 0 0.1rem 0.2rem",color: dateTypeEmptyColor  }}>
                        {t("report.type")}</div>
                        <span>
                            <Controller
                                name={`filters.${index}.dateType`}
                                control={control}
                                render={({ field }) => {
                                    return <Dropdown

                                        id={field.name} value={field.value}
                                        onChange={(e) => field.onChange(e.value)}
                                        options={dateTypes} placeholder={t("report.type_hint")} />;
                                } } />
                        </span>
                    </div>
                     <div className="field">
                     <div style={{fontSize:"11.5px", margin:"0 0 2px 0", color: operatorEmptyColor}}>{t("report.mode")} </div>
                        <span>
                            <Controller
                                name={`filters.${index}.operator`}
                                control={control}
                                render={({ field }) => <Dropdown
                                    id={field.name} value={field.value}
                                    onChange={(e) => field.onChange(e.value)}
                                    options={dateComparisonOperators} placeholder={t("report.equals")} />}
                            />
                        </span>
                    </div>

                    <div className="field">
                        <div style={{fontSize:"11.5px", margin:"0 0 2px 0", color: dateEmptyColor}}>{t("report.date")} </div>
                        <span>
                            <Controller
                                name={`filters.${index}.date`}
                                control={control}
                                render={({ field }) => <Calendar
                                    id={field.name}
                                    placeholder={t("report.type_hint2")}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.value)}
                                    dateFormat="dd/mm/yy" mask="99/99/9999" showIcon
                                />}
                            />
                        </span>
                    </div> 
                </form>
            ))}
            <div className="p-column-filter-buttonbar ml-0 mt-2 activity-apply-btn">
            <Button label={t("report.apply")} className="p-button p-component p-button-sm" onClick={handleSubmit(handleFormSubmit)} />
            </div>
        </>
    );
};