import { call, put } from "redux-saga/effects";
import PaymentScheduleActions from "./payment-schedule.reducer";
import * as api from "../../../Service/api";

export function* getPaymentSchedule(action) {
	const { paymentScheduleId } = action;
	// make the call to the api
	const response = yield call(api.getPaymentSchedule, paymentScheduleId);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentScheduleSuccess(response.data));
	} else {
		yield put(PaymentScheduleActions.paymentScheduleFailure(response.data));
	}
}

export function* getPaymentScheduleProjection(action) {
	const { businessId, startDate, endDate } = action;
	// make the call to the api

	const response = yield call(
		api.getPaymentScheduleProjection,
		businessId,
		startDate,
		endDate
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.paymentScheduleProjectionSuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleProjectionFailure(response.data)
		);
	}
}

export function* getPaymentSchedules(action) {
	const { subscriberId, options } = action;
	// make the call to the api

	const response = yield call(api.getPaymentSchedules, subscriberId, options);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentScheduleAllSuccess(response.data));
	} else {
		yield put(PaymentScheduleActions.paymentScheduleAllFailure(response.data));
	}
}
export function* getPaymentSchedulesMissed(action) {
	const { subscriberId, options } = action;
	// make the call to the api

	const response = yield call(api.getSubscriptionMissedSchedule, subscriberId, options);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentScheduleMissedSuccess(response.data));
	} else {
		yield put(PaymentScheduleActions.paymentScheduleMissedFailure(response.data));
	}
}
export function* getPaymentSchedulesPending(action) {
	const { subscriberId, options } = action;
	// make the call to the api

	const response = yield call(api.getSubscriptionPendingSchedule, subscriberId, options);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentSchedulePendingSuccess(response.data));
	} else {
		yield put(PaymentScheduleActions.paymentSchedulePendingFailure(response.data));
	}
}

export function* getPaymentScheduleRange(action) {
	const { businessId, startDate, endDate, options } = action;
	// make the call to the api

	const response = yield call(
		api.getPaymentScheduleRange,
		businessId,
		startDate,
		endDate,
		options
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.paymentScheduleRangeSuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleRangeFailure(response.data)
		);
	}
}

export function* getPaymentScheduleSummary(action) {
	const { businessId, startDate, endDate } = action;

	// make the call to the api
	const response = yield call(
		api.getPaymentScheduleSummary,
		businessId,
		startDate,
		endDate,
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.paymentScheduleSummarySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleSummaryFailure(response.data)
		);
	}
}
export function* getWeeklyPaymentScheduleSummary(action) {
	const { businessId, startDate, endDate } = action;

	// make the call to the api
	const response = yield call(
		api.getWeeklyPaymentScheduleSummary,
		businessId,
		startDate,
		endDate,
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.weeklyPaymentScheduleSummarySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.weeklyPaymentScheduleSummaryFailure(response.data)
		);
	}
}
export function* getThreeMonthsPaymentScheduleSummary(action) {
	const { businessId, startDate, endDate } = action;

	// make the call to the api
	const response = yield call(
		api.get3MonthsPaymentScheduleSummary,
		businessId,
		startDate,
		endDate,
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.threeMonthsPaymentScheduleSummarySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.threeMonthsPaymentScheduleSummaryFailure(response.data)
		);
	}
}

export function* getSixMonthsPaymentScheduleSummary(action) {
	const { businessId, startDate, endDate } = action;

	// make the call to the api
	const response = yield call(
		api.get6MonthsPaymentScheduleSummary,
		businessId,
		startDate,
		endDate,
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.sixMonthsPaymentScheduleSummarySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.sixMonthsPaymentScheduleSummaryFailure(response.data)
		);
	}
}

export function* getTwelveMonthsPaymentScheduleSummary(action) {
	const { businessId, startDate, endDate } = action;

	// make the call to the api
	const response = yield call(
		api.get12MonthsPaymentScheduleSummary,
		businessId,
		startDate,
		endDate,
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.twelveMonthsPaymentScheduleSummarySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.twelveMonthsPaymentScheduleSummaryFailure(response.data)
		);
	}
}

export function* updatePaymentSchedule(action) {
	const { paymentSchedule } = action;
	// make the call to the api
	const idIsNotNull = !!paymentSchedule.id;
	const response = yield call(
		idIsNotNull ? api.updatePaymentSchedule : api.createPaymentSchedule,
		paymentSchedule
	);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.paymentScheduleUpdateSuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleUpdateFailure(response.data)
		);
	}
}

export function* notifyPaymentSchedule(action) {
	const { paymentScheduleId } = action;
	// make the call to the api

	const response = yield call(api.notifyPaymentSchedule, paymentScheduleId);

	// success?
	if (response.ok) {
		yield put(
			PaymentScheduleActions.paymentScheduleNotifySuccess(response.data)
		);
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleNotifyFailure(response.data)
		);
	}
}

export function* deletePaymentSchedule(action) {
	const { paymentScheduleId } = action;
	// make the call to the api

	const response = yield call(api.deletePaymentSchedule, paymentScheduleId);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentScheduleDeleteSuccess());
	} else {
		yield put(
			PaymentScheduleActions.paymentScheduleDeleteFailure(response.data)
		);
	}
}

export function* getDailyCharts(action) {
	const { businessId, startDate, endDate } = action
	//make the call to the api 

	const response = yield call(api.getDailyCharts, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.dailyChartsSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.dailyChartsFailure(response.data))
	}
}
export function* getWeeklyCharts(action) {
	const { businessId, startDate, endDate } = action
	//make the call to the api 

	const response = yield call(api.getWeeklyCharts, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.weeklyChartsSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.weeklyChartsFailure(response.data))
	}
}

export function* getThreeMonthsCharts(action) {
	const { businessId, startDate, endDate } = action
	//make the call to the api 

	const response = yield call(api.get3MonthlyCharts, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.threeMonthsChartsSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.threeMonthsChartsFailure(response.data))
	}
}

export function* getSixMonthsCharts(action) {
	const { businessId, startDate, endDate } = action
	//make the call to the api 

	const response = yield call(api.get6MonthlyCharts, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.sixMonthsChartsSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.sixMonthsChartsFailure(response.data))
	}
}

export function* getTwelveMonthsCharts(action) {
	const { businessId, startDate, endDate } = action
	//make the call to the api 

	const response = yield call(api.get12MonthlyCharts, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.twelveMonthsChartsSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.twelveMonthsChartsFailure(response.data))
	}
}

export function* getMissedSchedulesCount(action) {
	const { subscriberId, options } = action
	//make the call to the api 

	const response = yield call(api.getSubscriptionMissedScheduleCount, subscriberId, options);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.missedPaymentSchedulesCountSuccess(response.data))
	} else {
		yield put(PaymentScheduleActions.missedPaymentSchedulesCountFailure(response.data))
	}
}

