import { call, put } from 'redux-saga/effects'
import * as api from "../../Service/api";
import PayoutChannelActions from '../actions/payoutchannel-actions'

export function* getPayoutChannel(action) {
    const { payoutChannelId } = action
    // make the call to the api
    const response = yield call(api.getPayoutChannel, payoutChannelId)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelSuccess(response.data))
    } else {
        yield put(PayoutChannelActions.payoutChannelFailure(response.data))
    }
}

export function* getPayoutChannels(action) {
    const { businessId, options } = action
    // make the call to the api
    const response = yield call(api.getPayoutChannels, businessId, options)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelAllSuccess(response.data))
    } else {
        yield put(PayoutChannelActions.payoutChannelAllFailure(response.data))
    }
}

export function* getSavedPayoutChannels(action) {
    const { businessId, options } = action;
    // make the call to the api
    const response = yield call(api.getSavedPayoutChannels, businessId, options);

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelSavedSuccess(response.data));
    } else {
        yield put(PayoutChannelActions.payoutChannelSavedFailure(response.data));
    }
}

export function* getFavouriteChannels(action) {
    const { businessId, options } = action;
    // make the call to the api
    const response = yield call(api.getFavouriteChannels, businessId, options);

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelFavouriteSuccess(response.data));
    } else {
        yield put(PayoutChannelActions.payoutChannelFavouriteFailure(response.data));
    }
}

export function* setFavouritePayoutChannels(action) {
    const { payoutChannelId } = action;
    // make the call to the api
    const response = yield call(api.setFavouritePayoutChannels, payoutChannelId);

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelSetFavouriteSuccess(response.data));
    } else {
        yield put(
            PayoutChannelActions.payoutChannelSetFavouriteFailure(response.data)
        );
    }
}

export function* setUnfavouritePayoutChannels(action) {
    const { payoutChannelId } = action;
    // make the call to the api
    const response = yield call(api.setUnfavouritePayoutChannels, payoutChannelId);

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelSetUnfavouriteSuccess(response.data));
    } else {
        yield put(
            PayoutChannelActions.payoutChannelSetUnfavouriteFailure(response.data)
        );
    }
}

export function* updatePayoutChannel(action) {
    const { payoutChannel } = action
    // make the call to the api
    const idIsNotNull = !!payoutChannel.id
    const response = yield call(idIsNotNull ? api.updatePayoutChannel : api.createPayoutChannel, payoutChannel)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelUpdateSuccess(response.data))
    } else {
        yield put(PayoutChannelActions.payoutChannelUpdateFailure(response.data))
    }
}

export function* deletePayoutChannel(action) {
    const { payoutChannelId } = action
    // make the call to the api
    const response = yield call(api.deletePayoutChannel, payoutChannelId)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelDeleteSuccess())
    } else {
        yield put(PayoutChannelActions.payoutChannelDeleteFailure(response.data))
    }
}

export function* archivePayoutChannel(action) {
    const { payoutChannelId, username } = action
    // make the call to the api
    const response = yield call(api.archivePayoutChannel, payoutChannelId, username)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.payoutChannelArchiveSuccess())
    } else {
        yield put(PayoutChannelActions.payoutChannelArchiveFailure(response.data))
    }
}

export function* getFilteredPayoutChannels(action) {
    const { businessId, query, options } = action
    // make the call to the api
    const response = yield call(api.getFilteredPayoutChannels, businessId, query, options)

    // success?
    if (response.ok) {
        yield put(PayoutChannelActions.filteredPayoutChannelsSuccess(response.data))
    } else {
        yield put(PayoutChannelActions.filteredPayoutChannelsFailure(response.data))
    }
}
