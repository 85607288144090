import { call, put } from "redux-saga/effects";
import * as api from "../../../Service/api";
import MembershipInviteActions from "./membership-invite.reducer";

export function* getMembershipInvite(action) {
  const { membershipInviteId } = action;
  // make the call to the api
  const response = yield call(api.getMembershipInvite, membershipInviteId);
  // const response = yield call(callApi, apiCall)
  // success?
  if (response.ok) {
    response.data = mapDateFields(response.data);
    yield put(MembershipInviteActions.membershipInviteSuccess(response.data));
  } else {
    yield put(MembershipInviteActions.membershipInviteFailure(response.data));
  }
}
export function* getMembershipInvites(action) {
  const { businessId, options } = action;
  // make the call to the api
  const response = yield call(api.getMembershipInvites, businessId, options);
  // const response = yield call(callApi, apiCall)
  // success?
  if (response.ok) {
    yield put(
      MembershipInviteActions.membershipInviteAllSuccess(response.data)
    );
  } else {
    yield put(
      MembershipInviteActions.membershipInviteAllFailure(response.data)
    );
  }
}

export function* updateMembershipInvite(action) {
  const { membershipInvite } = action;
  // make the call to the api

  const response = yield call(api.updateMembershipInvite, membershipInvite);
  // const response = yield call(callApi, apiCall)

  // success?
  if (response.ok) {
    response.data = mapDateFields(response.data);
    yield put(
      MembershipInviteActions.membershipInviteUpdateSuccess(response.data)
    );
  } else {
    yield put(
      MembershipInviteActions.membershipInviteUpdateFailure(response.data)
    );
  }
}
// Create Membership Invite
export function* createMembershipInvite(action) {
  const { membershipInvite } = action;
  // make the call to the api

  const response = yield call(api.createMembershipInvite, membershipInvite);
  // const response = yield call(callApi, apiCall)

  // success?
  if (response.ok) {
    response.data = mapDateFields(response.data);
    yield put(
      MembershipInviteActions.membershipInviteCreateSuccess(response.data)
    );
  } else {
    yield put(
      MembershipInviteActions.membershipInviteCreateFailure(response.data)
    );
  }
}
export function* deleteMembershipInvite(action) {
  const { membershipInviteId } = action;
  // make the call to the api
  const response = yield call(api.deleteMembershipInvite, membershipInviteId);
  // const response = yield call(callApi, apiCall)
  // success?
  if (response.ok) {
    yield put(MembershipInviteActions.membershipInviteDeleteSuccess());
  } else {
    yield put(
      MembershipInviteActions.membershipInviteDeleteFailure(response.data)
    );
  }
}
function mapDateFields(data) {
  if (data.invitedOn) {
    data.invitedOn = new Date(data.invitedOn);
  }
  return data;
}

export function* resendMembershipInvite(action) {
  const { membershipInviteId } = action;
  //make the call to the api
  const response = yield call(api.resendMembershipInvite, membershipInviteId);
  //success?
  if (response.ok) {
    yield put(MembershipInviteActions.membershipInviteResendSuccess());
  } else {
    yield put(
      MembershipInviteActions.membershipInviteResendFailure(response.data)
    )
  }
}
