import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import WalletActions from "../../Redux/transition/wallet/wallet.reducer";
//imports from libraries
import { useTranslation } from "react-i18next";
//imports from componensts and styles
import "../../Styles/pages/transactions.scss";

const Summary = () => {
    const { t } = useTranslation("common");
    const wallet = useSelector((state) => state.wallet.wallet);
    const globalWallet = useSelector((state) => state.wallet.globalWallet);
    const planGroupsCount = useSelector((state) => state.planGroups.count);
    const pendingCount = useSelector(state => state.settlements.statusCount)
    const planGroups = useSelector((state) => state.planGroups.planGroups);
    const defMembership = useSelector((state) => state.defMembership.defMembership)

    const dispatch = useDispatch()
    const getPlanGroupsCount = useCallback((businessId) => dispatch(PlanGroupActions.planGroupAllCountRequest(businessId)), [dispatch])
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
    const getGlobalWallet = useCallback((businessId) => { dispatch(WalletActions.globalWalletRequest(businessId)) }, [dispatch]);

    const [state, setState] = useState({
        defMembership: {},
        wallet: null,
        globalWallet: null,
        planGroupsCount: 0,
        pendingCount: 0,
        planGroups: null,
        balance:null,
        available:null,
        pending:null

    });

    useEffect(() => {
        if (!defMembership) {
            getMembership()
        }
        if (defMembership) {
            getPlanGroupsCount(defMembership.business.id)
            getGlobalWallet(defMembership.business.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (wallet) {
            setState((state) => {
                return { ...state, wallet: wallet };
            });
        }
        if (planGroupsCount) {
            setState((state) => {
                return { ...state, planGroupsCount: planGroupsCount };
            });
        }
        if (planGroups) {
            setState((state) => {
                return { ...state, planGroups: planGroups };
            });
        }
        if (pendingCount) {
            setState((state) => {
                return { ...state, pendingCount: pendingCount };
            });
        }
        if(globalWallet){
            let balance = globalWallet.redeemable - globalWallet.withdrawn
            let available = globalWallet.redeemable - (globalWallet.withdrawn + globalWallet.pending)
            let pending =  globalWallet.pending
            setState((state) => ({...state, available, balance, pending, globalWallet}))
        }
    }, [wallet, planGroupsCount, planGroups, pendingCount, globalWallet]);

    const amount = (money, currency) => {
        if (money) {
            return `${currency} ${money.toFixed(2)}`
        }
        else {
            return `${currency} 0.00`
        }
    }

    return (
        <>
            {state.globalWallet &&
                <>
                    <div className="" style={{ padding: "0px 1rem" }}>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-4">
                                <div className=" shadow-2 p-3 border-round mr-5" id="overview-balance">
                                    <div className="flex justify-content-between mb-3">
                                        <div>
                                            <span className="block  mb-3 overview-title" >{t("transaction.balance")}</span>
                                            <div className="   overview-amount" >{amount(state.balance, defMembership.business.currency)}</div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                            <i className="pi pi-wallet overview-icon" style={{ 'fontSize': '2.3em' }}></i>
                                        </div>
                                    </div>
                                    <span style={{ color: "black" }}>
                                        {`${t("transaction.total_balance")} ${state.planGroupsCount} ${t("product_accounts")}`}
                                    </span>

                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-4">
                                <div className=" shadow-2 p-3 border-round mr-5" id="overview-available">
                                    <div className="flex justify-content-between mb-3">
                                        <div>
                                            <span className="block  mb-3 overview-title" >{t("transaction.available")}</span>
                                            <div className="   overview-amount" >{amount(state.available, defMembership.business.currency)}</div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center border-round" style={{ width: '2.5rem', height: '2.5rem' }}>

                                        </div>
                                    </div>
                                    <span style={{ color: "black" }}>
                                        {`${t("transaction.available_amount")} ${state.planGroupsCount} ${t("product_accounts")}`}
                                    </span>

                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-4">
                                <div className=" shadow-2 p-3 border-round" id="overview-pending">
                                    <div className="flex justify-content-between mb-3">
                                        <div>
                                            <span className="block  mb-3 overview-title" >{t("transaction.pending")}</span>
                                            <div className="   overview-amount" >{amount(state.pending, defMembership.business.currency)}</div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center border-round" style={{ width: '2.5rem', height: '2.5rem' }}>

                                        </div>
                                    </div>
                                    <span style={{ color: "black" }}>
                                        {`${t("transaction.pending_amount")} ${state.pendingCount} ${t("requests")}`}
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>

    );
};

export default Summary;