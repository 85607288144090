import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  planGroupRequest: ["planGroupId"],
  planGroupAllRequest: ["businessId", "options"],
  planGroupsFilterRequest: ["businessId", "name", "options"],
  planGroupAllCountRequest: ["businessId"],
  planGroupGraphedRequest: ["businessId", "options"],
  planGroupGraphedActiveRequest: ["businessId", "options"],
  planGroupGraphedArchivedRequest: ["businessId", "options"],
  planGroupCreateRequest: ["planGroup"],
  planGroupUpdateRequest: ["planGroupId", "planGroup"],
  planGroupDeleteRequest: ["planGroupId"],
  planGroupArchiveRequest: ["planGroupId", "username"],
  setPlanGroupGraphedActive: ["planGroupsGraphed"],
  planGroupTierRequest: ["planGroupId", "options"],
  planGroupPaymentMissedRequest: ["planGroupId", "options"],
  planGroupPaymentMissedCountRequest: ["planGroupId", "options"],
  planGroupPaymentPendingRequest: ["planGroupId", "options"],
  planGroupPaymentPendingCountRequest: ["planGroupId", "options"],
  planGroupPaymentPaidRequest: ["planGroupId", "options"],
  planGroupPaymentPaidCountRequest: ["planGroupId", "options"],
  planGroupPerformanceRequest: ["planGroupId", "options"],
  planBusinessPerformanceRequest: ["businessId", "options"],
  planGroupPlansSummaryRequest: ["planGroupId", "options"],
  planGroupCustomersSummaryRequest: ["planGroupId", "options"],
  planGroupDuplicateRequest: ["id", "name"],
  enablePlanReportRequest: ["planGroupId", "report"],

  planGroupSuccess: ["planGroup"],
  planGroupAllSuccess: ["planGroups"],
  planGroupsFilterSuccess: ["filterPlanGroups"],
  planGroupAllCountSuccess: ["count"],
  planGroupGraphedSuccess: ["planGroupsGraphed"],
  planGroupGraphedActiveSuccess: ["planGroupsGraphedActive"],
  planGroupGraphedArchivedSuccess: ["planGroupsGraphedArchived"],
  planGroupUpdateSuccess: ["updatedPlanGroup"],
  planGroupCreateSuccess: ["planGroup"],
  planGroupDeleteSuccess: [],
  planGroupArchiveSuccess: [],
  planGroupTierSuccess: ["tiers"],
  planGroupPaymentMissedSuccess: ["paymentMissed"],
  planGroupPaymentMissedCountSuccess: ["paymentMissedCount"],
  planGroupPaymentPendingSuccess: ["paymentPending"],
  planGroupPaymentPendingCountSuccess: ["paymentPendingCount"],
  planGroupPaymentPaidSuccess: ["paymentPaid"],
  planGroupPaymentPaidCountSuccess: ["paymentPaidCount"],
  planGroupPerformanceSuccess: ["performance"],
  planBusinessPerformanceSuccess: ["performance"],
  planGroupPlansSummarySuccess: ["summary"],
  planGroupCustomersSummarySuccess: ["summary"],
  planGroupDuplicateSuccess: ["duplicate"],
  enablePlanReportSuccess: ["planReport"],

  planGroupFailure: ["error"],
  planGroupAllFailure: ["error"],
  planGroupsFilterFailure: ["error"],
  planGroupAllCountFailure: ["error"],
  planGroupGraphedFailure: ["error"],
  planGroupGraphedArchivedFailure: ["error"],
  planGroupGraphedActiveFailure: ["error"],
  planGroupCreateFailure: ["error"],
  planGroupUpdateFailure: ["error"],
  planGroupDeleteFailure: ["error"],
  planGroupArchiveFailure: ["error"],
  planGroupTierFailure: ["error"],
  planGroupPaymentMissedFailure: ["error"],
  planGroupPaymentMissedCountFailure: ["error"],
  planGroupPaymentPendingFailure: ["error"],
  planGroupPaymentPendingCountFailure: ["error"],
  planGroupPaymentPaidFailure: ["error"],
  planGroupPaymentPaidCountFailure: ["error"],
  planGroupPerformanceFailure: ["error"],
  planBusinessPerformanceFailure: ["error"],
  planGroupPlansSummaryFailure: ["error"],
  planGroupCustomersSummaryFailure: ["error"],
  planGroupDuplicateFailure: ["error"],
  planReportFailure: ["error"],
});

export const PlanGroupTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingOne: false,
  fetchingAll: false,
  filteringPlanGroups: false,
  fetchingAllCount: false,
  fetchingGraphed: false,
  fetchingGraphedArchived: false,
  fetchingGraphedActive: false,
  fetchingTiers: false,
  fetchingMissed: false,
  fetchingMissedCount: false,
  fetchingPending: false,
  fetchingPendingCount: false,
  fetchingPaid: false,
  fetchingPaidCount: false,
  fetchingPerformance: false,
  fetchingBusinessPerformance: false,
  fetchingPlansSummary: false,
  fetchingCustomersSummary: false,
  creating: false,
  updating: false,
  deleting: false,
  archiving: false,
  duplicating: false,
  enabling: false,

  planGroup: null,
  planGroups: null,
  filterPlanGroups: null,
  count: null,
  planGroupsGraphed: null,
  planGroupsGraphedArchived: null,
  planGroupsGraphedActive: null,
  tiers: null,
  missed: null,
  missedCount: null,
  pending: null,
  pendingCount: null,
  paid: null,
  paidCount: null,
  performance: null,
  businessPerformance: null,
  archiveSuccess: false,
  customersSummary: false,
  plansSummary: false,
  successArchive: null,
  duplicate: null,
  planReport: false,
  updatedPlanGroup: {},

  errorOne: null,
  errorAll: null,
  errorFiltering: null,
  errorAllCount: null,
  errorCreating: null,
  errorUpdating: null,
  errorDeleting: null,
  errorGraphed: null,
  errorGraphedArchived: null,
  errorGraphedActive: null,
  errorArchiving: null,
  errorTiers: null,
  errorMissed: null,
  errorMissedCount: null,
  errorPending: null,
  errorPendingCount: null,
  errorPaid: null,
  errorPaidCount: null,
  errorBusinessPerformance: null,
  errorPlansSummary: null,
  errorCustomersSummary: null,
  errorDuplicate: null,
  errorEnabling: null,
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
  return { ...state, fetchingOne: true, planGroup: null };
};

// request the data from an api
export const allRequest = (state) => {
  return { ...state, fetchingAll: true, planGroups: null };
};

// request the data from an api
export const filterRequest = (state) => {
  return { ...state, filteringPlanGroups: true, filterPlanGroups: null };
};

// request the data from an api
export const allCountRequest = (state) => {
  return { ...state, fetchingAllCount: true };
};

// request the data from an api
export const graphedRequest = (state) => {
  return { ...state, fetchingGraphed: true, planGroupsGraphed: null };
};

// request the data from an api
export const graphedArchivedRequest = (state) => {
  return {
    ...state,
    fetchingGraphedArchived: true,
    planGroupsGraphedArchived: null,
  };
};

// request the data from an api
export const graphedActiveRequest = (state) => {
  return {
    ...state,
    fetchingGraphedActive: true,
    planGroupsGraphedActive: null,
  };
};

// request to create from an api
export const createRequest = (state) => {
  return { ...state, creating: true, errorCreating: null, planGroup: null };
};
export const updateRequest = (state) => {
  return { ...state, updating: true, updatedPlanGroup: {} };
};
// request to delete from an api
export const deleteRequest = (state) => {
  return { ...state, deleting: true };
};

// request to archive from an api
export const archiveRequest = (state) => {
  return {
    ...state,
    archiving: true,
    errorArchiving: null,
    archiveSuccess: false,
  };
};

export const tierRequest = (state) => {
  return { ...state, fetchingTiers: true, tiers: null, errorTiers: null };
};

export const missedRequest = (state) => {
  return { ...state, fetchingMissed: true, missed: null, errorMissed: null };
};

export const missedCountRequest = (state) => {
  return {
    ...state,
    fetchingMissedCount: true,
    missedCount: null,
    errorMissedCount: null,
  };
};

export const pendingRequest = (state) => {
  return { ...state, fetchingPending: true, pending: null, errorPending: null };
};

export const pendingCountRequest = (state) => {
  return {
    ...state,
    fetchingPendingCount: true,
    pendingCount: null,
    errorPendingCount: null,
  };
};

export const paidRequest = (state) => {
  return { ...state, fetchingPaid: true, paid: null, errorPaid: null };
};

export const paidCountRequest = (state) => {
  return {
    ...state,
    fetchingPaidCount: true,
    paidCount: null,
    errorPaidCount: null,
  };
};
export const performanceRequest = (state) => {
  return {
    ...state,
    fetchingPerformance: true,
    performance: null,
    errorPerformance: null,
  };
};
export const businessPerformanceRequest = (state) => {
  return {
    ...state,
    fetchingBusinessPerformance: true,
    businessPerformance: null,
    errorBusinessPerformance: null,
  };
};
export const plansSummaryRequest = (state) => {
  return {
    ...state,
    fetchingPlansSummary: true,
    plansSummary: null,
    errorPlansSummary: null,
  };
};
export const customersSummaryRequest = (state) => {
  return {
    ...state,
    fetchingCustomersSummary: true,
    customersSummary: null,
    errorCustomersSummary: null,
  };
};
export const duplicateRequest = (state) => {
  return { ...state, duplicating: true, duplicate: null, errorDuplicate: null };
};
export const enablePlanReportRequest = (state) => {
  return { ...state, enabling: true, planReport: false, errorEnabling: null };
};
// successful api lookup for single entity
export const success = (state, action) => {
  const { planGroup } = action;
  return { ...state, fetchingOne: false, errorOne: null, planGroup };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
  const { planGroups } = action;
  return { ...state, fetchingAll: false, errorAll: null, planGroups };
};

// successful api lookup for all entities
export const filterSuccess = (state, action) => {
  const { filterPlanGroups } = action;
  return {
    ...state,
    filteringPlanGroups: false,
    errorFiltering: null,
    filterPlanGroups: filterPlanGroups,
  };
};

// successful api lookup for all entities
export const allCountSuccess = (state, action) => {
  const { count } = action;
  return { ...state, fetchingAllCount: false, errorAllCount: null, count };
};

// successful api lookup for all entities
export const graphedSuccess = (state, action) => {
  const { planGroupsGraphed } = action;
  return {
    ...state,
    fetchingGraphed: false,
    errorGraphed: null,
    planGroupsGraphed,
  };
};

// successful api lookup for all entities
export const graphedArchivedSuccess = (state, action) => {
  const { planGroupsGraphedArchived } = action;
  return {
    ...state,
    fetchingGraphedArchived: false,
    errorGraphedArchived: null,
    planGroupsGraphedArchived,
  };
};
// successful api lookup for all entities
export const graphedActiveSuccess = (state, action) => {
  const { planGroupsGraphedActive } = action;
  return {
    ...state,
    fetchingGraphedActive: false,
    errorGraphedActive: null,
    planGroupsGraphedActive,
  };
};

// successful api create
export const createSuccess = (state, action) => {
  const { planGroup } = action;
  return { ...state, creating: false, errorCreating: null, planGroup };
};
// successful api update
export const updateSuccess = (state, action) => {
  const { updatedPlanGroup } = action;
  return { ...state, updating: false, errorUpdating: null, updatedPlanGroup };
};
// successful api delete
export const deleteSuccess = (state) => {
  return { ...state, deleting: false, errorDeleting: null, planGroup: null };
};

// successful archiving delete
export const archiveSuccess = (state) => {
  return {
    ...state,
    archiving: false,
    errorArchiving: null,
    archiveSuccess: true,
  };
};

export const tierSuccess = (state, action) => {
  const { tiers } = action;
  return { ...state, fetchingTiers: false, errorTiers: null, tiers: tiers };
};

export const missedSuccess = (state, action) => {
  const { paymentMissed } = action;
  return {
    ...state,
    fetchingMissed: false,
    errorMissed: null,
    missed: paymentMissed,
  };
};

export const missedCountSuccess = (state, action) => {
  const { paymentMissedCount } = action;
  return {
    ...state,
    fetchingMissedCount: false,
    errorMissedCount: null,
    missedCount: paymentMissedCount,
  };
};

export const pendingSuccess = (state, action) => {
  const { paymentPending } = action;
  return {
    ...state,
    fetchingPending: false,
    errorPending: null,
    pending: paymentPending,
  };
};

export const pendingCountSuccess = (state, action) => {
  const { paymentPendingCount } = action;
  return {
    ...state,
    fetchingPendingCount: false,
    errorPendingCount: null,
    pendingCount: paymentPendingCount,
  };
};

export const paidSuccess = (state, action) => {
  const { paymentPaid } = action;
  return { ...state, fetchingPaid: false, errorPaid: null, paid: paymentPaid };
};

export const paidCountSuccess = (state, action) => {
  const { paymentPaidCount } = action;
  return {
    ...state,
    fetchingPaidCount: false,
    errorPaidCount: null,
    paidCount: paymentPaidCount,
  };
};
export const performanceSuccess = (state, action) => {
  const { performance } = action;
  return {
    ...state,
    fetchingPerformance: false,
    errorPerformance: null,
    performance,
  };
};

export const businessPerformanceSuccess = (state, action) => {
  const { performance } = action;
  return {
    ...state,
    fetchingBusinessPerformance: false,
    errorBusinessPerformance: null,
    businessPerformance: performance,
  };
};

export const plansSummarySuccess = (state, action) => {
  const { summary } = action;
  return {
    ...state,
    fetchingPlansSummary: false,
    plansSummary: summary,
    errorPlansSummary: null,
  };
};
export const customersSummarySuccess = (state, action) => {
  const { summary } = action;
  return {
    ...state,
    fetchingCustomersSummary: false,
    customersSummary: summary,
    errorCustomersSummary: null,
  };
};
export const duplicateSuccess = (state, action) => {
  return {
    ...state,
    duplicating: false,
    duplicate: true,
    errorDuplicate: null,
  };
};
export const enablePlanReportSuccess = (state, action) => {
  const { planReport } = action
  return { ...state, enabling: false, planReport, errorEnabling: null };
};
// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingOne: false, errorOne: error, planGroup: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingAll: false, errorAll: error, planGroups: null };
};

// Something went wrong fetching all entities.
export const filterFailure = (state, action) => {
  const { errorFiltering } = action;
  return {
    ...state,
    filteringPlanGroups: false,
    errorAll: errorFiltering,
    filterPlanGroups: null,
  };
};

// Something went wrong fetching all entities.
export const allCountFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingAllCount: false, errorAllCount: error };
};

// Something went wrong fetching all entities.
export const graphedFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingGraphed: false,
    errorGraphed: error,
    planGroupsGraphed: null,
  };
};

// Something went wrong fetching all entities.
export const graphedArchivedFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingGraphedArchived: false,
    errorGraphedArchived: error,
    planGroupsGraphedArchived: null,
  };
};

// Something went wrong fetching all entities.
export const graphedActiveFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingGraphedActive: false,
    errorGraphedActive: error,
    planGroupsGraphedActive: null,
  };
};

export const setPlanGroupGraphedActive = (state, action) => {
  const { planGroupsGraphed } = action;
  return {
    ...state,
    fetchingGraphed: false,
    errorGraphed: null,
    planGroupsGraphed,
  };
};

// Something went wrong creating.
export const createFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    creating: false,
    errorCreating: error,
    planGroup: null,
  };
};
// Something went wrong updating.
export const updateFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    updating: false,
    errorUpdating: error,
    updatedPlanGroup: {}
  };
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    deleting: false,
    errorDeleting: error,
    planGroup: state.planGroup,
  };
};
// Something went wrong archiving.
export const archiveFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    archiving: false,
    archiveSuccess: false,
    errorArchiving: error,
    planGroup: state.planGroup,
  };
};

export const tierFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingTiers: false,
    errorTiers: error,
    tiers: null,
  };
};

export const missedFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingMissed: false,
    errorMissed: error,
    missed: null,
  };
};

export const missedCountFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingMissedCount: false,
    errorMissedCount: error,
    missedCount: null,
  };
};

export const pendingFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPending: false,
    errorPending: error,
    pending: null,
  };
};

export const pendingCountFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPendingCount: false,
    errorPendingCount: error,
    pendingCount: null,
  };
};

export const paidFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPaid: false,
    errorPaid: error,
    paid: null,
  };
};
export const paidCountFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPaidCount: false,
    errorPaidCount: error,
    paidCount: null,
  };
};
export const performanceFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPerformance: false,
    errorPerformance: error,
    performance: null,
  };
};
export const businessPerformanceFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingBusinessPerformance: false,
    errorBusinessPerformance: error,
    businessPerformance: null,
  };
};
export const plansSummaryFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPlansSummary: false,
    plansSummary: null,
    errorPlansSummary: error,
  };
};
export const customersSummaryFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingCustomersSummary: false,
    customersSummary: null,
    errorCustomersSummary: error,
  };
};
export const duplicateFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    duplicating: false,
    duplicate: null,
    errorDuplicate: error,
  };
};
export const enablePlanReportFailure = (state, action) => {
  const { error } = action;
  return { ...state, enabling: false, planReport: false, errorEnabling: error };
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PLAN_GROUP_REQUEST]: request,
  [Types.PLAN_GROUP_ALL_REQUEST]: allRequest,
  [Types.PLAN_GROUPS_FILTER_REQUEST]: filterRequest,
  [Types.PLAN_GROUP_ALL_COUNT_REQUEST]: allCountRequest,
  [Types.PLAN_GROUP_GRAPHED_REQUEST]: graphedRequest,
  [Types.PLAN_GROUP_GRAPHED_ACTIVE_REQUEST]: graphedRequest,
  [Types.PLAN_GROUP_GRAPHED_ARCHIVED_REQUEST]: graphedArchivedRequest,
  [Types.PLAN_GROUP_GRAPHED_ACTIVE_REQUEST]: graphedActiveRequest,
  [Types.PLAN_GROUP_CREATE_REQUEST]: createRequest,
  [Types.PLAN_GROUP_UPDATE_REQUEST]: updateRequest,
  [Types.PLAN_GROUP_DELETE_REQUEST]: deleteRequest,
  [Types.PLAN_GROUP_ARCHIVE_REQUEST]: archiveRequest,
  [Types.SET_PLAN_GROUP_GRAPHED_ACTIVE]: setPlanGroupGraphedActive,
  [Types.PLAN_GROUP_TIER_REQUEST]: tierRequest,
  [Types.PLAN_GROUP_PAYMENT_MISSED_REQUEST]: missedRequest,
  [Types.PLAN_GROUP_PAYMENT_MISSED_COUNT_REQUEST]: missedCountRequest,
  [Types.PLAN_GROUP_PAYMENT_PENDING_REQUEST]: pendingRequest,
  [Types.PLAN_GROUP_PAYMENT_PENDING_COUNT_REQUEST]: pendingCountRequest,
  [Types.PLAN_GROUP_PAYMENT_PAID_REQUEST]: paidRequest,
  [Types.PLAN_GROUP_PAYMENT_PAID_COUNT_REQUEST]: paidCountRequest,
  [Types.PLAN_GROUP_PERFORMANCE_REQUEST]: performanceRequest,
  [Types.PLAN_BUSINESS_PERFORMANCE_REQUEST]: businessPerformanceRequest,
  [Types.PLAN_GROUP_PLANS_SUMMARY_REQUEST]: plansSummaryRequest,
  [Types.PLAN_GROUP_CUSTOMERS_SUMMARY_REQUEST]: customersSummaryRequest,
  [Types.PLAN_GROUP_DUPLICATE_REQUEST]: duplicateRequest,
  [Types.ENABLE_PLAN_REPORT_REQUEST]: enablePlanReportRequest,

  [Types.PLAN_GROUP_SUCCESS]: success,
  [Types.PLAN_GROUP_ALL_SUCCESS]: allSuccess,
  [Types.PLAN_GROUPS_FILTER_SUCCESS]: filterSuccess,
  [Types.PLAN_GROUP_ALL_COUNT_SUCCESS]: allCountSuccess,
  [Types.PLAN_GROUP_GRAPHED_SUCCESS]: graphedSuccess,
  [Types.PLAN_GROUP_GRAPHED_ARCHIVED_SUCCESS]: graphedArchivedSuccess,
  [Types.PLAN_GROUP_GRAPHED_ACTIVE_SUCCESS]: graphedActiveSuccess,
  [Types.PLAN_GROUP_CREATE_SUCCESS]: createSuccess,
  [Types.PLAN_GROUP_UPDATE_SUCCESS]: updateSuccess,
  [Types.PLAN_GROUP_DELETE_SUCCESS]: deleteSuccess,
  [Types.PLAN_GROUP_ARCHIVE_SUCCESS]: archiveSuccess,
  [Types.PLAN_GROUP_TIER_SUCCESS]: tierSuccess,
  [Types.PLAN_GROUP_PAYMENT_MISSED_SUCCESS]: missedSuccess,
  [Types.PLAN_GROUP_PAYMENT_MISSED_COUNT_SUCCESS]: missedCountSuccess,
  [Types.PLAN_GROUP_PAYMENT_PENDING_SUCCESS]: pendingSuccess,
  [Types.PLAN_GROUP_PAYMENT_PENDING_COUNT_SUCCESS]: pendingCountSuccess,
  [Types.PLAN_GROUP_PAYMENT_PAID_SUCCESS]: paidSuccess,
  [Types.PLAN_GROUP_PAYMENT_PAID_COUNT_SUCCESS]: paidCountSuccess,
  [Types.PLAN_GROUP_PERFORMANCE_SUCCESS]: performanceSuccess,
  [Types.PLAN_BUSINESS_PERFORMANCE_SUCCESS]: businessPerformanceSuccess,
  [Types.PLAN_GROUP_PLANS_SUMMARY_SUCCESS]: plansSummarySuccess,
  [Types.PLAN_GROUP_CUSTOMERS_SUMMARY_SUCCESS]: customersSummarySuccess,
  [Types.PLAN_GROUP_DUPLICATE_SUCCESS]: duplicateSuccess,
  [Types.ENABLE_PLAN_REPORT_SUCCESS]: enablePlanReportSuccess,

  [Types.PLAN_GROUP_FAILURE]: failure,
  [Types.PLAN_GROUP_ALL_FAILURE]: allFailure,
  [Types.PLAN_GROUPS_FILTER_FAILURE]: filterFailure,
  [Types.PLAN_GROUP_ALL_COUNT_FAILURE]: allCountFailure,
  [Types.PLAN_GROUP_GRAPHED_FAILURE]: graphedFailure,
  [Types.PLAN_GROUP_GRAPHED_ARCHIVED_FAILURE]: graphedArchivedFailure,
  [Types.PLAN_GROUP_GRAPHED_ACTIVE_FAILURE]: graphedActiveFailure,
  [Types.PLAN_GROUP_CREATE_FAILURE]: createFailure,
  [Types.PLAN_GROUP_UPDATE_FAILURE]: updateFailure,
  [Types.PLAN_GROUP_DELETE_FAILURE]: deleteFailure,
  [Types.PLAN_GROUP_ARCHIVE_FAILURE]: archiveFailure,
  [Types.PLAN_GROUP_TIER_FAILURE]: tierFailure,
  [Types.PLAN_GROUP_PAYMENT_MISSED_FAILURE]: missedFailure,
  [Types.PLAN_GROUP_PAYMENT_MISSED_COUNT_FAILURE]: missedCountFailure,
  [Types.PLAN_GROUP_PAYMENT_PENDING_FAILURE]: pendingFailure,
  [Types.PLAN_GROUP_PAYMENT_PENDING_COUNT_FAILURE]: pendingCountFailure,
  [Types.PLAN_GROUP_PAYMENT_PAID_FAILURE]: paidFailure,
  [Types.PLAN_GROUP_PAYMENT_PAID_COUNT_FAILURE]: paidCountFailure,
  [Types.PLAN_GROUP_PERFORMANCE_FAILURE]: performanceFailure,
  [Types.PLAN_BUSINESS_PERFORMANCE_FAILURE]: businessPerformanceFailure,
  [Types.PLAN_GROUP_PLANS_SUMMARY_FAILURE]: plansSummaryFailure,
  [Types.PLAN_GROUP_CUSTOMERS_SUMMARY_FAILURE]: customersSummaryFailure,
  [Types.PLAN_GROUP_DUPLICATE_FAILURE]: duplicateFailure,
  [Types.PLAN_REPORT_FAILURE]: enablePlanReportFailure,
});
