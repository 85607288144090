import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	paymentTrackRequest: ["paymentTrackId"],
	collectedPaymentTrackRequest: ["businessId", "filter", "options"],
	missedPaymentTrackRequest: ["businessId", "filter", "options"],
	externalPaymentTrackRequest: ["businessId", "filter", "options"],
	paymentTrackRangeRequest: ["businessId", "startDate", "endDate", "options"],
	paymentTrackRevenueRequest: ["businessId", "startDate", "endDate"],
	paymentTrackAllRequest: ["subscriberId", "options"],
	paymentTrackUpdateRequest: ["paymentTrack"],
	paymentTrackDeleteRequest: ["paymentTrackId"],
	paymentTrackGroupRequest: ["planGroupId", "options"],
	paymentTrackGroupCountRequest: ["planGroupId", "options"],
	paymentMarkExternallyRequest: ["scheduleId", "membershipId", "schedule"],
	subscriberExternalPaymentsRequest: ["businessId", "subId", "options"],
	planGroupExternalPaymentsRequest: ["businessId", "groupId", "options"],
	planExternalPaymentsRequest: ["businessId", "planId", "options"],
	businessCustomerExternalPaymentsRequest: ["businessId", "bcId", "options"],
	paymentTracksCountRequest: ["subscriberId", "options"],

	paymentTrackSuccess: ["paymentTrack"],
	collectedPaymentTrackSuccess: ["paymentTrack"],
	missedPaymentTrackSuccess: ["paymentTrack"],
	externalPaymentTrackSuccess: ["paymentTrack"],
	paymentTrackRangeSuccess: ["paymentTrackRange"],
	paymentTrackRevenueSuccess: ["revenue"],
	paymentTrackAllSuccess: ["paymentTracks"],
	paymentTrackUpdateSuccess: ["paymentTrack"],
	paymentTrackDeleteSuccess: [],
	PaymentTrackGroupSuccess: ["paymentTrackGroup"],
	PaymentTrackGroupCountSuccess: ["paymentTrackGroupCount"],
	PaymentMarkExternallySuccess: ["paymentMark"],
	subscriberExternalPaymentsSuccess: ["subsPayments"],
	planGroupExternalPaymentsSuccess: ["groupPayments"],
	planExternalPaymentsSuccess: ["planPayments"],
	businessCustomerExternalPaymentsSuccess: ["businessCustomerPayments"],
	paymentTracksCountSuccess: ["count"],


	paymentTrackFailure: ["error"],
	collectedPaymentTrackFailure: ["error"],
	missedPaymentTrackFailure: ["error"],
	externalPaymentTrackFailure: ["error"],
	paymentTrackRangeFailure: ["error"],
	paymentTrackRevenueFailure: ["error"],
	paymentTrackAllFailure: ["error"],
	paymentTrackUpdateFailure: ["error"],
	paymentTrackDeleteFailure: ["error"],
	paymentTrackGroupFailure: ["error"],
	paymentTrackGroupCountFailure: ["error"],
	paymentMarkExternallyFailure: ["error"],
	subscriberExternalPaymentsFailure: ["error"],
	planGroupExternalPaymentsFailure: ["error"],
	planExternalPaymentsFailure: ["error"],
	businessCustomerExternalPaymentsFailure: ["error"],
	paymentTracksCountFailure: ["error"],

});

export const PaymentTrackTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingOne: null,
	fetchingAll: null,
	fetchingCollectedPayments: null,
	fetchingMissedPayments: null,
	fetchingExternalPayments: null,
	fetchingRange: null,
	fetchingRevenue: null,
	fetchingGroup: false,
	fetchingGroupCount: null,
	updating: null,
	markingExternally: false,
	deleting: null,
	fetchingSubsExternalPayments: null,
	fetchingPlanGroupsExternalPayments: null,
	fetchingPlansExternalPayments: null,
	fetchingBusinessCustomersExternalPayments: null,
	fetchingPaymentTracksCount: null,

	paymentTrack: null,
	paymentMark: null,
	collectedPaymentTracks: null,
	missedPaymentTracks: null,
	externalPaymentTracks: null,
	paymentTracks: null,
	paymentTrackRange: null,
	paymentTrackGroup: null,
	paymentTrackGroupCount: null,
	revenue: null,
	subsPayments: null,
	groupPayments: null,
	planPayments: null,
	businessCustomerPayments: null,
	paymentTracksCount: null,

	errorOne: null,
	errorAll: null,
	errorCollectedPayments: null,
	errorMissedPayments: null,
	errorExternalPayments: null,
	errorRange: null,
	errorRevenue: null,
	errorUpdating: null,
	errorDeleting: null,
	errorGroup: null,
	errorGroupCount: null,
	errorMark: null,
	errorSubsPayments: null,
	errorGroupPayments: null,
	errorPlanPayments: null,
	errorBusinessCustomerPayments: null,
	errorFetchingPaymentTracksCount: null
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
	return { ...state, fetchingOne: true, paymentTrack: null };
};

// request the data from an api
export const allRequest = (state) => {
	return { ...state, fetchingAll: true, paymentTracks: null };
};

// request the data from an api
export const collectedRequest = (state) => {
	return { ...state, fetchingCollectedPayments: true, collectedPaymentTracks: null, errorCollectedPayments: null };
};
// request the data from an api
export const missedRequest = (state) => {
	return { ...state, fetchingMissedPayments: true, missedPaymentTracks: null, errorMissedPayments: null };
};
// request the data from an api
export const externalRequest = (state) => {
	return { ...state, fetchingExternalPayments: true, externalPaymentTracks: null, errorExternalPayments: null };
};

// request the range of tracks from an api
export const rangeRequest = (state) => {
	return { ...state, fetchingRange: true, paymentTrackRange: null };
};

// request the revenue from an api
export const revenueRequest = (state) => {
	return { ...state, fetchingRevenue: true, revenue: null };
};

// request to update from an api
export const updateRequest = (state) => {
	return { ...state, updating: true };
};
// reque}t to delete from an api
export const deleteRequest = (state) => {
	return { ...state, deleting: true };
};

// request the data from an api
export const groupRequest = (state) => {
	return { ...state, fetchingGroup: true, paymentTrackGroup: null };
};
export const groupCountRequest = (state) => {
	return { ...state, fetchingGroupCount: true, paymentTrackGroupCount: null };
};
// request the data from an api
export const markRequest = (state) => {
	return { ...state, markingExternally: true, paymentMark: null };
};

// request the data from an api
export const subscriberExternalPaymentsRequest = (state) => {
	return { ...state, fetchingSubsExternalPayments: true, subsPayments: null };
};
// request the data from an api
export const planGroupExternalPaymentsRequest = (state) => {
	return { ...state, fetchingPlanGroupsExternalPayments: true, groupPayments: null };
};
// request the data from an api
export const planExternalPaymentsRequest = (state) => {
	return { ...state, fetchingPlansExternalPayments: true, planPayments: null };
};
// request the data from an api
export const businessCustomerExternalPaymentsRequest = (state) => {
	return { ...state, fetchingBusinessCustomersExternalPayments: true, businessCustomerPayments: null };
};

export const paymentTracksCountRequest = (state) => {
	return { ...state, fetchingPaymentTracksCount: true, paymentTracksCount: null, errorFetchingPaymentTracksCount: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
	const { paymentTrack } = action;
	return { ...state, fetchingOne: false, errorOne: null, paymentTrack };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
	const { paymentTracks } = action;
	return { ...state, fetchingAll: false, errorAll: null, paymentTracks };
};

export const collectedSuccess = (state, action) => {
	const { paymentTrack } = action;
	return { ...state, fetchingCollectedPayments: false, errorCollectedPayments: null, collectedPaymentTracks: paymentTrack };
};
export const missedSuccess = (state, action) => {
	const { paymentTrack } = action;
	return { ...state, fetchingMissedPayments: false, errorMissedPayments: null, missedPaymentTracks: paymentTrack };
};
export const externalSuccess = (state, action) => {
	const { paymentTrack } = action;
	return { ...state, fetchingExternalPayments: false, errorExternalPayments: null, externalPaymentTracks: paymentTrack };
};

// successful api lookup for range of schedules
export const rangeSuccess = (state, action) => {
	const { paymentTrackRange } = action;
	return {
		...state,
		fetchingRange: false,
		errorRange: null,
		paymentTrackRange,
	};
};

// successful api lookup the revenue for a payment range
export const revenueSuccess = (state, action) => {
	const { revenue } = action;
	return { ...state, fetchingRevenue: false, errorRevenue: null, revenue };
};

// successful api update
export const updateSuccess = (state, action) => {
	const { paymentTrack } = action;
	return { ...state, updating: false, errorUpdating: null, paymentTrack };
};
// successful api delete
export const deleteSuccess = (state) => {
	return { ...state, deleting: false, errorDeleting: null, paymentTrack: null };
};

// successful api lookup for plan group entity
export const groupSuccess = (state, action) => {
	const { paymentTrackGroup } = action;
	return {
		...state,
		fetchingGroup: false,
		errorGroup: null,
		paymentTrackGroup,
	};
};
export const groupCountSuccess = (state, action) => {
	const { paymentTrackGroupCount } = action;
	return {
		...state,
		fetchingGroupCount: false,
		errorGroupCount: null,
		paymentTrackGroupCount,
	};
};
// successful external mark
export const markSuccess = (state, action) => {
	const { paymentMark } = action
	return {
		...state,
		markingExternally: false,
		errorMark: null,
		paymentMark,
	};
};

// request the data from an api
export const subscriberExternalPaymentsSuccess = (state, action) => {
	const { subsPayments } = action
	return { ...state, fetchingSubsExternalPayments: false, subsPayments, errorSubsPayments: null };
};
// Success the data from an api
export const planGroupExternalPaymentsSuccess = (state, action) => {
	const { groupPayments } = action
	return { ...state, fetchingPlanGroupsExternalPayments: false, groupPayments, errorGroupPayments: null };
};
// Success the data from an api
export const planExternalPaymentsSuccess = (state, action) => {
	const { planPayments } = action
	return { ...state, fetchingPlansExternalPayments: false, planPayments, errorPlanPayments: null };
};
// Success the data from an api
export const businessCustomerExternalPaymentsSuccess = (state, action) => {
	const { businessCustomerPayments } = action
	return { ...state, fetchingBusinessCustomersExternalPayments: false, businessCustomerPayments, errorBusinessCustomerPayments: null };
};

export const paymentTracksCountSuccess = (state, action) => {
	const { count } = action
	return { ...state, fetchingPaymentTracksCount: false, paymentTracksCount: count, errorFetchingPaymentTracksCount: null }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingOne: false, errorOne: error, paymentTrack: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingAll: false, errorAll: error, paymentTracks: null };
};

export const collectedFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingCollectedPayments: false, errorCollectedPayments: error, collectedPaymentTracks: null };
};
export const missedFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingMissedPayments: false, errorMissedPayments: error, missedPaymentTracks: null };
};
export const externalFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingExternalPayments: false, errorExternalPayments: error, missedPaymentTracks: null };
};

// Something went wrong fetching range of tracks
export const rangeFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingRange: false,
		errorRange: error,
		paymentTrackRange: null,
	};
};

// Something went wrong fetching range of tracks
export const revenueFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingRevenue: false,
		errorRevenue: error,
		revenue: null,
	};
};

// Something went wrong updating.
export const updateFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		updating: false,
		errorUpdating: error,
		paymentTrack: state.paymentTrack,
	};
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		deleting: false,
		errorDeleting: error,
		paymentTrack: state.paymentTrack,
	};
};

// Something went wrong fetching group.
export const groupFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingGroup: false,
		errorGroup: error,
		paymentTrackGroup: null,
	};
};
export const groupCountFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingGroupCount: false,
		errorGroupCount: error,
		paymentTrackGroupCount: null,
	};
};
// Something went wrong marking externally.
export const markFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		markingExternally: false,
		errorMark: error,
		paymentMark: null,
	};
};

// request the data from an api
export const subscriberExternalPaymentsFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingSubsExternalPayments: false, subsPayments: null, errorSubsPayments: error };
};
// Failure the data from an api
export const planGroupExternalPaymentsFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingPlanGroupsExternalPayments: false, groupPayments: null, errorGroupPayments: error };
};
// Failure the data from an api
export const planExternalPaymentsFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingPlansExternalPayments: false, planPayments: null, errorPayments: error };
};
// Failure the data from an api
export const businessCustomerExternalPaymentsFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingBusinessCustomersExternalPayments: false, businessCustomerPayments: null, errorBusinessCustomerPayments: error };
};

export const paymentTracksCountFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingPaymentTracksCount: false, paymentTracksCount: null, errorFetchingPaymentTracksCount: error }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PAYMENT_TRACK_REQUEST]: request,
	[Types.PAYMENT_TRACK_ALL_REQUEST]: allRequest,
	[Types.COLLECTED_PAYMENT_TRACK_REQUEST]: collectedRequest,
	[Types.MISSED_PAYMENT_TRACK_REQUEST]: missedRequest,
	[Types.EXTERNAL_PAYMENT_TRACK_REQUEST]: externalRequest,
	[Types.PAYMENT_TRACK_RANGE_REQUEST]: rangeRequest,
	[Types.PAYMENT_TRACK_REVENUE_REQUEST]: revenueRequest,
	[Types.PAYMENT_TRACK_UPDATE_REQUEST]: updateRequest,
	[Types.PAYMENT_TRACK_DELETE_REQUEST]: deleteRequest,
	[Types.PAYMENT_TRACK_GROUP_REQUEST]: groupRequest,
	[Types.PAYMENT_TRACK_GROUP_COUNT_REQUEST]: groupCountRequest,
	[Types.PAYMENT_MARK_EXTERNALLY_REQUEST]: markRequest,
	[Types.SUBSCRIBER_EXTERNAL_PAYMENTS_REQUEST]: subscriberExternalPaymentsRequest,
	[Types.PLAN_GROUP_EXTERNAL_PAYMENTS_REQUEST]: planGroupExternalPaymentsRequest,
	[Types.PLAN_EXTERNAL_PAYMENTS_REQUEST]: planExternalPaymentsRequest,
	[Types.BUSINESS_CUSTOMER_EXTERNAL_PAYMENTS_REQUEST]: businessCustomerExternalPaymentsRequest,
	[Types.PAYMENT_TRACKS_COUNT_REQUEST]: paymentTracksCountRequest,

	[Types.PAYMENT_TRACK_SUCCESS]: success,
	[Types.PAYMENT_TRACK_ALL_SUCCESS]: allSuccess,
	[Types.COLLECTED_PAYMENT_TRACK_SUCCESS]: collectedSuccess,
	[Types.MISSED_PAYMENT_TRACK_SUCCESS]: missedSuccess,
	[Types.EXTERNAL_PAYMENT_TRACK_SUCCESS]: externalSuccess,
	[Types.PAYMENT_TRACK_RANGE_SUCCESS]: rangeSuccess,
	[Types.PAYMENT_TRACK_REVENUE_SUCCESS]: revenueSuccess,
	[Types.PAYMENT_TRACK_UPDATE_SUCCESS]: updateSuccess,
	[Types.PAYMENT_TRACK_DELETE_SUCCESS]: deleteSuccess,
	[Types.PAYMENT_TRACK_GROUP_SUCCESS]: groupSuccess,
	[Types.PAYMENT_TRACK_GROUP_COUNT_SUCCESS]: groupCountSuccess,
	[Types.PAYMENT_MARK_EXTERNALLY_SUCCESS]: markSuccess,
	[Types.SUBSCRIBER_EXTERNAL_PAYMENTS_SUCCESS]: subscriberExternalPaymentsSuccess,
	[Types.PLAN_GROUP_EXTERNAL_PAYMENTS_SUCCESS]: planGroupExternalPaymentsSuccess,
	[Types.PLAN_EXTERNAL_PAYMENTS_SUCCESS]: planExternalPaymentsSuccess,
	[Types.BUSINESS_CUSTOMER_EXTERNAL_PAYMENTS_SUCCESS]: businessCustomerExternalPaymentsSuccess,
	[Types.PAYMENT_TRACKS_COUNT_SUCCESS]: paymentTracksCountSuccess,

	[Types.PAYMENT_TRACK_FAILURE]: failure,
	[Types.PAYMENT_TRACK_ALL_FAILURE]: allFailure,
	[Types.COLLECTED_PAYMENT_TRACK_FAILURE]: collectedFailure,
	[Types.MISSED_PAYMENT_TRACK_FAILURE]: missedFailure,
	[Types.EXTERNAL_PAYMENT_TRACK_FAILURE]: externalFailure,
	[Types.PAYMENT_TRACK_RANGE_FAILURE]: rangeFailure,
	[Types.PAYMENT_TRACK_REVENUE_FAILURE]: revenueFailure,
	[Types.PAYMENT_TRACK_UPDATE_FAILURE]: updateFailure,
	[Types.PAYMENT_TRACK_DELETE_FAILURE]: deleteFailure,
	[Types.PAYMENT_TRACK_GROUP_FAILURE]: groupFailure,
	[Types.PAYMENT_TRACK_GROUP_COUNT_FAILURE]: groupCountFailure,
	[Types.PAYMENT_MARK_EXTERNALLY_FAILURE]: markFailure,
	[Types.SUBSCRIBER_EXTERNAL_PAYMENTS_FAILURE]: subscriberExternalPaymentsFailure,
	[Types.PLAN_GROUP_EXTERNAL_PAYMENTS_FAILURE]: planGroupExternalPaymentsFailure,
	[Types.PLAN_EXTERNAL_PAYMENTS_FAILURE]: planExternalPaymentsFailure,
	[Types.BUSINESS_CUSTOMER_EXTERNAL_PAYMENTS_FAILURE]: businessCustomerExternalPaymentsFailure,
	[Types.PAYMENT_TRACKS_COUNT_FAILURE]: paymentTracksCountFailure,

});
