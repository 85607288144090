import React from 'react'
import "../Styles/components/StatusBadge.scss"

function StatusBadge({ status = "", className = "", type }) {
    status = status.toLowerCase()
    if (type === 2) {
        return (
            <span className={`type2 ${(status.toLowerCase())} ${className}`}>{status}</span>
        )
    }
    return (
        <span className={`status status-${(status.toLowerCase())} ${className}`}>{status}</span>
    )
}

export default StatusBadge