import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, } from 'primereact/api';
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../Components/Loading";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import BusinessActions from "../../Redux/actions/business-actions"
import DocumentActions from '../../Redux/transition/document-upload/document-upload.reducer';
import PaySwitchActions from "../../Redux/transition/payswitch/payswitch.reducer";
import ProductCreate from "./ProductCreate";
import InviteSubscriber from "../../Pages/Subscriptions/Invitation/InviteSubscriber"
import "../../Styles/pages/product.scss"
import ProductsDataTable from "./ProductsDataTable";
import OnboardingNotification from "../../Components/OnboardingNotification";

const Products = () => {

  const defMembership = useSelector((state) => state.defMembership.defMembership)
  const archivingPlan = useSelector((state) => state.plans.archiving)
  const archiving = useSelector((state) => state.planGroups.archiving)
  const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive)
  const planGroupsGraphedArchived = useSelector((state) => state.planGroups.planGroupsGraphedArchived)
  const fetchingPerformance = useSelector((state) => state.planGroups.fetchingBusinessPerformance)
  const fetchingPlanGroupsGraphed = useSelector((state) => state.planGroups.fetchingGraphed)
  const fetchingPlanGroupsGraphedArchived = useSelector((state) => state.planGroups.fetchingGraphedArchived)
  const fetchingPlanGroupsGraphedActive = useSelector((state) => state.planGroups.fetchingGraphedActive)
  const verificationStatus = useSelector((state) => state.documents.verifStatus);
  const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
  const business = useSelector((state) => state.businesses.business);

  const dispatch = useDispatch()
  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
  const getArchivedPlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedArchivedRequest(businessId)) }, [dispatch])
  const getPlanBusinessPerformance = useCallback((businessId) => { dispatch(PlanGroupActions.planBusinessPerformanceRequest(businessId)) }, [dispatch])
  const getBusinessVerificationStatus = useCallback((businessId) => dispatch(DocumentActions.businessVerificationStatusRequest(businessId)), [dispatch]);
  const getBusiness = useCallback((businessId) => dispatch(BusinessActions.businessRequest(businessId)), [dispatch]);
  const getCredentials = useCallback((businessId) => { dispatch(PaySwitchActions.paySwitchCredentialRequest(businessId)) }, [dispatch]);

  const { t } = useTranslation("common");
  const history = useHistory();
  const [saved, setSaved] = useState(null);
  const [tiersCreated, setTiersCreated] = useState(null);
  const [planId, setplanId] = useState(null);
  const [checked, setChecked1] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [trigger, setTrigger] = useState(false)
  const [showProductEdit, setShowProductEdit] = useState(false)
  const [filterValue, setFilterValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState(null);

  const [state, setState] = useState({
    documentsSelection: null, creatingPlanGroups: null, success: false, status: "ACTIVE", archiving: false, setDisplayBasic: false, tierVisible: false,
    tierGroup: null, verificationStatus: null, business: null, visible: false, visiblePlan: false, hide: true, hidePlan: true, notOwnerVisible: false,
    verifPendingVisible: false, initialTier: null, fetchingVerifRecord: true, rowId: null, rowData: null, archived: false, planArchiving: false,
    tierArchive: false, priceChange: false, changing: false,
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'newPlan.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'tierObject.tierName': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'newPlan.billingPeriod': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'billingObject': { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [billingPeriod] = useState([t("plan.period_ONCE"), t("plan.period_DAILY"), t("plan.period_DAILY_WITH_SATURDAYS"),
  t("plan.period_DAILY_WITH_WEEKENDS"), t("plan.period_WEEKLY"), t("plan.period_BIWEEKLY"), t("plan.period_MONTHLY"),
  t("plan.period_QUARTERLY"), t("plan.period_HALFYEARLY"), t("plan.period_YEARLY")]);

  const onFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setFilterValue(value);
  };

  const onDropdownChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setDropdownValue(value);
  };

  const toast = useRef(null);
  const toastBL = useRef(null);
  let doc = [];

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }
    if (defMembership) {
      getActivePlanGroups(defMembership.business.id)
      getArchivedPlanGroups(defMembership.business.id)
      getPlanBusinessPerformance(defMembership.business.id)
      getCredentials(defMembership.business.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }

    if (defMembership) {
      getBusiness(defMembership.business.id)
    }
    if (!verificationStatus) {
      getBusinessVerificationStatus(defMembership.business.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    const saved = localStorage.getItem("created");
    const planId = localStorage.getItem("planNum");
    const tiersCreated = localStorage.getItem("tiersCreated");
    setSaved(saved)
    setplanId(planId)
    setTiersCreated(tiersCreated)
  }, [saved, planId, tiersCreated])

  useEffect(() => {
    let loading = fetchingVerifStatus;
    setState(state => { return { ...state, fetchingVerifRecord: loading } });
    if (business) {
      setState((state) => ({ ...state, business: business }))
    }
    if (verificationStatus) {
      setState(state => { return { ...state, verifStatus: verificationStatus } })
    }
  }, [fetchingVerifStatus, business, verificationStatus])

  useEffect(() => {
    if (saved && tiersCreated > 1) {
       showBottomLeft()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved, planId, tiersCreated])

  const showBottomLeft = () => {
    // Check if the function has already run in this session
    if (!localStorage.getItem('toastShown')){
      toastBL.current.show({
        style: { cursor: "pointer",backgroundColor: "white" }, severity: 'success',
        summary:
          <div style={{ display: "flex", flexDirection: "row" }}> 
          <i className="pi pi-check  check-icon" />
            <p style={{ marginLeft: "0.25rem" }}>
              {t("plan.created_text")} {tiersCreated} {tiersCreated === "1" ? "plan" : "plans"}
            </p>
          </div>,
        detail: <span className="name-body" id="planName"
          onClick={() => {
            history.push({
              pathname: "/products/detail/" + planId
            })
          }}><div>
            <span style={{ textDecoration: "underline", color: "#008DD5" }}>{t("product.title")}</span>
            <span style={{marginLeft:'3px', color:"#3b3b3b" }}>{t("product.title-2")}</span>
          </div>
        </span>, life: 5000,
      });
    }
    localStorage.setItem('toastShown', 'true');
  };

  const toggleTierDialog = () => { setState({ ...state, tierVisible: !state.tierVisible }); };
  const toggleSetOpenDialog = () => { setOpenNotification(!openNotification); };

  //Toggle Invite Subscriber Dialogue Visibility
  const toogleInviteSubscriber = () => {
    if (fetchingVerifStatus) {
      return <Loading />
    }
    else {
      if (business.verified) {
        setState(state => { return { ...state, visible: true, } })
      }
      else if (!business.verified) {
        if (state.verifStatus && !state.fetchingVerifRecord) {
          return toggleVerifPendingDialogue();
        }
        if (!state.verifStatus && !state.fetchingVerifRecord) {
          switch (defMembership.role) {
            case "OWNER":
              return <OnboardingNotification visible={true} onHide={toggleSetOpenDialog} />
            default:
              toggleNotOwnerDialogue();
          }
        }
      }
    }
  }

  const onHide = () => { setState(state => { return { ...state, visible: false } }) }

  //Toggle not Owner dialogue visibility
  const toggleVerifPendingDialogue = () => { setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible } }) }

  //Toggle not Owner dialogue visibility
  const toggleNotOwnerDialogue = () => { setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible } }) }

  //Dialog Footer for verif alert**
  const renderVerifFooter = (component) => {
    return (
      <div>
        <Button label={t("common.ok")} style={{ backgroundColor: "#d0021b" }} icon="pi pi-check" autoFocus
          onClick={component === 'not_owner' ? toggleNotOwnerDialogue : toggleVerifPendingDialogue}
        />
      </div>
    );
  }

  const loadingTable = archiving || archivingPlan || fetchingPlanGroupsGraphed || fetchingPlanGroupsGraphedArchived || fetchingPlanGroupsGraphedActive

  let newData;
  if ((!checked && planGroupsGraphedActive) || (checked && planGroupsGraphedArchived)) {
    let planGroupAssignedActive = Object.assign({}, planGroupsGraphedActive);
    let planGroupAssignedArchived = Object.assign({}, planGroupsGraphedArchived);
    let planGroupAssigned = !checked ? planGroupAssignedActive : planGroupAssignedArchived
    planGroupAssigned.groups.map((item, ind) => {
      const {
        group: { name },
        group,
        plans,
      } = item;

      let tierNames = [];
      let tierBilling = [];
      let plan = plans.map((plan) => {
        return plan
      })
      let newPlan = Object.assign({}, plan);
      let newTier = [...plan,]
      let newerTier = Object.assign(newTier, { groupName: name });
      // eslint-disable-next-line array-callback-return
      plan.map((ele, i) => {
        tierNames.push([ele.name])
        tierBilling.push([ele.billingPeriod])
      });
      let tierName = [tierNames].toString()
      let tierPeriod = [tierBilling].toString()
      let tierObject = { tierName }
      newData = { ...group, tiers: newerTier, ...newPlan, tierObject, billingObject: tierPeriod }
      doc.push({
        ...newData
      });
      return null;
    });
  }

  const loading = !defMembership || fetchingVerifStatus || fetchingPlanGroupsGraphed || !business || fetchingPerformance

  return (
    <>
      <Toast ref={toastBL} position="bottom-left"
        className="toastBL"
        onRemove={(message) => {
          if (message.severity === 'warn' || message.severity === 'success' ) {
            localStorage.removeItem('created')
            localStorage.removeItem('planNum')
            localStorage.removeItem('tierCreated')
            localStorage.removeItem('saved')
          }
        }}
      />
      {loading ? <Loading /> :
        <div>
          <ProductCreate toggleDialog={toggleTierDialog} toggleState={state.tierVisible} tierGroup={state.tierGroup} />

          <Dialog header={t("idoc.id_verif_pending_header")} visible={state.verifPendingVisible}
            onHide={toggleVerifPendingDialogue} style={{ width: "360px" }} footer={renderVerifFooter('verif_pending')}>
            <span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#d5a326" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
          </Dialog>
          <Dialog header={t("idoc.not_owner_header")} style={{ width: "360px" }} visible={state.notOwnerVisible} onHide={toggleNotOwnerDialogue} footer={renderVerifFooter('not_owner')}>
            <p>{t("idoc.not_owner_text")}</p>
          </Dialog>
          <InviteSubscriber visible={state.visible} onHide={onHide} initialTier={state.initialTier} />

          <Toast ref={toast} onRemove={(message) => {
            if (message.severity === "success") {
              getActivePlanGroups(defMembership.business.id)
            }
          }} />
          <Card id="filters-card" className="action_bar_cards" style={{ height: "6rem" }}>
            <div className="p-grid p-fluid card-main-div">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="p-col-12 p-md-3 filters ">
                  <div className="p-inputgroup">
                    <span className="p-input-icon-right">
                      <i className="pi pi-search" style={{ marginRight: "1rem", cursor: "pointer" }} />
                      <InputText className="search_input search_box border-round-md" value={filterValue} onChange={onFilterChange} placeholder={t("product.search")} />
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-3 filters" >
                  <Dropdown className="plans-dropdown border-round-md" id="plans-dropdown" value={dropdownValue} options={billingPeriod} onChange={onDropdownChange} placeholder={t("product.filter")} showClear />
                  <div className="p-inputgroup">
                  </div>
                </div>
              </div>
              <div className="toggle_switch">
                <InputSwitch checked={checked}
                  onChange={(e) => {
                    setChecked1(e.value)
                    if (!e.value) {
                      getActivePlanGroups(defMembership.business.id)
                    } else {
                      if (!planGroupsGraphedArchived) {
                        getArchivedPlanGroups(defMembership.business.id)
                        getArchivedPlanGroups(defMembership.business.id)
                      }
                    }
                  }}
                />
                <span style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>{t("product.show")}</span>
              </div>
            </div >
          </Card>
          <div>
            <ProductsDataTable doc={doc} checked={checked} filters={filters} toggleVerifPendingDialogue={toggleVerifPendingDialogue} loadingTable={loadingTable}
              defMembership={defMembership} toogleInviteSubscriber={toogleInviteSubscriber} toggleTierDialog={toggleTierDialog} toggleNotOwnerDialogue={toggleNotOwnerDialogue}
              loading={loading} setTrigger={setTrigger} trigger={trigger} showProductEdit={showProductEdit} setShowProductEdit={setShowProductEdit} />
          </div>
        </div>
      }
    </>
  );
};
export default Products;