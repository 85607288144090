import React from 'react'
import { useTranslation } from "react-i18next";
import "../Styles/components/loader.scss";

function Loading() {
	const { t } = useTranslation("common");

	return (
		<div className="fallback-loader">
			<div className="loader">
				<h1 style={{ marginTop: 0 }}>{t("loading")}</h1>
				<div className="bar1"></div>
				<div className="bar2"></div>
				<div className="bar3"></div>
				<div className="bar4"></div>
				<div className="bar5"></div>
				<div className="bar6"></div>
			</div>
		</div>
	)
}

export default Loading
