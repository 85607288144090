import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    accountRequest: [],
    accountSuccess: ['account'],
    accountFailure: ['error'],
    accountUpdateRequest: ['account'],
    accountUpdateSuccess: [],
    accountUpdateFailure: ['error'],
    accountResetSuccess: null,
    emailVerificationResend: ['user'],
    emailVerificationResendSuccess: ['user'],
    emailVerificationResendFailure: ['error'],
})

export const AccountTypes = Types
export default Creators
