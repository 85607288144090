/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RecipientDetails } from "./Steps/RecipientDetails.js.js"
import { TransferDetails } from './Steps/TransferDetails';
import Summary from "../index";
import "../../../Styles/pages/sendMoney.scss"
import Confirmation from './Steps/Confirmation.js';

//Redux imports
import PlanGroupActions from '../../../Redux/transition/plan-group/plan-group.reducer'
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import PayoutChannelActions from "../../../Redux/actions/payoutchannel-actions"
import BankActions from "../../../Redux/actions/bank-actions";
//Component import
import Loading from "../../../Components/Loading";
import { FavouriteSection, AddAccountDialog } from "../../../Components/sendMoney/FavouriteSection";
//Prime react import
import { Toast } from 'primereact/toast';
import 'primeicons/primeicons.css';
import * as Yup from "yup";
import validator from 'validator';
//styles import
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";

export const SendMoney = ({ setActiveIndex, location }) => {
	const [activeStep, setActiveStep] = useState(location?.state ? location.state.activeStep : 0);
	const [Recipient, setRecipient] = useState(location?.state ? location.state.payoutChannel : null)
	const [walletExist, setWalletExist] = useState({
		walletExist: false,
		data: {}
	})
	const [settlement, setSettlement] = useState({})
	
	// redux state from store 
	const toast = useRef(null);
	const { t } = useTranslation("common");
	const dispatch = useDispatch();
	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const planGroups = useSelector((state) => state.planGroups.planGroups);
	const planGroupsCount = useSelector((state) => state.planGroups.count);
	const fetchingPlanGroupsCount = useSelector((state) => state.planGroups.fetchingPendingCount);
	// const fetchingPlanGroups = useSelector((state) => state.planGroups.fetchingAll);
	const fetchingWallet = useSelector((state) => state.wallet.fetchingOne);
	const fetchingPendingCount = useSelector(state => state.settlements.fetchingStatusCount)
	const payoutChannelsSaved = useSelector((state) => state.payoutChannels.payoutChannelsSaved);
	const payoutChannelsFavourite = useSelector((state) => state.payoutChannels.payoutChannelsFavourite);
	const errorSettingFavourite = useSelector((state) => state.payoutChannels.errorSettingFavourite);
	const fetchingFavourite = useSelector((state) => state.payoutChannels.fetchingFavourite);
	const settingFavourite = useSelector((state) => state.payoutChannels.settingFavourite);
	const settingUnfavourite = useSelector((state) => state.payoutChannels.settingUnfavourite);
	const errorSettingUnfavourite = useSelector((state) => state.payoutChannels.errorSettingUnfavourite);
	const banks = useSelector((state) => state.banks.banks);
	const filteredChannels = useSelector((state) => state.banks.filteredBanks);

	// redux dispatch actions
	const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
	const getPlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupAllRequest(businessId)); }, [dispatch]);
	const getPlanGroupsCount = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupAllCountRequest(businessId)); }, [dispatch]);
	const getWallet = useCallback((businessId) => { dispatch(WalletActions.walletRequest(businessId)) }, [dispatch]);
	const getSettlementsStatusCount = useCallback((businessId) => { dispatch(SettlementActions.settlementStatusCountRequest(businessId, "PENDING")); }, [dispatch]);
	const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
	const getFavouriteChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelFavouriteRequest(businessId, options)), [dispatch]);
	const setFavouritePayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSetFavouriteRequest(businessId, options)), [dispatch]);
	const payoutChannelSetUnfavourite = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSetUnfavouriteRequest(businessId, options)), [dispatch]);
	const getAllBanks = useCallback((countryId) => dispatch(BankActions.bankAllRequest(countryId)), [dispatch]);
	const filterChannelsByBanks = useCallback((businessId, query, options) => dispatch(BankActions.bankFilterRequest(businessId, query, options)), [dispatch])

	const [state, setState] = useState({
			planGroups: null,
			planGroupsGraphed: null,
			planGroupsCount: 0,
			size: 10,
			page: 0,
			first: 0,
			sort: 'id,desc',
			isNewChannel: false,
			newChannel: {},
			payoutChannels: [],
			favouritePayoutChannels: [],
			newFav: null,
			addNewFavVisible: false,
			callFav: false,
			setFav: false,
			setUnfav: false,
			fav: null,
			data: null,
			isFiltered: false,
			systemBanks: [],
			filteredPayoutChannels: [],
			selectedBank: null,
			favFiltered: false,
			unFavFiltered: false,
			query: null
	})

	// const fetchAllplanGroups = useCallback(page => {
	// 		getPlanGroups(defMembership.business.id, { page, size: state.size, sort: state.sort });
	// }, [ state.size, state.sort])

	useEffect(() => {
			if (!defMembership) {
					getMembership()
			}
			if (defMembership) {
					// fetchAllplanGroups(0);
					getPlanGroupsCount(defMembership.business.id);
					getWallet(defMembership.business.id);
					getSettlementsStatusCount(defMembership.business.id);
					getPlanGroups(defMembership.business.id);
					getAllBanks(defMembership.business.country.id);
					getSavedPayoutChannels(defMembership.business.id);
					getFavouriteChannels(defMembership.business.id);
			}
			if (!planGroups && defMembership) {
					getPlanGroups(defMembership.business.id)
			}
	}, [])

	useEffect(() => {
			if (planGroups) {
					setState((state) => ({ ...state, planGroups: planGroups }))
			}
			if (planGroupsCount) {
					setState((state) => {
							return { ...state, planGroupsCount: planGroupsCount };
					});
			}
			if (banks) {
					setState((state) => {
							return { ...state, systemBanks: banks };
					});
			}
			if (filteredChannels) {
					setState((state) => {
							return { ...state, filteredPayoutChannels: filteredChannels };
					});
			}
			if (payoutChannelsSaved) {
					setState((state) => {
							return { ...state, payoutChannels: payoutChannelsSaved, isNewChannel: payoutChannelsSaved.length === 0 };
					});
			}
			if (payoutChannelsFavourite) {
					setState((prev) => { return { ...prev, favouritePayoutChannels: payoutChannelsFavourite, } });
			}
	}, [planGroups, planGroupsCount, payoutChannelsSaved, payoutChannelsFavourite, banks, filteredChannels])

	useEffect(() => {
			if (!settingFavourite && state.callFav) {
					getFavouriteChannels(defMembership.business.id);
					getSavedPayoutChannels(defMembership.business.id);
					if (errorSettingFavourite) {
							toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_add_fav") });
					} else {
							toast.current.show({
									severity: "success",
									summary: t("successful"),
									detail: `${state.newFav.name} ${t("recipient.save_fav")}`,
									life: 3000,
							});
					}
					setState((prev) => { return { ...prev, callFav: false, newFav: null } });
			}
			if (!settingUnfavourite && state.loadUnFav) {
					getFavouriteChannels(defMembership.business.id);
					getSavedPayoutChannels(defMembership.business.id);
					if (errorSettingUnfavourite) {
							toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_removed_fav") });
					} else {
							toast.current.show({
									severity: "success",
									summary: t("successful"),
									detail: t("recipient.removed_fav"),
									life: 3000,
							});
					}
					setState((prev) => { return { ...prev, loadUnFav: false } });
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defMembership.business.id, errorSettingFavourite, errorSettingUnfavourite, getFavouriteChannels, settingFavourite, settingUnfavourite, state.callFav, state.loadUnFav]);

	useEffect(() => {
			if (!settingUnfavourite && state.setUnfav) {
					getFavouriteChannels(defMembership.business.id);
					getSavedPayoutChannels(defMembership.business.id);

					if (errorSettingUnfavourite) {
							toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_removed_fav") });
					} else {
							toast.current.show({
									severity: "success",
									summary: t("successful"),
									detail: `${state.newFav.name} ${t("recipient.remove_success")}`,
									life: 3000,
							})
					};
					setState((state) => { return { ...state, setUnfav: false, newFav: null, unFavFiltered: false } })
			}
			if (!settingFavourite && state.setFav) {
					getFavouriteChannels(defMembership.business.id);
					getSavedPayoutChannels(defMembership.business.id);
					if (errorSettingFavourite) {
							toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_add_fav") });
					} else {
							toast.current.show({
									severity: "success",
									summary: t("successful"),
									detail: `${state.fav} ${t("recipient.save_fav")}`,
									life: 3000,
							});
					};
					setState((state) => { return { ...state, setFav: false, fav: null, favFiltered: false } })
			}
			if (!settingUnfavourite && state.selectedBank && state.unFavFiltered) {
					filterChannelsByBanks(defMembership.business.id, state.query);
			}
			if (!settingFavourite && state.selectedBank && state.favFiltered) {
					filterChannelsByBanks(defMembership.business.id, state.query);
			}
	}, [settingUnfavourite, state.setUnfav, errorSettingFavourite, settingFavourite, state.selectedBank,
			state.fav, errorSettingFavourite, state.setFav, state.favFiltered, state.unFavFiltered])

	useEffect(() => {
			if (!defMembership) {
					getMembership();
			}
			if (defMembership) {
					const options = `&page=${state.page}&size=${state.size}&sort=${state.sort}`
					let query = ""
					const appendQuery = (param) => {
							query = query ? query + "&" + param : param
					}
					if (state.selectedBank) appendQuery(state.selectedBank.id)
					query = `${query}${options}`
					filterChannelsByBanks(defMembership.business.id, query);
					setState((state) => { return { ...state, query: query } })
			}
	}, [filterChannelsByBanks, defMembership, state.selectedBank, getMembership, state.page, state.size, state.sort]);

	useEffect(() => {
			if (!defMembership) {
					getMembership();
			}
			if (defMembership) {
					const options = `&page=${state.page}&size=${state.size}&sort=${state.sort}`
					let query = ""
					const appendQuery = (param) => {
							query = query ? query + "&" + param : param
					}
					if (state.selectedBank) appendQuery(state.selectedBank.id)
					query = `${query}${options}`
					filterChannelsByBanks(defMembership.business.id, query);
			}
	}, [filterChannelsByBanks, defMembership, state.selectedBank, getMembership, state.page, state.size, state.sort]);

	const loading = fetchingPendingCount || fetchingWallet ||  fetchingPlanGroupsCount

	const showAddingFav = (name) => {
			toast.current.show({
					severity: "info",
					summary: t("recipient.add"),
					detail: t("recipient.saving_fav", { name }),
					life: 3000,
			});
	};

	const showRemovingFav = (name) => {
			toast.current.show({
					severity: "info",
					summary: t("recipient.remove"),
					detail: t("recipient.removing_fav", { name }),
					life: 3000,
			});
	};

	const showErrorFav = () => {
			toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.select_error") });
	}
	Yup.addMethod(Yup.number, "validatePhoneNumber", function (errorMessage) {
			return this.test(`test-mobile-number-require`, errorMessage, function (value) {
					const { path, createError } = this;
					const valid = validator.isMobilePhone(value ? value.toString() : "") ? "true" : "false";
					return (value && valid === "false") || createError({ path, message: errorMessage });
			});
	});
	Yup.addMethod(Yup.number, "validateBankNumber", function (errorMessage) {
			return this.test(`test-mobile-number-require`, errorMessage, function (value) {
					const { path, createError } = this;
					const valid = validator.isIBAN(value ? value.toString() : "") ? "true" : "false";
					return (value && valid === "false") || createError({ path, message: errorMessage });
			});
	});

	const showWarn = () => {
			toast.current.show({ severity: 'warn', summary: t("product_account.warning_summary"), detail: t("product_account.warning_detail"), life: 3000 });
	}

		const routeTo = (data) => {
		if (data?.available < 0.5) {
			showWarn()
			}
		else {
			if(activeStep === 0){
				setActiveStep(1)
				setRecipient(data)
			}
		}
	}
	
	const selectedChannelTemplate = (option, props) => {
			if (option) { return (<div>{option.name} - {option.identifier}</div>); }
			return <span>{props.placeholder}</span>;
	};

	const channelOptionTemplate = (option) => (<>{option.name} - {option.identifier}</>);

	const steps = [{ label: 'recipient.details', icon: "pi-user" }, { label: 'totalTransfers.details', icon: "pi-wallet" }, { label: 'totalTransfers.confirm_step', icon: "pi-check-circle" }];

	// handles what happens when you switch between send money stages
	const handleNext = () => {
		if (activeStep === 2) {
			setActiveStep(0)
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handlePrevious = () => {
		if (activeStep === 0) return;
		else{setActiveStep((prevActiveStep) => prevActiveStep - 1)}
	};
	const handleCancel = () => {
		if (activeStep === 0) return;
		if (activeStep === 2){setActiveStep((prevActiveStep) => prevActiveStep - 2)}
		else{setActiveStep((prevActiveStep) => prevActiveStep - 1)}
	};

	return (
		<>
			{(loading ? <Loading /> :
        <div>
						<Summary />
            <Toast ref={toast} />
            <AddAccountDialog {...{ state, showAddingFav, setState, setFavouritePayoutChannels, t, channelOptionTemplate, payoutChannelsFavourite: state.favouritePayoutChannels, selectedChannelTemplate }} />
            <div className="carousel-demo">
                <FavouriteSection  {...{
                    fetchingFavourite,
                    payoutChannelsFavourite: state.favouritePayoutChannels,
                    setFavouritePayoutChannels,
                    payoutChannelSetUnfavourite,
                    onClickAccountCard: routeTo,
                    setFieldValue: () => { },
                    setState, t, showRemovingFav, showErrorFav
                }} />
            </div>
            
        
    


			 <span className='wallet-titles wallet mt-5' style={{width:"100%", display:"block"}}>{t("withdrawal_details")}</span>
			<div className="card">
				<div className='fav-card-content'>
					<ul className="list-none p-0 m-0 flex flex-column md:flex-row ">
						{steps.map(({ label, icon }, i) => (
							<li key={i} className={`relative ${i + 1 !== steps.length ? "mr-0 md:mr-8" : ""}  flex-auto`}>
								<div className={`step-box step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}`}>
									<i className={`pi step-box-icon ${activeStep === i ? "step-box-active-icon " + icon : i < activeStep ? "step-box-pre-icon pi-check-circle" : "step-box-post-icon " + icon}`}></i>
									<div>
										<div className={`step-box-title step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}-title`}>{t("totalTransfers.step")} {i + 1}</div>
										<span className={`step-box-des step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}-des  hidden md:block`}>{t(label)}</span>
									</div>
								</div>
								{i + 1 !== steps.length && <div className="hidden md:block step-box-bar" ></div>}
							</li>))}
					</ul>
				</div>
				<div>
					{activeStep === 0 && <RecipientDetails {...{ setRecipient, handleNext, setWalletExist }} />}
					{activeStep === 1 && <TransferDetails {...{ Recipient, handleNext, handlePrevious, handleCancel, walletExist, setSettlement }} />}
					{activeStep === 2 && <Confirmation {...{ Recipient, handleNext, handlePrevious,handleCancel, setActiveIndex, walletExist, settlement }} />}
				</div>
			</div>
			</div> )}
		</>
	)
};
