/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import DocumentActions from '../../Redux/transition/document-upload/document-upload.reducer';
import Loading from "../../Components/Loading";
import BusinessEdit from './BusinessEdit';
import { Card } from "primereact/card";
import "../../Styles/pages/settings.scss";

const BusinessPage = () => {

    //Redux State from Store
    const errorDeletingBusiness = useSelector((state) => state.businesses.errorDeleting)
    const defMembership = useSelector((state => state.defMembership.defMembership))
    const verificationStatus = useSelector((state) => state.documents.verifStatus);

    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
    const getBusinessVerificationStatus = useCallback((businessId) => dispatch(DocumentActions.businessVerificationStatusRequest(businessId)), [dispatch]);

    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [toggle, setToggle] = useState(false);

    const [state, setState] = useState({
        entityId: null,
        business: null,
        modalDisplay: false,
        setDisplayResponsive: false,
        status: null,
    })

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getBusinessVerificationStatus(defMembership.business.id)
        }
    }, [])

    useEffect(() => {
        if (defMembership) {
            setState((prev) => {
                return { ...prev, entityId: defMembership.business.id, business: defMembership.business }
            })
        }
        if (verificationStatus) {
            setState((prev) => {
                return { ...prev, status: verificationStatus }
            })
        }
    }, [defMembership, errorDeletingBusiness])

    const toggleDialogDisplay = useCallback(() => {
        setDisplayResponsive(!displayResponsive);
    }, [displayResponsive])

    if (!defMembership) {
        return <Loading />;
    }

    // eslint-disable-next-line no-unused-vars
    const onToggle = () => {
        toggleDialogDisplay()
        setState(() => ({
            ...state, business: defMembership.business
        }))
    }

    const onClickToggle = () => {
        setToggle(!toggle)
    }

    return (
        <>
        <Card>
            <BusinessEdit business={state.business} toggle={toggle} onToggle={onClickToggle} />
            </Card>
        </>
    )
}
export default BusinessPage;