import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    businessCustomerSubscriptionAmountsRequest: ["bcId"],
    businessCustomerAllRequest: ["businessId", "options"],
    exactFilteredBusinessCustomerRequest: ["businessId", "query", "options"],
    businessCustomerSearchRequest: ["businessId", "query"],
    businessCustomerSummarySearchRequest: ["businessId", "query"],
    businessCustomerSummaryRequest: ["businessId"],
    businessCustomerUpdateRequest: ['businessCustomerId', 'businessCustomer'],
    businessCustomerSubscriptionsRequest: ["businessCustomerId"],
    businessCustomerRecordRequest: ["businessCustomerId"],
    businessCustomerSubscriptionsCountRequest: ["businessCustomerId"],
    businessCustomerUploadRequest: ["businessId", "membershipId", "file"],
    businessCustomerImportRequest: ["businessId", "options"],
    businessCustomerImportCountRequest: ["businessId"],
    businessCustomerEntryRequest: ["importId",],
    businessCustomerApplyRequest: ["id",],
    businessCustomersBulkPauseRequest: ["membershipId", "ids"],
    businessCustomersBulkCancelRequest: ["membershipId", "ids"],
    businessCustomersSinglePauseRequest: ["businessCustomerId", "membershipId"],
    businessCustomersSingleCancelRequest: ["businessCustomerId", "membershipId"],
    businessCustomerPaymentsRequest: ["businessCustomerId", "size"],
    businessCustomerMissedPaymentsRequest: ["businessCustomerId", "size"],
    businessCustomerSubscriptionActivityRequest: ["businessCustomerId", "size"],
    customerReceivedPaymentRequest: ["businessCustomerId", "options"],
    customerMissedPaymentRequest: ["businessCustomerId"],
    customerPendingPaymentRequest: ["businessCustomerId"],
    customerReportRequest: ["businessId", "options"],
    customerChartsRequest: ["businessId", "duration", "options"],
    successfulPaymentsRequest: ["bcId", "options"],
    expiredPaymentsRequest: ["bcId", "options"],

    businessCustomerSubscriptionAmountsSuccess: ["businessCustomerSubscriptionAmounts"],
    businessCustomerAllSuccess: ["businessCustomers"],
    exactFilteredBusinessCustomerSuccess: ["customers"],
    businessCustomerSummarySearchSuccess: ["businessCustomerSummarySearch"],
    businessCustomerSummarySuccess: ["businessCustomerSummary"],
    businessCustomerSearchSuccess: ["customers"],
    businessCustomerUpdateSuccess: ['customer'],
    businessCustomerSubscriptionsSuccess: ["details"],
    businessCustomerRecordSuccess: ["customerRecord"],
    businessCustomerSubscriptionsCountSuccess: ["businessCustomerSubscriptionsCount"],
    businessCustomerUploadSuccess: ["customer"],
    businessCustomerImportSuccess: ["importedCustomer"],
    businessCustomerImportCountSuccess: ["importedCustomerCount"],
    businessCustomersBulkPauseSuccess: ["pausedCustomers"],
    businessCustomersBulkCancelSuccess: ["cancelledCustomers"],
    businessCustomersSinglePauseSuccess: ["pausedCustomers"],
    businessCustomersSingleCancelSuccess: ["cancelledCustomers"],
    businessCustomerEntrySuccess: ["entry"],
    businessCustomerApplySuccess: ["appliedImport"],
    businessCustomerPaymentsSuccess: ["recentPayments"],
    businessCustomerMissedPaymentsSuccess: ["recentMissedPayments"],
    businessCustomerSubscriptionActivitySuccess: ["recentSubscriptionActivities"],
    customerReceivedPaymentSuccess: ["customerReceivedPayments"],
    customerMissedPaymentSuccess: ["customerMissedPayments"],
    customerPendingPaymentSuccess: ["customerPendingPayments"],
    customerReportSuccess: ["customerReports"],
    customerChartsSuccess: ["customerCharts"],
    successfulPaymentsSuccess: ["successfulPayments"],
    expiredPaymentsSuccess: ["expiredPayments"],

    businessCustomerSubscriptionAmountsFailure: ["error"],
    businessCustomerAllFailure: ["error"],
    exactFilteredBusinessCustomersFailure: ["error"],
    businessCustomerSummarySearchFailure: ["error"],
    businessCustomerSummaryFailure: ["error"],
    businessCustomerSearchFailure: ["error"],
    businessCustomerUpdateFailure: ['error'],
    businessCustomerSubscriptionsFailure: ["error"],
    businessCustomerRecordFailure: ["error"],
    businessCustomerSubscriptionsCountFailure: ["error"],
    businessCustomerUploadFailure: ["error"],
    businessCustomersBulkPauseFailure: ["error"],
    businessCustomersBulkCancelFailure: ["error"],
    businessCustomersSinglePauseFailure: ["error"],
    businessCustomersSingleCancelFailure: ["error"],
    businessCustomerImportFailure: ["error"],
    businessCustomerImportCountFailure: ["error"],
    businessCustomerEntryFailure: ["error"],
    businessCustomerApplyFailure: ["error"],
    businessCustomerPaymentsFailure: ["error"],
    businessCustomerMissedPaymentsFailure: ["error"],
    businessCustomerSubscriptionActivityFailure: ["error"],
    customerReceivedPaymentFailure: ["error"],
    customerMissedPaymentFailure: ["error"],
    customerPendingPaymentFailure: ["error"],
    customerReportFailure: ["error"],
    customerChartsFailure: ["error"],
    successfulPaymentsFailure: ["error"],
    expiredPaymentsFailure: ["error"],

});
export const BusinessCustomerTypes = Types;
export default Creators;


/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingAllBusinessCustomers: false,
    fetchingFilteredCustomers: null,
    fetchingExactFilteredBusinessCustomers: null,
    updating: false,
    fetchingbusinessCustomerSubscriptions: false,
    fetchingBusinessCustomerSummarySearch: false,
    fetchingBusinessCustomerSummary: false,
    fetchingBusinessCustomerRecord: false,
    pausingBulkBusinessCustomers: false,
    cancellingBulkBusinessCustomers: false,
    pausingBusinessCustomersSinglePause: false,
    cancellingSingleBusinessCustomer: false,
    fetchingCount: null,
    fetchingImportedCustomers: null,
    fetchingImportedCustomersCount: null,
    fetchingEntries: null,
    uploading: false,
    applying: false,
    fetchingRecentPayments: false,
    fetchingRecentMissedPayments: false,
    fetchingRecentSubscriptionActivity: false,
    fetchingCustomerReceivedPayments: false,
    fetchingCustomerReceivedPayment: false,
    fetchingCustomerMissedPayments: false,
    fetchingCustomerPendingPayments: false,
    fetchingCustomerReports: false,
    fetchingCustomerCharts: false,
    fetchingSuccessfulPayments: false,
    fetchingExpiredPayments: false,
    fetchingBusinessCustomerSubscriptionAmounts: false,

    businessCustomers: null,
    businessCustomerSummarySearch: null,
    businessCustomerSummary: null,
    filteredCustomers: null,
    exactFilteredBusinessCustomers: null,
    businessCustomersBulkPause: null,
    businessCustomersBulkCancel: null,
    businessCustomersSinglePause: null,
    businessCustomersSingleCancel: null,
    updateSuccess: null,
    businessCustomerSubcriptions: null,
    businessCustomerRecord: null,
    count: null,
    uploadCustomer: null,
    importedCustomer: null,
    importedCustomerCount: null,
    entries: null,
    appliedImport: null,
    recentPayments: null,
    recentMissedPayments: null,
    recentSubscriptionActivity: null,
    customerReceivedPayments: null,
    customerReceivedPayment: null,
    customerMissedPayments: null,
    customerPendingPayments: null,
    customerReports: null,
    customerCharts: null,
    successfulPayments: null,
    expiredPayments: null,
    businessCustomerSubscriptionAmounts: null,

    businessCustomersError: null,
    businessCustomerSummarySearchError: null,
    businessCustomerSummaryError: null,
    filteredCustomersError: null,
    exactFilteredBusinessCustomersError: null,
    updateError: null,
    businessCustomerSubscriptionsError: null,
    businessCustomerRecordError: null,
    countError: null,
    uploadError: null,
    importError: null,
    importCountError: null,
    entryError: null,
    appliedImportError: null,
    recentSubscriptionActivityError: null,
    recentMissedPaymentsError: null,
    recentPaymentsError: null,
    businessCustomersBulkPauseError: null,
    businessCustomersBulkCancelError: null,
    businessCustomersSinglePauseError: null,
    businessCustomersSingleCancelError: null,
    customerReceivedPaymentsError: null,
    customerReceivedPaymentError: null,
    customerMissedPaymentsError: null,
    customerPendingPaymentsError: null,
    customerReportsError: null,
    customerChartsError: null,
    successfulPaymentsError: null,
    expiredPaymentsError: null,
    businessCustomerSubscriptionAmountsError: null,
});

/* ------------- Reducers ------------- */

// request data from an api
export const allRequest = (state) => {
    return { ...state, fetchingAllBusinessCustomers: true, businessCustomers: null }
};

// request data from an api
export const customerSearchRequest = (state) => {
    return { ...state, fetchingFilteredCustomers: true, filteredCustomers: null }
}

//request data from an api
export const exactFilteredBusinessCustomerRequest = (state) => {
    return { ...state, fetchingExactFilteredBusinessCustomers: true, exactFilteredBusinessCustomers: null }
}

// update request 
export const updateRequest = (state) => {
    return { updating: true, updateSuccess: null }
};
// request data from an api 
export const businessCustomerSubscriptionsRequest = (state) => { return { ...state, fetchingbusinessCustomerSubscriptions: true, businessCustomerSubcriptions: null } }

// request data from an api 
export const businessCustomersBulkPauseRequest = (state) => { return { ...state, pausingBulkBusinessCustomers: true, businessCustomersBulkPause: null } }

// request data from an api 
export const businessCustomersBulkCancelRequest = (state) => { return { ...state, cancellingBulkBusinessCustomers: true, businessCustomersBulkCancel: null } }
// request data from an api 
export const businessCustomersSinglePauseRequest = (state) => { return { ...state, pausingBusinessCustomersSinglePause: true, businessCustomersSinglePause: null } }

// request data from an api 
export const businessCustomersSingleCancelRequest = (state) => { return { ...state, cancellingSingleBusinessCustomer: true, businessCustomersSingleCancel: null } }

// request data from an api 
export const businessCustomerRecordRequest = (state) => { return { ...state, fetchingBusinessCustomerRecord: true, businessCustomerRecord: null } }

// request data from an api 
export const businessCustomerSummarySearchRequest = (state) => { return { ...state, fetchingBusinessCustomerSummarySearch: true, businessCustomerSummarySearch: null, businessCustomerSummarySearchFailure: null } }

// request data from an api 
export const businessCustomerSummaryRequest = (state) => { return { ...state, fetchingBusinessCustomerSummary: true, businessCustomerSummary: null, businessCustomerSummaryFailure: null } }

// request data from an api 
export const countRequest = (state) => { return { ...state, fetchingCount: true, count: null } }

export const uploadRequest = (state) => {
    return { ...state, uploading: true, uploadCustomer: null }
}

export const importRequest = (state) => {
    return { ...state, fetchingImportedCustomers: true, importCustomer: null }
}
export const importCountRequest = (state) => {
    return { ...state, fetchingImportedCustomersCount: true, importedCustomerCount: null }
}

export const entryRequest = (state) => {
    return { ...state, fetchingEntries: true, entries: null }
}

export const applyRequest = (state) => {
    return { ...state, applying: true, appliedImport: null }
}

export const businessCustomerPaymentsRequest = (state) => {
    return { ...state, fetchingRecentPayments: true, recentPayments: null, recentPaymentsError: null }
}

export const businessCustomerMissedPaymentsRequest = (state) => {
    return { ...state, fetchingRecentMissedPayments: true, recentMissedPayments: null, recentMissedPaymentsError: null }
}

export const businessCustomerSubscriptionActivityRequest = (state) => {
    return { ...state, fetchingRecentSubscriptionActivity: true, recentSubscriptionActivity: null, recentSubscriptionActivityError: null }
}

export const customerReceivedPaymentRequest = (state) => {
    return { ...state, fetchingCustomerReceivedPayments: true, customerReceivedPayments: null, customerReceivedPaymentsError: null }
}

export const customerMissedPaymentRequest = (state) => {
    return { ...state, fetchingCustomerMissedPayments: true, customerMissedPayments: null, customerMissedPaymentsError: null }
}

export const customerPendingPaymentRequest = (state) => {
    return { ...state, fetchingCustomerPendingPayments: true, customerPendingPayments: null, customerPendingPaymentsError: null }
}

export const customerReportsRequest = (state) => {
    return { ...state, fetchingCustomerReports: true, customerReports: null, customerReportsError: null }
}
export const customerChartsRequest = (state) => {
    return { ...state, fetchingCustomerCharts: true, customerCharts: null, customerChartsError: null }
}

export const successfulPaymentsRequest = (state) => {
    return { ...state, fetchingSuccessfulPayments: true, successfulPayments: null, successfulPaymentsError: null }
}
export const expiredPaymentsRequest = (state) => {
    return { ...state, fetchingExpiredPayments: true, expiredPayments: null, expiredPaymentsError: null }
}
//request data from api
export const businessCustomerSubscriptionAmountsRequest = (state) => {
    return { ...state, fetchingBusinessCustomerSubscriptionAmounts: true, businessCustomerSubscriptionAmounts: null }
}

// successful api lookup for single entity
export const allSuccess = (state, action) => {
    const { businessCustomers } = action;
    return { ...state, fetchingAllBusinessCustomers: false, businessCustomersError: null, businessCustomers }
};

// successful api lookup for exact filtered customers
export const exactFilteredBusinessCustomerSuccess = (state, action) => {
    const { customers } = action
    return {
        ...state,
        fetchingExactFilteredBusinessCustomers: false,
        exactFilteredBusinessCustomersError: null,
        exactFilteredBusinessCustomers: customers
    }
}
// succesful api lookup for filtered customers
export const customerSearchSuccess = (state, action) => {
    const { customers } = action
    return {
        ...state,
        fetchingFilteredCustomers: false,
        filteredCustomersError: null,
        filteredCustomers: customers
    }
}

// successful update
export const updateSuccess = (state, action) => {
    const { customer } = action;
    return { ...state, updating: false, updateError: null, updateSuccess: customer }
};
// successful api lookup for business customer subscriptions 
export const businessCustomerSubcriptionsSuccess = (state, action) => {
    const { details } = action
    return {
        ...state,
        fetchingbusinessCustomerSubscriptions: false,
        businessCustomerSubcriptions: details,
        businessCustomerSubscriptionsError: null
    }
}

// successful api lookup for business customer record
export const businessCustomerRecordSuccess = (state, action) => {
    const { customerRecord } = action
    return {
        ...state,
        fetchingBusinessCustomerRecord: false,
        businessCustomerRecord: customerRecord,
        businessCustomerRecordError: null
    }
}

// successful api lookup for business customers subscriptions count 
export const countSuccess = (state, action) => {
    const { businessCustomerSubscriptionsCount } = action
    return {
        ...state,
        fetchingCount: false,
        count: businessCustomerSubscriptionsCount,
        countError: null
    }
}

export const businessCustomersBulkPauseSuccess = (state, action) => {
    const { pausedCustomers } = action
    return {
        ...state, pausingBulkBusinessCustomers: false, businessCustomersBulkPause: pausedCustomers, businessCustomersBulkPauseError: null
    }
}
export const businessCustomersBulkCancelSuccess = (state, action) => {
    const { cancelledCustomers } = action
    return {
        ...state, cancellingBulkBusinessCustomers: false, businessCustomersBulkCancelError: null, businessCustomersBulkCancel: cancelledCustomers
    }
}
export const businessCustomersSinglePauseSuccess = (state, action) => {
    const { pausedCustomers } = action
    return {
        ...state, pausingBusinessCustomersSinglePause: false, businessCustomersSinglePause: pausedCustomers, businessCustomersSinglePauseError: null,
    }
}
export const businessCustomersSingleCancelSuccess = (state, action) => {
    const { cancelledCustomers } = action
    return {
        ...state, cancellingSingleBusinessCustomer: false, businessCustomersSingleCancelError: null, businessCustomersSingleCancel: cancelledCustomers
    }
}

export const uploadSuccess = (state, action) => {
    const { customer } = action;
    return { ...state, uploading: false, uploadCustomer: customer }
}

export const importSuccess = (state, action) => {
    const { importedCustomer } = action;
    return { ...state, fetchingImportedCustomers: false, importedCustomer: importedCustomer }
}

export const importCountSuccess = (state, action) => {
    const { importedCustomerCount } = action;
    return { ...state, fetchingImportedCustomersCount: false, importedCustomerCount: importedCustomerCount }
}

export const entrySuccess = (state, action) => {
    const { entry } = action;
    return { ...state, fetchingEntries: false, entries: entry }
}

export const applySuccess = (state, action) => {
    const { appliedImport } = action;
    return { ...state, applying: false, appliedImport: appliedImport }
}


export const businessCustomerSummarySearchSuccess = (state, action) => {
    const { businessCustomerSummarySearch } = action
    return { ...state, fetchingBusinessCustomerSummarySearch: false, businessCustomerSummarySearch, businessCustomerSummarySearchFailure: null }
}

export const businessCustomerSummarySuccess = (state, action) => {
    const { businessCustomerSummary } = action
    return { ...state, fetchingBusinessCustomerSummary: false, businessCustomerSummary, businessCustomerSummaryFailure: null }
}

export const businessCustomerPaymentsSuccess = (state, action) => {
    const { recentPayments } = action
    return { ...state, fetchingRecentPayments: false, recentPayments: recentPayments, recentPaymentsError: null }
}
export const businessCustomerMissedPaymentsSuccess = (state, action) => {
    const { recentMissedPayments } = action
    return { ...state, fetchingRecentMissedPayments: false, recentMissedPayments, recentMissedPaymentsError: null }
}
export const businessCustomerSubscriptionActivitySuccess = (state, action) => {
    const { recentSubscriptionActivities } = action
    return { ...state, fetchingRecentSubscriptionActivity: false, recentSubscriptionActivity: recentSubscriptionActivities, recentSubscriptionActivityError: null }
}

export const customerReceivedPaymentSuccess = (state, action) => {
    const { customerReceivedPayments } = action
    return { ...state, fetchingCustomerReceivedPayments: false, customerReceivedPayments: customerReceivedPayments, customerReceivedPaymentsError: null }
}
export const customerMissedPaymentSuccess = (state, action) => {
    const { customerMissedPayments } = action
    return { ...state, fetchingCustomerMissedPayments: false, customerMissedPayments: customerMissedPayments, customerMissedPaymentsError: null }
}
export const customerPendingPaymentSuccess = (state, action) => {
    const { customerPendingPayments } = action
    return { ...state, fetchingCustomerPendingPayments: false, customerPendingPayments: customerPendingPayments, customerPendingPaymentsError: null }
}

export const successfulPaymentsSuccess = (state, action) => {
    const { successfulPayments } = action
    return { ...state, fetchingSuccessfulPayments: false, successfulPayments, successfulPaymentsError: null }
}
export const expiredPaymentsSuccess = (state, action) => {
    const { expiredPayments } = action
    return { ...state, fetchingExpiredPayments: false, expiredPayments: expiredPayments, expiredPaymentsError: null }
}
export const customerReportsSuccess = (state, action) => {
    const { customerReports } = action
    return { ...state, fetchingCustomerReports: false, customerReports: customerReports, customerReportsError: null }
}
export const customerChartsSuccess = (state, action) => {
    const { customerCharts } = action
    return { ...state, fetchingCustomerCharts: false, customerCharts: customerCharts, customerChartsError: null }
}

//successful api lookup for single entity
export const businessCustomerSubscriptionAmountsSuccess = (state, action) => {
    const { businessCustomerSubscriptionAmounts } = action;
    return {
        ...state, fetchingBusinessCustomerSubscriptionAmounts: false, businessCustomerSubscriptionAmountsError: null,
        businessCustomerSubscriptionAmounts
    }
}

// Something went wrong fetching all count.
export const allFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingAllBusinessCustomers: false, businessCustomersError: error, businessCustomers: null }
};

// Something went wrong fetching filtered customers
export const customerSearchFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingFilteredCustomers: false,
        filteredCustomersError: error,
        filteredCustomers: null
    }
}
// Something went wrong fetching filtered customers
export const exactFilteredBusinessCustomersFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingExactFilteredBusinessCustomers: false,
        exactFilteredBusinessCustomersError: error,
        exactFilteredBusinessCustomers: null
    }
}

// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action;
    return { ...state, updating: false, updateError: error, updateSuccess: null }
};

// Something went wrong fetching business customer subscriptions 
export const businessCustomerSubscriptionsFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingbusinessCustomerSubscriptions: false,
        businessCustomerSubcriptions: null,
        businessCustomerSubscriptionsError: error
    }
}

// Something went wrong fetching business customer record 
export const businessCustomerRecordFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingBusinessCustomerRecord: false,
        businessCustomerRecord: null,
        businessCustomerRecordError: error
    }
}

// Something went wrong fetching business customer subscriptions count
export const countFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingCount: false,
        count: null,
        countError: error
    }
}

export const uploadFailure = (state, action) => {
    const { error } = action;
    return { ...state, uploading: false, uploadError: error }
}

export const importFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingImportedCustomers: false, importError: error, importedCustomer: null }
}

export const importCountFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingImportedCustomersCount: false, importCountError: error, importedCustomerCount: null }
}

export const entryFailure = (state, action) => {
    const { error } = action;
    return { ...state, fetchingEntries: false, entryError: error }
}

export const applyFailure = (state, action) => {
    const { error } = action;
    return { ...state, applying: false, appliedImportError: error }
}

export const businessCustomerSummarySearchFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingBusinessCustomerSummarySearch: false, businessCustomerSummarySearchSuccess: null, businessCustomerSummarySearchError: error }
}
export const businessCustomersBulkPauseFailure = (state, action) => {
    const { error } = action
    return { ...state, pausingBulkBusinessCustomers: false, businessCustomersBulkPause: null, businessCustomersBulkPauseError: error }
}
export const businessCustomersBulkCancelFailure = (state, action) => {
    const { error } = action
    return { ...state, cancellingBulkBusinessCustomers: false, businessCustomersBulkCancel: null, businessCustomersBulkCancelError: error }
}
export const businessCustomersSinglePauseFailure = (state, action) => {
    const { error } = action
    return { ...state, pausingBusinessCustomersSinglePause: false, businessCustomersSinglePause: null, businessCustomersSinglePauseError: error }
}
export const businessCustomersSingleCancelFailure = (state, action) => {
    const { error } = action
    return { ...state, cancellingSingleBusinessCustomer: false, businessCustomersSingleCancel: null, businessCustomersSingleCancelError: error }
}

export const businessCustomerSummaryFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingBusinessCustomerSummary: false, businessCustomerSummarySuccess: null, businessCustomerSummaryError: error }
}

export const businessCustomerPaymentsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingRecentPayments: false, recentPayments: null, recentPaymentsError: error }
}
export const businessCustomerMissedPaymentsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingRecentMissedPayments: false, recentMissedPayments: null, recentMissedPaymentsError: error }
}
export const businessCustomerSubscriptionActivityFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingRecentSubscriptionActivity: false, recentSubscriptionActivity: null, recentSubscriptionActivityError: error }
}

export const customerPendingPaymentFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCustomerPendingPayments: false, customerPendingPayments: null, customerPendingPaymentsError: error }
}

export const customerMissedPaymentFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCustomerMissedPayments: false, customerMissedPayments: null, customerMissedPaymentsError: error }
}

export const customerReceivedPaymentFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCustomerReceivedPayments: false, customerReceivedPayments: null, customerReceivedPaymentsError: error }
}

export const customerReportsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCustomerReports: false, customerReports: null, customerReportsError: error }
}
export const customerChartsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingCustomerCharts: false, customerCharts: null, customerChartsError: error }
}
export const successfulPaymentsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingSuccessfulPayments: false, successfulPayments: null, successfulPaymentsError: error }
}
export const expiredPaymentsFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingExpiredPayments: false, expiredPayments: null, expiredPaymentsError: error }
}
//something went wrong fetching businessCustomerSubscriptionAmounts?
export const businessCustomerSubscriptionAmountsFailure = (state, action) => {
    const { error } = action;
    return {
        ...state, fetchingBusinessCustomerSubscriptionAmounts: false, businessCustomerSubscriptionAmountsError: error,
        businessCustomerSubscriptionAmounts: null
    }
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.BUSINESS_CUSTOMER_ALL_REQUEST]: allRequest,
    [Types.BUSINESS_CUSTOMER_SEARCH_REQUEST]: customerSearchRequest,
    [Types.EXACT_FILTERED_BUSINESS_CUSTOMER_REQUEST]: exactFilteredBusinessCustomerRequest,
    [Types.BUSINESS_CUSTOMER_UPDATE_REQUEST]: updateRequest,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_REQUEST]: businessCustomerSubscriptionsRequest,
    [Types.BUSINESS_CUSTOMER_RECORD_REQUEST]: businessCustomerRecordRequest,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_COUNT_REQUEST]: countRequest,
    [Types.BUSINESS_CUSTOMER_SUMMARY_SEARCH_REQUEST]: businessCustomerSummarySearchRequest,
    [Types.BUSINESS_CUSTOMER_SUMMARY_REQUEST]: businessCustomerSummaryRequest,
    [Types.BUSINESS_CUSTOMER_UPLOAD_REQUEST]: uploadRequest,
    [Types.BUSINESS_CUSTOMER_IMPORT_REQUEST]: importRequest,
    [Types.BUSINESS_CUSTOMER_IMPORT_COUNT_REQUEST]: importCountRequest,
    [Types.BUSINESS_CUSTOMER_ENTRY_REQUEST]: entryRequest,
    [Types.BUSINESS_CUSTOMER_APPLY_REQUEST]: applyRequest,
    [Types.BUSINESS_CUSTOMER_PAYMENTS_REQUEST]: businessCustomerPaymentsRequest,
    [Types.BUSINESS_CUSTOMER_MISSED_PAYMENTS_REQUEST]: businessCustomerMissedPaymentsRequest,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_ACTIVITY_REQUEST]: businessCustomerSubscriptionActivityRequest,
    [Types.CUSTOMER_RECEIVED_PAYMENT_REQUEST]: customerReceivedPaymentRequest,
    [Types.CUSTOMER_MISSED_PAYMENT_REQUEST]: customerMissedPaymentRequest,
    [Types.CUSTOMER_PENDING_PAYMENT_REQUEST]: customerPendingPaymentRequest,
    [Types.CUSTOMER_REPORT_REQUEST]: customerReportsRequest,
    [Types.CUSTOMER_CHARTS_REQUEST]: customerChartsRequest,
    [Types.SUCCESSFUL_PAYMENTS_REQUEST]: successfulPaymentsRequest,
    [Types.EXPIRED_PAYMENTS_REQUEST]: expiredPaymentsRequest,
    [Types.BUSINESS_CUSTOMERS_BULK_PAUSE_REQUEST]: businessCustomersBulkPauseRequest,
    [Types.BUSINESS_CUSTOMERS_BULK_CANCEL_REQUEST]: businessCustomersBulkCancelRequest,
    [Types.BUSINESS_CUSTOMERS_SINGLE_PAUSE_REQUEST]: businessCustomersSinglePauseRequest,
    [Types.BUSINESS_CUSTOMERS_SINGLE_CANCEL_REQUEST]: businessCustomersSingleCancelRequest,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_AMOUNTS_REQUEST]: businessCustomerSubscriptionAmountsRequest,

    [Types.BUSINESS_CUSTOMER_ALL_SUCCESS]: allSuccess,
    [Types.BUSINESS_CUSTOMER_SEARCH_SUCCESS]: customerSearchSuccess,
    [Types.EXACT_FILTERED_BUSINESS_CUSTOMER_SUCCESS]: exactFilteredBusinessCustomerSuccess,
    [Types.BUSINESS_CUSTOMER_UPDATE_SUCCESS]: updateSuccess,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_SUCCESS]: businessCustomerSubcriptionsSuccess,
    [Types.BUSINESS_CUSTOMER_SUMMARY_SEARCH_SUCCESS]: businessCustomerSummarySearchSuccess,
    [Types.BUSINESS_CUSTOMER_SUMMARY_SUCCESS]: businessCustomerSummarySuccess,
    [Types.BUSINESS_CUSTOMER_RECORD_SUCCESS]: businessCustomerRecordSuccess,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_COUNT_SUCCESS]: countSuccess,
    [Types.BUSINESS_CUSTOMER_UPLOAD_SUCCESS]: uploadSuccess,
    [Types.BUSINESS_CUSTOMER_IMPORT_SUCCESS]: importSuccess,
    [Types.BUSINESS_CUSTOMER_IMPORT_COUNT_SUCCESS]: importCountSuccess,
    [Types.BUSINESS_CUSTOMER_ENTRY_SUCCESS]: entrySuccess,
    [Types.BUSINESS_CUSTOMER_APPLY_SUCCESS]: applySuccess,
    [Types.BUSINESS_CUSTOMER_PAYMENTS_SUCCESS]: businessCustomerPaymentsSuccess,
    [Types.BUSINESS_CUSTOMER_MISSED_PAYMENTS_SUCCESS]: businessCustomerMissedPaymentsSuccess,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_ACTIVITY_SUCCESS]: businessCustomerSubscriptionActivitySuccess,
    [Types.CUSTOMER_RECEIVED_PAYMENT_SUCCESS]: customerReceivedPaymentSuccess,
    [Types.CUSTOMER_MISSED_PAYMENT_SUCCESS]: customerMissedPaymentSuccess,
    [Types.CUSTOMER_PENDING_PAYMENT_SUCCESS]: customerPendingPaymentSuccess,
    [Types.CUSTOMER_REPORT_SUCCESS]: customerReportsSuccess,
    [Types.CUSTOMER_CHARTS_SUCCESS]: customerChartsSuccess,
    [Types.SUCCESSFUL_PAYMENTS_SUCCESS]: successfulPaymentsSuccess,
    [Types.EXPIRED_PAYMENTS_SUCCESS]: expiredPaymentsSuccess,
    [Types.BUSINESS_CUSTOMERS_BULK_PAUSE_SUCCESS]: businessCustomersBulkPauseSuccess,
    [Types.BUSINESS_CUSTOMERS_BULK_CANCEL_SUCCESS]: businessCustomersBulkCancelSuccess,
    [Types.BUSINESS_CUSTOMERS_SINGLE_PAUSE_SUCCESS]: businessCustomersSinglePauseSuccess,
    [Types.BUSINESS_CUSTOMERS_SINGLE_CANCEL_SUCCESS]: businessCustomersSingleCancelSuccess,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_AMOUNTS_SUCCESS]: businessCustomerSubscriptionAmountsSuccess,

    [Types.BUSINESS_CUSTOMER_ALL_FAILURE]: allFailure,
    [Types.BUSINESS_CUSTOMER_SEARCH_FAILURE]: customerSearchFailure,
    [Types.EXACT_FILTERED_BUSINESS_CUSTOMERS_FAILURE]: exactFilteredBusinessCustomersFailure,
    [Types.BUSINESS_CUSTOMER_UPDATE_FAILURE]: updateFailure,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_FAILURE]: businessCustomerRecordFailure,
    [Types.BUSINESS_CUSTOMER_RECORD_FAILURE]: businessCustomerRecordFailure,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTIONS_COUNT_FAILURE]: countFailure,
    [Types.BUSINESS_CUSTOMER_SUMMARY_FAILURE]: businessCustomerSummaryFailure,
    [Types.BUSINESS_CUSTOMER_UPLOAD_FAILURE]: uploadFailure,
    [Types.BUSINESS_CUSTOMER_IMPORT_FAILURE]: importFailure,
    [Types.BUSINESS_CUSTOMER_IMPORT_COUNT_FAILURE]: importCountFailure,
    [Types.BUSINESS_CUSTOMER_ENTRY_FAILURE]: entryFailure,
    [Types.BUSINESS_CUSTOMER_APPLY_FAILURE]: applyFailure,
    [Types.BUSINESS_CUSTOMER_PAYMENTS_FAILURE]: businessCustomerPaymentsFailure,
    [Types.BUSINESS_CUSTOMER_MISSED_PAYMENTS_FAILURE]: businessCustomerMissedPaymentsFailure,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_ACTIVITY_FAILURE]: businessCustomerSubscriptionActivityFailure,
    [Types.CUSTOMER_RECEIVED_PAYMENT_FAILURE]: customerReceivedPaymentFailure,
    [Types.CUSTOMER_MISSED_PAYMENT_FAILURE]: customerMissedPaymentFailure,
    [Types.CUSTOMER_PENDING_PAYMENT_FAILURE]: customerPendingPaymentFailure,
    [Types.CUSTOMER_REPORT_FAILURE]: customerReportsFailure,
    [Types.CUSTOMER_CHARTS_FAILURE]: customerChartsFailure,
    [Types.SUCCESSFUL_PAYMENTS_FAILURE]: successfulPaymentsFailure,
    [Types.EXPIRED_PAYMENTS_FAILURE]: expiredPaymentsFailure,
    [Types.BUSINESS_CUSTOMERS_BULK_PAUSE_FAILURE]: businessCustomersBulkPauseFailure,
    [Types.BUSINESS_CUSTOMERS_BULK_CANCEL_FAILURE]: businessCustomersBulkCancelFailure,
    [Types.BUSINESS_CUSTOMERS_SINGLE_PAUSE_FAILURE]: businessCustomersSinglePauseFailure,
    [Types.BUSINESS_CUSTOMERS_SINGLE_CANCEL_FAILURE]: businessCustomersSingleCancelFailure,
    [Types.BUSINESS_CUSTOMER_SUBSCRIPTION_AMOUNTS_FAILURE]: businessCustomerSubscriptionAmountsFailure

})
