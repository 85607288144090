import React, { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

const CustomerEdit = ({ displayBasic, onHide, id, state, setState }) => {
    const { t } = useTranslation("common");

    const toast = useRef(null);

    //redux state from store
    const updatingCustomer = useSelector((state) => state.businessCustomers.updating);
    const updateError = useSelector((state) => state.businessCustomers.updateError);
    const updateSuccess = useSelector((state) => state.businessCustomers.updateSuccess);
    const defMembership = useSelector((state) => state.defMembership.defMembership);

    const dispatch = useDispatch();
    const updateCustomer = useCallback((businessCustomerId, businessCustomer) => dispatch(BusinessCustomersActions.businessCustomerUpdateRequest(businessCustomerId, businessCustomer)), [dispatch]);
    const getEnterpriseCustomers = useCallback((businessId, options) => dispatch(BusinessCustomersActions.businessCustomerAllRequest(businessId, options)), [dispatch]);
    const getBusinessCustomerRecord = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessCustomerId)), [dispatch]);
    const getSummaryCustomers = useCallback((businessId, query) => dispatch(BusinessCustomersActions.businessCustomerSummaryRequest(businessId, query)), [dispatch]);

    useEffect(() => {
        if (!updatingCustomer && state.updating && updateError) {
            showError();
        }
        if (!updatingCustomer && state.updating && updateSuccess) {
            showSuccess();
            setState((state) => { return { ...state, updating: false } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, updatingCustomer, state.updating, updateSuccess]);

    const showSuccess = () => {
        toast.current.show({ severity: "success", summary: t("business_customers.success_summary"), detail: t("business_customers.success_detail"), life: 3000 });
    };

    const showError = () => {
        toast.current.show({ severity: "error", summary: t("business_customers.error_summary"), detail: t("business_customers.error_detail"), life: 3000 });
    };

    const renderFooter = (name) => {
        return (
            <div>
                <Button
                    style={{ background: "#D0021B", color: "white", border: 'none', fontFamily: "robotoCondensed", margin: "0rem 1rem 1rem 0rem" }}
                    label={t("business_customers.update")}
                    disabled={!state.firstName || !state.lastName}
                    loading={updatingCustomer}
                    onClick={() => {
                        onSubmit();
                    }}
                    autoFocus
                />
            </div>
        );
    };

    const onSubmit = () => {
        if (!state.firstName || state.firstName.trim() === "") {
            setState((prev) => {
                return { ...prev, firstNameError: true };
            });
            return;
        } else {
            setState((prev) => {
                return { ...prev, firstNameError: false };
            });
        }
        if (!state.lastName || state.lastName.trim() === "") {
            setState((prev) => {
                return { ...prev, lastNameError: true };
            });
            return;
        } else {
            setState((prev) => {
                return { ...prev, lastNameError: false };
            });
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
            setState((prev) => {
                return { ...prev, emailError: true };
            });
            return;
        } else {
            setState((prev) => {
                return { ...prev, emailError: false };
            });
        }

        const businessCustomer = {
            id: id,
            firstName: state.firstName,
            lastName: state.lastName,
            customerNum: state.customerNum,
            email: state.email || null,
        };
        updateCustomer(id, businessCustomer);
        setState((prev) => {
            return { ...prev, updating: true };
        });
    }
    const handleChange = (e) => {
        const inputValue = e.target.value;

        // eslint-disable-next-line
        const allowedCharacters = /^[a-zA-Z0-9\s\-\/]*$/;

        if (allowedCharacters.test(inputValue)) {
            setState((prev) => {
                return { ...prev, customerNum: inputValue };
            })
        }
    };

    return (
        <>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        onHide();
                        getEnterpriseCustomers(defMembership.business.id, { sort: "addedOn,desc" });
                        getBusinessCustomerRecord(id);
                        getSummaryCustomers(defMembership.business.id);
                    }
                }}
            />
            <Dialog
                header={t("business_customers.edit_header")}
                visible={displayBasic}
                style={{ width: "350px" }}
                footer={renderFooter("displayBasic")}
                id="tier_dialog"
                className="tier_dialog"
                onHide={() => {
                    onHide();
                }}
            >
                <>
                    <div className="p-grid p-fluid" style={{ margin: "0 auto" }}>
                        <div className="edit_forms_div">
                            <div className="flexDirection_styles">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label className="customer_labels">{t("common.first_name")}</label>
                                    <span style={{ color: "#d0021b", marginLeft: '0.2rem' }}>*</span>
                                </div>
                                <InputText
                                    error={state.firstNameError}
                                    required
                                    id="first_name-required"
                                    name="firstName"
                                    label={t("common.first_name")}
                                    variant="outlined"
                                    value={state.firstName}
                                    onChange={({ target }) =>
                                        setState((prev) => {
                                            return { ...prev, firstName: target.value };
                                        })
                                    }
                                    maxLength={50}
                                />
                            </div>
                            <div className="label_inputs">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label className="customer_labels">{t("common.last_name")}</label>
                                    <span style={{ color: "#d0021b", marginLeft: '0.2rem' }}>*</span>
                                </div>
                                <InputText
                                    error={state.lastNameError}
                                    required
                                    id="last_name-required"
                                    name="lastName"
                                    label={t("common.last_name")}
                                    variant="outlined"
                                    value={state.lastName}
                                    onChange={({ target }) =>
                                        setState((prev) => {
                                            return { ...prev, lastName: target.value };
                                        })
                                    }
                                    maxLength={50}
                                />
                            </div>
                            <div className="label_inputs">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label className="customer_labels">{t("common.email")}</label>
                                    <span style={{ color: "#d0021b", marginLeft: '0.2rem' }}>*</span>
                                </div>
                                <InputText
                                    required
                                    id="email-required"
                                    label={t("common.email")}
                                    name="email"
                                    variant="outlined"
                                    value={state.email}
                                    onChange={({ target }) =>
                                        setState((prev) => {
                                            return { ...prev, email: target.value };
                                        })
                                    }
                                    maxLength={50}
                                />
                                <label>{state.emailError ? <span className="email-error-text">{t("business_customers.invalid_email_error")}</span> : ""}  </label>
                            </div>
                            <div className="label_inputs">
                                <label className="customer_labels">{t("business_customers.customer_ref")}</label><InputText
                                    id="customer_id-required"
                                    name="id"
                                    label={t("business_customers.customer_num")}
                                    variant="outlined"
                                    value={state.customerNum}
                                    onChange={handleChange}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </div >
                </>
            </Dialog >

        </>
    )
}

export default CustomerEdit