import { call, put } from "redux-saga/effects";
import PlanActions from "./plan.reducer";
import * as api from "../../../Service/api";

export function* getPlan(action) {
	const { planId } = action;
	// make the call to the api
	const response = yield call(api.getPlan, planId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planSuccess(response.data));
	} else {
		yield put(PlanActions.planFailure(response.data));
	}
}

export function* getPlans(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.getPlans, businessId, options);

	// success?
	if (response.ok) {
		yield put(PlanActions.planAllSuccess(response.data));
	} else {
		yield put(PlanActions.planAllFailure(response.data));
	}
}

export function* createPlan(action) {
	const { plan } = action;
	// make the call to the api

	const response = yield call(api.createPlan, plan);

	// success?
	if (response.ok) {
		yield put(PlanActions.planCreateSuccess(response.data));
	} else {
		yield put(PlanActions.planCreateFailure(response.data));
	}
}
export function* firstCreate(action) {
	const { firstPlan } = action;
	// make the call to the api

	const response = firstPlan
	// const response = yield call(api.createPlan, plan);
	// success?
	if (firstPlan) {
		yield put(PlanActions.firstTierSuccess(response.data));
	} else {
		yield put(PlanActions.firstTierFailure(response.data));
	}
}

export function* updatePlan(action) {
	const { plan, planId } = action;
	// make the call to the api

	const response = yield call(api.updatePlan, planId, plan);

	// success?
	if (response.ok) {
		yield put(PlanActions.planUpdateSuccess(response.data));
	} else {
		yield put(PlanActions.planUpdateFailure(response.data));
	}
}
export function* priceChange(action) {
	const { planId, amount } = action;
	// make the call to the api

	const response = yield call(api.changePlanPrice, planId, amount);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPriceChangeSuccess(response.data));
	} else {
		yield put(PlanActions.planPriceChangeFailure(response.data));
	}
}

export function* deletePlan(action) {
	const { planId } = action;
	// make the call to the api
	const response = yield call(api.deletePlan, planId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planDeleteSuccess());
	} else {
		yield put(PlanActions.planDeleteFailure(response.data));
	}
}
export function* archivePlan(action) {
	const { planId, membershipId } = action;
	// make the call to the api
	const response = yield call(api.archivePlan, planId, membershipId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planArchiveSuccess());
	} else {
		yield put(PlanActions.planArchiveFailure(response.data));
	}
}

export function* planPerformance(action) {
	const { tierId } = action;
	// make the call to the api
	const response = yield call(api.planPerformance, tierId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPerformanceSuccess(response.data));
	} else {
		yield put(PlanActions.planPerformanceFailure(response.data));
	}
}

export function* migratePlan(action) {
	const { migratedData } = action;
	// make the call to the api
	const response = yield call(api.migratePlan, migratedData);

	// success?
	if (response.ok) {
		yield put(PlanActions.planMigrateSuccess());
	} else {
		yield put(PlanActions.planMigrateFailure(response.data));
	}
}
export function* getPlanPaymentMissed(action) {
	const { planId, options } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentMissed, planId, options);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPaymentMissedSuccess(response.data));
	} else {
		yield put(PlanActions.planPaymentMissedFailure(response.data));
	}
}

export function* getPlanPaymentMissedCount(action) {
	const { planId } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentMissedCount, planId);

	// success?
	if (response.ok) {
		// console.log("Archived");
		yield put(PlanActions.planPaymentMissedCountSuccess(response.data));

	} else {
		yield put(PlanActions.planPaymentMissedCountFailure(response.data));
	}
}

export function* getPlanPaymentPending(action) {
	const { planId, options } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentPending, planId, options);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPaymentPendingSuccess(response.data));
	} else {
		yield put(PlanActions.planPaymentPendingFailure(response.data));
	}
}

export function* getPlanPaymentPendingCount(action) {
	const { planId } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentPendingCount, planId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPaymentPendingCountSuccess(response.data));
	} else {
		yield put(PlanActions.planPaymentPendingCountFailure(response.data));
	}
}

export function* getPlanPaymentCollected(action) {
	const { planId, options } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentCollected, planId, options);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPaymentCollectedSuccess(response.data));

	} else {
		yield put(PlanActions.planPaymentCollectedFailure(response.data));
	}
}

export function* getPlanPaymentCollectedCount(action) {
	const { planId } = action;
	// make the call to the api
	const response = yield call(api.getPlanPaymentCollectedCount, planId);

	// success?
	if (response.ok) {
		yield put(PlanActions.planPaymentCollectedCountSuccess(response.data));
	} else {
		yield put(PlanActions.planPaymentCollectedCountFailure(response.data));
	}
}
