/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import SubscriberActions from "../../Redux/transition/subscriber/subscriber.reducer";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import Loading from "../../Components/Loading";
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber"
import "../../Styles/pages/products.scss"
import "../../Styles/dataTable.scss"
import CustomDataTable from '../../Components/CustomDataTable';
import PlanEdit from './PlanEdit';
import { Paginator } from 'primereact/paginator';
import RoleAccess from '../../Components/RoleAccess';
import MarkAsPaid from '../../Components/MarkAsPaid';

const PlanDetail = () => {
    const { t } = useTranslation("common");
    const { id: planId } = useParams();
    const [displayBasic, setDisplayBasic] = useState(false);
    const history = useHistory();
    const [state, setState] = useState({
        sort: 'id,desc',
        count: null,
        size: 15,
        first: 0,
        page: 0,
        plan: {},
        subscribers: [],
        unmounted: false,
        fetching: true,
        planArchiving: false,
        performance: {},
        missed: {},
        pending: [],
        missedCount: 0,
        pendingCount: 0,
        collected: [],
        collectedCount: 0,
        id: null,
        groupExternalPayments: {},
        flex: null,
        tabChange: true,
    });
    const [visible, setVisible] = useState(false);
    const [visibleInvite, setVisibleInvite] = useState(false);
    const [nameChangeVisible, setNameChangeVisible] = useState({})
    const [trigger, setTrigger] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const toast = useRef(null);

    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const { plan, archiving, archiveSuccess, errorArchiving, performance, missed,
        fetchingMissed, missedCount, fetchingPaymentMissedCount,
        pending, fetchingPaymentPending, fetchingPending, pendingCount, fetchingPaymentPendingCount, collected, collectedCount, fetchingCollected, fetchingCollectedCount
    } = useSelector((state) => state.plans)
    const { planSubscribers, fetchingPlanSubscribers, fetchingPlan } = useSelector((state) => state.subscribers)
    const planPayments = useSelector((state) => state.paymentTracks.planPayments);
    const fetchingExternalPayments = useSelector((state) => state.paymentTracks.fetchingPlansExternalPayments)

    const dispatch = useDispatch();
    const getPlan = useCallback((planId, options) => dispatch(PlanActions.planRequest(planId, options)), [dispatch]);
    const getPlanSubscribers = useCallback((planId, options) => dispatch(SubscriberActions.subscriberPlanRequest(planId, options)), [dispatch])
    const archivePlan = useCallback((planId, membershipId) => { dispatch(PlanActions.planArchiveRequest(planId, membershipId)) }, [dispatch])
    const planPerformance = useCallback((tierId) => { dispatch(PlanActions.planPerformanceRequest(tierId)) }, [dispatch])
    const getPlanPaymentMissed = useCallback((planId, options) => dispatch(PlanActions.planPaymentMissedRequest(planId, options)), [dispatch])
    const getPlanPaymentMissedCount = useCallback((planId) => dispatch(PlanActions.planPaymentMissedCountRequest(planId)), [dispatch])
    const getPlanPaymentPending = useCallback((planId, options) => dispatch(PlanActions.planPaymentPendingRequest(planId, options)), [dispatch])
    const getPlanPaymentPendingCount = useCallback((planId,) => dispatch(PlanActions.planPaymentPendingCountRequest(planId,)), [dispatch])
    const getPlanPaymentCollected = useCallback((planId, options) => dispatch(PlanActions.planPaymentCollectedRequest(planId, options)), [dispatch])
    const getPlanPaymentCollectedCount = useCallback((planId,) => dispatch(PlanActions.planPaymentCollectedCountRequest(planId,)), [dispatch])
    const getGroupExternalPayments = useCallback((businessId, groupId, options) => { dispatch(PaymentTrackActions.planExternalPaymentsRequest(businessId, groupId, options)) }, [dispatch])

    useEffect(() => {
        getPlan(planId);
        getPlanSubscribers(planId);
        planPerformance(planId);
        getPlanPaymentMissed(planId, { size: state.size });
        getPlanPaymentMissedCount(planId)
        getPlanPaymentPending(planId, { size: state.size });
        getPlanPaymentPendingCount(planId)
        getPlanPaymentCollected(planId, { size: state.size });
        getPlanPaymentCollectedCount(planId)
        getGroupExternalPayments(defMembership.business.id, planId, { size: state.size })
    }, [trigger, getPlan,]);

    useEffect(() => {
        if (plan) {
            setState(state => { return { ...state, plan: plan, flex: plan.flex } });
        }
        if (planSubscribers) {
            setState(state => { return { ...state, subscribers: planSubscribers } });
        }
        if (performance) {
            setState(state => { return { ...state, performance } });
        }
        if (missed) {
            setState((state) => { return { ...state, missed: missed?.content } })
        }
        if (pending) {
            setState((state) => ({ ...state, pending: pending?.content }))
        }
        if (missedCount) {
            setState((state) => ({ ...state, missedCount: missedCount }))
        }
        if (pendingCount) {
            setState((state) => ({ ...state, pendingCount: pendingCount }))
        }
        if (collected) {
            setState((state) => ({ ...state, collected: collected }))
        }
        if (collectedCount) {
            setState((state) => ({ ...state, collectedCount: collectedCount }))
        }
        if (planPayments) {
            setState((state) => ({ ...state, groupExternalPayments: planPayments?.content }))
        }
    }, [plan, planSubscribers, getPlan, getPlanSubscribers, performance, collected, collectedCount,
        planPayments, state.groupExternalPayments, missed])


    useEffect(() => {
        if (!archiving && state.planArchiving && archiveSuccess) {
            showSuccess({ message: t("product.planArchive_successful"), severity: "success", })
            setState((state) => ({ ...state, planArchiving: false }))
            getPlan(planId);
            getPlanSubscribers(planId);
        }
        if (!archiving && state.planArchiving && errorArchiving) {
            showError()
            setState((state) => ({ ...state, planArchiving: false }))
        }
    }, [state.planArchiving, archiving, errorArchiving, archiveSuccess])

    useEffect(() => {
        switch (activeTab) {
            case 1:
                getPlanPaymentCollected(planId, { page: state.page, sort: "receivedAt,desc", size: state.size });
                break;
            case 2:
                getPlanPaymentMissed(planId, { page: state.page, size: state.size });
                break;
            case 3:
                getPlanPaymentPending(planId, { page: state.page, size: state.size });
                break;
            case 4:
                getGroupExternalPayments(defMembership.business.id, planId, { page: state.page, size: state.size })
                break;
            default:
                getPlanSubscribers(planId);
                break;
        }
    }, [activeTab])

    const showSuccess = (data) => { toast.current.show({ severity: 'success', summary: t("product.success_summary"), detail: data.message, life: 3000 }); }
    const showError = () => { toast.current.show({ severity: 'error', summary: t("product.error_summary"), detail: t("product.archive_error"), life: 3000 }); }

    if (fetchingPlanSubscribers || fetchingPaymentMissedCount ||
        fetchingPaymentPendingCount ||
        fetchingPaymentPending || fetchingCollectedCount) {
        return <Loading />;
    }
    const accept = () => {
        setState((state) => ({ ...state, planArchiving: true }))
        archivePlan(state.plan.id, defMembership.id)
        toast.current.show({ severity: 'info', summary: t("product.archive"), detail: t("products.archive_plan_info"), life: 3000 });
    }

    const reject = () => { setVisible(false) }

    const sideMenu = [
        {
            label: t('products.edit_plan_info'),
            onclick: () => { setNameChangeVisible(state.plan) }
        }, {
            label: t('products.change_plan_price'),
            onclick: () => { },
            show: state.plan.policy === "TIER" && !state.plan.flex
        }, {
            label: t('products.archive_plan'),
            icon: "pi-lock",
            onclick: () => { setVisible(true) }
        }, {
            label: t('subscriber.invite_subscriber'),
            icon: "pi-user-plus",
            onclick: () => {
                setVisibleInvite(true)
            }, allRoles: true
        },
    ]

    const moneyTemplate = (amount = 0, lan, className = "") => (<p className={className} >{`${defMembership.business.currency} ${amount?.toFixed(2)}`} <span>{t(lan)}</span></p>)
    const formatTime = (time) => { if (moment(time).isValid()) { return moment(time).format("MMMM Do YYYY"); } };
    const dateFormatterBody = (rowData, e) => <p className='tier-date'>{rowData[e.field] ? formatTime(rowData[e.field]) : "--"}</p>
    const statusTemplate = (rowData, e) => {
        return rowData[e.field] && <span className={`product-badge status-${(rowData[e.field] ? rowData[e.field].toLowerCase() : '')}`}>{rowData[e.field]}</span>;
    }
    const referenceTemplate = (rowData) => <p className='link' onClick={() => history.push(`/subscribers/subscriber/subscription/${rowData.id}`)}>{rowData.subscriberReference}</p>
    const amountTemplate = (tier, e) => tier[e.field] ? `${defMembership.business.country.currency} ${tier[e.field]?.toFixed(2)}` : <p className="transactions_data">
        {t("products.flexible_amount")}
    </p>

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscriptionStatus === "ACTIVE" ? < Button
                label={t("subscriber.paid")}
                style={{ color: "#008dd5" }}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    setDisplayBasic(true)
                    setState((state) => ({
                        ...state,
                        id: rowData.id,
                        scheduledDate: rowData.scheduledDate
                    }));
                }}
            /> : "")
        }
    }
    const customerNameTemplate = (sub) => {
        return (
            <div className="customerBodyTemplate">
                {sub.businessCustomer?.name ? (
                    <span className="tier-name">
                        {sub.businessCustomer?.name}
                    </span>
                ) : "--"}
            </div>
        );
    };
    const customerPhoneEmailTemplate = (sub) => {
        return (
            <div className="customerBodyTemplate">
                {sub.businessCustomer?.phoneNo ?
                    <span className="tier-phone">
                        {sub.businessCustomer.phoneNo}
                    </span>
                    : "--"}
                {sub.businessCustomer?.email ? (
                    <span className="tier-email">
                        {sub.businessCustomer.email}
                    </span>
                ) : ""}
            </div>
        )
    }

    const paymentIdTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span>
                    {rowData.latestDlr.id}
                </span>
            </div>
        }
    }
    const occurredOnTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span className='tier-date'>
                    {moment(rowData.externallyReceivedOn).format('DD MMM YYYY')}
                </span>
            </div>
        }
    }
    const costTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div>
                    {
                        rowData.subscription.plan?.flex === true ?
                            <div>
                                <span style={{ display: "block", marginBottom: "0.5rem" }}>{t("products.flexible_amount")}</span>
                                {rowData.extReceivedAmount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.extReceivedAmount?.toFixed(2)}</span>
                                    : "--"}
                            </div>
                            :
                            <p className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.cost?.toFixed(2)}</p>
                    }
                </div>
            </div>
        }
    }

    const notesTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {
                    rowData.extReceivedNotes ?
                        <div>
                            <p> {rowData.extReceivedNotes}</p>
                        </div> : "--"
                }
            </div>
        }
    }

    const paymentSubscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate" style={{ alignItems: "flex-start" }}>
                    {
                        rowData.subscription.subscriberReference !== null ?
                            <p style={{ marginBottom: "15px" }} className="reference-text detail-navigation" id="planName"
                                onClick={() => {
                                    if (rowData.subscription.id)
                                        history.push({ pathname: `/subscribers/subscriber/subscription/${rowData.subscription.id}`, rowData });
                                }}
                            >
                                {rowData.subscription.subscriberReference}
                            </p>
                            : ""
                    }
                    {
                        rowData.subscription.plan?.name ?
                            <span className=" tier-name-content">
                                {rowData.subscription.plan.name !== null ? rowData.subscription.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscription.plan.billingPeriod !== null ? t(`product.${rowData.subscription.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription.plan?.groupName &&
                        <span className="plan-text detail-navigation hov "
                            onClick={() => {
                                history.push({
                                    pathname: "/products/detail/" + rowData.subscription.plan.groupId
                                })
                            }}>
                            {rowData.subscription.plan.groupName}
                        </span>
                    }
                </div >
            </div >
        }
    }



    //this is to show the dot icon if only if both data are present
    const showDot = (bol1, bol2) => {
        if (bol1 && bol2) return <i className="pi pi-circle-fill info-dot" />
    }
    const transactionColumn = [
        {
            field: "customer.name", header: t("products.customer_name"), body: (tier) => <p className="link"
                onClick={() => history.push("/subscribers/subscriber" + tier.businessCustomer.id)} >{tier.customer.name}</p>
        },
        { field: "scheduledDate", body: dateFormatterBody, header: t("products.scheduledDate") },
        { field: "scheduleStatus", body: statusTemplate, header: t("products.scheduleStatus") },
        { field: "subscriptionStatus", header: t("products.subscriptionStatus"), body: statusTemplate },
        { field: "amountDue", header: t("amount"), body: amountTemplate },
        { body: markActionTemplate }
    ]
    const pendingTransactionColumn = [
        {
            field: "customer.name", header: t("products.customer_name"), body: (tier) => <p className="link"
                onClick={() => history.push("/subscribers/subscriber" + tier.businessCustomer.id)} >{tier.customer.name}</p>
        },
        { field: "scheduledDate", body: dateFormatterBody, header: t("products.scheduledDate") },
        { field: "scheduleStatus", body: statusTemplate, header: t("products.scheduleStatus") },
        { field: "subscriptionStatus", header: t("products.subscriptionStatus"), body: statusTemplate },
        { field: "amountDue", header: t("amount"), body: amountTemplate },
    ]

    const externalPayments = [

        { field: 'id', body: paymentIdTemplate, header: t("products.ref"), headerClassName: "amount-header" },
        { field: 'subscriber', body: paymentSubscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header" },
        { field: 'externallyReceivedOn', body: occurredOnTemplate, header: t("subscriber.occurred_on"), headerClassName: "amount-header" },
        { field: 'extReceivedAmount', body: costTemplate, header: t("dashboard.amount"), headerClassName: "amount-header" },
        { body: notesTemplate, header: t("subscriber.notes"), headerClassName: "amount-header" },
    ]
    const tabs = [{
        label: "subscriber.title", columns: [
            { field: "status", body: statusTemplate, header: t("products.status") },
            { field: "subscriberReference", header: t("products.reference"), body: referenceTemplate },
            { field: "businessCustomer.name", header: t("common.name"), className: 'tier-name', body: customerNameTemplate },
            { field: "businessCustomer.phoneNoMasked", header: t("products.contact"), body: customerPhoneEmailTemplate },
            { field: "joinedOn", body: dateFormatterBody, header: t("products.joined_on") },
            { field: "nextBillingDate", body: dateFormatterBody, header: t("products.next_billing_date") },
        ],
        value: state.subscribers, count: state.subscribers.length, index: 0
    }, {
        label: "dashboard.collected_payments", columns: [
            {
                field: "customer.reference", header: t("products.ref"), body: (tier) => <p className="link"
                    onClick={() => history.push("/payments/detail/" + tier.id)}>{tier.id}</p>
            },
            {
                field: "customer.name", header: t("products.customer_name"), body: (tier) => <p className="link"
                    onClick={() => history.push("/subscribers/subscriber/subscription/" + tier.subscriber.id)} >{tier.customer.name}</p>
            },
            { field: "receivedAt", body: dateFormatterBody, header: t("subscriber.occurred_on") },
            { field: "paymentMode", header: t("subscriber.payment_method") },
            { field: "amount", header: t("subscriber.amount_paid"), body: amountTemplate },
            { field: "fees", header: t("subscriber.service_fee"), body: amountTemplate },
            { field: "redeemable", header: t("subscriber.amount_received"), body: amountTemplate },
        ],
        value: state.collected, count: state.collectedCount, paginator: true, index: 1

    },
    {
        label: t('subscriber.failed_transaction'), value: state.missed,
        columns: transactionColumn, count: state.missedCount, index: 2
    },
    {
        label: t('subscriber.pending'), value: state.pending,
        columns: pendingTransactionColumn, count: state.pendingCount, index: 3
    },
    {
        label: t('transaction.external'), value: state.groupExternalPayments, columns: externalPayments, count: state.groupExternalPayments?.length, paginator: true, index: 4
    }
    ]

    const archiveFooter = () => {
        return (
            <div>
                <Button
                    id="footer-btn-cancel"
                    label={t("cancel")}
                    icon="pi pi-times"
                    onClick={reject}
                />
                <Button
                    id="archive-btn"
                    loading={archiving}
                    label={t("share.archive")}
                    icon="pi pi-check"
                    onClick={() => { accept(); }}
                />
            </div>
        );
    };

    const toggleInvite = () => {
        setVisibleInvite(false)
    }

    const totalNumber = tabs[activeTab].index === 0
        ? state.subscribers.length
        : tabs[activeTab].index === 1
            ? state.collectedCount
            : tabs[activeTab].index === 2
                ? state.missedCount
                : tabs[activeTab].index === 3
                    ? state.pendingCount
                    : tabs[activeTab].index === 4
                        ? state.groupExternalPayments?.length
                        : null

    const onPageChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        }
        else if (tabs[activeTab].index === 0) {
            getPlanSubscribers(planId);
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 1) {
            getPlanPaymentCollected(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 2) {
            getPlanPaymentMissed(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 3) {
            getPlanPaymentPending(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 4) {
            getGroupExternalPayments(defMembership.business.id, planId, { page: e.page, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else {
            return;
        }
    }

    const createdDate = state.plan.createdDate ? moment(state.plan.createdDate).format("MMMM DD YYYY") : "";
    const lastModifiedDate = state.plan.lastModifiedDate ? moment(state.plan.lastModifiedDate).format("MMMM DD YYYY") : "";

    const loading = fetchingCollected || fetchingPlanSubscribers || fetchingMissed || fetchingPending || fetchingExternalPayments || fetchingPlan;
    return (
        <div >
            <Toast
                onRemove={(message) => {
                    if (message.severity === "success") {
                        setVisible(false);
                    }
                }} ref={toast} />
            <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                scheduledDate={state.scheduledDate}
                callbackFunctions={() => {
                    getPlan(planId);
                    getPlanSubscribers(planId);
                    planPerformance(planId);
                    getPlanPaymentMissed(planId);
                    getPlanPaymentMissedCount(planId)
                    getPlanPaymentPending(planId);
                    getPlanPaymentPendingCount(planId)
                    getPlanPaymentCollected(planId);
                    getPlanPaymentCollectedCount(planId)
                    getGroupExternalPayments(defMembership.business.id, planId)
                }}
            />
            <InviteSubscriber visible={visibleInvite} toggleVisible={visibleInvite} onHide={toggleInvite} initialTier={{ data: state.plan }} />
            <PlanEdit tier={nameChangeVisible} onHide={() => setNameChangeVisible({})} setTrigger={setTrigger} trigger={trigger} />
            <Dialog
                header={t("products.archive_header_plan")}
                visible={visible}
                onHide={() => setVisible(false)}
                footer={archiveFooter()}
                className="tier_dialog"
                id="tier_dialog"
                style={{ width: "300px" }}
            >
                <div className="archive-dialog-content">
                    <p style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            className="pi pi-exclamation-circle"
                            style={{
                                color: "#D0021B",
                                fontSize: "40px",
                                fontWeight: "bolder",
                            }}
                        />
                    </p>
                    <div style={{ padding: "10px" }}>
                        <div style={{ marginBottom: "0.5rem" }}>
                            <span>
                                {t("products.archive_question_plan", { name: state.plan.name })}{" "}
                                <span style={{ fontWeight: "bold", color: "#000" }}>
                                    {state.rowData?.name}
                                </span>{"?"}{" "}
                            </span>
                        </div>
                        <p className="archive-notice">
                            {t("product.archive_notice")}
                        </p>
                    </div>
                    <hr style={{ border: "1px solid #F2F2F2" }} />
                </div></Dialog>

            <div className="flex">
                <Card className="planDetailCard" style={{ width: "75%" }}>
                    <div style={{ marginBottom: "2rem" }}>
                        <div className='grid'>
                            <div className="col-7">
                                <p className='planDetailCard-name'>{state.plan.name}</p>
                                <p className='planDetailCard-dec desc-info'>
                                    {state.plan.description}
                                    {showDot(state.plan.description, state.plan.group?.name)}
                                    {state.plan.group?.name?.toUpperCase()}
                                </p>
                                <div>
                                    <div className='grid'>
                                        <span className={`planDetailCard-badge status-${state.plan.group?.status === "ARCHIVED" ?
                                            state.plan.group.status?.toLowerCase() : state.plan.status?.toLowerCase()} col-2`}>
                                            {state.plan.group?.status === "ARCHIVED" ? state.plan.group?.status : state.plan.status}
                                        </span>
                                        <div className='col-10 archive-info' >
                                            {createdDate !== lastModifiedDate && <p className="planCard-date">
                                                {t("product.updated_on")} <span className="planModifiedDate">{":"}{" "}{lastModifiedDate}</span>
                                            </p>}
                                            {createdDate === lastModifiedDate && <p className="planCard-date">
                                                {t("product.created_on")} <span className="planModifiedDate">{":"}{" "}{createdDate}</span>
                                            </p>}
                                            {state.plan.archivedBy &&
                                                <span>
                                                    <span className='archived-by'>{t("products.archived_by")}</span> :  <span className="planDetailCard-date-data">{state.plan.archivedBy.name}</span>
                                                </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                {(state.plan.policy === "SCHEDULE" || state.plan.policy === "SUBSCRIPTION" || state.plan.flex) ?
                                    <span className="planDetailCard-side-amount">{t("products.flexible_amount")}</span> :
                                    state.plan.cost &&
                                    <span className="planDetailCard-side-amount">{defMembership.business.currency} {state.plan.cost}</span>
                                }
                                <p className="planDetailCard-side-info">
                                    {<>
                                        {t("subscriber.deduction_policy")}
                                        {state.plan.policy === "TIER" && <span className={`planDetailCard-badge ${state.plan.policy?.toLowerCase()}`}>{t("PLAN")}</span>}
                                        {state.plan.policy === "SUBSCRIPTION" && <span className={`planDetailCard-badge ${state.plan.policy?.toLowerCase()}`}>{state.plan.policy}</span>}
                                        {state.plan.policy === "SCHEDULE" && <span className="policyScheduleSpan"> <span className="policySchedule">{t("products.schedule")}</span></span>}
                                    </>}

                                </p>
                                <p className="planDetailCard-side-info">
                                    {state.plan.billingPeriod && <>{t("billing_period")}:<span>{t(`product.${state.plan.billingPeriod?.toLowerCase()}`)}</span></>}
                                    {showDot(state.plan.billingPeriod, state.plan.deductions)}
                                    {state.plan.deductions && <>{t("plan.deductions")}:<span>{state.plan.deductions}</span></>}
                                </p>
                                <p className="planDetailCard-side-info">
                                    <>{t("grace_period")} : <span>{state.plan.graceDays}</span></>
                                    {showDot(state.plan.graceDays, !!state.plan.reminderDays)}
                                    {!!state.plan.reminderDays && <>{t("products.reminder")} <span>{state.plan.reminderDays}</span></>}
                                </p>
                                <p className="planDetailCard-side-info"><span>{t("plan.num_active_subscription")} </span>: {state.performance.activeSubscriptionCount}</p>

                            </div>
                        </div>
                    </div>
                    <div className="grid-bo" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div className="bottom-left">
                            <div className="planDetailCard-performance-col">
                                <div><i className="pi pi-money-bill" /><h6>{t("plan.lifetime")}</h6></div>
                                {moneyTemplate(state.performance.collected, "dashboard.collected", "collected")}
                                {moneyTemplate(state.performance.missed, "dashboard.missed", "missed")}
                            </div >
                        </div>
                        <div className="bottom-middle">
                            <div className="planDetailCard-performance-col">
                                <div><i className="pi pi-money-bill" /><h6>{t("subscriber.seven_days")}</h6></div>
                                {moneyTemplate(state.performance.sevenDayCollected, "dashboard.collected", "collected")}
                                {moneyTemplate(state.performance.sevenDayMissed, "dashboard.missed", "missed")}
                            </div>
                        </div>
                        <div className="bottom-right">
                            <div className="planDetailCard-performance-col">
                                <div><i className="pi pi-money-bill" /><h6>{t("subscriber.one_month")}</h6></div>
                                {moneyTemplate(state.performance.thirtyDayCollected, "dashboard.collected", "collected")}
                                {moneyTemplate(state.performance.thirtyDayMissed, "dashboard.missed", "missed")}
                            </div>
                        </div>
                    </div>

                </Card >
                {
                    (state.plan.status !== "ARCHIVED" && state.plan.group?.status !== "ARCHIVED") && <div id="side-menu">
                        {sideMenu.map(({ label, icon, onclick, show = true, allRoles = false }, i) => (
                            <RoleAccess roles={["OWNER", "ADMIN"]} allRoles={allRoles} key={i}>
                                {
                                    show && <div className="col-12">
                                        <span onClick={onclick}>
                                            <i className={"pi " + icon} />
                                            <span> {label}</span>
                                        </span>
                                        <hr />
                                    </div>
                                }
                            </RoleAccess>
                        ))
                        }
                    </div>
                }
            </div >
            <CustomDataTable
                value={tabs[activeTab].value}
                columns={tabs[activeTab].columns}
                tabs={tabs.map(({ label, i }) => ({ label, i }))}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setState={setState}
                tableClassName="tier_detail_data_table"
                title="products.plan_activity"
                children={null}
                rows={state.size}
                loading={loading}
                tab={state.tabChange}
            />
            <Paginator
                rows={state.size}
                first={state.first}
                totalRecords={totalNumber}
                onPageChange={onPageChange}
            />
        </div >
    )
}

export default PlanDetail;
