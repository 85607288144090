import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */
import { AccountTypes } from "../actions/account-actions";
import { DefMembersipTypes } from "../actions/defMembership-actions";
import { PaymentScheduleTypes } from "../transition/payment-schedule/payment-schedule.reducer";
import { PlanGroupTypes } from "../transition/plan-group/plan-group.reducer";
import { PlanTypes } from "../transition/plan/plan.reducer";
import { CountryTypes } from "../actions/country-actions";
import { SignupTypes } from "../actions/signup-actions";
import { SubscriberTypes } from "../transition/subscriber/subscriber.reducer";
import { SelfSignupTypes } from "../transition/self-signup/self-signup.reducer";
import { BusinessTypes } from "../actions/business-actions";
import { MembershipTypes } from "../transition/membership/membership.reducer";
import { PaymentTrackTypes } from "../transition/payment-track/payment-track.reducer";
import { PayoutChannelTypes } from "../actions/payoutchannel-actions";
import { BankTypes } from "../actions/bank-actions";
import { BankBranchTypes } from "../actions/bankBranches-actions";
import { SettlementTypes } from "../transition/settlement/settlement.reducer";
import { WalletTypes } from "../transition/wallet/wallet.reducer";
import { MembershipInviteTypes } from "../transition/membership-invite/membership-invite.reducer";
import { ProfileTypes } from "../actions/profile-actions";
import { PhoneVerificationTypes } from "../transition/phone-verification/phone-verification.reducer";
import { EnterpriseTypes } from "../transition/enterprise/enterprise.reducer";
import { DocumentTypes } from "../transition/document-upload/document-upload.reducer";
import { BusinessCustomerTypes } from "../transition/customers/business-customer.reducer";
import { PlanReportTypes } from "../transition/plan-report/plan-report.reducer";
import { ReportTypes } from "../transition/report/report.reducer";
import { PaySwitchTypes } from "../transition/payswitch/payswitch.reducer";
// saga-redux-import-needle

/* ------------- Sagas ------------- */

import { getAccountData, updateAccountData, resendEmailVerification } from "./account-saga";
import { getDefMembershipData } from "./defMembership-saga";
import {
  getPaymentSchedule,
  getPaymentSchedules,
  getPaymentSchedulesMissed,
  getPaymentSchedulesPending,
  updatePaymentSchedule,
  deletePaymentSchedule,
  notifyPaymentSchedule,
  getPaymentScheduleRange,
  getPaymentScheduleProjection,
  getPaymentScheduleSummary,
  getDailyCharts,
  getWeeklyCharts,
  getWeeklyPaymentScheduleSummary,
  getThreeMonthsPaymentScheduleSummary,
  getThreeMonthsCharts,
  getSixMonthsCharts,
  getTwelveMonthsCharts,
  getSixMonthsPaymentScheduleSummary,
  getTwelveMonthsPaymentScheduleSummary,
  getMissedSchedulesCount,
} from "../transition/payment-schedule/payment-schedule.sagas";
import {
  getPlanGroup,
  getPlanGroups,
  getPlanGroupsFilter,
  getPlanGroupsCount,
  getPlanGroupsGraphed,
  createPlanGroup,
  updatePlanGroup,
  deletePlanGroup,
  archivePlanGroup,
  getPlanGroupsGraphedActive,
  getPlanGroupsGraphedArchived,
  getPlanGroupTier,
  getPlanGroupPaymentMissed,
  getPlanGroupPaymentMissedCount,
  getPlanGroupPaymentPending,
  getPlanGroupPaymentPendingCount,
  getPlanGroupPaymentPaid,
  getPlanGroupPaymentPaidCount,
  getPlanGroupPerformance,
  getPlanBusinessPerformance,
  getPlanGroupPlansSummary,
  getPlanGroupCustomersSummary,
  getPlanGroupDuplicate,
  enablePlanReport,
} from "../transition/plan-group/plan-group.sagas";
import {
  getPlan,
  getPlans,
  createPlan,
  firstCreate,
  updatePlan,
  deletePlan,
  archivePlan,
  priceChange,
  planPerformance,
  getPlanPaymentMissed,
  getPlanPaymentMissedCount,
  getPlanPaymentPending,
  getPlanPaymentPendingCount,
  getPlanPaymentCollected,
  getPlanPaymentCollectedCount,
  migratePlan,
} from "../transition/plan/plan.sagas";
import {
  getCountriesData,
  getCountryData,
  updateCountryData,
  deleteCountryData,
} from "./country-saga";
import { updateSignupData } from "./signup-saga";
import {
  getSubscriber,
  getSubscribers,
  updateSubscriber,
  deleteSubscriber,
  getPlanSubscribers,
  getCountSubscribers,
  rescheduleSubscriber,
  checkExclusivity,
  countStatusSubscribers,
  searchSubscribers,
  countSearchSubscribers,
  countDateSearchSubscribers,
  filterSubscribers,
  dateSearchSubscribers,
  countSubscribers,
  getSubscriberMissedSchedules,
  cancelSubscripition,
  pauseSubscription,
  resumeSubscription,
  subscriptionReschedule,
  subscribersBulkPause,
  subscribersBulkCancel,
  getSubscriberRevenue,
} from "../transition/subscriber/subscriber.sagas";
import {
  getSelfSignup,
  resendSelfSignup,
  resendBulkSelfSignup,
  getSelfSignups,
  searchInvitations,
  getSearchCount,
  getSelfSignupsCount,
  getSelfSignupsFilter,
  deleteSelfSignup,
} from "../transition/self-signup/self-signup.sagas";
import {
  getBusiness,
  getBusinesses,
  getBusinessSummary,
  createBusiness,
  updateBusiness,
  deleteBusiness,
  getQrCode,
  getIndustries,
} from "./business-saga";
import {
  getMembership,
  getMemberships,
  updateMembership,
  deleteMembership,
} from "../transition/membership/membership.sagas";
import {
  getCredentials,
  createCredentials,
  getPaymentCredentials
} from "../transition/payswitch/payswitch.saga";
import {
  getPaymentTrack,
  getPaymentTracks,
  deletePaymentTrack,
  getPaymentTrackRange,
  getPaymentTrackRevenue,
  getPaymentTrackGroup,
  getPaymentTrackGroupCount,
  markPaymentExternally,
  getSubsExternalPayments,
  getGroupExternalPayments,
  getBusinessCustomerExternalPayments,
  getPlanExternalPayments,
  getCollectedPaymentTracks,
  getMissedPaymentTracks,
  getExternalPaymentTracks,
  getPaymentTracksCount,
} from "../transition/payment-track/payment-track.sagas";
import {
  getPayoutChannel,
  getPayoutChannels,
  getSavedPayoutChannels,
  getFavouriteChannels,
  setFavouritePayoutChannels,
  setUnfavouritePayoutChannels,
  updatePayoutChannel,
  deletePayoutChannel,
  archivePayoutChannel,
  getFilteredPayoutChannels,
} from "./payoutchannel-saga";
import { getBank, getBanks, getFilteredBanks } from "./bank-saga";
import { getBankBranch, getBankBranches } from "./bankBranches-saga";
import {
  getSettlement,
  getSettlements,
  getSettlementsCount,
  getSettlementSources,
  getSettlementSourcesGroup,
  getSettlementsStatusCount,
  updateSettlement,
  deleteSettlement,
  createSettlement,
  getListOfWithdrawals,
  getStructureSettlement,
} from "../transition/settlement/settlement.sagas";
import {
  getWallet,
  getGlobalWallet,
  favouriteWallets,
  favouriteWallet,
  unfavouriteWallet,
  getWalletStatistics,
} from "../transition/wallet/wallet.sagas";
import {
  getMembershipInvite,
  getMembershipInvites,
  createMembershipInvite,
  updateMembershipInvite,
  deleteMembershipInvite,
  resendMembershipInvite,
} from "../transition/membership-invite/membership-invite.saga";
import { getProfile, updateProfile } from "./profile.sagas";
import {
  generateCode,
  verifyCode,
} from "../transition/phone-verification/phone-verification.saga";
import {
  getEnterprise,
  generateEnterprise,
} from "../transition/enterprise/enterprise.saga";
import {
  getDocuments,
  getOrganisationDocuments,
  createRecord,
  verifyDocument,
  reviewDocument,
  previewDocument,
  getBusinessVerificationStatus,
  uploadLogo,
} from "../transition/document-upload/document-upload.saga";
import {
  getBusinessCustomerSubscriptions,
  getBusinessCustomers,
  getFilteredBusinessCustomers,
  getExactFilteredBusinessCustomers,
  getBusinessCustomerRecord,
  getCount,
  updateEnterpriseCustomer,
  uploadCustomers,
  getImportCustomers,
  getImportCustomersCount,
  getEntries,
  applyImport,
  getCustomerRecentPayments,
  getCustomerRecentMissedPayments,
  getRecentCustomerSubscriptionActivity,
  getCustomerReceivedPayments,
  getCustomerMissedPayments,
  getCustomerPendingPayments,
  getCustomerReports,
  getBusinessCustomerCharts,
  getSuccessfulPayments,
  getExpiredPayments,
  getBusinessCustomerSummarySearch,
  getBusinessCustomerSummary,
  getBusinessCustomersBulkCancel,
  getBusinessCustomersBulkPause,
  getBusinessCustomersSingleCancel,
  getBusinessCustomersSinglePause,
  getBusinessCustomerSubscriptionAmounts,
} from "../transition/customers/business-customer.sagas";
import {
  getPlanReport,
  getPlanReportChart,
  getTransactionReport,
  getTransactionReportChart,
} from "../transition/plan-report/plan-report.saga";
import {
  subscribeReport,
  transactionCustomReport,
} from "../transition/report/report.sagas";


// saga-method-import-needle

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    takeLatest(AccountTypes.ACCOUNT_REQUEST, getAccountData),
    takeLatest(AccountTypes.ACCOUNT_UPDATE_REQUEST, updateAccountData),
    takeLatest(AccountTypes.EMAIL_VERIFICATION_RESEND, resendEmailVerification),

    takeLatest(DefMembersipTypes.DEF_MEMBERSHIP_REQUEST, getDefMembershipData),

    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_REQUEST,
      getPaymentSchedule
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_ALL_REQUEST,
      getPaymentSchedules
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_MISSED_REQUEST,
      getPaymentSchedulesMissed
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_PENDING_REQUEST,
      getPaymentSchedulesPending
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_RANGE_REQUEST,
      getPaymentScheduleRange
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_PROJECTION_REQUEST,
      getPaymentScheduleProjection
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_SUMMARY_REQUEST,
      getPaymentScheduleSummary
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_UPDATE_REQUEST,
      updatePaymentSchedule
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_DELETE_REQUEST,
      deletePaymentSchedule
    ),
    takeLatest(
      PaymentScheduleTypes.PAYMENT_SCHEDULE_NOTIFY_REQUEST,
      notifyPaymentSchedule
    ),
    takeLatest(PaymentScheduleTypes.DAILY_CHARTS_REQUEST, getDailyCharts),
    takeLatest(PaymentScheduleTypes.WEEKLY_CHARTS_REQUEST, getWeeklyCharts),
    takeLatest(
      PaymentScheduleTypes.THREE_MONTHS_CHARTS_REQUEST,
      getThreeMonthsCharts
    ),
    takeLatest(
      PaymentScheduleTypes.SIX_MONTHS_CHARTS_REQUEST,
      getSixMonthsCharts
    ),
    takeLatest(
      PaymentScheduleTypes.TWELVE_MONTHS_CHARTS_REQUEST,
      getTwelveMonthsCharts
    ),
    takeLatest(
      PaymentScheduleTypes.WEEKLY_PAYMENT_SCHEDULE_SUMMARY_REQUEST,
      getWeeklyPaymentScheduleSummary
    ),
    takeLatest(
      PaymentScheduleTypes.THREE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST,
      getThreeMonthsPaymentScheduleSummary
    ),
    takeLatest(
      PaymentScheduleTypes.SIX_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST,
      getSixMonthsPaymentScheduleSummary
    ),
    takeLatest(
      PaymentScheduleTypes.TWELVE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST,
      getTwelveMonthsPaymentScheduleSummary
    ),
    takeLatest(
      PaymentScheduleTypes.MISSED_PAYMENT_SCHEDULES_COUNT_REQUEST,
      getMissedSchedulesCount
    ),

    takeLatest(PlanGroupTypes.PLAN_GROUP_REQUEST, getPlanGroup),
    takeLatest(PlanGroupTypes.PLAN_GROUP_ALL_REQUEST, getPlanGroups),
    takeLatest(PlanGroupTypes.PLAN_GROUPS_FILTER_REQUEST, getPlanGroupsFilter),
    takeLatest(PlanGroupTypes.PLAN_GROUP_ALL_COUNT_REQUEST, getPlanGroupsCount),
    takeLatest(PlanGroupTypes.PLAN_GROUP_GRAPHED_REQUEST, getPlanGroupsGraphed),
    takeLatest(PlanGroupTypes.PLAN_GROUP_CREATE_REQUEST, createPlanGroup),
    takeLatest(PlanGroupTypes.PLAN_GROUP_UPDATE_REQUEST, updatePlanGroup),
    takeLatest(PlanGroupTypes.PLAN_GROUP_DELETE_REQUEST, deletePlanGroup),
    takeLatest(PlanGroupTypes.PLAN_GROUP_ARCHIVE_REQUEST, archivePlanGroup),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_GRAPHED_ACTIVE_REQUEST,
      getPlanGroupsGraphedActive
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_GRAPHED_ARCHIVED_REQUEST,
      getPlanGroupsGraphedArchived
    ),
    takeLatest(PlanGroupTypes.PLAN_GROUP_TIER_REQUEST, getPlanGroupTier),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_MISSED_REQUEST,
      getPlanGroupPaymentMissed
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_MISSED_COUNT_REQUEST,
      getPlanGroupPaymentMissedCount
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_PENDING_REQUEST,
      getPlanGroupPaymentPending
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_PENDING_COUNT_REQUEST,
      getPlanGroupPaymentPendingCount
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_PAID_REQUEST,
      getPlanGroupPaymentPaid
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PAYMENT_PAID_COUNT_REQUEST,
      getPlanGroupPaymentPaidCount
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PERFORMANCE_REQUEST,
      getPlanGroupPerformance
    ),
    takeLatest(
      PlanGroupTypes.PLAN_BUSINESS_PERFORMANCE_REQUEST,
      getPlanBusinessPerformance
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_PLANS_SUMMARY_REQUEST,
      getPlanGroupPlansSummary
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_CUSTOMERS_SUMMARY_REQUEST,
      getPlanGroupCustomersSummary
    ),
    takeLatest(
      PlanGroupTypes.PLAN_GROUP_DUPLICATE_REQUEST,
      getPlanGroupDuplicate
    ),
    takeLatest(PlanReportTypes.PLAN_REPORT_REQUEST, getPlanReport),
    takeLatest(PlanReportTypes.PLAN_REPORT_CHART_REQUEST, getPlanReportChart),
    takeLatest(
      PlanReportTypes.TRANSACTION_REPORT_REQUEST,
      getTransactionReport
    ),
    takeLatest(
      PlanReportTypes.TRANSACTION_REPORT_CHART_REQUEST,
      getTransactionReportChart
    ),
    takeLatest(PlanGroupTypes.ENABLE_PLAN_REPORT_REQUEST, enablePlanReport),

    takeLatest(PlanTypes.PLAN_REQUEST, getPlan),
    takeLatest(PlanTypes.PLAN_ALL_REQUEST, getPlans),
    takeLatest(PlanTypes.FIRST_TIER_CREATE_FAILURE, firstCreate),
    takeLatest(PlanTypes.PLAN_CREATE_REQUEST, createPlan),
    takeLatest(PlanTypes.PLAN_UPDATE_REQUEST, updatePlan),
    takeLatest(PlanTypes.PLAN_PRICE_CHANGE_REQUEST, priceChange),
    takeLatest(PlanTypes.PLAN_DELETE_REQUEST, deletePlan),
    takeLatest(PlanTypes.PLAN_ARCHIVE_REQUEST, archivePlan),
    takeLatest(PlanTypes.PLAN_PERFORMANCE_REQUEST, planPerformance),
    takeLatest(PlanTypes.PLAN_MIGRATE_REQUEST, migratePlan),
    takeLatest(PlanTypes.PLAN_PAYMENT_MISSED_REQUEST, getPlanPaymentMissed),
    takeLatest(
      PlanTypes.PLAN_PAYMENT_MISSED_COUNT_REQUEST,
      getPlanPaymentMissedCount
    ),
    takeLatest(PlanTypes.PLAN_PAYMENT_PENDING_REQUEST, getPlanPaymentPending),
    takeLatest(
      PlanTypes.PLAN_PAYMENT_PENDING_COUNT_REQUEST,
      getPlanPaymentPendingCount
    ),
    takeLatest(
      PlanTypes.PLAN_PAYMENT_COLLECTED_REQUEST,
      getPlanPaymentCollected
    ),
    takeLatest(
      PlanTypes.PLAN_PAYMENT_COLLECTED_COUNT_REQUEST,
      getPlanPaymentCollectedCount
    ),

    takeLatest(SignupTypes.SIGNUP_UPDATE_REQUEST, updateSignupData),

    takeLatest(CountryTypes.COUNTRY_REQUEST, getCountryData),
    takeLatest(CountryTypes.COUNTRY_ALL_REQUEST, getCountriesData),
    takeLatest(CountryTypes.COUNTRY_UPDATE_REQUEST, updateCountryData),
    takeLatest(CountryTypes.COUNTRY_DELETE_REQUEST, deleteCountryData),

    takeLatest(SubscriberTypes.SUBSCRIBER_REQUEST, getSubscriber),
    takeLatest(SubscriberTypes.SUBSCRIBER_ALL_REQUEST, getSubscribers),
    takeLatest(SubscriberTypes.SUBSCRIBERS_REQUEST, searchSubscribers),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_COUNT_ALL_REQUEST,
      getCountSubscribers
    ),
    takeLatest(SubscriberTypes.SUBSCRIBER_PLAN_REQUEST, getPlanSubscribers),
    takeLatest(SubscriberTypes.SUBSCRIBER_UPDATE_REQUEST, updateSubscriber),
    takeLatest(SubscriberTypes.SUBSCRIBER_DELETE_REQUEST, deleteSubscriber),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_RESCHEDULE_REQUEST,
      rescheduleSubscriber
    ),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_EXCLUSIVITY_REQUEST,
      checkExclusivity
    ),
    takeLatest(
      SubscriberTypes.COUNT_SEARCH_SUBSCRIBERS_REQUEST,
      countSearchSubscribers
    ),
    takeLatest(
      SubscriberTypes.COUNT_DATE_SEARCH_SUBSCRIBERS_REQUEST,
      countDateSearchSubscribers
    ),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_DATE_SEARCH_REQUEST,
      dateSearchSubscribers
    ),
    takeLatest(SubscriberTypes.SUBSCRIBER_COUNT_REQUEST, countSubscribers),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_MISSED_SCHEDULES_REQUEST,
      getSubscriberMissedSchedules
    ),
    takeLatest(
      SubscriberTypes.CANCEL_SUBSCRIPTION_REQUEST,
      cancelSubscripition
    ),
    takeLatest(SubscriberTypes.PAUSE_SUBSCRIPTION_REQUEST, pauseSubscription),
    takeLatest(SubscriberTypes.RESUME_SUBSCRIPTION_REQUEST, resumeSubscription),
    takeLatest(
      SubscriberTypes.COUNT_STATUS_SUBSCRIBERS_REQUEST,
      countStatusSubscribers
    ),
    takeLatest(SubscriberTypes.SUBSCRIBER_FILTER_REQUEST, filterSubscribers),
    takeLatest(
      SubscriberTypes.RESCHEDULE_SUBSCRIPTION_REQUEST,
      subscriptionReschedule
    ),
    takeLatest(
      SubscriberTypes.SUBSCRIBERS_BULK_PAUSE_REQUEST,
      subscribersBulkPause
    ),
    takeLatest(
      SubscriberTypes.SUBSCRIBERS_BULK_CANCEL_REQUEST,
      subscribersBulkCancel
    ),
    takeLatest(
      SubscriberTypes.SUBSCRIBER_REVENUE_REQUEST, getSubscriberRevenue
    ),

    takeLatest(SelfSignupTypes.SELF_SIGNUP_REQUEST, getSelfSignup),
    takeLatest(SelfSignupTypes.RESEND_SELF_SIGNUP_REQUEST, resendSelfSignup),
    takeLatest(
      SelfSignupTypes.RESEND_BULK_SELF_SIGNUP_REQUEST,
      resendBulkSelfSignup
    ),
    takeLatest(SelfSignupTypes.SELF_SIGNUP_ALL_REQUEST, getSelfSignups),
    takeLatest(SelfSignupTypes.INVITATION_SEARCH_REQUEST, searchInvitations),
    takeLatest(SelfSignupTypes.SELF_SIGNUP_COUNT_REQUEST, getSelfSignupsCount),
    takeLatest(SelfSignupTypes.SEARCH_COUNT_REQUEST, getSearchCount),
    takeLatest(
      SelfSignupTypes.SELF_SIGNUP_FILTER_REQUEST,
      getSelfSignupsFilter
    ),
    takeLatest(SelfSignupTypes.SELF_SIGNUP_DELETE_REQUEST, deleteSelfSignup),

    takeLatest(BusinessTypes.BUSINESS_REQUEST, getBusiness),
    takeLatest(BusinessTypes.BUSINESS_ALL_REQUEST, getBusinesses),
    takeLatest(BusinessTypes.BUSINESS_SUMMARY_REQUEST, getBusinessSummary),
    takeLatest(BusinessTypes.BUSINESS_CREATE_REQUEST, createBusiness),
    takeLatest(BusinessTypes.BUSINESS_UPDATE_REQUEST, updateBusiness),
    takeLatest(BusinessTypes.BUSINESS_DELETE_REQUEST, deleteBusiness),
    takeLatest(BusinessTypes.BUSINESS_QR_CODE_REQUEST, getQrCode),
    takeLatest(BusinessTypes.BUSINESS_INDUSTRIES_REQUEST, getIndustries),

    takeLatest(MembershipTypes.MEMBERSHIP_REQUEST, getMembership),
    takeLatest(MembershipTypes.MEMBERSHIP_ALL_REQUEST, getMemberships),
    takeLatest(MembershipTypes.MEMBERSHIP_UPDATE_REQUEST, updateMembership),
    takeLatest(MembershipTypes.MEMBERSHIP_DELETE_REQUEST, deleteMembership),

    takeLatest(PaySwitchTypes.PAY_SWITCH_CREDENTIAL_REQUEST, getCredentials),
    takeLatest(PaySwitchTypes.PAY_SWITCH_CREDENTIAL_CREATE_REQUEST, createCredentials),
    takeLatest(PaySwitchTypes.PAYMENT_CREDENTIAL_REQUEST, getPaymentCredentials),

    takeLatest(PaymentTrackTypes.PAYMENT_TRACK_REQUEST, getPaymentTrack),
    takeLatest(PaymentTrackTypes.PAYMENT_TRACK_ALL_REQUEST, getPaymentTracks),
    takeLatest(
      PaymentTrackTypes.COLLECTED_PAYMENT_TRACK_REQUEST,
      getCollectedPaymentTracks
    ),
    takeLatest(
      PaymentTrackTypes.MISSED_PAYMENT_TRACK_REQUEST,
      getMissedPaymentTracks
    ),
    takeLatest(
      PaymentTrackTypes.EXTERNAL_PAYMENT_TRACK_REQUEST,
      getExternalPaymentTracks
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACK_RANGE_REQUEST,
      getPaymentTrackRange
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACK_REVENUE_REQUEST,
      getPaymentTrackRevenue
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACK_DELETE_REQUEST,
      deletePaymentTrack
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACK_GROUP_REQUEST,
      getPaymentTrackGroup
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACK_GROUP_COUNT_REQUEST,
      getPaymentTrackGroupCount
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_MARK_EXTERNALLY_REQUEST,
      markPaymentExternally
    ),
    takeLatest(
      PaymentTrackTypes.PAYMENT_TRACKS_COUNT_REQUEST,
      getPaymentTracksCount
    ),
    takeLatest(
      PaymentTrackTypes.SUBSCRIBER_EXTERNAL_PAYMENTS_REQUEST,
      getSubsExternalPayments
    ),
    takeLatest(
      PaymentTrackTypes.PLAN_GROUP_EXTERNAL_PAYMENTS_REQUEST,
      getGroupExternalPayments
    ),
    takeLatest(
      PaymentTrackTypes.PLAN_EXTERNAL_PAYMENTS_REQUEST,
      getPlanExternalPayments
    ),
    takeLatest(
      PaymentTrackTypes.BUSINESS_CUSTOMER_EXTERNAL_PAYMENTS_REQUEST,
      getBusinessCustomerExternalPayments
    ),

    takeLatest(PayoutChannelTypes.PAYOUT_CHANNEL_REQUEST, getPayoutChannel),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_ALL_REQUEST,
      getPayoutChannels
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_SAVED_REQUEST,
      getSavedPayoutChannels
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_FAVOURITE_REQUEST,
      getFavouriteChannels
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_SET_FAVOURITE_REQUEST,
      setFavouritePayoutChannels
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_SET_UNFAVOURITE_REQUEST,
      setUnfavouritePayoutChannels
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_UPDATE_REQUEST,
      updatePayoutChannel
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_DELETE_REQUEST,
      deletePayoutChannel
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_ARCHIVE_REQUEST,
      archivePayoutChannel
    ),
    takeLatest(
      PayoutChannelTypes.PAYOUT_CHANNEL_FILTERED_REQUEST,
      getFilteredPayoutChannels
    ),

    takeLatest(BankTypes.BANK_REQUEST, getBank),
    takeLatest(BankTypes.BANK_ALL_REQUEST, getBanks),
    takeLatest(BankTypes.BANK_FILTER_REQUEST, getFilteredBanks),

    takeLatest(BankBranchTypes.BANK_BRANCH_REQUEST, getBankBranch),
    takeLatest(BankBranchTypes.BANK_BRANCH_ALL_REQUEST, getBankBranches),

    takeLatest(SettlementTypes.SETTLEMENT_REQUEST, getSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_COUNT_REQUEST, getSettlementsCount),
    takeLatest(
      SettlementTypes.SETTLEMENT_STATUS_COUNT_REQUEST,
      getSettlementsStatusCount
    ),
    takeLatest(SettlementTypes.SETTLEMENT_ALL_REQUEST, getSettlements),
    takeLatest(
      SettlementTypes.SETTLEMENT_SOURCES_REQUEST,
      getSettlementSources
    ),
    takeLatest(SettlementTypes.PLAN_SETTLEMENTS_REQUEST, getListOfWithdrawals),

    takeLatest(PhoneVerificationTypes.GENERATE_CODE_REQUEST, generateCode),
    takeLatest(PhoneVerificationTypes.VERIFY_CODE_REQUEST, verifyCode),

    takeLatest(
      SettlementTypes.SETTLEMENT_SOURCES_GROUP_REQUEST,
      getSettlementSourcesGroup
    ),
    takeLatest(SettlementTypes.SETTLEMENT_UPDATE_REQUEST, updateSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_CREATE_REQUEST, createSettlement),
    takeLatest(SettlementTypes.SETTLEMENT_DELETE_REQUEST, deleteSettlement),
    takeLatest(SettlementTypes.STRUCTURE_SETTLEMENT_REQUEST, getStructureSettlement),

    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_REQUEST,
      getMembershipInvite
    ),
    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_ALL_REQUEST,
      getMembershipInvites
    ),
    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_CREATE_REQUEST,
      createMembershipInvite
    ),
    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_UPDATE_REQUEST,
      updateMembershipInvite
    ),
    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_DELETE_REQUEST,
      deleteMembershipInvite
    ),
    takeLatest(
      MembershipInviteTypes.MEMBERSHIP_INVITE_RESEND_REQUEST,
      resendMembershipInvite
    ),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMERS_BULK_PAUSE_REQUEST, getBusinessCustomersBulkPause),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMERS_BULK_CANCEL_REQUEST, getBusinessCustomersBulkCancel),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMERS_SINGLE_PAUSE_REQUEST, getBusinessCustomersSinglePause),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMERS_SINGLE_CANCEL_REQUEST, getBusinessCustomersSingleCancel),
    takeLatest(ProfileTypes.PROFILE_REQUEST, getProfile),
    takeLatest(ProfileTypes.PROFILE_UPDATE_REQUEST, updateProfile),

    takeLatest(EnterpriseTypes.ENTERPRISE_REQUEST, getEnterprise),
    takeLatest(EnterpriseTypes.GENERATE_REQUEST, generateEnterprise),

    takeLatest(DocumentTypes.DOCUMENT_REQUEST, getDocuments),
    takeLatest(DocumentTypes.ORGANISATION_REQUEST, getOrganisationDocuments),
    takeLatest(DocumentTypes.BUSINESS_VERIFICATION_REQUEST, createRecord),
    takeLatest(DocumentTypes.DOCUMENT_VERIFICATION_REQUEST, verifyDocument),
    takeLatest(DocumentTypes.DOCUMENT_REVIEW_REQUEST, reviewDocument),
    takeLatest(DocumentTypes.DOCUMENT_PREVIEW_REQUEST, previewDocument),
    takeLatest(DocumentTypes.BUSINESS_VERIFICATION_STATUS_REQUEST, getBusinessVerificationStatus),
    takeLatest(DocumentTypes.BUSINESS_LOGO_UPLOADING, uploadLogo),

    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_ALL_REQUEST, getBusinessCustomers),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_SUMMARY_SEARCH_REQUEST, getBusinessCustomerSummarySearch),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_SUMMARY_REQUEST, getBusinessCustomerSummary),
    takeLatest(BusinessCustomerTypes.EXACT_FILTERED_BUSINESS_CUSTOMER_REQUEST, getExactFilteredBusinessCustomers),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_SEARCH_REQUEST, getFilteredBusinessCustomers),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_UPDATE_REQUEST, updateEnterpriseCustomer),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_SUBSCRIPTIONS_REQUEST, getBusinessCustomerSubscriptions),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_RECORD_REQUEST,
      getBusinessCustomerRecord
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_SUBSCRIPTIONS_COUNT_REQUEST,
      getCount
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_UPLOAD_REQUEST,
      uploadCustomers
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_IMPORT_REQUEST,
      getImportCustomers
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_IMPORT_COUNT_REQUEST,
      getImportCustomersCount
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_ENTRY_REQUEST,
      getEntries
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_APPLY_REQUEST,
      applyImport
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_PAYMENTS_REQUEST,
      getCustomerRecentPayments
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_MISSED_PAYMENTS_REQUEST,
      getCustomerRecentMissedPayments
    ),
    takeLatest(
      BusinessCustomerTypes.BUSINESS_CUSTOMER_SUBSCRIPTION_ACTIVITY_REQUEST,
      getRecentCustomerSubscriptionActivity
    ),
    takeLatest(
      BusinessCustomerTypes.CUSTOMER_RECEIVED_PAYMENT_REQUEST,
      getCustomerReceivedPayments
    ),
    takeLatest(
      BusinessCustomerTypes.CUSTOMER_MISSED_PAYMENT_REQUEST,
      getCustomerMissedPayments
    ),
    takeLatest(
      BusinessCustomerTypes.CUSTOMER_PENDING_PAYMENT_REQUEST,
      getCustomerPendingPayments
    ),
    takeLatest(
      BusinessCustomerTypes.CUSTOMER_REPORT_REQUEST,
      getCustomerReports
    ),
    takeLatest(
      BusinessCustomerTypes.CUSTOMER_CHARTS_REQUEST,
      getBusinessCustomerCharts
    ),
    takeLatest(
      BusinessCustomerTypes.SUCCESSFUL_PAYMENTS_REQUEST,
      getSuccessfulPayments
    ),
    takeLatest(
      BusinessCustomerTypes.EXPIRED_PAYMENTS_REQUEST,
      getExpiredPayments
    ),
    takeLatest(BusinessCustomerTypes.BUSINESS_CUSTOMER_SUBSCRIPTION_AMOUNTS_REQUEST, getBusinessCustomerSubscriptionAmounts),

    takeLatest(WalletTypes.WALLET_REQUEST, getWallet),
    takeLatest(WalletTypes.GLOBAL_WALLET_REQUEST, getGlobalWallet),
    takeLatest(WalletTypes.FAVOURITE_WALLETS_REQUEST, favouriteWallets),
    takeLatest(WalletTypes.FAVOURITE_WALLET_REQUEST, favouriteWallet),
    takeLatest(WalletTypes.UNFAVOURITE_WALLET_REQUEST, unfavouriteWallet),
    takeLatest(WalletTypes.WALLET_STATISTICS_REQUEST, getWalletStatistics),

    takeLatest(ReportTypes.SUBSCRIPTION_REPORT_REQUEST, subscribeReport),
    takeLatest(
      ReportTypes.TRANSACTION_CUSTOM_REPORT_REQUEST,
      transactionCustomReport
    ),
  ]);
}
