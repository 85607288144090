import React,{useState} from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Carousel } from 'primereact/carousel';
import "../../Styles/pages/transactions.scss";

export const AddAccountDialog = (props) => {
    const { state, setState, setFavouritePayoutChannels, t, channelOptionTemplate, selectedChannelTemplate,  payoutChannelsFavourite } = props;
    const run = () => {
        if (state.newFav) {
            setFavouritePayoutChannels(state.newFav.id);
            setState((prev) => { return { ...prev, callFav: true, addNewFavVisible: false, addingFav: true }; });
        }
    };
    
    const onHide = () => {
        setState((prev) => { return { ...prev, newFav: null, addNewFavVisible: false }; });
    };
    const footer = (
        <div className="payout_channel-buttonsB">
            <Button label="Cancel" icon="pi pi-times" style={{ color: "#008dd5", backgroundColor: "transparent", border: "none" }} onClick={onHide} />
            <Button label="Add Favorite" icon="pi pi-plus" style={{ color: "white", backgroundColor: "#d0021b" }} onClick={run} />
        </div>
    );
    
    return (
        <Dialog header={t('saved_recipients.add_fav_recipient')} footer={footer} className="addFacDial defaultDial" visible={state.addNewFavVisible} style={{ width: '360px' , height:"250px"}} onHide={onHide}>
            <div className="p-col">
                <h2 className="payout_channel-title accounts-channel-title">{t("saved_recipients.choose_fav_recipient")}</h2>
                <p className="favorite-dropdown-title" style={{marginLeft:"1.2rem"}}>{t("saved_recipients.saved_accounts")}</p>
                <Dropdown
                    value={state.newFav}
                    options={state.payoutChannels.filter(channel => !payoutChannelsFavourite?.map(favorite => favorite.id).includes(channel.id))}
                    itemTemplate={channelOptionTemplate}
                    onChange={(e) => setState((state) => {
                        return { ...state, newFav: e.value };
                    })}
                    optionLabel="name"
                    id="payoutChannel"
                    valueTemplate={selectedChannelTemplate}
                    className="payout_channel-dropdown favorite-dropdown" />
            </div>
        </Dialog>
    );
};
const AccountCard = ({ name = '', identifier = '', channelName = '', icon, onClick, remove, }) => {
    const { t } = useTranslation("common");
    const [removeFav, setRemoveFav]= useState(false)
    const confirm = () => {
        setRemoveFav(true);
    };
    const onHide = () => setRemoveFav(false);
    const footer = (
        <div className="payout_channel-buttonsC">
            <Button label="No" icon="pi pi-times" style={{ color: "#008dd5", backgroundColor: "transparent", border: "none" }} onClick={onHide} />
            <Button label="Yes" icon="pi pi-check" style={{ color: "white", backgroundColor: "#d0021b" }} onClick={()=> remove()} />
        </div>
    );
    return (
        <div className="fav">
            <Dialog header={t("recipient.confirmation_header")} visible={removeFav} onHide={onHide} footer={footer}>{<span><i className="pi pi-exclamation-triangle"/> {t("recipient.remove_favorite")} </span> } </Dialog>
            <Button icon="pi pi-times" onClick={confirm} className="p-button-rounded p-button-danger p-button-outlined fav-button-cancel p-2" aria-label="Cancel" />
            <p className="fav-name">{name}</p>
            <div onClick={onClick}  className="fav-info">
                {icon ? <img src={icon} className="fav-info-icon"  alt={name} /> : <Skeleton className="icon" shape="circle" size="75px"></Skeleton>}
                <div className="fav-info-text">
                    <p className="fav-info-text-num">{identifier}</p>
                    <p className="fav-info-text-channel">{channelName}</p>
                    
                </div>
            </div>
        </div>
    );
};
const AddAccountCard = ({ onClick, t }) => {
    return (
        <div onClick={onClick} className="fav fav-add" style={{width:"30rem"}}>
            <i className="icon pi-left pi pi-plus"></i>
            <span className="fav-info-text-num"> {t("recipient.add_fav_recipient")} </span>
        </div>
    );
};


export const FavouriteSection = ({ payoutChannelsFavourite, fetchingFavourite, onClickAccountCard, payoutChannelSetUnfavourite,state, setState, isNewChannel = false, showRemovingFav, showErrorFav }) => {
    const { t } = useTranslation("common");
   
    const responsiveOptions = [
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1451px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '678px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1161px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '1858px',
            numVisible: 3,
            numScroll: 2
        },
    ];

    const addFav = () => {
        setState((prev) => { return { ...prev, addNewFavVisible: true }; });
    };
    if (isNewChannel) { return <></>; }
    let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,];
    arr = arr.map((i) => {
        if (!!payoutChannelsFavourite && payoutChannelsFavourite.length !== 0 && payoutChannelsFavourite.length > i)  { return payoutChannelsFavourite[i]; }
        else { return 0; }
    });

    return (
        <>
            <h6 className="wallet-titles">{t("recipient.favorites")}</h6>
            <div className="carousel-demo">
                <div className="card card-background">.
                    <Carousel numVisible={4} numScroll={2} value={arr} responsiveOptions={responsiveOptions} itemTemplate={(channel) => {
                        if (fetchingFavourite) { return <div className="fav"><ProgressSpinner className="spinner-content" style={{ display: "flex", justifyContent: "center", width: "auto", height: "90px" }} /></div>; }
                        if (channel) {
                            return (<>
                                <AccountCard
                                    name={channel.name}
                                    identifier={channel.identifier}
                                    channelName={channel.systemBank.name || channel.channelName}
                                    onClick={() => { onClickAccountCard(channel) }}
                                    icon={channel.systemBank.logoUrl}
                                    remove={() => {
                                        if (payoutChannelsFavourite.find(({ id }) => id === parseInt(channel.id)) === -1) {
                                            showErrorFav();
                                        }
                                        payoutChannelSetUnfavourite(channel.id);
                                        setState((prev) => { return { ...prev, loadUnFav: true, removingFav: true }; });
                                    }} />
                            </>);
                        } else {
                            return <AddAccountCard onClick={addFav} t={t} />;
                        }
                    }}></Carousel>
                </div>
            </div>
        </>);
};
