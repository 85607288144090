import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    bankRequest: ['bankId'],
    bankAllRequest: ['countryId'],
    bankFilterRequest: ['businessId', 'query', 'options'],

    bankSuccess: ['bank'],
    bankAllSuccess: ['banks'],
    bankFilterSuccess: ['filteredBanks'],

    bankFailure: ['error'],
    bankAllFailure: ['error'],
    bankFilterFailure: ['error']
})

export const BankTypes = Types
export default Creators
