import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import "../Styles/components/Share.scss";
import { classNames } from "primereact/utils";
import RoleAccess from "../Components/RoleAccess";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "./../Redux/transition/plan-group/plan-group.reducer";

const SharePlan = (props) => {
  const enablingReport = useSelector((state) => state.planGroups.enabling);
  const successEnabling = useSelector((state) => state.planGroups.planReport);
  const errorEnabling = useSelector((state) => state.planGroups.errorEnabling);
  const business = useSelector((state) => state.businesses.business);

  const dispatch = useDispatch();
  const enablePlanReport = useCallback((planGroupId, report) => {dispatch(PlanGroupActions.enablePlanReportRequest(planGroupId, report))}, [dispatch]);
  const getActivePlanGroups = useCallback((businessId) => {dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId))}, [dispatch]);

  const { t } = useTranslation("common");
  const { visible, setSharePlanDialog, sharedPlan: { plan, shortUrl }, } = props;
  const toast = useRef(null);
  const savedState = JSON.parse(localStorage.getItem("sharePlanPopupState") || "{}");
  const defaultState = { save: false, isReport: false, addPin: false, pin: "", change_pin: true, };
  const initialPlanState = savedState[plan] || {};
  const [changePin, setChangePin] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [state, setState] = useState({ saveButtonClicked: false, copied: false, ...defaultState, ...initialPlanState, });
  const [report, setReport] = useState("");
  const [successReportable, setSuccessReportable] = useState(false);
  const [successReportCode, setSuccessReportCode] = useState("");
  const [save, setSave] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [closeToast, setCloseToast] = useState(true);
  const [successToggle, setSuccessToggle] = useState(false);
  const [reportPin, setReportPin] = useState("");
  const [reportBox, setReportBox] = useState(false);
  const [successAddPinState, setSuccessAddPinState] = useState("");
  const [reportBoxClick, setReportBoxClick] = useState(false);
  const [pinBoxClick, setPinBoxClick] = useState(false);
  const [showReportCode,setShowReportCode]=useState(false);


  useEffect(() => {
    if (!showToast && props) { setReport(props.reportable); }
    if (!showToast && props.reportCode === null) { setReportBox(false); }
    if (!showToast && props.reportCode === "") { setReportBox(false); }
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      setReportPin(props.reportCode)
    }
    else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      setReportPin(null)
    }
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      setReportBox(true)
    }
    else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      setReportBox(false)
    }
    // eslint-disable-next-line
  }, [props.reportable, props.reportCode, props.dialogClosed, showToast]);

  useEffect(() => {
    if (successEnabling?.reportCode === "" && showToast) {
      setReportBox(false);
      setReportPin(null);
    }
    if (successEnabling?.reportCode !== "" && showToast) {
      setReportBox(true)
      setReportPin(successEnabling?.reportCode)
    }
    if (successEnabling?.Reportable && showToast) {
      setReport(successReportable)
    }
    // eslint-disable-next-line
  }, [successEnabling, showToast,]);

  useEffect(() => {
    if (successEnabling?.reportable) {
      setSuccessReportable(true)
    }
    else {
      setSuccessReportable(false)
    }
    if (successEnabling?.reportCode !== "") {
      setSuccessReportCode(successEnabling?.reportCode)
    }
    else {
      setSuccessReportCode("")
    }
    // eslint-disable-next-line
  }, [successEnabling]);

  useEffect(() => {
    if (showToast) {
      if (report !== successReportable) {
        setSuccessToggle(true)
      }
      else {
        setSuccessToggle(false)
      }
    }

  }, [reportBoxClick, showToast, report, successReportable])

  useEffect(() => {
    if (showToast) {
      if (reportBox !== successAddPinState) { setSuccessToggle(true) }
      else { setSuccessToggle(false) }
    }
  }, [pinBoxClick, showToast, reportBox, successAddPinState])

  useEffect(() => {
    if (!enablingReport && successEnabling && enabling) {
      toast.current.show({
        life: 3000,
        severity: "success",
        summary: t("share.saved"),
        detail: t("share.save_message"),
      });
    }
    if (!enablingReport && errorEnabling && enabling) {
      toast.current.show({
        life: 3000,
        severity: "error",
        summary: t("share.saved"),
        detail: t("share.save_message"),
      });
    }
    // eslint-disable-next-line
  }, [enabling, enablingReport, successEnabling]);

  const showCopyPin = () => {
    if ((showToast && successReportCode !== "")) {
      return <span>{t("share.pin", { pin: successReportCode })}</span>;
    }
    if ((showToast && successReportCode === "")) {
      return;
    }
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      return <span>{t("share.pin", { pin: props.reportCode })}</span>;
    } else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      return;
    }
  };

  const textDisplay = () => {
    if (!showToast && props.reportable) { return t("share.report", { plan }) };
    if (!showToast && !props.reportable) { return t("share.signup", { plan }) };
    if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
      return t("share.report", { plan })
    } else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
      return t("share.signup", { plan })
    }
    if (showToast && successReportable) { return t("share.report", { plan }) }
    else if (showToast && !successReportable) { return t("share.signup", { plan }) }
    else if (showToast && successReportable && successReportCode !== "") { return t("share.report", { plan }) }
    else if (showToast && successReportable && successReportCode !== null) { return t("share.report", { plan }) }
  };
  const copyText = !showToast && report && (props.reportCode || reportPin) ? `${t("share.report", { plan })} ${shortUrl} ${t(
    "share.pin")} ${reportPin}` : !showToast && report && !props.reportCode ? `${t("share.report", { plan })} ${t("share.url", { shortUrl })}`
    : `${t("share.signup", { plan })} ${t("share.url", { shortUrl })}`;

  const copyTextSave =
    showToast && report && successReportCode !== "" ? `${t("share.report", { plan })} ${shortUrl} ${t("share.pin")} ${reportPin}`
      : showToast && report && successReportCode === "" ? `${t("share.report", { plan })} ${t("share.url", { shortUrl })}`
        : `${t("share.signup", { plan })} ${t("share.url", { shortUrl })}`;

  useEffect(() => {
    if (!visible) {
      setState((state) => ({
        ...state, ...defaultState, copied: false,
      }));
    } else {
      if (savedState[plan]) {
        setState((prev) => ({
          ...prev,
          ...savedState[plan],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          ...defaultState,
          copied: false,
        }));
      }
      setState((prev) => ({
        ...prev,
        change_pin: !prev.save,
      }));
    }
    // eslint-disable-next-line
  }, [visible]);

  const handleSaveClick = () => {
    enablePlanReport(props.sharedPlan.planGroupId, {
      reportable: report,
      reportCode: reportPin === undefined || !reportBox ? "" : reportPin,
    });
    setEnabling(true);
    setChangePin(false);
    setShowReportCode(false)
    setState((prev) => ({
      ...prev,
      ...defaultState,
      copied: false,
    }));
  };

  const handleCancelClick = () => {
    if (!showToast) {
      setReportPin(props.reportCode)
      setSave(false)
    }
    if (showToast) {
      setReportPin(successReportCode)
      setSuccessToggle(false)
    }
    setChangePin(false);
  };

  const onValueUpdate = (obj, value) => {
    if (obj === "isReport" && !value) {
      setState((prev) => ({
        ...prev,
        isReport: value,
        addPin: false,
        save: false,
        change_pin: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [obj]: value,
        copied: false,
        save: !(obj === "pin" || obj === "addPin" || obj === "isReport"),
      }));
    }
  };

  const hasPin = reportPin?.toString().length === 5;

  return (
    <>
      <Toast
        ref={toast}
        onRemove={(message) => {
          if (message.severity === "success") {
            setSave(false);
            getActivePlanGroups(business.id);
            setShowToast(true)
            setCloseToast(false)
            setSuccessAddPinState(reportBox)
          }
        }}
      />
      <Dialog
        header={t("product.share_url")}
        headerClassName="planShareHeader"
        className="planShare share_dialog"
        id="share_dialog"
        visible={visible}
        onHide={() => {
          setSharePlanDialog(false);
          setReport(props.reportable)
          setReport(props.reportable)
          setReportPin(props.reportCode);
          props.setDialogClosed(true)
          setShowToast(false)
          setCloseToast(true)
          setSave(false)

          if (!showToast && (props.reportCode !== undefined && props.reportCode !== null && props.reportCode !== "")) {
            setReportBox(true)
          } else if (!showToast && (props.reportCode === undefined && props.reportCode === null && props.reportCode === "")) {
            setReportBox(false)
          }
          if (showToast && successReportCode !== "") 
          {setReportBox(true) } 
          else if (showToast && successReportCode === "") 
          { setReportBox(false) }
        }}
      >
        <p className="share-title">{t("product.share_title")}</p>
        <div className="planShare-box">
          <div style={{ marginTop: "1rem" }}>
            <span>
              <span>{textDisplay()}</span>
              <p>{t("share.url", { shortUrl })}</p>
              <br />
              {showCopyPin()}
            </span></div>
          <Button
            label={state.copied ? t("share.copied") : t("share.copy")}
            className="copy_btn"
            onClick={() => {
              !showToast ? navigator.clipboard.writeText(copyText) : navigator.clipboard.writeText(copyTextSave);
              setState((state) => ({
                ...state,
                copied: true,
                change_pin: true,
              }));
            }}
          />
        </div>
        <RoleAccess roles={["OWNER", "ADMIN"]}>
          <div className="col-12 mt-4">
            <Checkbox
              inputId="isReport"
              onChange={(e) => {
                if (closeToast && e.checked !== props.reportable) { setSave(true) }
                else { setSave(false) }
                if ((showToast && e.checked !== successEnabling.reportable)) { setSuccessToggle(true) }
                else { setSuccessToggle(false) }
                setReport(e.checked);
                if (!e.checked) { setReportBox(false); if (!props.reportCode) { setReportPin(""); } }
                setReportBoxClick(!reportBoxClick)
              }}
              checked={report}
            ></Checkbox>
            <label htmlFor="isReport" className="p-checkbox-label">{t("share.enable_report")} </label>
            <br />
            <span className="p-checkbox-hint">{t("share.enable_report_hint")} </span>
          </div>
          <div className="col-12">
            <Checkbox
              inputId="addPin"
              disabled={!report}
              onChange={(e) => {
                if (e.checked) {
                  setReportBox(null)
                  setChangePin(false)
                  setShowReportCode(false)
                }
                if(save && !e.checked ){
                  setShowReportCode(false)
                  setReportPin(null)
                }
                if(!save && !e.checked){
                  setReportBox("")
                  setReportPin(props.reportCode)
                  setShowReportCode(true)
                }

                if(changePin && !e.checked){
                  setShowReportCode(true)
                }

                if (showToast && successReportCode) {
                  setReportPin(successReportCode)
                }
                if (showToast && successReportCode === "") {
                   setReportPin(null)
                }

                if (closeToast && e.checked && props.reportCode) {
                  setSave(false)
                }
                if (closeToast && !e.checked && props.reportCode) {
                  setSave(true)
                }
                if (closeToast && e.checked && !props.reportCode) {
                  setSave(true)
                }
                if (closeToast && !e.checked && !props.reportCode) {
                  setSave(false)
                }

                if (showToast && successAddPinState !== e.checked) {
                  setSuccessToggle(true)
                }
                if (showToast && successAddPinState === e.checked) {
                  setSuccessToggle(false)
                }

                setPinBoxClick(!pinBoxClick)
                setReportBox(e.checked);
              }}
              checked={reportBox} >
            </Checkbox>
            <label htmlFor="addPin" className="p-checkbox-label"> {t("share.add_pin")}  </label>
            <br />
            <span className="p-checkbox-hint">{t("share.add_pin_hint")}</span>
          </div>
          <div className="flex justify-content-between align-items-end ml-1">
            <div className="field flex flex-column md:col-2 mb-0">
              {showReportCode ? (
              <InputText
                disabled={!reportBox}
                value={reportPin}
                className="borderless"
              />) : 
              (report && reportBox && (
                <InputText
                  disabled={(!showToast && props.reportCode && !changePin) || (showToast && !changePin && successReportCode)}
                  placeholder="12345"
                  inputId="pin"
                  value={reportPin}
                  className={classNames({
                    border: (props.reportCode && hasPin) || (!hasPin && successReportCode) || (reportPin && hasPin) || reportPin,
                    borderless: (!showToast && !save) || (showToast && !successToggle),
                    changePin:changePin,
                  })}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^[+]?\d*\.?\d*$/.test(newValue) || newValue === '') {
                      setReportPin(newValue);
                      onValueUpdate("pin", newValue);
                    }
                  }}
                  useGrouping={false}
                  maxLength={5}
                />
              ))
              }
              {report && reportPin && !hasPin && (<small className="p-error">{t("share.pin_err")}</small>)}
              {(report && !showToast && props.reportCode && reportBox) && (
                <Button
                  className="p-button-link change_pin"
                  label={changePin ? t("cancel") : t("share.change_pin")}
                  onClick={() => {
                    if (changePin) { handleCancelClick(); setSave(false) }
                    else { setChangePin(true); setSave(true); setReportPin(null); }
                  }}
                ></Button>
              )}
              {(report && showToast && successReportCode && reportBox) && (
                <Button
                  className="p-button-link change_pin"
                  label={changePin ? t("cancel") : t("share.change_pin")}
                  onClick={() => {
                    if (changePin) {
                      handleCancelClick();
                    }
                    else {
                      setChangePin(true);
                      setSave(true);
                      setReportPin(null);
                      setSuccessToggle(true)
                    }
                  }}
                ></Button>
              )}
            </div>

            {(!showToast && save) && (
              <>
                <Button
                  label={t("share.save")}
                  disabled={reportBox && !hasPin}
                  loading={enablingReport}
                  className="save_btn"
                  onClick={() => handleSaveClick()}
                />
              </>
            )}
            {(showToast && successToggle) && (
              <>
                <Button
                  label={t("share.save")}
                  disabled={reportBox && !hasPin}
                  loading={enablingReport}
                  className="save_btn"
                  onClick={() => handleSaveClick()}
                />
              </>
            )}
          </div>
        </RoleAccess>
      </Dialog>
    </>
  );
};
export default SharePlan;
