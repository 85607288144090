import React from "react"
import { Card } from "primereact/card";
import moment from "moment";
import { Button } from "primereact/button";
const ProductsDetailCard = (props) => {

      const createdOn = props.state.planGroup ? moment(props.state.planGroup.createdDate).format("MMMM DD YYYY") : "";
      const updatedOn = props.state.planGroup ? moment(props.state.planGroup.lastModifiedDate).format("MMMM DD YYYY") : "";

      return (
            <Card className="ml-0 products-detail-card">
                  <div className="grid">
                        <div className="col-6 md:col-6 lg:col-7">
                              <div className="grid planCard-info">
                                    {props.state.planGroup.exclusive ? <span className="exclusive" style={{ marginLeft: "0.5rem" }}>{props.t("products.EXCLUSIVE")}</span> :
                                          <span className="non-exclusive" style={{ marginLeft: "0.5rem" }}>{props.t("products.NON-EXCLUSIVE")}</span>}
                                    <p className={`planCard-badge product-status status-${props.state.planGroup.status?.toLowerCase()}`}>{props.state.planGroup.status}</p>
                                    {createdOn === updatedOn && <div style={{ display: "flex", flexDirection: "row" }}> <p className="planCard-date">
                                          {props.t("product.created_on")}{":"}</p>&nbsp; <span className="planModifiedDate">{createdOn}</span>
                                    </div>}
                                    {createdOn !== updatedOn && <div style={{ display: "flex", flexDirection: "row" }}><p className="planCard-date">
                                          {props.t("product.updated_on")}{":"}</p>&nbsp; <span className="planModifiedDate">{updatedOn}</span>
                                    </div>}
                              </div>
                              {props.state.planGroup.status === "ARCHIVED" && <div> <Button icon="pi pi-lock" id="archived_icon" /> </div>}
                              <div style={{ display: "flex", flexDirection: "column", }}>
                                    <span className="productCard-name">{props.state.planGroup.name}</span>
                                    <span className="planCard-dec">{props.state.planGroup.description}</span>
                              </div>
                        </div>
                        <div className="col-6 md:col-6 lg:col-5">
                              <div className="balance-div">
                                    <span id="balance_label">{props.t("Balance")}{":"}</span>&nbsp;
                                    <span style={{ fontSize: "20px", color: "#495057", marginTop: "0.1rem" }}>{props.defMembership.business?.currency}
                                          {" "}{props.state.performance.balance?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                              </div>
                              <div className="available-div">
                                    <span id="available_label">{props.t("product.available")}{":"}</span>&nbsp;
                                    <span className="detail-card-value">{props.defMembership.business?.currency}{" "}{props.state.performance.available?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                              </div>
                              <div className="pending-div">
                                    <span id="pending_label">{props.t("products.pending")}{":"}</span>&nbsp;
                                    <span className="detail-card-value">{props.defMembership.business?.currency}
                                          {" "}{props.state.performance.pending?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                              </div>
                        </div>
                  </div>
            </Card >
      )
};
export default ProductsDetailCard;