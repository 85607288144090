import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";


const {Types, Creators} = createActions({
    
    enterpriseRequest: ["businessId"],
    generateRequest: ["businessId"],

    enterpriseSuccess: ["enterprise"],
    generateSuccess: ["enterprise"],

    enterpriseFailure: ["error"],
    generateFailure:["error"]
})

export const EnterpriseTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    fetchingEnterprise: null,

    enterprise: null,
    generating: null,
    
    errorFetching: null,
    errorGenerating: null,
})

export const request = (state) => {
    return{ ...state, fetchingEnterprise: true,}
}


export const generateRequest = (state) => {
    return{ ...state, generating: true,}
}


export const success = (state, action) => {
    const {enterprise} = action
    return{ ...state, fetchingEnterprise:false, enterprise}
}


export const generateSuccess = (state, action) => {
    const {enterprise} = action
    return{ ...state, generating:false, errorGenerating:false, enterprise}
}


export const failure = (state,action) => {
    const {error} = action
    return{ ...state, errorFetching:error, fetchingEnterprise: false, enterprise:null}
}


export const generateFailure = (state, action) => {
    const {error} = action 
    return{ ...state, errorGenerating:error, generating:false}
}

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ENTERPRISE_REQUEST]: request,
    [Types.GENERATE_REQUEST]: generateRequest,

    [Types.ENTERPRISE_SUCCESS]:success,
    [Types.GENERATE_SUCCESS]: generateSuccess,

    [Types.ENTERPRISE_FAILURE]: failure,
    [Types.GENERATE_FAILURE] : generateFailure
    // [Types.GENERATE_FAILURE]: generateFailure,

});

