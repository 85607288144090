/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useTranslation } from "react-i18next";
import Customer from "./customers";
import Import from "./import";
import "../../Styles/pages/customers.scss";
import "../../Styles/pages/dashboard.scss";
import { TabMenu } from "primereact/tabmenu";


const CustomersIndex = () => {
    const { t } = useTranslation("common");
    const [activeIndex, setActiveIndex] = useState(0)
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
    }, []);

    return (
        <div className="customerContent">
            <TabMenu className={"p-tabMenu customerTabs"}
                activeIndex={activeIndex}
                onTabChange={(e) => { setActiveIndex(e.index) }}
                model={[{ label: t("business_customers.customers"), className: "tab tab1" },
                { label: t("business_customers.imports"), className: "tab edge-tab-end" }]} />
            {activeIndex === 0 && <Customer />}
            {activeIndex === 1 && <Import />}
        </div>
    )
}

export default CustomersIndex;