import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Checkbox } from 'primereact/checkbox';

const PlanCreateForm = (props) => {

    return (
        <div>
            <form onSubmit={props.formik?.handleSubmit} className="p-fluid">
                <div className="form_div">
                    <span className="plan-title">{props.t("plan.plan_for")}{" "}{!props?.tierExist ? "General" : props.tierExist?.name}  </span>

                    <p className="first_hr" />

                    <div className="p-field">
                        <label
                            htmlFor="name"
                            className={classNames({
                                "p-error": props.isFormFieldValid("name"),
                            })} >
                            <div style={{ margin: "1rem 0rem 0.5rem 0rem" }}>
                                <span className="labels">{props.t("plan.name")}</span>
                                <span className="isterik">*</span>
                            </div>
                        </label>
                        <InputText
                            id="name"
                            name="name"
                            placeholder={props.t("plan.name")}
                            value={props.formik?.values.name}
                            onChange={props.formik?.handleChange}
                            className={classNames({
                                "p-invalid": props.isFormFieldValid("name",),
                            })}
                            maxLength={50} />
                        <span style={{ marginLeft: "0.3rem" }}>{props.getFormErrorMessage("name")}</span>
                    </div>
                    <br />
                    <div>
                        <label
                            htmlFor="description"
                            className={classNames("labels_error_message", {
                                "p-error": props.isFormFieldValid("description"),
                            })}>
                            <div className="labels_error_message">
                                <span className="labels"> {props.t("plan.plan_description")}</span>
                                <span className="labels">{props.t("plan.optional")}</span>
                            </div>
                        </label>
                        <InputText
                            id="description"
                            name="description"
                            placeholder={props.t("plan.plan_des")}
                            value={props.formik?.values.description}
                            onChange={props.formik?.handleChange}
                            maxLength={100} />
                    </div>
                    <br />

                    <p className="first_hr" />

                    <div className="policy_main_div">
                        <div className="grid">
                            < div className="policy_div">
                                <div style={{ display: "flex", flexDirection: "row" }} >
                                    <div className="field_hint">
                                        <div>
                                            <label
                                                htmlFor="policy"
                                                className={classNames({
                                                    "p-error": props.isFormFieldValid("policy"),
                                                })}
                                            >
                                                <span className="labels"> {props.t("plan.billing_policy")}</span> <span className="isterik">*</span>
                                            </label>
                                            <div className="inputFields">
                                                <Dropdown
                                                    id='policy'
                                                    name="policy"
                                                    optionLabel="Policy"
                                                    value={props.formik?.values.policy}
                                                    onChange={(e) => { props.formik.setFieldValue("policy", e.value); }}
                                                    options={props.businessCustomers?.business?.enterprise ? props.enterprisePolicyPlan : props.policyPlan}
                                                    placeholder={props.t("plan.choose_billing_policy")}
                                                    className={classNames({
                                                        "p-invalid": props.isFormFieldValid("policy")
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <span className="policy_error_message">
                                            {props.getFormErrorMessagePolicy("policy")}
                                        </span>
                                    </div>
                                    <div className="policyHelperText_div">
                                        <span>{props.policyHelperText()}</span>
                                    </div>
                                </div>
                                {props.formik?.values.policy === null && <span className="policy_hint" >{props.t("plan.policy_hint")}</span>}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            {props.formik?.values.policy?.Policy === "Fixed billing" && (
                                <div className="plan_cost_div">
                                    <div>
                                        <label
                                            htmlFor="cost"
                                            className={classNames({
                                                "p-error": props.isFormFieldValidCost("cost"),
                                            })} ><div classNames="labels_error_message"><span className="labels">{props.t("plan.cost")}</span>{" "}
                                                <span className="labels">({props.defMembership?.business.currency})</span></div>
                                        </label>
                                        <div className="inputFields">
                                            <InputText
                                                id="plan_cost"
                                                name="cost"
                                                type="number"
                                                placeholder={props.t("plan.plan_amount")}
                                                value={props.formik?.values.cost}
                                                onChange={props.formik?.handleChange}
                                                className={classNames({
                                                    "p-invalid": props.isFormFieldValid("name",),
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <span className="cost_error_message"> {props.getFormErrorMessageCost("cost")}</span>
                                </div>
                            )}
                            {
                                (props.formik?.values.policy?.Policy === "Fixed billing" ||
                                    props.formik?.values.policy?.Policy === "Subscription billing") &&
                                <div className="setup_checkbox">
                                    <Checkbox onChange={props.onChangeFeeCheck} checked={props.formik.values.feeCheck} ></Checkbox>
                                    <p className="setup_fee_checkbox_label" >{props.t("plan.fee_checkbox_label")}</p>
                                </div>
                            }
                        </div>

                        {(props.formik?.values.policy?.Policy === "Fixed billing" && props.formik.values.feeCheck) &&
                            <div className="setup-main-div">
                                <div>
                                    <label
                                        htmlFor="setup"
                                        className={classNames("labels_error_message", {
                                            "p-error": props.isFormFieldValidSetupFee("setup"),
                                        })} ><div classNames="labels_error_message"><span className="labels">{props.t("plan.setup")}</span>{" "}
                                            <span className="labels">({props.defMembership?.business.currency})</span>
                                            <span className="isterik">*</span></div>
                                    </label>
                                    <div className="inputFields">
                                        <InputText
                                            id="setup"
                                            name="setup"
                                            type="number"
                                            placeholder={props.t("plan.setup")}
                                            value={props.formik?.values.setup}
                                            onChange={props.formik?.handleChange}
                                            className={classNames({
                                                "p-invalid": props.isFormFieldValid("setup")
                                            })}
                                        />
                                    </div>
                                    <div style={{ marginTop: "0.4rem" }}>
                                        <span className="bolder_hints">{props.t("plan.one_time_text")}{" "}</span>
                                        <span className="setup_fee_hints">{props.t("plan.one_time_continue_text")}</span>
                                    </div>
                                </div>
                                <div className="setup_fee">
                                    {props.getFormErrorMessageSetupFee("setup")}
                                </div>
                            </div>}
                    </div>

                    {(props.formik?.values.policy?.code === "SUBSCRIPTION" && props.formik.values.feeCheck) &&
                        <div style={{ marginTop: "1rem", display: "flex", flexDirection: "row" }} >
                            <div>
                                <div>
                                    <label
                                        htmlFor="setup"
                                        className={classNames("labels_error_message", {
                                            "p-error": props.isFormFieldValidSetupFee("setup"),
                                        })} ><div classNames="labels_error_message" ><span className="labels">{props.t("plan.setup")}</span>{" "}
                                            <span className="labels">({props.defMembership?.business.currency})</span>
                                            <span className="isterik">*</span></div>
                                    </label>
                                    <div className="inputFields">
                                        <InputText
                                            id="setup"
                                            name="setup"
                                            type="number"
                                            placeholder={props.t("plan.setup")}
                                            value={props.formik?.values.setup}
                                            onChange={props.formik?.handleChange}
                                            className={classNames({
                                                "p-invalid": props.isFormFieldValid("setup")
                                            })}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: "0.4rem" }}>
                                    <span className="bolder_hints">{props.t("plan.one_time_text")}{" "}</span>
                                    <span className="setup_fee_hints">{props.t("plan.one_time_continue_text")}</span>
                                </div>
                            </div>
                            <div className="setup_fee">
                                {props.getFormErrorMessageSetupFee("setup")}
                            </div>
                        </div>}

                    <div className="billingPeriod_div">
                        <div className="grid">
                            <div className="field_hint">
                                <div>
                                    <label
                                        htmlFor="billingPeriod"
                                        className={classNames({
                                            "p-error": props.isFormFieldValid("billingPeriod"),
                                        })}>  <span className="labels"> {props.t("plan.billing_period")}</span> <span className="isterik">*</span>
                                    </label>
                                    <div className="inputFields">
                                        <Dropdown
                                            id="billingPeriod"
                                            name="billingPeriod"
                                            value={props.formik?.values.billingPeriod}
                                            onChange={(e) => props.onChangeBillingPeriod(e)}
                                            options={props.period} optionLabel="Period"
                                            placeholder={props.t('plan.choose_billing_period')}
                                            className={classNames({
                                                "p-invalid": props.isFormFieldValid("billingPeriod")
                                            })} />
                                    </div>
                                </div>
                                <div className="style_error_messages">
                                    {props.getFormErrorMessageBilling("billingPeriod")}
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="grid">
                        <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                            <label
                                htmlFor="deductions"
                                className={classNames({
                                    "p-error": props.isFormFieldValid("name"),
                                })} > <span className="labels"> {props.t("plan.deduction_detail")}</span>
                            </label>
                            <div className="inputFields">
                                <InputText
                                    id="deductions"
                                    name="deductions"
                                    type="number"
                                    placeholder={props.t("plan.deductions_placeholder")}
                                    value={props.formik?.values.deductions}
                                    onChange={props.formik?.handleChange}
                                    disabled={
                                        props.formik?.values.billingPeriod &&
                                            props.formik?.values.billingPeriod.code === "ONCE" ? true : false} />
                            </div>
                            <div className="deduction_error_message">
                                {props.getFormErrorMessageDeductions("deductions")}
                            </div>
                            <div>
                                <span className="bolder_hints">{props.t("plan.hint_deductions1")}</span>
                                <span className="hints">{props.t("plan.hint_deductions2")}</span>
                            </div>
                        </div>
                    </div >

                    <p className="first_hr" />

                    <div style={{ display: "flex", flexDirection: "column", marginTop: "0.5rem" }}>
                        <div className="grid">
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    style={{ marginBottom: "0.5rem" }}
                                    htmlFor="graceDays"
                                    className={classNames({
                                        "p-error": props.isFormFieldValid("name"),
                                    })}>
                                    <span className="labels"> {props.t("grace_period")}</span>
                                </label>
                                <InputText
                                    id="graceDays"
                                    name="graceDays"
                                    type="number"
                                    required
                                    value={props.formik?.values.graceDays}
                                    disabled={props.disableDays}
                                    onChange={props.formik?.handleChange} />
                                {props.getFormErrorMessageGraceDays("graceDays")}
                                <div style={{ marginTop: "0.7rem" }}>
                                    <span className="hints">{props.t("plan.hint_graceDays1")}{" "}</span>
                                    <span className="bolder_hints">{props.t("plan.hint_graceDays2")}{" "}</span>
                                    <span className="hints">{props.t("plan.hint_graceDays3")}{" "}</span>
                                    <span className="bolder_hints">{props.t("plan.missed")}</span>
                                </div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    style={{ marginBottom: "0.5rem" }}
                                    htmlFor="ReminderDays"
                                    className={classNames({
                                        "p-error": props.isFormFieldValid("name"),
                                    })}> <span className="labels">{props.t("reminder")}</span>
                                </label>
                                <InputText
                                    id="reminderDays"
                                    name="reminderDays"
                                    type="number"
                                    value={props.formik?.values.reminderDays}
                                    disabled={props.disableDays}
                                    onChange={props.formik?.handleChange} />
                                {props.getFormErrorMessageReminderDays("reminderDays")}
                                <div style={{ marginTop: "0.7rem" }}>
                                    <span className="hints">{props.t("plan.hint_reminderDays1")}{" "}</span>
                                    <span className="bolder_hints">{props.t("plan.hint_reminderDays2")}{" "}</span>
                                    <span className="hints">{props.t("plan.hint_reminderDays3")}{" "}</span>
                                    <span className="bolder_hints">{props.t("plan.hint_reminderDays4")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </form >
        </div >
    )
}
export default PlanCreateForm;