import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  membershipInviteRequest: ['membershipInviteId'],
  membershipInviteAllRequest: ['businessId', 'options'],
  membershipInviteUpdateRequest: ['membershipInvite'],
  membershipInviteDeleteRequest: ['membershipInviteId'],
  membershipInviteCreateRequest: ['membershipInvite'],
  membershipInviteResendRequest: ['membershipInviteId'],

  membershipInviteSuccess: ['membershipInvite'],
  membershipInviteAllSuccess: ['membershipInvites'],
  membershipInviteUpdateSuccess: ['membershipInvite'],
  membershipInviteDeleteSuccess: [],
  membershipInviteCreateSuccess: ['membershipInvite'],
  membershipInviteResendSuccess: [],

  membershipInviteFailure: ['error'],
  membershipInviteAllFailure: ['error'],
  membershipInviteUpdateFailure: ['error'],
  membershipInviteDeleteFailure: ['error'],
  membershipInviteCreateFailure: ['error'],
  membershipInviteResendFailure: ['error']
})
export const MembershipInviteTypes = Types
export default Creators
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  fetchingOne: false,
  fetchingAll: false,
  updating: false,
  deleting: false,
  creating: false,
  resending: false,

  membershipInvite: null,
  membershipInvites: null,
  resendMembershipInvites: null,

  errorOne: null,
  errorAll: null,
  errorUpdating: null,
  errorDeleting: null,
  errorCreating: null,
  errorResending: null
})
/* ------------- Reducers ------------- */
// request the data from an api
export const request = (state) => {
  return {
    ...state,
    fetchingOne: true,
    membershipInvite: null
  }
}
// request the data from an api
export const allRequest = (state) => {
  return {
    ...state,
    fetchingAll: true,
    membershipInvites: null
  }
}
// request to create from an api
export const createRequest = (state) => {
  return {
    ...state,
    creating: true,
    membershipInvites: null,
    errorCreating: null
  }
}
// request to update from an api
export const updateRequest = (state) => {
  return {
    ...state,
    updating: true,
    errorUpdating: null
  }
}
// request to delete from an api
export const deleteRequest = (state) => {
  return {
    ...state,
    deleting: true,
    errorDeleting: null
  }
}

// request to resend from an api
export const resendRequest = (state) => {
  return { ...state, resending: true, errorResending: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
  const { membershipInvite } = action
  return {
    ...state,
    fetchingOne: false,
    errorOne: null,
    membershipInvite
  }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
  const { membershipInvites } = action
  return {
    ...state,
    fetchingAll: false,
    errorAll: null,
    membershipInvites
  }
}
// successful api create
export const createSuccess = (state, action) => {
  const { membershipInvite } = action
  return {
    ...state,
    creating: false,
    errorCreating: null,
    membershipInvite
  }
}
// successful api update
export const updateSuccess = (state, action) => {
  const { membershipInvite } = action
  return {
    ...state,
    updating: false,
    errorUpdating: null,
    membershipInvite
  }
}
// successful api delete
export const deleteSuccess = (state) => {
  return {
    ...state,
    deleting: false,
    errorDeleting: null,
    membershipInvite: null
  }
}

//successful api resend
export const resendSuccess = (state) => {
  return {
    ...state, resending: false,
    errorResending: null,
    resendMembershipInvites: null
  }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action
  return {
    ...state,
    fetchingOne: false,
    errorOne: error,
    membershipInvite: null
  }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    fetchingAll: false,
    errorAll: error,
    membershipInvites: null
  }
}
// Something went wrong creating.
export const createFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    creating: false,
    errorCreating: error,
    membershipInvite: state.membershipInvite
  }
}
// Something went wrong updating.
export const updateFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    updating: false,
    errorUpdating: error,
    membershipInvite: state.membershipInvite
  }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
  const { error } = action
  return {
    ...state,
    deleting: false,
    errorDeleting: error,
    membershipInvite: state.membershipInvite
  }
}

// Something went wrong resending.
export const resendFailure = (state, action) => {
  const { error } = action
  return { ...state, errorResending: error, resendMembershipInvites: null, resending: false }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MEMBERSHIP_INVITE_REQUEST]: request,
  [Types.MEMBERSHIP_INVITE_ALL_REQUEST]: allRequest,
  [Types.MEMBERSHIP_INVITE_UPDATE_REQUEST]: updateRequest,
  [Types.MEMBERSHIP_INVITE_CREATE_REQUEST]: createRequest,
  [Types.MEMBERSHIP_INVITE_DELETE_REQUEST]: deleteRequest,
  [Types.MEMBERSHIP_INVITE_RESEND_REQUEST]: resendRequest,

  [Types.MEMBERSHIP_INVITE_SUCCESS]: success,
  [Types.MEMBERSHIP_INVITE_ALL_SUCCESS]: allSuccess,
  [Types.MEMBERSHIP_INVITE_UPDATE_SUCCESS]: updateSuccess,
  [Types.MEMBERSHIP_INVITE_CREATE_SUCCESS]: createSuccess,
  [Types.MEMBERSHIP_INVITE_DELETE_SUCCESS]: deleteSuccess,
  [Types.MEMBERSHIP_INVITE_RESEND_SUCCESS]: resendSuccess,

  [Types.MEMBERSHIP_INVITE_FAILURE]: failure,
  [Types.MEMBERSHIP_INVITE_ALL_FAILURE]: allFailure,
  [Types.MEMBERSHIP_INVITE_UPDATE_FAILURE]: updateFailure,
  [Types.MEMBERSHIP_INVITE_CREATE_FAILURE]: createFailure,
  [Types.MEMBERSHIP_INVITE_DELETE_FAILURE]: deleteFailure,
  [Types.MEMBERSHIP_INVITE_RESEND_FAILURE]: resendFailure
})
