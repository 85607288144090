import { call, put } from "redux-saga/effects";
import ProfileActions from "../actions/profile-actions";
import * as api from "../../Service/api";

// attempts to account
export function* getProfile() {
  const response = yield call(api.getProfile);

  // success?
  if (response.ok) {
    console.log("Profile - OK");
    // console.log('get profile response:',response)
    yield put(ProfileActions.profileSuccess(response.data));
  } else {
    console.log("Profile - FAIL");
    yield put(ProfileActions.profileFailure("WRONG"));
  }
}

// attempts to update profile settings
export function* updateProfile(action) {
  const { profile } = action;
  // console.log(action, profile)
  // const apiCall = call(api.updateProfile, profile);
  const response = yield call(api.updateProfile, profile);

  // success?
  if (response.ok) {
    console.log("ProfileUpdate - OK");
    // console.log('response:',response)
    yield put(ProfileActions.profileUpdateSuccess(response.data));
  } else {
    console.log("ProfileUpdate - FAIL");
    yield put(ProfileActions.profileUpdateFailure("WRONG"));
  }
}
