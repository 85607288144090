import { call, put } from 'redux-saga/effects'
import WalletActions from './wallet.reducer'
import * as api from "../../../Service/api";

export function* getWallet(action) {
  const { walletId } = action
  // make the call to the api
  const response = yield call(api.getWallet, walletId)

  // success?
  if (response.ok) {
    yield put(WalletActions.walletSuccess(response.data))
  } else {
    yield put(WalletActions.walletFailure(response.data))
  }
}
export function* getGlobalWallet(action) {
  const { businessId } = action
  // make the call to the api
  const response = yield call(api.getGlobalWallet, businessId)

  // success?
  if (response.ok) {
    yield put(WalletActions.globalWalletSuccess(response.data))
  } else {
    yield put(WalletActions.globalWalletFailure(response.data))
  }
}

export function* favouriteWallets(action) {
  const { businessId } = action
  // make the call to the api
  const response = yield call(api.getFavouriteWallets, businessId)

  // success?
  if (response.ok) {
    yield put(WalletActions.favouriteWalletsSuccess(response.data))
  } else {
    yield put(WalletActions.favouriteWalletsFailure(response.data))
  }
}

export function* getWalletStatistics(action) {
  const { businessId , options} = action
  // make the call to the api
  const response = yield call(api.getWalletStatistics, businessId, options)
  // success?
  if (response.ok) {
    yield put(WalletActions.walletStatisticsSuccess(response.data))
  } else {
    yield put(WalletActions.walletStatisticsFailure(response.data))
  }
}

export function* favouriteWallet(action) {
  const { id } = action
  // make the call to the api
  const response = yield call(api.favouriteWallet, id)

  // success?
  if (response.ok) {
    yield put(WalletActions.favouriteWalletSuccess(response.data))
  } else {
    yield put(WalletActions.favouriteWalletFailure(response.data))
  }
}

export function* unfavouriteWallet(action) {
  const { id } = action
  // make the call to the api
  const response = yield call(api.unfavouriteWallet, id)

  // success?
  if (response.ok) {
    yield put(WalletActions.unfavouriteWalletSuccess(response.data))
  } else {
    yield put(WalletActions.unfavouriteWalletFailure(response.data))
  }
}

export function* setSelections(action) {
  yield put(WalletActions.setWalletSelections(action))
}

export function* resetSelections(action) {
  yield put(WalletActions.resetWalletSelections())
}

export function* setSources(action) {
  yield put(WalletActions.setWalletSources(action))
}

export function* resetSources(action) {
  yield put(WalletActions.resetWalletSources())
}
