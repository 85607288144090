import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { CountryTypes } from "../actions/country-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOne: null,
    fetchingAll: null,
    updating: null,
    deleting: null,
    country: null,
    countries: null,
    errorOne: null,
    errorAll: null,
    errorUpdating: null,
    errorDeleting: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => { return { ...state, fetchingOne: true, country: null } }


// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, countries: null } }


// request to update from an api
export const updateRequest = (state) => { return { ...state, updating: true } }

// request to delete from an api
export const deleteRequest = (state) => { return { ...state, deleting: true } }

// successful api lookup for single entity
export const success = (state, action) => {
    const { country } = action
    return { ...state, fetchingOne: false, errorOne: null, country }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { countries } = action
    return { ...state, fetchingAll: false, errorAll: null, countries }
}
// successful api update
export const updateSuccess = (state, action) => {
    const { country } = action
    return { ...state, updating: false, errorUpdating: null, country }
}
// successful api delete
export const deleteSuccess = (state) => {
    return { ...state, deleting: false, errorDeleting: null, country: null }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOne: false, country: null, errorOne: error }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, countries: null, errorAll: error }
}
// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action
    return { ...state, updating: false, country: state.country, errorUpdating: error }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
    const { error } = action
    return { ...state, deleting: false, country: state.country, errorDeleting: error }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [CountryTypes.COUNTRY_REQUEST]: request,
    [CountryTypes.COUNTRY_ALL_REQUEST]: allRequest,
    [CountryTypes.COUNTRY_UPDATE_REQUEST]: updateRequest,
    [CountryTypes.COUNTRY_DELETE_REQUEST]: deleteRequest,

    [CountryTypes.COUNTRY_SUCCESS]: success,
    [CountryTypes.COUNTRY_ALL_SUCCESS]: allSuccess,
    [CountryTypes.COUNTRY_UPDATE_SUCCESS]: updateSuccess,
    [CountryTypes.COUNTRY_DELETE_SUCCESS]: deleteSuccess,

    [CountryTypes.COUNTRY_FAILURE]: failure,
    [CountryTypes.COUNTRY_ALL_FAILURE]: allFailure,
    [CountryTypes.COUNTRY_UPDATE_FAILURE]: updateFailure,
    [CountryTypes.COUNTRY_DELETE_FAILURE]: deleteFailure
})
