import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    signupUpdateRequest: ['signup'],
    signupUpdateSuccess: ['business'],
    signupUpdateFailure: ['error']
})

export const SignupTypes = Types
export default Creators
