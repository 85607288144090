import React, { useEffect, useCallback } from 'react'
import Logo from "../assets/images/Reveknew_Logo_Colour_and_Black.png";
import img2 from '../assets/images/safe_lock.png'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from "primereact/button";
import DefMembershipActions from "../Redux/actions/defMembership-actions";


const NoAccess = () => {
    const { t } = useTranslation();
    const history = useHistory()

    const defMembership = useSelector((state) => state.defMembership.defMembership);

    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])

    useEffect(() => {
        if (!defMembership) {
            getMembership()
        }
        // eslint-disable-next-line
    }, [])

    const role = ["VIEWER", "MANAGER"].includes(defMembership?.role)

    const handlePush = () => {
        if (role) {
            history.push('/subscribers')
        } else {
            history.push('/home')
        }
    }

    return <div className="">
        <div className="access-box">
            <div className="" style={{ margin: "20px 0px 0px 10px" }}>
                <img src={Logo} alt="Reveknew-Logo" width={150} />
            </div>
            <div className="mt-4 mb-5" >
                <h1 className="text-dark font-bold" style={{ textAlign: "center", fontFamily: "Roboto-Bold" }} >{t("common.access_denied_header")}</h1>
                <h3 className="mb-3 text-muted font-large" style={{ textAlign: "center", fontFamily: "Roboto-Bold", marginBottom: "50px" }}>{t("common.access_denied_detail")}</h3>
            </div>
            <div style={{ justifyContent: 'center', alignItems: "center", margin: 'auto', width: '50%' }}>
                <img src={img2} alt={""} height={450} /> <br />
            </div>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Button className='p-button-info' icon='pi pi-chevron-left' onClick={() => handlePush()} label={role === true ? t("common.back_to_subscribers") : t("common.back")} />
            </div>
        </div>
    </div>;
}
export default NoAccess;
