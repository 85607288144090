import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { ProfileTypes } from "../actions/profile-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  profile: null,
  error: null,
  fetching: false,
  updating: false,
  update:null,
  updated:false,
  errorUpdating:null
});

/* ------------- Reducers ------------- */

// we're attempting to fetch profile
export const request = (state) => {
  return { ...state, fetching: true };
};

// we've successfully logged in
export const success = (state, data) => {
  const { profile } = data;
  return { ...state, fetching: false, error: null, profile };
};

// we've had a problem getting the profile
export const failure = (state, { error }) => {
  return { ...state, fetching: false, updating: false, profile: null, error };
};

// we're attempting to updating profile settings
export const updateRequest = (state) => {
  return { ...state, updating: true, updated:false, update:null, errorUpdating:null };
};

// we've successfully updated the profile settings
export const updateSuccess = (state, data) => {
  const { profile } = data;
  return { ...state, error: null, updating: false, update:profile, updated:true, errorUpdating:null };
};

// we've had a problem updating the profile settings
export const updateFailure = (state, { error }) => {
  return { ...state, updating: false, errorUpdating:error, updated: false, update: null };
};
// we're attempting to reset the profile
export const profileResetSuccess = (state) => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ProfileTypes.PROFILE_REQUEST]: request,
  [ProfileTypes.PROFILE_SUCCESS]: success,
  [ProfileTypes.PROFILE_FAILURE]: failure,
  [ProfileTypes.PROFILE_UPDATE_REQUEST]: updateRequest,
  [ProfileTypes.PROFILE_UPDATE_SUCCESS]: updateSuccess,
  [ProfileTypes.PROFILE_UPDATE_FAILURE]: updateFailure,
  [ProfileTypes.PROFILE_RESET_SUCCESS]: profileResetSuccess,
});
