import React from 'react'
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';

const SummaryCards = ({
    style, title, value, subDesc, cardInfo,

}) => {

    const titleValue = (val, title) => {
        if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && val) {
            return "";
        }
        else if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && !val) {
            return "";
        }
        else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && val) {
            return val
        }
        else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && !val) {
            return 0
        }
    }
    return (
        <div style={{ marginRight: "1.5rem" }}
            className="p-col-12 p-lg-6 p-xl-3 reports-card-content " id='reports-card-content'
        >
            <div>
                <div className='grid' style={{ marginTop: "5px" }}>
                    <div className='col-10'> <span className={`card-titles + ${style}`}>{title}</span></div>
                    <Tooltip target=".custom-target-icon" />
                    <div className='col-2'>
                        {cardInfo && <i className='custom-target-icon pi pi-info-circle'
                            style={{ cursor: "pointer" }}
                            data-pr-tooltip={cardInfo}>
                        </i>}
                    </div>
                    <div className="summary-card-info">
                        <span className="summary-card-text">{titleValue(value, title)}</span>
                        <span className="summary-card-subDesc">{subDesc} </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductSummaryCards = ({ style, title, cardInfo, type,
    subsNewValue, subtitleNew, subsActiveValue, subtitleActive, subtitleCompleted,
    subsCompletedValue, subsCancelledValue, subtitleCancelled, subsPausedValue, subtitlePaused,
    missedValue, missedSubDesc, revenueValue, revenueSubDesc, collectedValue, collectedSubDesc,
    loading, subsActiveDesc, subsPausedDesc, subsNewDesc, subsCancelledDesc, subsCompletedDesc,
    processedWithdrawalValue, reversedWithdrawalValue, pendingWithdrawalValue, reversedWithdrawalDesc, pendingWithdrawalDesc, processedWithdrawalDesc
}) => {
    return (
        <div style={{ marginRight: "1.5rem" }}
            className="p-col-12 p-lg-6 p-xl-3 products-report-content"
            id='reports-card-content'
        >
            <div>
                <div className='grid' style={{ marginTop: "5px" }}>
                    <div className='col-10'> <span className={`card-titles + ${style}`}>{title}</span></div>
                    <Tooltip target=".custom-target-icon" />
                    <div className='col-2'>
                        {cardInfo && <i className='custom-target-icon pi pi-info-circle'
                            style={{ cursor: "pointer" }}
                            data-pr-tooltip={cardInfo}>
                        </i>}
                    </div>
                </div>
            </div>
            {type === "subs" && <div className="summary-card-info">
                <div className='grid'>
                    <div className='col-6'>
                        <div className="column-view" style={{ marginBottom: "1.5rem" }}>
                            <span className="green-success-txts">{subtitleNew}</span>
                            <div className="subs-flex-row">
                                <span className="subsValues">{subsNewValue}</span>&nbsp;
                                <span className="subsTitle">{subsNewDesc}</span>
                            </div>
                        </div>

                        <div className="column-view" style={{ marginBottom: "1.5rem" }}>
                            <span className="yellow-progress-txts">{subtitlePaused}</span>
                            <div className="subs-flex-row">
                                <span className="subsValues">{subsPausedValue}</span>&nbsp;
                                <span className="subsTitle">{subsPausedDesc}</span>
                            </div>
                        </div>

                        <div className="column-view">
                            <span className="red-cancelled-txts">{subtitleCancelled}</span>
                            <div className="subs-flex-row">
                                <span className="subsValues">{subsCancelledValue}</span>&nbsp;
                                <span className="subsTitle">{subsCancelledDesc}</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-6'>
                        <div className="column-view" style={{ marginBottom: "1.5rem" }}>
                            <span className="green-success-txts">{subtitleActive}</span>
                            <div className="subs-flex-row">
                                <span className="subsValues">{subsActiveValue}</span>&nbsp;
                                <span className="subsTitle">{subsActiveDesc}</span>
                            </div>
                        </div>

                        <div className="column-view">
                            <span className="grey-completed-txt">{subtitleCompleted} </span>
                            <div className="subs-flex-row">
                                <span className="subsValues">{subsCompletedValue}</span>&nbsp;
                                <span className="subsTitle">{subsCompletedDesc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            {type === "pays" && <div className="summary-card-info">
                <div className="grid">
                    <div className="col-6">
                        <div >
                            <span className="green-success-txts">{collectedSubDesc}</span>
                            <span>{collectedValue}</span>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                            <span className="red-cancelled-txts">{missedSubDesc}</span>
                            <span>{missedValue}</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <span className="blue-revenue-txt">{revenueSubDesc}</span>
                        <span>{revenueValue}</span>
                    </div>
                </div>
            </div>
            }
            {type === "width" && <div className="summary-card-info">
                <div className="grid">
                    <div className="col-6">
                        <div>
                            <span className="green-success-txts">{processedWithdrawalDesc}</span>
                            <span>{processedWithdrawalValue}</span>
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <span className="red-cancelled-txts">{reversedWithdrawalDesc}</span>
                            <span>{reversedWithdrawalValue}</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                            <span className="yellow-progress-txts">{pendingWithdrawalDesc}</span>
                            <span>{pendingWithdrawalValue}</span>
                        </div>
                    </div>
                </div>
            </div>}
            {
                loading ? <div className="card flex justify-content-center">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                </div> :
                    <></>
            }
        </div >
    )
}


export const SummarySection = ({ summary, header = "", loading, }) => {
    return (
        <>
            <span className="wallet-titles summary-title">{header}</span>
            <div className='report_cards' style={{ marginBottom: "1rem", padding: "1rem" }}>
                {
                    summary.map((summaryContent, i) => (
                        <SummaryCards{...summaryContent} loading={loading} key={i} />
                    ))
                }
            </div>
        </>
    )
}

export const ProductsSummarySection = ({ summary, header = "", loading, }) => {
    return (
        <>
            <span className="wallet-titles summary-title">{header}</span>
            <div className='report_cards' style={{ marginBottom: "1rem", padding: "1rem" }}>
                {
                    summary.map((summaryContent, i) => (
                        <ProductSummaryCards{...summaryContent} loading={loading} key={i} />
                    ))
                }
            </div>
        </>
    )
}
export default SummaryCards;