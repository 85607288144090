import { setAuthToken } from "./Service/api";

const getMeta =async (getAccessTokenSilently,callbackFun, catchErr ) => {
    
    try {
        await getAccessTokenSilently()
            .then((data) => {
                setAuthToken(data);
                if(!data){
                    window.location.replace('/')
                }
                console.log('dataIn');
                
            })
            .then(() => {
             callbackFun()

            })
 
            
    } catch (e) {
       if(e.message === "Login required"){
        window.location.replace('/')
           catchErr()
           
       }
        // console.log('login error', e.message);
       
    } 
};

export default getMeta;