import { call, put } from 'redux-saga/effects'
import ReportActions from './report.reducer'
import * as api from "../../../Service/api";

export function* subscribeReport(action) {
  const { businessId, body } = action
  // make the call to the api
  const response = yield call(api.subscribeReport, businessId, body)

  // success?
  if (response.ok) {
    yield put(ReportActions.subscriptionReportSuccess(response.data))
  } else {
    yield put(ReportActions.subscriptionReportFailure(response.data))
  }
}
export function* transactionCustomReport(action) {
  const { businessId, body } = action
  // make the call to the api
  const response = yield call(api.getTransactionCustomReport, businessId, body)

  // success?
  if (response.ok) {
    yield put(ReportActions.transactionCustomReportSuccess(response.data))
  } else {
    yield put(ReportActions.transactionCustomReportFailure(response.data))
  }
}
