import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import CustomDataTable from "../../../Components/CustomDataTable";
import moment from "moment";

function ProductAccountsTable(props) {
    const { showWarn, getUnfavouriteWallet, getFavouriteWallet, favouritingWallet, unfavouritingWallet, walletsData,
        loading, state, setState, planGroups, walletStatistics, defMembership, setWalletName } = props;

    const history = useHistory();
    const { t } = useTranslation("common");
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    useEffect(() => {
        if (planGroups) {
            setState((state) => ({ ...state, planGroups: planGroups }))
        }
        if (walletStatistics) {
            setState((state) => ({ ...state, walletStatistics: walletStatistics }))
        }
        initFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
            },
        });
        setGlobalFilterValue('');
    };

    const routeTo = (data) => {
        if (data?.available < 0.5) {
            showWarn()
        }
        else {
            history.push({ pathname: "/money/send", search: "walletId=" + data.groupId })
        }
    }

    const route = (data) => {
        history.push({ pathname: "/money/product_accounts/withdrawalsList", state: data, search: "walletId=" + data.groupId })
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="name_template">
                {
                    rowData.name ?
                        <span className="wallet-name" >{rowData.name !== null ? rowData.name : "--"}</span> : null
                }{
                    rowData.description ?
                        <span className="wallet-name-description"  >{rowData.description !== null ? rowData.description : "--"}</span> : null
                }
            </div>
        }
    }

    const balanceBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="balance_detail_template">
                <div className="bal_main_div">
                    <span className="balance_label">{t("Balance")}{":"}</span>
                    <span className="balance_amt">{defMembership?.business.currency} {rowData.balance}</span>
                </div>
                <div className="avail_main_div">
                    <span className="available_label">{t("Available")}{":"}</span>
                    <span className="avail_span"><span className="available_amt">{defMembership?.business.currency} {rowData.available}</span></span>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span className="pending_label" >{t("Pending")}{":"}</span>
                    <span className="pending_span"><span className="pending_amt">
                        {defMembership?.business.currency} {rowData.pendingWthdlAmount.toFixed(2)}</span></span>
                </div>
            </div>
        }
    }

    const balanceDetailTemplate = (rowData) => {
        return <div className="balance_template withdraw">
            <div className="withdraws"> {rowData?.requestedWthdlCount || 0} {rowData?.requestedWthdlCount > 1 ? <span>{t("product_account.withdrawals")}</span> :
                <span>{t("product_account.no_withdrawal")}</span>}
            </div>
            {<div style={{ display: "flex", flexDirection: "row" }}>{rowData.lastSettlement?.requestedOn && <div className="latest mt-1">
                <span>{t("product_account.latest")}: </span>{moment(rowData.lastSettlement?.requestedOn).format('LL')}</div>}
                {(rowData.lastSettlement?.status === "PENDING") &&
                    <div style={{ marginLeft: "1rem" }}>
                        <span className={`status-pending status-${(rowData.lastSettlement?.status ? rowData.lastSettlement?.status : '')} status-content`}>{rowData.lastSettlement.status}</span>
                    </div>}
                {(rowData.lastSettlement?.status === "PROCESSED") &&
                    <div style={{ marginLeft: "1rem" }}>
                        <span className={`status-active status-${(rowData.lastSettlement?.status ? rowData.lastSettlement.status : '')} status-content`}>{rowData.lastSettlement?.status}</span>
                    </div>}
                {(rowData.lastSettlement?.status === "REVERSED") &&
                    <div style={{ marginLeft: "1rem" }}>
                        <span className={`status-reversed status-${(rowData.lastSettlement?.status ? rowData.lastSettlement?.status : '')} status-content`}>{rowData.lastSettlement?.status}</span>
                    </div>
                }
            </div>}
            {rowData.lastSettlement?.requested && <div className="withdraws-amount">{defMembership?.business.currency} {rowData.lastSettlement?.requested?.toFixed(2)}</div>}
            <div style={{ display: "flex", flexDirection: "row" }}>
                <span className="identifiers">{rowData.lastSettlement?.channel.channelName}</span>
                {rowData.lastSettlement?.channel.channelName === null && <span className="identifiers">{rowData.lastSettlement?.channel.identifier}</span>}
                {rowData.lastSettlement?.channel.channelName && <span className="identifiers_hr" />}
                <span className="identifiers">{rowData.lastSettlement?.channel.systemBank.name}</span>
            </div>
            <div>{rowData.lastSettlement?.user?.name && <div><span className="wallets_requested_by">
                {t("product_account.requested_by")}{":"} </span>
                <span className="requester_name">{rowData.lastSettlement?.user?.name}</span></div>}
            </div>
        </div>
    }

    const favoriteTemplate = (rowData) => {
        if (rowData) {
            return <div className="car-buttons star-buttons" >
                {rowData.favourite ? <Button icon="pi pi-star-fill" className="p-button-rounded" id="wallet-favorite"
                    onClick={() => {
                        getUnfavouriteWallet(rowData.groupId)
                        setState((state) => ({
                            ...state, loadUnFav: true,
                            unfavouritingId: rowData.groupId, favouritingWallet: false, unfavouritingWallet: true
                        }))
                    }}
                    loading={unfavouritingWallet && state.unfavouritingId === rowData.groupId}
                    disabled={unfavouritingWallet && state.unfavouritingId === rowData.groupId}
                    tooltip={t("product_account.unfavourite")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
                    : <Button icon="pi pi-star" className="p-button-rounded p-button-outlined" id="wallet-unfavorite"
                        tooltip={t("product_account.favourite")}
                        onClick={() => {
                            getFavouriteWallet(rowData.groupId)
                            setState((state) => ({
                                ...state, callFav: true,
                                favouritingId: rowData.groupId, unfavouritingWallet: false, favouritingWallet: true
                            }))
                            setWalletName(rowData.name)
                        }}
                        loading={favouritingWallet && state.favouritingId === rowData.groupId}
                        disabled={favouritingWallet && state.favouritingId === rowData.groupId}
                        tooltipOptions={{
                            position: "bottom",
                            mouseTrack: true,
                            mouseTrackTop: 15,
                        }} />
                }
            </div>
        }
    }
    const actionTemplate = (rowData) => {
        if (rowData) {
            return <div className="car-buttons star-buttons">
                <Button icon="pi pi-list" className="p-button p-button-rounded mr-2 wallet-button" id="wallet-button"
                    onClick={() => route(rowData)}
                    tooltip={t("product_account.list_icon")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
                <Button icon="pi pi-send" className="p-button p-button-rounded wallet-button" id="wallet-button" onClick={() => { routeTo(rowData) }}
                    tooltip={t("product_account.send_icon")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
            </div>
        }
    }

    const columns = [
        { field: 'name', body: favoriteTemplate, headerClassName: "wallet_headers" },
        { field: 'name', body: nameBodyTemplate, header: t("product_account.product_account_info"), headerClassName: "wallet_headers", className: "nameBodyTemplate" },
        { field: "balance", body: balanceBodyTemplate, header: t("product_account.balance"), headerClassName: "wallet_headers balanceBodyTemplateHeader" },
        { field: "details", body: balanceDetailTemplate, header: t("product_account.withdrawals"), headerClassName: "wallet_headers" },
        { field: "cost", body: actionTemplate, headerClassName: "wallet_headers" },
    ]

    return (
        <>
            <div>
                <h6 className="wallet-titles">{t("product_account.product_accounts")}</h6>
                <CustomDataTable
                    loading={loading}
                    header={() =>
                        <div className="plans-tabs-btn w-full align-items-start flex">
                            <div className="p-grid p-fluid activity-actions-container p-input-icon-right">
                                <InputText
                                    type="text"
                                    placeholder={t('product_account.search')}
                                    value={globalFilterValue}
                                    onChange={onGlobalFilterChange}
                                    id="wallet_name_search"
                                ></InputText>
                                {globalFilterValue ? <Button type="submit" onClick={clearFilter} icon="pi pi-times" id="withdrwals_cancel_btn" className="p-button-danger p-button-text buttons" /> :
                                    <i className="pi pi-search p-button-info p-button-text buttons" />}
                            </div>
                        </div>
                    }
                    paginator
                    rows={15}
                    columns={columns}
                    filters={filters}
                    globalFilterFields={["name"]}
                    value={walletsData()} />
            </div>
        </>
    )
}
export default ProductAccountsTable;