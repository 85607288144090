/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import MembershipInviteActions from "../../Redux/transition/membership-invite/membership-invite.reducer";
import MembershipActions from "../../Redux/transition/membership/membership.reducer";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import ColleaguesInvited from "./ColleaguesInvited";
import ColleaguesList from "./ColleaguesList"
import "../../App.css";
import "../../Styles/pages/colleagues.scss";



//let mount = false;
const Colleagues = () => {
  const { t } = useTranslation("common");
  const [activeIndex, setActiveIndex] = useState(0);

  //Redux State from Store

  const defMembership = useSelector((state) => state.defMembership.defMembership);

  const errorInvite = useSelector((state) => state.membershipInvites.errorCreating);
  const creatingInvite = useSelector((state) => state.membershipInvites.creating);

  //Redux Dispatch Actions
  const dispatch = useDispatch();
  const createMembershipInvite = (membershipInvite) => {
    dispatch(
      MembershipInviteActions.membershipInviteCreateRequest(membershipInvite)
    );
  };

  const fetchAllInvitedMembers = useCallback((businessId, options) => {
    dispatch(
      MembershipInviteActions.membershipInviteAllRequest(businessId, options)
    );
  }, [dispatch])

  const getDefMembership = useCallback(() => {
    dispatch(DefMembershipActions.defMembershipRequest());
  }, [dispatch])

  const getMembership = useCallback((businessId) => {
    dispatch(MembershipActions.membershipAllRequest(businessId))
  }, [dispatch])

  const [state, setState] = useState({ creating: false, success: null, sort: 'invitedOn,desc' });
  const [displayBasic, setDisplayBasic] = useState(false);
  const toast = useRef(null);
  const roleService = [
    { roleName: t('colleague.manager'), code: "MANAGER" },
    { roleName: t('colleague.viewer'), code: "VIEWER" },
    { roleName: t('colleague.admin'), code: "ADMIN" },
  ];

  useEffect(() => {
    if (!defMembership) {
      getDefMembership();
    }

    if (defMembership) {
      getMembership(defMembership.business.id)
      fetchAllInvitedMembers(defMembership.business.id, { sort: state.sort })
    }
  }, [])

  useEffect(() => {
    if (state.creating && !creatingInvite) {
      if (errorInvite) {
        showError();
        setState((state) => ({ ...state, creating: false }))
      } else {
        showSuccess();
        setState((state) => ({ ...state, creating: false }))
      }
    }
  }, [creatingInvite, state.creating, defMembership, errorInvite]);

  const formik = useFormik({
    initialValues: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      inviteRole: null,
    },

    validate: (data) => {
      let errors = {};

      if (!data.firstName) {
        errors.firstName = t('colleague.firstName_error');
      }

      if (!data.lastName) {
        errors.lastName = t('colleague.lastName_error');
      }
      if (!data.inviteRole) {
        errors.inviteRole = t('user_profile.role_error');
      }

      if (!data.email) {
        errors.email = t('colleague.email_error');
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t('colleague.email_invalid');
      }
      return errors;
    },

    onSubmit: (data) => {
      const inviteData = {
        id: data.id || null,
        firstName: data.firstName || null,
        lastName: data.lastName || null,
        email: data.email || null,
        inviteRole: data.inviteRole?.code,
        invitedBy: { id: defMembership.id },
        business: { id: defMembership.business.id },
        invitedOn: new Date(),
        status: "PENDING",
      };

      createMembershipInvite(inviteData);
      setState((state) => ({ ...state, creating: true }))
    },
  });


  // Toast Success
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: t('colleague.success'),
      detail: t('colleague.success_detail'),
      life: 3000,
    });
  }

  // Toast Error
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: t('colleague.error'),
      detail: errorInvite.status === 500 ? errorInvite.title : t('colleague.invite_error'),
      life: 3000,
    });
  }

  const items = [
    { label: t('colleague.colleagues'), className: "tab  tab1" },
    { label: t('colleague.invited'), className: "tab  edge-tab-end" },
  ];
  /* FORMIK */

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const toggleDialogDisplay = () => {
    setDisplayBasic(!displayBasic);
  };
  /* FORMIK */

  return (
    <div>
      <div>
        <Button
          label={t('colleague.invite_colleague')}
          icon="pi pi-plus-circle"
          style={{ float: "right" }}
          className="send_button"
          onClick={toggleDialogDisplay}
          severity="info"

        />
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="colleague-tab"
        />

        <div className="card inviteTable" style={{ padding: "0" }}>

          <Dialog
            header={t('colleague.invite')}
            visible={displayBasic}
            style={{ width: "370px" }}
            onHide={toggleDialogDisplay}
            className="tier_dialog"
            id="tier_dialog"
          >
            <div style={{ padding: "1.5rem" }}>

              <Toast
                className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
                ref={toast} onRemove={(message) => {
                  if (message.severity === 'success') {
                    formik.resetForm()
                    fetchAllInvitedMembers(defMembership.business.id, { sort: state.sort })
                    toggleDialogDisplay()
                  }
                }}
              />
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div>
                  <InputText
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    autoFocus
                    style={{ height: "3rem", marginBottom: "0.5rem" }}
                    placeholder={t("colleague.firstName")}
                    className={classNames({
                      "p-invalid": isFormFieldValid("firstName"),
                    })}
                    maxLength={50}
                  />
                  <label htmlFor="firstName"></label>
                  {getFormErrorMessage("firstName")}
                </div>

                <div style={{ marginTop: "1.5rem" }}>
                  <InputText
                    id="lastName"
                    name="lastName"

                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    style={{ height: "3rem", marginBottom: "0.5rem" }}
                    placeholder={t("colleague.lastName")}
                    className={classNames({
                      "p-invalid": isFormFieldValid("lastName"),
                    })}
                    maxLength={50}
                  />
                  {getFormErrorMessage("lastName")}
                </div>
                <div style={{ marginTop: "1.5rem" }}>
                  <InputText
                    id="email"
                    name="email"

                    value={formik.values.email}
                    onChange={formik.handleChange}
                    style={{ height: "3rem", marginBottom: "0.5rem" }}
                    placeholder={t('colleague.email')}
                    className={classNames({
                      "p-invalid": isFormFieldValid("email"),
                    })}
                    maxLength={50}
                  />
                  {getFormErrorMessage("email")}
                </div>

                <div className="inviteRole" style={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
                  <Dropdown
                    id="inviteRole"

                    name="inviteRole"
                    value={formik.values.inviteRole}
                    onChange={(e) =>
                      formik.setFieldValue("inviteRole", e.value)
                    }
                    style={{ height: "3rem", marginBottom: "0.5rem" }}
                    options={roleService}
                    optionLabel="roleName"
                    placeholder={t('colleague.select_role')}
                    className={classNames({
                      "p-invalid": isFormFieldValid("firstName"),
                    })}
                  />
                  {getFormErrorMessage("inviteRole")}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="submit" style={{ width: "fit-content" }} className="p-mt-2 colleague_invite" label={t('colleague.invite_button')} loading={creatingInvite} />
                </div>

              </form>
            </div>
          </Dialog>


          {activeIndex === 0 && <ColleaguesList />}
          {activeIndex === 1 && <ColleaguesInvited />}
        </div>
      </div>
    </div>
  );
};

export default (Colleagues);
