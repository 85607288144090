import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
//Redux actions import
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
//primereact imports
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { TreeSelect } from 'primereact/treeselect';
import { useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { classNames } from "primereact/utils";
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
//Other imports
import moment from "moment";
import "../../Styles/components/sti.css";
import "../../Styles/pages/invitation.scss"
import Loading from "../../Components/Loading";

const MoveSubscriber = (props) => {
    //redux state and dispatch to props + props from parent component
    const { visible, onHide, sourceTier, } = props
    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const business = useSelector((state) => state.businesses.business)
    const migrating = useSelector((state) => state.plans.migrating)
    const migrateSuccess = useSelector((state) => state.plans.migrateSuccess)
    const migrateError = useSelector((state) => state.plans.errorMigrating)
    const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive)

    const dispatch = useDispatch()
    const moveSubscribers = useCallback((migratedData) => { dispatch(PlanActions.planMigrateRequest(migratedData)) }, [dispatch])
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])

    const { t } = useTranslation("common");
    const toast = useRef(null);
    const toastConfirm = useRef(null);
    const [moving, setMoving] = useState(false);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [state, setState] = useState({
        selectedNodeKey: null,
        inviteData: null,
        checking: false,
        checkingExclusive: false,
        success: false,
        requesting: false,
        input: "",
        visible: false,
        hide: true,
        defMembership: null,
        confirmVisible: false,
        desireVisible: false,
        selectOptions: [],
        sort: 'requestedOn,desc',
        size: 10,
        generating: false,
        plan_tier: null,
        newData_group: null,
        exclusivity: null,
        key: 0 - 1,
        exists: false,
        migrateData: null,
        sourceName: null,
        targetName: null,
    });

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if (planGroupsGraphedActive) {
            const planGroupAssigned = planGroupsGraphedActive.groups.map((groupItem, ind) => {
                const {
                    group: { id },
                    group,
                    plans,
                } = groupItem;

                // Filter the plans to include only those with archivedBy === null
                const filteredPlans = plans
                    ? plans.filter(planItem => planItem.archivedBy === null && planItem.policy !== "SUBSCRIPTION")
                    : [];

                // Filter the children to include only those with archivedBy === null in their data object
                const filteredChildren = filteredPlans.map((planItem, idx) => ({
                    key: `${ind}-${idx}`,
                    label: planItem.name,
                    data: {
                        ...planItem,
                        archivedBy: null,
                    },
                }));

                return {
                    key: id,
                    label: group.name,
                    selectable: false,
                    data: group,
                    children: filteredChildren,
                };
            });

            // Filter the main array to include only those with archivedBy === null in their data object
            const filteredPlanGroupAssigned = planGroupAssigned.filter(item => item.data.archivedBy === null && item.data.policy !== "SUBSCRIPTION");

            setState(prev => ({
                ...prev,
                selectOptions: filteredPlanGroupAssigned,
            }));
        }
    }, [planGroupsGraphedActive]);
    const notWantedId = sourceTier?.data.id

    const filteredGroups = state.selectOptions.map((group) => {
        // If the group has children, filter out the unwanted plan
        const children = group.children?.filter((child) => child.data.id !== notWantedId);
        return {
            ...group,
            children
        };
    }).filter((group) => {
        // Filter out groups that have no children or are not matching the unwanted plan ID
        return group.children && group.children.length > 0;
    });

    useEffect(() => {
        if (moving && !migrating && migrateSuccess) {
            showSuccess()
        }
        if (moving && !migrating && migrateError) {
            showError(migrateError)
            setMoving(false)
        }

        if (sourceTier) {
            Object.assign(sourceTier, { label: sourceTier.data.name, key: `${0}-${0}` })
            setState((state) => ({ ...state, selectedNodeKey: sourceTier }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moving, migrating, migrateError, migrateSuccess, sourceTier, state.confirmVisible,])
    const showSuccess = () => {
        toast.current.show({
            severity: 'success', summary: t("invitation.success_header"),
            detail: t("migration.success_detail"), life: 3000, id: 5
        });
    }

    const showError = (data) => {
        const message = data?.message
        toast.current.show({ severity: 'error', summary: t("invitation.invitation_error_header"), detail: message ? message : t("migration.error_detail"), life: 3000, id: 2 });
    }

    const resetForm = () => {
        formik.resetForm();
        setState((state) => { return { ...state, checking: false, requesting: false, selectedNodeKey: null } })
    }

    const acceptMigrate = () => {
        toogleInviteSubscriber()
        moveSubscribers(state.migrateData)
        setMoving(true)
    }

    const hideExclusivity = () => {
        setState({ ...state, checking: false, success: false, requesting: false, desireVisible: !state.desireVisible })
    }

    const formik = useFormik({
        initialValues: {
            migratedBy: "",
            source: sourceTier ? sourceTier.data : "",
            target: "",
            startDate: null,
        },

        validate: (data) => {
            let errors = {};
            if (!sourceTier && !data.source) {
                errors.source = t("invitation.plan_error");
            }
            if (!checked && !data.startDate) {
                errors.startDate = t("invitation.date_error")
            }
            if (!data.target) {
                errors.target = t("invitation.plan_error");
            }
            return errors;
        },

        onSubmit: (data) => {

            const id = defMembership.id
            const sourceId = sourceTier ? sourceTier.data.id : data.source.id
            const sourceName = sourceTier.data.name
            const targetId = data.target.id
            const targetName = data.target.name
            const dateSelected = checked ? moment().format("YYYY-MM-DD") : null
            if (sourceId === targetId) {
                setDisplayBasic(true)
            }
            else {
                const migrateData = {
                    migratedBy: { id },
                    source: { id: sourceId },
                    target: { id: targetId },
                    startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : dateSelected,
                };
                setState({ ...state, desireVisible: true, migrateData, sourceName, targetName });
            }
        },
    });

    const toogleInviteSubscriber = () => {
        setState(state => { return { ...state, visible: !state.visible } })
    }

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };


    const selectedData = (selectedNodeKey = state.selectedNodeKey) => {

        if (selectedNodeKey) {
            let selectedIndex = selectedNodeKey?.split("-")
            let sampleData = state.selectOptions[parseInt(selectedIndex[0])].children[parseInt(selectedIndex[1])]
            return sampleData
        }
        return null
    }

    const today = new Date();
    const invalidDates = [today];

    const renderFooter = () => {
        return (
            <div>
                <Button label={t("invitation.close")} icon="pi pi-times" onClick={() => setDisplayBasic(false)} autoFocus />
            </div>
        );
    }

    return (
        <>
            <Dialog className="tier_dialog" id="tier_dialog" style={{ width: '350px' }} visible={state.desireVisible} header={<div className="flex align-items-center">
                <span className="">{t("migration.confirm_header")}</span>
            </div>} onHide={() => hideExclusivity()}
                footer={
                    <div>
                        <Button style={{ color: "#d0021b", fontWeight: "bolder", margin: "0rem" }} icon="pi pi-times" onClick={() => hideExclusivity()} label={t("cancel")} className="p-button-text" />
                        <Button style={{ color: "white", fontSize: "12px", backgroundColor: "#008DD5", border: "none" }} icon="pi pi-check" loading={migrating} disabled={migrating} onClick={() => acceptMigrate()} label={t("confirm")} />
                    </div>
                }
                modal breakpoints={{ '960px': '75vw', '640px': '100vw' }}>

                <div style={{ margin: "1rem 0.5rem 1rem 0.5rem" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{t("migration.migrate_description1")} </span>
                        <span className="migrate_description2">{t("migration.migrate_description2")} </span>
                    </div>

                    <div style={{ marginTop: "1rem" }}><span className="desc_title">{t("migration.current_plan")}</span></div>
                    <div className="tier_name_blue_background">
                        <span className="sourceName">{state.sourceName}</span>
                        {sourceTier?.data.policy === "SCHEDULE" && <div>
                            <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }} className="other_policies">{t("product.scheduled_amount")}</p>
                            <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${sourceTier?.data.billingPeriod.toLowerCase()}`)}</p>
                        </div>}

                        {sourceTier?.data.policy === "SUBSCRIPTION" && <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}><span className="other_policies">
                            {t("product.subscribed_amount")}</span>
                            <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${sourceTier?.data.billingPeriod.toLowerCase()}`)}</p></p>}
                        {!sourceTier?.data.flex ?
                            <p>
                                {sourceTier?.data.cost === null ? "" :
                                    <div>
                                        <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>{defMembership.business.currency} {sourceTier?.data.cost?.toFixed(2)}</p>
                                        <p style={{ color: "#d0021b", fontWeight: "bold" }}>{t(`product.${sourceTier?.data.billingPeriod.toLowerCase()}`)}</p>
                                    </div>
                                }
                            </p> :
                            <div> <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>{t("migration.flex_amount")}</p>
                                <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${sourceTier?.data.billingPeriod.toLowerCase()}`)}</p>
                            </div>}
                    </div>

                    <hr style={{ marginTop: "1rem", backgroundColor: "#ced4da", height: "1px" }} />

                    <div style={{ marginTop: "1rem" }}><span className="desc_title">{t("migration.new_plan")}</span></div>
                    <div className="tier_name_green_background">
                        <span className="targetName">{state.targetName}</span>
                        {formik.values.target.policy === "SCHEDULE" && <div><span style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }} className="other_policies">{t("product.scheduled_amount")}</span>
                            <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${formik.values.target.billingPeriod.toLowerCase()}`)}</p>
                        </div>}
                        {formik.values.target.policy === "SUBSCRIPTION" && <p>
                            <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }} className="other_policies">
                                {t("product.subscribed_amount")}
                            </p>
                            <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${formik.values.target.billingPeriod.toLowerCase()}`)}</p></p>}

                        {!formik.values.target.flex ?
                            <p>
                                {formik.values.target.cost === null ? <span style={{ color: "#256029", marginRight: "0.7rem" }}>{ }</span> :

                                    <div>
                                        <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>{defMembership.business.currency} {formik.values.target.cost?.toFixed(2)}
                                        </p>
                                        <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${formik.values.target?.billingPeriod?.toLowerCase()}`)}</p>
                                    </div>
                                }
                            </p> :
                            <div>
                                <p style={{ color: "black", fontSize: "14px", fontWeight: "bold", marginBottom: "0px" }}>{t("migration.flex_amount")}</p>
                                <p style={{ color: "#d0021b", fontWeight: "bold" }}> {t(`product.${formik.values.target?.billingPeriod?.toLowerCase()}`)}</p>
                            </div>}
                    </div>
                </div>
            </Dialog>

            <Dialog header={t("invitation.warning")} visible={displayBasic} style={{ width: '350px' }} footer={renderFooter} onHide={() => setDisplayBasic(false)}>
                <p>{t("migration.alert")}</p>
            </Dialog>

            <Toast ref={toastConfirm} onRemove={(message) => {
                if (message.severity === 'success') {
                    resetForm()
                }
            }} />

            <Toast ref={toast} onRemove={(message) => {
                if (message.severity === 'success') {
                    onHide()
                    getActivePlanGroups(defMembership.business.id)
                    resetForm();
                    setChecked(false);
                    setMoving(false)
                    setDisplayBasic(false)
                    hideExclusivity()
                }
            }} />

            <div className="p-grid p-nogutter" style={{ textAlign: 'justify', display: 'flex', justifyContent: 'space-between' }}>
                <Dialog header={t("migration.header")} visible={visible} style={{ width: '355px' }} className="tier_dialog" id="tier_dialog" onHide={onHide}>
                    {(!planGroupsGraphedActive || !business || !defMembership) ? <Loading /> :
                        <div style={{ margin: "1rem 0.5rem 0rem 0.5rem" }}>
                            <div className="p-fluid">
                                <span className="desc">{t("migration.move_txt")}</span>
                                <span className="red_txt">{" "}{t("migration.all_subscribers")}{" "}</span>
                                <span className="desc">{t("migration.for")}</span>{" "}
                                <span className="tier_label">{sourceTier?.label}</span>
                                {" "}<span className="desc">{t("migration.to_new_plan")}</span>
                                <span>
                                </span>
                            </div>

                            <hr style={{ marginTop: "1rem", backgroundColor: "#ced4da", height: "1px" }} />

                            <div className="p-fluid">
                                <div style={{ marginBottom: "0.4rem" }}>
                                    <div className="flex_styling">
                                        <span className="migrate_tier_labels">{t("migration.new_plan")}</span>
                                        <span className="isterik">*</span>
                                    </div>
                                </div>

                                {<TreeSelect
                                    id="target"
                                    name="target"
                                    value={state.selectedNodeKey}
                                    options={filteredGroups}
                                    onChange={(e) => {
                                        const selectedNode = selectedData(e.value)
                                        formik.setFieldValue("target", selectedNode.data)
                                        setState((state) => {
                                            return { ...state, selectedNodeKey: e.value }
                                        })
                                    }}
                                    filter
                                    placeholder={t("invitation.plan_select")}
                                    className={classNames({
                                        "p-invalid": isFormFieldValid("target"),
                                    })}>
                                </TreeSelect>
                                }
                                <label
                                    htmlFor="target"
                                    className={classNames({
                                        "p-error": isFormFieldValid("target"),
                                    })}
                                ></label>
                                {getFormErrorMessage("target")}
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", marginTop: "2rem" }}>
                                <span className="migrate_tier_labels">{t("migration.start_date")}</span>
                                <span className="isterik">*</span>
                            </div>

                            <form onSubmit={formik.handleSubmit} className="p-fluid" style={{ display: 'flex', flexDirection: 'column', marginTop: "1rem" }}>
                                <div className="field-checkbox" >
                                    <Checkbox
                                        id="check_box"
                                        inputId="binary"
                                        checked={checked}
                                        onChange={e => setChecked(e.checked)}
                                    />
                                    &nbsp; &nbsp; <label style={{ fontSize: "14px", fontFamily: "Roboto", color: "#000" }} htmlFor="binary">{t("invitation.date_checkbox")}</label>
                                </div>
                                {!checked ?
                                    (<div className="date-content" style={{ marginBottom: "0px" }}>
                                        <div style={{ marginBottom: "0.9rem" }}>
                                            <div className="fiel col-12 md:col-4 " style={{ width: "100%", height: "3rem" }}>
                                                <Calendar
                                                    id="startDate invite-calender "
                                                    name="startDate"
                                                    value={formik.values.startDate}
                                                    /* Disable today's date  */
                                                    disabledDates={invalidDates}
                                                    /* Setting the minimum date to today's date so any date less than today's date can't be selected */
                                                    minDate={today}
                                                    /* Creating a read only input field to prevent entering date manually with keyboard. */
                                                    readOnlyInput
                                                    inputMode=""
                                                    onChange={formik.handleChange} showIcon
                                                    placeholder={t("migration.date_select")}
                                                    className="p-datepicker-header invite-calender datepicker"
                                                />
                                            </div>
                                            <label
                                                htmlFor="startDate"
                                                className={classNames({
                                                    "p-error": isFormFieldValid("startDate"),
                                                })}
                                            ></label>
                                        </div>
                                        {getFormErrorMessage("startDate")}
                                    </div>) : (<p> { } </p>)}

                                <Button
                                    type="submit" disabled={migrating}
                                    className="p-mt-2"
                                    id="submit_btn"
                                    style={{ backgroundColor: "#d0021b", margin: "2rem 0rem 2rem auto", fontFamily: "Roboto", fontWeight: "bold", width: "max-content" }}>
                                    {t("migration.submit")}
                                </Button>
                            </form>
                        </div>
                    }
                </Dialog>
            </div>
        </>
    )
}

export default (MoveSubscriber);