import { call, put } from 'redux-saga/effects'
import SignupActions from '../actions/signup-actions'
import DefMembershipActions from '../actions/defMembership-actions'
import { signupBusiness } from "../../Service/api";

export function* updateSignupData(action) {
    const { signup } = action
    // make the call to the api
    const response = yield call(signupBusiness, signup)

    // success?
    if (response.ok) {
        console.log('Signup OK')
        yield put(SignupActions.signupUpdateSuccess(response.data.business))
        yield put(DefMembershipActions.defMembershipRequest())
    } else {
        yield put(SignupActions.signupUpdateFailure(response.data))
    }
}
