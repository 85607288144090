import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    countryRequest: ['countryId'],
    countryAllRequest: ['options'],
    countryUpdateRequest: ['country'],
    countryDeleteRequest: ['countryId'],

    countrySuccess: ['country'],
    countryAllSuccess: ['countries'],
    countryUpdateSuccess: ['country'],
    countryDeleteSuccess: [],

    countryFailure: ['error'],
    countryAllFailure: ['error'],
    countryUpdateFailure: ['error'],
    countryDeleteFailure: ['error']
})

export const CountryTypes = Types
export default Creators
