import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	subscriberRequest: ["subscriberId"],
	subscriberAllRequest: ["businessId", "options"],
	subscribersRequest: ["businessId", "options"],
	subscriberCountAllRequest: ["businessId"],
	subscriberPlanRequest: ["planId"],
	subscriberUpdateRequest: ["subscriber"],
	subscriberDeleteRequest: ["subscriberId"],
	subscriberRescheduleRequest: ["subscriberId"],
	subscriberExclusivityRequest: ["newPlanId", "msisdn"],
	subscriberSearchRequest: ["query", "businessId", "options"],
	countStatusSubscribersRequest: ["businessId", "status", "searchText"],
	subscriberFilterRequest: ["query", "businessId", "options"],
	countSearchSubscribersRequest: ["query", "businessId"],
	subscriberDateSearchRequest: ["query", "businessId", "startDate", "endDate", "options",],
	countDateSearchSubscribersRequest: ["query", "businessId", "startDate", "endDate"],
	subscriberCountRequest: ["query", "filters",],
	subscriberMissedSchedulesRequest: ["subscriberId", "missedByDate"],
	cancelSubscriptionRequest: ["subscriptionId", "membershipId"],
	pauseSubscriptionRequest: ["subscriptionId", "membershipId"],
	resumeSubscriptionRequest: ["id", "startFrom", "membershipId"],
	subscribersBulkCancelRequest: ["businessId", "membershipId", "ids"],
	subscribersBulkPauseRequest: ["businessId", "membershipId", "ids"],
	rescheduleSubscriptionRequest: ["subscriberId", "startFrom", "membershipId"],
	subscriberRevenueRequest: ["subscriberId"],

	subscriberSuccess: ["subscriber"],
	subscriberAllSuccess: ["subscribers"],
	subscribersSuccess: ["subscribers"],
	subscriberCountAllSuccess: ["countAllSubscribers"],
	subscriberPlanSuccess: ["planSubscribers"],
	subscriberUpdateSuccess: ["subscriber"],
	subscriberDeleteSuccess: [],
	subscriberRescheduleSuccess: [],
	subscriberExclusivitySuccess: ["exclusivity"],
	subscriberSearchSuccess: ["searchSubscribers"],
	countStatusSubscribersSuccess: ["countStatusSubscribers"],
	countSearchSubscribersSuccess: ["countSearchSubscribers"],
	countDateSearchSubscribersSuccess: ["countDateSearchSubscribers"],
	subscriberFilterSuccess: ["filteredSubscribers"],
	subscriberCountSuccess: ["countSubscribers"],
	subscriberMissedSchedulesSuccess: ["missedByDate"],
	subscriberDateSearchSuccess: ["dateSearchSubscribers"],
	cancelSubscriptionSuccess: ["cancelledSubscription"],
	pauseSubscriptionSuccess: ["pausedSubscription"],
	resumeSubscriptionSuccess: ["resumedSubscription"],
	subscribersBulkCancelSuccess: [],
	subscribersBulkPauseSuccess: [],
	rescheduleSubscriptionSuccess: ["rescheduledSubscription"],
	subscriberRevenueSuccess: ["revenue"],


	subscriberFailure: ["error"],
	subscriberAllFailure: ["error"],
	subscribersFailure: ["error"],
	subscriberCountAllFailure: ["error"],
	subscriberPlanFailure: ["error"],
	subscriberUpdateFailure: ["error"],
	subscriberDeleteFailure: ["error"],
	subscriberRescheduleFailure: ["error"],
	subscriberExclusivityFailure: ["error"],
	subscriberSearchFailure: ["error"],
	countStatusSubscribersFailure: ["error"],
	countSearchSubscribersFailure: ["error"],
	countDateSearchSubscribersFailure: ["error"],
	subscriberFilterFailure: ["error"],
	subscriberCountFailure: ["error"],
	subscriberDateSearchFailure: ["error"],
	subscriberMissedSchedulesFailure: ["error"],
	cancelSubscriptionFailure: ["error"],
	pauseSubscriptionFailure: ["error"],
	resumeSubscriptionFailure: ["error"],
	subscribersBulkCancelFailure: ["error"],
	subscribersBulkPauseFailure: ["error"],
	rescheduleSubscriptionFailure: ["error"],
	subscriberRevenueFailure: ["error"],

});

export const SubscriberTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingOne: false,
	fetchingAll: false,
	fetchAll: false,
	searchingAll: false,
	countingStatusSubscribers: false,
	countingSearchSubscribers: false,
	countingDateSearchSubscribers: false,
	filteringAll: false,
	countingAll: false,
	counting: false,
	fetchingPlan: false,
	fetchingExclusivity: false,
	fetchingDateSearch: false,
	fetchingSubscriberMissedSchedules: false,
	updating: false,
	deleting: false,
	cancellingSubscription: false,
	pausingSubscription: false,
	resumingSubscription: false,
	subscribersBulkCanceling: false,
	subscribersBulkPausing: false,
	reschedulingSubscription: false,
	fetchingSubscriberRevenue: false,

	subscriber: null,
	subscribers: null,
	subscribersAll: null,
	planSubscribers: null,
	searchSubscribers: null,
	countStatusSubscribers: null,
	countSearchSubscribers: null,
	countDateSearchSubscribers: null,
	filterSubscribers: null,
	countSubscribers: null,
	countAllSubscribers: null,
	dateSearchSubscribers: null,
	subscriberMissedSchedules: null,
	exclusivity: null,
	cancelledSubscription: null,
	pausedSubscription: null,
	resumedSubscription: null,
	rescheduledSubscription: null,
	subscriberRevenue: null,

	errorOne: null,
	errorAll: null,
	errorNew: null,
	errorSearching: null,
	errorCountStatusSubscribers: null,
	errorCountSearchSubscribers: null,
	errorCountDateSearchSubscribers: null,
	errorFiltering: null,
	errorCounting: null,
	errorCountingAll: null,
	errorPlan: null,
	errorUpdating: null,
	errorDeleting: null,
	errorExclusivity: null,
	errorDateSearch: null,
	errorSubscriberMissedSchedules: null,
	errorCancellingSubscription: null,
	errorPausingSubscription: null,
	errorResumingSubscription: null,
	errorSubscribersBulkCancel: null,
	errorSubscribersBulkPause: null,
	errorReschedulingSubscription: null,
	errorFetchingSubscriberRevenue: null
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
	return {
		...state,
		fetchingOne: true,
		subscriber: null,
	};
};

// request the data from an api
export const allRequest = (state) => {
	return {
		...state,
		fetchingAll: true,
		subscribers: null,
	};

};
// request the data from an api
export const allNewRequest = (state) => {
	return {
		...state,
		fetchAll: true,
		subscribersAll: null,
		errorNew: null
	};

};
export const allCountRequest = (state) => {
	return {
		...state,
		countingAll: true,
		countAllSubscribers: null,
	};
};

// request the data from an api
export const planRequest = (state) => {
	return {
		...state,
		fetchingPlan: true,
		planSubscribers: null,
	};
};

// request the data from an api
export const exclusivityRequest = (state) => {
	return {
		...state,
		fetchingExclusivity: true,
		exclusivity: null,
		errorExclusivity: null
	};
};
// request to update from an api
export const updateRequest = (state) => {
	return {
		...state,
		updating: true,
	};
};
// equest to delete from an api
export const deleteRequest = (state) => {
	return {
		...state,
		deleting: true,
	};
};
export const rescheduleRequest = (state) => {
	return {
		...state,
		updating: true,
	};
};
export const searchRequest = (state) => {
	return {
		...state,
		searchingAll: true,
		searchSubscribers: null,
	};
};
export const countSearchSubscribersRequest = (state) => {
	return {
		countingSearchSubscribers: true,
		countSearchSubscribers: null,
	};
};
export const countDateSearchSubscribersRequest = (state) => {
	return {
		countingDateSearchSubscribers: true,
		countDateSearchSubscribers: null,
	};
};
export const countStatusSubscribersRequest = (state) => {
	return {
		countingStatusSubscribers: true,
		countStatusSubscribers: null
	}
}
export const filterRequest = (state) => {
	return {
		...state,
		filteringAll: true,
		filteredSubscribers: null,
	};
};
export const countRequest = (state) => {
	return {
		...state,
		counting: true,
		countSubscribers: null,
	};
};

export const dateSearchRequest = (state) => {
	return {
		...state,
		fetchingDateSearch: true,
		dateSearchSubscribers: null,
	};
};

export const subscriberMissedSchedulesRequest = (state) => {
	return {
		...state,
		fetchingSubscriberMissedSchedules: true,
		subscriberMissedSchedules: null,
	};
};

export const cancelSubscriptionRequest = (state) => {
	return {
		...state,
		cancellingSubscription: true,
		cancelledSubscription: null,
	}
}

export const pauseSubscriptionRequest = (state) => {
	return {
		...state,
		pausingSubscription: true,
		pausedSubscription: null,
	}
}

export const resumeSubscriptionRequest = (state) => {
	return {
		...state,
		resumingSubscription: true,
		resumedSubscription: null,
	}
}

export const subscribersBulkCancelRequest = (state) => {
	return {
		...state,
		subscribersBulkCanceling: true,
		// cancelledSubscription: null,
	}
}

export const subscribersBulkPauseRequest = (state) => {
	return {
		...state,
		subscribersBulkPausing: true,
	}
}

export const rescheduleSubscriptionRequest = (state) => {
	return {
		...state,
		reschedulingSubscription: true,
		// pausedSubscription: null,
	}
}

export const subscriberRevenueRequest = (state) => {
	return {
		...state,
		fetchingSubscriberRevenue: true,
		subscriberRevenue: null,
	}
}

// successful api lookup for single entity
export const success = (state, action) => {
	const { subscriber } = action;
	return { ...state, fetchingOne: false, errorOne: null, subscriber };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
	const { subscribers } = action;
	return { ...state, fetchingAll: false, errorAll: null, subscribers };
};
export const allNewSuccess = (state, action) => {
	const { subscribers } = action;
	return { ...state, fetchAll: false, errorNew: null, subscribersAll: subscribers };
};
// successful api lookup for all status count entities
export const countStatusSubscribersSuccess = (state, action) => {
	const { countStatusSubscribers } = action
	return { ...state, countingStatusSubscribers: false, countStatusSubscribers, errorCountStatusSubscribers: null }
}
export const countAllSuccess = (state, action) => {
	const { countAllSubscribers } = action;
	return { ...state, countingAll: false, errorCountingAll: null, countAllSubscribers };
};

// successful api lookup for subscribers in a plan
export const planSuccess = (state, action) => {
	const { planSubscribers } = action;
	return { ...state, fetchingPlan: false, errorPlan: null, planSubscribers };
};

// successful api lookup for all entities
export const exclusivitySuccess = (state, action) => {
	const { exclusivity } = action;
	return {
		...state,
		fetchingExclusivity: false,
		errorExclusivity: null,
		exclusivity,
	};
};

// successful api update
export const updateSuccess = (state, action) => {
	const { subscriber } = action;
	return { ...state, updating: false, errorUpdating: null, subscriber };
};
// successful api delete
export const deleteSuccess = (state) => {
	return { ...state, deleting: false, errorDeleting: null, subscriber: null };
};

export const rescheduleSuccess = (state, action) => {
	return { ...state, updating: false, errorUpdating: null };
};

export const searchSuccess = (state, action) => {
	const { searchSubscribers } = action;
	return {
		...state,
		searchingAll: false,
		errorSearching: null,
		searchSubscribers,
	};
};

export const countSearchSubscribersSuccess = (state, action) => {
	const { countSearchSubscribers } = action;
	return {
		...state,
		countingSearchSubscribers: false,
		countSearchSubscribers,
		errorCountSearchSubscribers: null,
	};
};

export const countDateSearchSubscribersSuccess = (state, action) => {
	const { countDateSearchSubscribers } = action;
	return {
		...state,
		countingDateSearchSubscribers: false,
		errorCountDateSearchSubscribers: null,
		countDateSearchSubscribers,
	};
};

export const filterSuccess = (state, action) => {
	const { filteredSubscribers } = action;
	return {
		...state,
		filteringAll: false,
		errorFiltering: null,
		filteredSubscribers,
	};
};
export const countSuccess = (state, action) => {
	const { countSubscribers } = action;
	return {
		...state,
		countingAll: false,
		errorCounting: null,
		countSubscribers,
	};
};

export const dateSearchSuccess = (state, action) => {
	const { dateSearchSubscribers } = action;
	return {
		...state,
		fetchingDateSearch: false,
		errorDateSearch: null,
		dateSearchSubscribers,
	};
};

export const subscriberMissedSchedulesSuccess = (state, action) => {
	const { subscriberMissedSchedules } = action;
	return {
		...state,
		fetchingSubscriberMissedSchedules: false,
		errorSubscriberMissedSchedules: null,
		subscriberMissedSchedules,
	};
};

export const cancelSubscriptionSuccess = (state, action) => {
	const { cancelledSubscription } = action
	return {
		...state,
		cancellingSubscription: false,
		errorCancellingSubscription: null,
		cancelledSubscription
	}
}

export const pauseSubscriptionSuccess = (state, action) => {
	const { pausedSubscription } = action
	return {
		...state,
		pausingSubscription: false,
		errorPausingSubscription: null,
		pausedSubscription
	}
}

export const resumeSubscriptionSuccess = (state, action) => {
	const { resumedSubscription } = action
	return {
		...state,
		resumingSubscription: false,
		errorResumingSubscription: null,
		resumedSubscription
	}
}
export const subscribersBulkCancelSuccess = (state, action) => {
	// const { cancelledSubscription } = action
	return {
		...state,
		subscribersBulkCanceling: false,
		errorSubscribersBulkCancel: null,
		// pausingSubscription: null,
		// cancelledSubscription
	}
}

export const subscribersBulkPauseSuccess = (state, action) => {
	// const { pausedSubscription } = action
	return {
		...state,
		subscribersBulkPausing: false,
		errorSubscribersBulkPause: null,
		// pausedSubscription
	}
}

export const rescheduleSubscriptionSuccess = (state, action) => {
	const { rescheduledSubscription } = action
	return {
		...state,
		reschedulingSubscription: false,
		rescheduledSubscription,
		errorReschedulingSubscription: null
	}
}

export const subscriberRevenueSuccess = (state, action) => {
	const { revenue } = action
	return {
		...state,
		fetchingSubscriberRevenue: false,
		subscriberRevenue: revenue,
	}
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingOne: false, errorOne: error, subscriber: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingAll: false, errorAll: error, subscribers: null };
};
// Something went wrong fetching all entities.
export const allNewFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchAll: false, errorNew: error, subscribersAll: null };
};
// Something went wrong fetching all count status entities.
export const countStatusSubscribersFailure = (state, action) => {
	const { error } = action;
	return { ...state, countingStatusSubscribers: false, errorCountStatusSubscribers: error, countStatusSubscribers: null }
}

export const countAllFailure = (state, action) => {
	const { error } = action;
	return { ...state, countingAll: false, errorcountingAll: error, countAllSubscribers: null };
};

export const subscriberRevenueFailure = (state, action) => {
	const { error } = action
	return {
		...state,
		fetchingSubscriberRevenue: false,
		subscriberRevenue: null,
		errorFetchingSubscriberRevenue: error
	}
}
// Something went wrong fetching plan related subscribers.
export const plainFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingPlan: false,
		errorPlan: error,
		planSubscribers: null,
	};
};

// Something went wrong fetching all entities.
export const exclusivityFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingExclusivity: false,
		errorExclusivity: error,
		exclusivity: null,
	};
};

// Something went wrong updating.
export const updateFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		updating: false,
		errorUpdating: error,
		subscriber: state.subscriber,
	};
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		deleting: false,
		errorDeleting: error,
		subscriber: state.subscriber,
	};
};

export const rescheduleFailure = (state, action) => {
	const { error } = action;
	return { ...state, updating: false, errorUpdating: error };
};

export const searchFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		searchingAll: false,
		errorSearching: error,
		searchSubscribers: null,
	};
};

export const countSearchSubscribersFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		countingSearchSubscribers: false,
		countSearchSubscribers: null,
		errorCountSearchSubscribers: error,
	};
};

export const countDateSearchSubscribersFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		countingDateSearchSubscribers: false,
		countDateSearchSubscribers: null,
		errorDateCountSearchSubscribers: error,
	};
};

export const filteredFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		filteringAll: false,
		errorFiltering: error,
		filteredSubscribers: null,
	};
};
export const countFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		countingAll: false,
		errorCounting: error,
		countSubscribers: null,
	};
};

export const dateSearchFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingDateSearch: false,
		errorDateSearch: error,
		dateSearchSubscribers: null,
	};
};

export const subscriberMissedSchedulesFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingSubscriberMissedSchedules: false,
		errorSubscriberMissedSchedules: error,
		subscriberMissedSchedules: null,
	};
};

export const cancelSubscriptionFailure = (state, action) => {
	const { error } = action
	return {
		cancellingSubscription: false,
		errorCancellingSubscription: error,
		cancelledSubscription: null
	}
}

export const pauseSubscriptionFailure = (state, action) => {
	const { error } = action
	return {
		pausingSubscription: false,
		errorPausingSubscription: error,
		pausedSubscription: null
	}
}

export const resumeSubscriptionFailure = (state, action) => {
	const { error } = action
	return {
		resumingSubscription: false,
		errorResumingSubscription: error,
		resumedSubscription: null
	}
}
export const subscribersBulkCancelFailure = (state, action) => {
	const { error } = action
	return {
		subscribersBulkCanceling: false,
		errorSubscribersBulkCancel: error,
		// cancelledSubscription: null
	}
}

export const subscribersBulkPauseFailure = (state, action) => {
	const { error } = action
	return {
		subscribersBulkPausing: false,
		errorSubscribersBulkPause: error,
		// pausedSubscription: null
	}
}

export const rescheduleSubscriptionFailure = (state, action) => {
	const { error } = action
	return {
		...state,
		reschedulingSubscription: false,
		rescheduledSubscription: null,
		errorReschedulingSubscription: error
	}
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SUBSCRIBER_REQUEST]: request,
	[Types.SUBSCRIBER_ALL_REQUEST]: allRequest,
	[Types.SUBSCRIBERS_REQUEST]: allNewRequest,
	[Types.SUBSCRIBER_COUNT_ALL_REQUEST]: allCountRequest,
	[Types.SUBSCRIBER_PLAN_REQUEST]: planRequest,
	[Types.SUBSCRIBER_UPDATE_REQUEST]: updateRequest,
	[Types.SUBSCRIBER_DELETE_REQUEST]: deleteRequest,
	[Types.SUBSCRIBER_RESCHEDULE_REQUEST]: rescheduleRequest,
	[Types.SUBSCRIBER_EXCLUSIVITY_REQUEST]: exclusivityRequest,
	[Types.SUBSCRIBER_SEARCH_REQUEST]: searchRequest,
	[Types.COUNT_STATUS_SUBSCRIBERS_REQUEST]: countStatusSubscribersRequest,
	[Types.COUNT_SEARCH_SUBSCRIBERS_REQUEST]: countSearchSubscribersRequest,
	[Types.COUNT_DATE_SEARCH_SUBSCRIBERS_REQUEST]: countDateSearchSubscribersRequest,
	[Types.SUBSCRIBER_FILTER_REQUEST]: filterRequest,
	[Types.SUBSCRIBER_COUNT_REQUEST]: countRequest,
	[Types.SUBSCRIBER_DATE_SEARCH_REQUEST]: dateSearchRequest,
	[Types.SUBSCRIBER_MISSED_SCHEDULES_REQUEST]: subscriberMissedSchedulesRequest,
	[Types.CANCEL_SUBSCRIPTION_REQUEST]: cancelSubscriptionRequest,
	[Types.PAUSE_SUBSCRIPTION_REQUEST]: pauseSubscriptionRequest,
	[Types.RESUME_SUBSCRIPTION_REQUEST]: resumeSubscriptionRequest,
	[Types.SUBSCRIBERS_BULK_CANCEL_REQUEST]: subscribersBulkCancelRequest,
	[Types.SUBSCRIBERS_BULK_PAUSE_REQUEST]: subscribersBulkPauseRequest,
	[Types.RESCHEDULE_SUBSCRIPTION_REQUEST]: rescheduleSubscriptionRequest,
	[Types.SUBSCRIBER_REVENUE_REQUEST]: subscriberRevenueRequest,

	[Types.SUBSCRIBER_SUCCESS]: success,
	[Types.SUBSCRIBER_ALL_SUCCESS]: allSuccess,
	[Types.SUBSCRIBERS_SUCCESS]: allNewSuccess,
	[Types.SUBSCRIBER_COUNT_ALL_SUCCESS]: countAllSuccess,
	[Types.SUBSCRIBER_PLAN_SUCCESS]: planSuccess,
	[Types.SUBSCRIBER_UPDATE_SUCCESS]: updateSuccess,
	[Types.SUBSCRIBER_DELETE_SUCCESS]: deleteSuccess,
	[Types.SUBSCRIBER_RESCHEDULE_SUCCESS]: rescheduleSuccess,
	[Types.SUBSCRIBER_EXCLUSIVITY_SUCCESS]: exclusivitySuccess,
	[Types.COUNT_STATUS_SUBSCRIBERS_SUCCESS]: countStatusSubscribersSuccess,
	[Types.SUBSCRIBER_SEARCH_SUCCESS]: searchSuccess,
	[Types.COUNT_SEARCH_SUBSCRIBERS_SUCCESS]: countSearchSubscribersSuccess,
	[Types.COUNT_DATE_SEARCH_SUBSCRIBERS_SUCCESS]: countDateSearchSubscribersSuccess,
	[Types.SUBSCRIBER_FILTER_SUCCESS]: filterSuccess,
	[Types.SUBSCRIBER_COUNT_SUCCESS]: countSuccess,
	[Types.SUBSCRIBER_DATE_SEARCH_SUCCESS]: dateSearchSuccess,
	[Types.SUBSCRIBER_MISSED_SCHEDULES_SUCCESS]: subscriberMissedSchedulesSuccess,
	[Types.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccess,
	[Types.PAUSE_SUBSCRIPTION_SUCCESS]: pauseSubscriptionSuccess,
	[Types.RESUME_SUBSCRIPTION_SUCCESS]: resumeSubscriptionSuccess,
	[Types.SUBSCRIBERS_BULK_CANCEL_SUCCESS]: subscribersBulkCancelSuccess,
	[Types.SUBSCRIBERS_BULK_PAUSE_SUCCESS]: subscribersBulkPauseSuccess,
	[Types.RESCHEDULE_SUBSCRIPTION_SUCCESS]: rescheduleSubscriptionSuccess,
	[Types.SUBSCRIBER_REVENUE_SUCCESS]: subscriberRevenueSuccess,

	[Types.SUBSCRIBER_FAILURE]: failure,
	[Types.SUBSCRIBER_ALL_FAILURE]: allFailure,
	[Types.SUBSCRIBERS_FAILURE]: allNewFailure,
	[Types.SUBSCRIBER_COUNT_ALL_FAILURE]: countAllFailure,
	[Types.SUBSCRIBER_UPDATE_FAILURE]: updateFailure,
	[Types.SUBSCRIBER_DELETE_FAILURE]: deleteFailure,
	[Types.SUBSCRIBER_RESCHEDULE_FAILURE]: rescheduleFailure,
	[Types.SUBSCRIBER_EXCLUSIVITY_FAILURE]: exclusivityFailure,
	[Types.SUBSCRIBER_SEARCH_FAILURE]: searchFailure,
	[Types.COUNT_STATUS_SUBSCRIBERS_FAILURE]: countStatusSubscribersFailure,
	[Types.COUNT_SEARCH_SUBSCRIBERS_FAILURE]: countSearchSubscribersFailure,
	[Types.COUNT_DATE_SEARCH_SUBSCRIBERS_FAILURE]: countDateSearchSubscribersFailure,
	[Types.SUBSCRIBER_FILTER_FAILURE]: filteredFailure,
	[Types.SUBSCRIBER_COUNT_FAILURE]: countFailure,
	[Types.SUBSCRIBER_DATE_SEARCH_FAILURE]: dateSearchFailure,
	[Types.SUBSCRIBER_MISSED_SCHEDULES_FAILURE]: subscriberMissedSchedulesFailure,
	[Types.CANCEL_SUBSCRIPTION_FAILURE]: cancelSubscriptionFailure,
	[Types.PAUSE_SUBSCRIPTION_FAILURE]: pauseSubscriptionFailure,
	[Types.RESUME_SUBSCRIPTION_FAILURE]: pauseSubscriptionFailure,
	[Types.SUBSCRIBERS_BULK_CANCEL_FAILURE]: subscribersBulkCancelFailure,
	[Types.SUBSCRIBERS_BULK_PAUSE_FAILURE]: subscribersBulkPauseFailure,
	[Types.RESCHEDULE_SUBSCRIPTION_FAILURE]: rescheduleSubscriptionFailure,
	[Types.SUBSCRIBER_REVENUE_FAILURE]: subscriberRevenueFailure
});
