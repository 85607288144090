import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { BankBranchTypes } from '../actions/bankBranches-actions'

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOne: null,
    fetchingAll: null,
    bankBranch: null,
    bankBranches: null,
    errorOne: null,
    errorAll: null,

})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => { return { ...state, fetchingOne: true, bankBranch: null } }

// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, bankBranches: null } }


// successful api lookup for single entity
export const success = (state, action) => {
    const { bankBranch } = action
    return { ...state, fetchingOne: false, errorOne: null, bankBranch }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { bankBranches } = action
    return { ...state, fetchingAll: false, errorAll: null, bankBranches }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOne: false, errorOne: error, bankBranch: null }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, errorAll: error, bankBranches: null }
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [BankBranchTypes.BANK_BRANCH_REQUEST]: request,
    [BankBranchTypes.BANK_BRANCH_ALL_REQUEST]: allRequest,

    [BankBranchTypes.BANK_BRANCH_SUCCESS]: success,
    [BankBranchTypes.BANK_BRANCH_ALL_SUCCESS]: allSuccess,

    [BankBranchTypes.BANK_BRANCH_FAILURE]: failure,
    [BankBranchTypes.BANK_BRANCH_ALL_FAILURE]: allFailure,
})
