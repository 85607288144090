import { call, put } from "redux-saga/effects";
import * as api from '../../../Service/api'
import DocumentActions from "./document-upload.reducer";


export function* getDocuments(action) {
    const { countryId } = action;
    const response = yield call(api.getDocuments, countryId);

    if (response.ok) {
        yield put(DocumentActions.documentSuccess(response.data))
    }
    else {
        yield put(DocumentActions.documentFailure(response.data))
    }
}
export function* getOrganisationDocuments(action) {
    const { countryId } = action;
    const response = yield call(api.getOrganisationDocuments, countryId);

    if (response.ok) {
        yield put(DocumentActions.organisationSuccess(response.data))
    }
    else {
        yield put(DocumentActions.organisationFailure(response.data))
    }
}

export function* createRecord(action) {
    const { business } = action;
    const response = yield call(api.createBusinessVerification, business);

    if (response.ok) {
        yield put(DocumentActions.businessVerificationSuccess(response.data))
    }
    else {
        yield put(DocumentActions.businessVerificationFailure(response.data))
    }
}

export function* verifyDocument(action) {
    const { requestId, membershipId, documentId, refrence, file } = action;
    const response = yield call(api.verifyDocument, requestId, membershipId, documentId, refrence, file);

    if (response.ok) {
        yield put(DocumentActions.documentVerificationSuccess({ ...response.data, documentId }))
    }
    else {
        yield put(DocumentActions.documentVerificationFailure({ ...response.data, documentId }))
    }
}
export function* reviewDocument(action) {
    const { requestId } = action;
    const response = yield call(api.reviewDocument, requestId);

    if (response.ok) {
        yield put(DocumentActions.documentReviewSuccess(response.ok))
    }
    else {
        yield put(DocumentActions.documentReviewFailure(response.data))
    }
}
export function* previewDocument(action) {
    const { proofId } = action;
    const response = yield call(api.previewDocument, proofId,);

    if (response.ok) {
        yield put(DocumentActions.documentPreviewSuccess(response.data))
    }
    else {
        yield put(DocumentActions.documentPreviewFailure(response.data))
    }
}

export function* getBusinessVerificationStatus(action) {
    const { businessId } = action;
    const response = yield call(api.businessVerificationRequest, businessId)

    if (response.ok) {
        yield put(DocumentActions.businessVerificationStatusSuccess(response.data))
    } else {
        yield put(DocumentActions.businessVerificationStatusFailure(response.data))
    }
}

export function* uploadLogo(action) {
    const { businessId, file } = action
    // make the call to the api
    const response = yield call(api.uploadLogo, businessId, file);

    // success?
    if (response.ok) {
        yield put(DocumentActions.businessLogoUploadSuccess(response.data, file))
    } else {
        yield put(DocumentActions.businessLogoUploadFailure(response.data))
    }
}