import { call, put } from 'redux-saga/effects'
import PhoneVerificationActions from './phone-verification.reducer'
import * as api from "../../../Service/api";

export function * generateCode (action) {
  const { generationRequest } = action
  const response = yield call(api.generateVerificationCode, generationRequest)

  // success?
  
  if (response.ok) {
    yield put(PhoneVerificationActions.generateCodeSuccess(response.data))
  } else {
    yield put(PhoneVerificationActions.generateCodeFailure(response.data))
  }
}

export function * verifyCode (action) {
  // const { verificationRequest } = action
  // make the call to the api
//   const apiCall = call(api.checkVerificationCode, verificationRequest)
  // const response = yield call(api.checkVerificationCode, verificationRequest)

  // success?
  // if (response.ok) {
  //   yield put(PhoneVerificationActions.verifyCodeSuccess(response.data))
  //   yield put(SubscriberActions.subscriberSuccess(response.data))
  // } else {
  //   yield put(PhoneVerificationActions.verifyCodeFailure(response.data))
  // }
}