import React from "react";
import moment from "moment";
import { TabMenu } from "primereact/tabmenu";
import "../../Styles/pages/dashboard.scss";
export const formatTime = (time) => {
    if (moment(time).isValid()) {
        return moment(time).format("DD MMM YYYY HH:mm A");
    }
};
export const tabActive = ({ state }) => {
    if (state.filterDate) {
        return 6
    } else {
        return 1
    }
}
export const initialState = {
    lastWeekEnd: "now-7d",
    lastFortnightEnd: "now-2w",
    lastMonthEnd: "now-1M",
    lastQuarterEnd: "now-3M",
    lastHalfYearEnd: "now-6M",
    lastYearEnd: "now-1y",
    todayStart: "now",
    tabMenuItems: [
        { label: "", id: 0, className: "tab tab2", value: "" },
        { label: "subscriber.today", id: 1, className: "tab tab1", value: "now" },
        { label: "subscriber.week", id: 2, className: "tab default-tab", value: "now-7d" },
        { label: "subscriber.month", id: 3, className: "tab edge-tab-end", value: "now-1M" },
    ],
    activeTab: 1,
    activeCategory: { label: "Activity", id: 1, className: "tab" },
    layout: "list",
    filterDate: null,
    filterSubscriber: null,
    sort: "joinedOn,desc",
    startDate: "now-7d",
    filterItems: [
        { label: "subscriber.no_filter", value: null },
        { label: "subscriber.seven_days", value: "now-7d" },
        { label: "subscriber.two_weeks", value: "now-2w" },
        { label: "subscriber.one_month", value: "now-1M" },
        { label: "subscriber.quarter_year", value: "now-3M" },
        { label: "subscriber.half_year", value: "now-6M" },
        { label: "subscriber.one_year", value: "now-1y" },
        { label: "subscriber.two_years", value: "now-36M" },
    ],
    filterSubscribersItems: [
        { label: "No filter", value: null },
        { label: "Active", value: "ACTIVE" },
        { label: "Paused", value: "PAUSED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "Complete", value: "COMPLETE" },
        // { label: "Subscribed", value: "Subscribed" },
        // { label: "Renewed", value: "Renewed" },
        // { label: "Expired", value: "Expired" },
    ],
    sortItems: [
        { label: "Subscribers", value: "id,asc" },
        { label: "Plan", value: "plan.group.id,desc" },
    ],
    query: "*",
    isSubmit: false,
    sortType: 'desc',
    input: "",
    isRangeChange: false,
    isNameChange: false,
    count: null,
    size: 15,
    first: 0,
    subscribers: null,
}
export const TableTemplate = ({ state, onTabChange, tabActive, t }) => {
    return (<TabMenu
        model={state.tabMenuItems.map((item) => {
            item.label = t(item.label);
            return item;
        })}
        className={"tabMenu"}
        activeIndex={state.activeTab ? state.activeTab : tabActive}
        onTabChange={onTabChange}
    />)
}