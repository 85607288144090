import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PayoutChannelActions from "../../Redux/actions/payoutchannel-actions"
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import "./../../Styles/pages/transactions.scss"

export const SavedPayout = ({ setFieldValue, values, state, setState }) => {
    const { t } = useTranslation("common");

    const params = new URLSearchParams(window.location.search);
    const payoutChannelId = params.get('payoutChannelId')

    //Redux State from Store
    const payoutChannelsSaved = useSelector((state) => state.payoutChannels.payoutChannelsSaved);
    const payoutChannel = useSelector((state) => state.payoutChannels.payoutChannel);
    const defMembership = useSelector((state) => state.defMembership.defMembership);

    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);

    const [states, setStates] = useState({
        payoutChannels: [],
        payoutChannel: {}
    });

    useEffect(() => {
        if (!defMembership) {
            getMembership()
        }
        if (defMembership) {
            getSavedPayoutChannels(defMembership.business.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (payoutChannel) {
            setStates((states) => {
                return { ...states, payoutChannel: payoutChannel };
            });
        }
        if (payoutChannelsSaved) {
            setStates((states) => {
                return { ...states, payoutChannels: payoutChannelsSaved };
            });
            if (payoutChannelId) {
                let PreSelectSavedAccount = { ...payoutChannelsSaved.find(({ id }) => id === parseInt(payoutChannelId)) }
                PreSelectSavedAccount.channelName = PreSelectSavedAccount.systemBank.name
                setFieldValue("payoutChannel", PreSelectSavedAccount)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payoutChannelsSaved, payoutChannel]);

    const channelOptionTemplate = (option) => (<div>  {option.name} - {option.identifier} </div>);
    const selectedChannelTemplate = (option, props) => {
        if (option) { return (<div>{option.name} - {option.identifier}</div>); }
        return <span>{props.placeholder}</span>;
    };

    return <>
        <div className="p-col payout_channel-saved">
            <h6 className="wallet-titles wallet-info"
            >{t("recipient.saved")}</h6>
            <Dropdown
                value={values.payoutChannel}
                options={state.payoutChannels}
                itemTemplate={channelOptionTemplate}
                onChange={(e) => {
                    if (!values.payoutChannel.channelName) {
                        setFieldValue("payoutChannel", { ...e.value, channelName: e.value.systemBank })
                    }
                    setFieldValue("payoutChannel", e.value)
                }}
                optionLabel="name"
                id="payoutChannel"
                className="payout_channel-dropdown"
                placeholder={t("recipient.choose_saved")}
                valueTemplate={selectedChannelTemplate}
                disabled={payoutChannelId ? payoutChannelId : false} />
            <Button
                icon="pi pi-user-plus"
                className="p-button-outlined p-button-secondary payout_channel-saved-new-button"
                type="button"
                onClick={(e) => { setState({ ...state, isNewChannel: true }); }}
                label={t("recipient.create")} />
        </div>
        {
            values.payoutChannel.name &&
            <div className="p-col p-grid payout_channel-saved-info mt-3" >
                {[{ label: t("recipient.name"), value: values.payoutChannel.name },
                { label: t("recipient.account"), value: values.payoutChannel.identifier },
                { label: t("recipient.payment_channel_1"), value: values.payoutChannel.systemBank.name }
                ].map(({ label, value }, key) => (
                    <div className="payout_channel-saved-info-item" key={key}>
                        <span className="payout_channel-saved-info-item-title">{label}: </span>
                        <span className="payout_channel-saved-info-item-value"> {value || ""}</span>
                    </div>
                ))}
            </div>
        }
        <div className="payout_channel-buttonsBar">
            <Button
                className="p-button-info payout_channel-buttonsBar-continue-button"
                type="submit"
                disabled={payoutChannelId ? !states.payoutChannel.name : !values.payoutChannel.name}
                label={t("continue")} />
        </div>
    </>;
};
