/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import ProfileActions from "../../Redux/actions/profile-actions";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

function ProfilePageEdit(props) {
  let location = useLocation();
  let history = useHistory();
  const { t } = useTranslation("common");

  const profile = useSelector((state) => state.profile.profile);
  const defMembership = useSelector(
    (state) => state.defMembership.defMembership
  );

  const dispatch = useDispatch();
  const getProfile = () => dispatch(ProfileActions.profileRequest());
  const getMembership = () =>
    dispatch(DefMembershipActions.defMembershipRequest());

  const [state, setState] = useState({
    updatingProfile: null,
    gettingProfile: null,
    defMembership: null,
    success: false,
    error: null,
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!location.state) {
      getProfile();
    } else {
      let profile = location.state.userInfo;
      formik.setFieldValue("firstName", profile.firstName);
      formik.setFieldValue("lastName", profile.lastName);
      formik.setFieldValue("email", profile.email);
    }

    if (profile) {
      formik.setFieldValue("firstName", profile.firstName);
      formik.setFieldValue("lastName", profile.lastName);
      formik.setFieldValue("email", profile.email);
      
    }

    setState((state) => {
      return { ...state, updatingProfile: props.updating };
    });

    if(props.updating){
        formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("email", "");
    }

    if (!defMembership) {
      getMembership();
    }

    if (defMembership) {
      setState((prev) => {
        return { ...prev, defMembership: defMembership };
      });
    }

    if (state.updatingProfile && !state.success) {
      setState((state) => {
        return {
          ...state,
          success: true,
        };
      });
      getMembership();
      getProfile();
      props.getAccount();
      history.push("/user/profile");
    }

    if (state.updatingProfile) {
        setOpen(true);
      } else {
        setOpen(false);
        
      }
  }, [props.updating, state.defMembership,]);

  const formik = useFormik({
    initialValues: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.firstName) {
        errors.firstName = t("user_profile.first_name_error");
      }
      if (!data.lastName) {
        errors.lastName = t("user_profile.last_name_error");
      }

      if (!data.email) {
        errors.email = t("user_profile.email_error");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = t("user_profile.invalid_email_error");
      }
      return errors;
    },

    onSubmit: (data) => {
      const updateData = {
        firstName: data.firstName || null,
        lastName: data.lastName || null,
        email: data.email || null,
        login: profile.login,
      };
      setState({
        ...state,
        success: false,
      });
      props.updateAccount(updateData);
      formik.resetForm();
      props.getAccount();
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div className="form-demo ">
      <div className="p-d-flex p-jc-center">
        <div className="card edit-form">
          <h3 className="p-text-center">{t("user_profile.form_title")} </h3> <br />
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  autoFocus
                  required
                  className={classNames({
                    "p-invalid": isFormFieldValid("firstName"),
                  })}
                  maxLength={50}
                />
                <label
                  htmlFor="firstName"
                  className={classNames({
                    "p-error": isFormFieldValid("firstName"),
                  })}
                >
                  {t("common.first_name")}
                </label>
              </span>
              {getFormErrorMessage("fisrtName")}
            </div>
            <br /> <br />
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  required
                  className={classNames({
                    "p-invalid": isFormFieldValid("lastName"),
                  })}
                  maxLength={50}
                />
                <label
                  htmlFor="lastName"
                  className={classNames({
                    "p-error": isFormFieldValid("lastName"),
                  })}
                >
                  {t("common.last_name")}
                </label>
              </span>
              {getFormErrorMessage("lastName")}
            </div>
            <br /> <br />
            <div className="p-field">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                  })}
                  maxLength={50}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  {t("common.email")}
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>
            <br /> <br />
            {state.updatingProfile &!open ? (
               <Backdrop className={classes.backdrop} open >
                  <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
              <Button
                type="submit"
                label={t("user_profile.form_update")}
                className="p-mt-2"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    updating: state.profile.updating,
    error: state.account.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) =>
      dispatch(ProfileActions.profileUpdateRequest(account)),
    getAccount: () => dispatch(ProfileActions.profileRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePageEdit);
