import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PaymentTrackActions from "../../../../Redux/transition/payment-track/payment-track.reducer";
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../../../Redux/actions/defMembership-actions";
import PaymentScheduleActions from "../../../../Redux/transition/payment-schedule/payment-schedule.reducer";
import SubscriberActions from "../../../../Redux/transition/subscriber/subscriber.reducer";
// imports from other libraries ;
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
//imports from styles
import "../../../../Styles/pages/subscriptionDetail.scss"
import "../../../../Styles/components/sti.css"
import "../../../../Styles/pages/inviteSubscriber.scss"
import "../../../../Styles/pages/invitation.scss"
import MarkAsPaid from "../../../../Components/MarkAsPaid";
import CustomDataTable from "../../../../Components/CustomDataTable";
import { Paginator } from 'primereact/paginator';


const TransactionsTabs = (props) => {
    const history = useHistory();
    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(0);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [state, setState] = useState({
        subscriberObjects: {},
        paymentTracks: [],
        paymentMissedSchedules: [],
        scheduleId: null,
        scheduledFor: null,
        date: null,
        paymentData: [],
        marking: true,
        cancelled: false,
        response: null,
        paused: false,
        tabSelected: false,
        id: null,
        key: false,
        page: 0,
        size: 15,
        sort: 'receivedAt,desc',
        sortOrder: 'scheduledFor,desc',
        sortBy: "externallyReceivedOn,desc",
        subsExternalPayments: [],
        flex: null,
        externallyReceivedPayments: {},
        externalTabSelected: false,
        first: 0,
        missedSchedules: {},
        collectedPayments: {},
    });

    let { id } = useParams();

    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const externalPaymentTracks = useSelector((state) => state.paymentTracks.subsPayments)
    const fetchingMissed = useSelector((state) => state.paymentSchedules.fetchingMissed)
    const paymentSchedulesMissed = useSelector((state) => state.paymentSchedules.paymentSchedulesMissed)
    const paymentTracks = useSelector((state) => state.paymentTracks.paymentTracks)
    const fetchingTracks = useSelector((state) => state.paymentTracks.fetchingAll)
    const fetchingSubsExternalPayments = useSelector((state) => state.paymentTracks.fetchingSubsExternalPayments)


    const dispatch = useDispatch()
    const getSubscriber = useCallback((subscriberId) => { dispatch(SubscriberActions.subscriberRequest(subscriberId)) }, [dispatch])
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
    const getAllPaymentSchedules = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.paymentScheduleAllRequest(subscriberId, options)) }, [dispatch])
    const getMissedPaymentSchedules = useCallback((subscriberId, options) => { dispatch(PaymentScheduleActions.paymentScheduleMissedRequest(subscriberId, options)) }, [dispatch])
    const getAllPaymentTracks = useCallback((subscriberId, options) => { dispatch(PaymentTrackActions.paymentTrackAllRequest(subscriberId, options)) }, [dispatch])
    const getSubsExternalPayments = useCallback((businessId, subId, options) => { dispatch(PaymentTrackActions.subscriberExternalPaymentsRequest(businessId, subId, options)) }, [dispatch])

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getSubsExternalPayments(defMembership.business?.id, id, { page: state.page, sort: state.sortBy, size: state.size })
            getMissedPaymentSchedules(id, { page: state.page, sort: state.sortOrder, size: state.size })
            getAllPaymentTracks(id, { page: state.page, sort: state.sort, size: state.size });
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (externalPaymentTracks) {
            setState((state) => { return { ...state, externallyReceivedPayments: externalPaymentTracks } })
        }
        if (paymentTracks) {
            setState((state) => { return { ...state, collectedPayments: paymentTracks } })
        }
        if (paymentSchedulesMissed) {
            setState((state) => { return { ...state, missedSchedules: paymentSchedulesMissed } })
        }
    }, [externalPaymentTracks, state.externallyReceivedPayments, paymentSchedulesMissed, paymentTracks])

    const formatTime = (time) => {
        if (moment(time).isValid()) {
            return moment(time).format("LLL");
        }
    };
    const dateFormatterBody = (rowData, e) => { return rowData[e] ? formatTime(rowData[e]) : "-" }
    const transactionsIdTemplate = (rowData) => {
        return <p className="transactions_data detail-navigation" onClick={() => {
            history.push({
                pathname: "/payments/detail/" + rowData.id
            })
        }}>{rowData.reference}</p>
    }

    const loading = fetchingMissed || fetchingTracks || fetchingSubsExternalPayments

    const receivedAtTemplate = (rowData, e) => {
        return (<div>
            {rowData.receivedAt && <>
                <p className="transactions_data">{dateFormatterBody(rowData, "receivedAt")} </p>
            </>}
        </div>)
    }
    const attemptedOnTemplate = (rowData, e) => {
        if (rowData.scheduledFor) {
            return <p className="transactions_data">{dateFormatterBody(rowData, "scheduledFor")}</p>;
        } else {
            return "--"
        }
    }
    const paymentTemplate = (rowData, e) => {
        return (<div>
            {(rowData.paymentMode) ?
                <p className="transactions_data"> {rowData.paymentMode} </p> : "--"
            }
        </div>)
    }

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscription?.status === "ACTIVE" ? < Button
                label={t("subscriber.paid")}
                style={{ color: "#008dd5" }}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    setDisplayBasic(true)
                    setState((state) => ({
                        ...state,
                        id: rowData.id,
                        scheduledFor: rowData.scheduledFor,
                        flex: rowData.subscription.plan?.flex
                    }));
                }}
            /> : "")
        }
    }

    const paymentIdTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span>
                    {rowData.latestDlr.id}
                </span>
            </div>
        }
    }
    const occurredOnTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span className='tier-date'>
                    {moment(rowData.externallyReceivedOn).format('DD MMM YYYY')}
                </span>
            </div>
        }
    }


    const costTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div>
                    {
                        rowData.subscription.plan?.flex === true ?
                            <div>
                                <span style={{ display: "block", marginBottom: "0.5rem" }}>{t("products.flexible_amount")}</span>
                                {rowData.extReceivedAmount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.extReceivedAmount?.toFixed(2)}</span>
                                    : "--"}
                            </div>
                            :
                            <p className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.cost?.toFixed(2)}</p>
                    }
                </div>
            </div>
        }
    }

    const notesTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {
                    rowData.extReceivedNotes ?
                        <div>
                            <p> {rowData.extReceivedNotes}</p>
                        </div> : "--"
                }
            </div>
        }
    }

    const paymentSubscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate" style={{ alignItems: "flex-start" }}>
                    {
                        rowData.subscription.subscriberReference !== null ?
                            <p style={{ marginBottom: "15px" }} className="reference-text detail-navigation" id="planName">
                                {rowData.subscription.subscriberReference}
                            </p>
                            : ""
                    }
                    {
                        rowData.subscription.plan?.name ?
                            <span className="detail-navigation tier-name-content"
                                onClick={() => { history.push({ pathname: "/products/plan/detail/" + rowData.subscription.plan.id }) }}
                            >
                                {rowData.subscription.plan.name !== null ? rowData.subscription.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscription.plan.billingPeriod !== null ? t(`product.${rowData.subscription.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription.plan?.groupName &&
                        <span className="plan-text detail-navigation hov"
                            onClick={() => {
                                history.push({
                                    pathname: "/products/detail/" + rowData.subscription.plan.groupId
                                })
                            }}
                        >
                            {rowData.subscription.plan.groupName}
                        </span>
                    }
                </div >
            </div >
        }
    }

    const successColumns = [
        {
            field: "transaction_id",
            header: () => { return <p className="transactions_header">{t("subscriber.reference")}</p> },
            body: transactionsIdTemplate
        },
        {
            field: "received_at",
            body: receivedAtTemplate, header: () => { return <p className="transactions_header">{t("subscriber.occurred_on")}</p> }
        },
        { field: "payment_mode", body: paymentTemplate, header: () => { return <p className="transactions_header">{t("subscriber.payment_method")} </p> } },
        {
            field: "amount paid", header: () => { return <p className="transactions_header">{t("subscriber.amount_paid")} </p> },
            body: (rowData) =>
                <p className="transactions_data">{rowData.business?.currency} {""} {rowData.amount?.toFixed(2)}</p>
        },
        {
            field: "fees", header: () => { return <p className="transactions_header">{t("subscriber.service_fee")} </p> },
            body: (rowData) => <p className="transactions_data">{rowData.business?.currency} {""} {rowData.fees}</p>
        },
        {
            field: "amount received", header: () => { return <p className="transactions_header">{t("subscriber.amount_received")} </p> },
            body: (rowData) =>
                <div>
                    <p className="transactions_data">{rowData.business?.currency} {""} {rowData.redeemable}</p>
                </div>
        }
    ]
    const failedColumns = [
        {
            field: "transactions_id", header: () => { return <p className="transactions_header">{t("subscriber.transaction_id")} </p> },
            body: (rowData) => <p className="transactions_data">{rowData.id}</p>
        },
        { field: "billingAttemptedOn", body: attemptedOnTemplate, header: () => { return <p className="transactions_header">{t("subscriber.billing_attempted_on")}</p> } },
        {
            field: "plan_amount", header: () => { return <p className="transactions_header">{t("subscriber.plan_amount")}</p> }, body: (rowData) => (
                rowData.subscription.plan?.flex === true ? <p className="transactions_data">
                    {t("products.flexible_amount")}
                </p> :
                    <p className="transactions_data">
                        {rowData.business?.currency} {""} {rowData.cost?.toFixed(2)}
                    </p>

            )
        },
        { field: "status", header: () => { return <p className="transactions_header">{t("status")}</p> }, body: (rowData) => <p className="transactions_data">{rowData.status}</p> },
        { body: markActionTemplate }
    ]

    const externalPayments = [

        { field: 'id', body: paymentIdTemplate, header: t("products.ref"), sort: true, headerClassName: "amount-header" },
        { field: 'subscriber', body: paymentSubscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header" },
        { field: 'extReceivedOn', body: occurredOnTemplate, header: t("subscriber.occurred_on"), sort: true, headerClassName: "amount-header" },
        { field: 'cost', body: costTemplate, header: t("dashboard.amount"), sort: true, headerClassName: "amount-header" },
        { body: notesTemplate, header: t("subscriber.notes"), headerClassName: "amount-header" },
    ]

    const tabs = [{
        label: t('successful'), value: state.collectedPayments, columns: successColumns, count: props.payments?.length, index: 0
    },
    {
        label: t('failed'), value: state.missedSchedules, columns: failedColumns, count: props.missed?.length, index: 1
    },
    {
        label: t('transaction.external'), value: state.externallyReceivedPayments?.content, columns: externalPayments, count: state.externallyReceivedPayments?.length, index: 2
    }]

    const totalRecords =
        tabs[activeTab].index === 0
            ? props.collectedPaymentsCount
            : tabs[activeTab].index === 1
                ? props.missedSchedulesCount
                : state.externallyReceivedPayments?.totalElements;


    const onChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        }
        else if (tabs[activeTab].index === 0) {
            getAllPaymentTracks(id, { page: e.page, sort: state.sort, size: state.size })
            setState((state) => ({ ...state, first: e.first, page: state.page }));
        }
        else if (tabs[activeTab].index === 1) {
            getMissedPaymentSchedules(id, { page: e.page, sort: state.sortOrder, size: state.size })
            setState((state) => ({ ...state, first: e.first, page: state.page }));
        }
        else {
            getSubsExternalPayments(defMembership.business?.id, id, { page: e.page, sort: state.sortOrder, size: state.size })
            setState((state) => ({ ...state, first: e.first, page: state.page }));
        }
    }

    const onTabChange = () => {
        if (tabs[activeTab].index === 0) {
            getAllPaymentTracks(id, { page: state.page, sort: state.sort, size: state.size })
        } else if (tabs[activeTab].index === 1) {
            getMissedPaymentSchedules(id, { page: state.page, sort: state.sort, size: state.size })
        } else {
            getSubsExternalPayments(defMembership.business?.id, id, { page: state.page, sort: state.sortOrder, size: state.size })
        }
    }

    return (
        <>
            <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                scheduledFor={state.scheduledFor}
                callbackFunctions={() => {
                    getSubscriber(id);
                    getSubsExternalPayments(defMembership.business?.id, id)
                    getAllPaymentSchedules(id);
                    getMissedPaymentSchedules(id);
                    getAllPaymentTracks(id);
                }}
            />
            <div className="title-div">
                <span className="transactions-title"> {t('subscriber.payment_transactions')}</span>
            </div>
            <div className="detail_card">
                <CustomDataTable
                    value={tabs[activeTab].value}
                    columns={tabs[activeTab].columns}
                    tabs={tabs.map(({ label, i }) => ({ label, i }))}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setState={setState}
                    tableClassName="tier_detail_data_table"
                    children={null}
                    loading={loading}
                    rows={state.size}
                    resetPage={onTabChange}
                />
                <Paginator className="subscription_detail_pagination" totalRecords={totalRecords} rows={state.size} first={state.first} onPageChange={onChange} />
            </div>
        </>
    )
}
export default TransactionsTabs;