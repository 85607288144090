import React, { Component, useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

const AppSubmenu = (props) => {
	const [state, setState] = useState({});
	const { t } = useTranslation("common");

	const onMenuItemClick = (event, item, index) => {
		//avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		if (props.root && props.onRootItemClick) {
			props.onRootItemClick({
				originalEvent: event,
				item: item,
			});
		}

		//execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
		}

		if (index === state.activeIndex) setState({ activeIndex: null });
		else setState({ activeIndex: index });

		if (props.onMenuItemClick) {
			props.onMenuItemClick({
				originalEvent: event,
				item: item,
			});
		}
	};

	const isHorizontalOrSlim = useCallback(() => {
		return props.layoutMode === "horizontal" || props.layoutMode === "slim";
	},[props.layoutMode]);

	useEffect(()=>{
		if (isHorizontalOrSlim()) {
			setState({ activeIndex: null });
		}
	},[isHorizontalOrSlim])

	const onKeyDown = (event, item, index) => {
		if (event.key === "Enter") {
			onMenuItemClick(event, item, index);
		}
	};

	const onMenuItemMouseEnter = (index) => {
		if (props.root && props.menuActive && isHorizontalOrSlim()) {
			setState({ activeIndex: index });
		}
	};


	const renderLinkContent = (item) => {
		let submenuIcon = item.items && (
			<i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
		);
		let badge = item.badge && (
			<span className="menuitem-badge">{item.badge}</span>
		);

		return (
			<React.Fragment>
				<i className={classNames("layout-menuitem-icon", item.icon)}></i>
				<span className="layout-menuitem-text">{t(item.label)}</span>
				{submenuIcon}
				{badge}
			</React.Fragment>
		);
	};

	const renderLink = (item, i) => {
		let content = renderLinkContent(item);

		if (item.to) {
			return (
				<NavLink
					activeClassName="active-route"
					to={item.to}
					onClick={(e) => onMenuItemClick(e, item, i)}
					exact
					role="menuitem"
					target={item.target}
					onMouseEnter={(e) => onMenuItemMouseEnter(i)}
					className={item.styleClass}
				>
					{content}
				</NavLink>
			);
		} else {
			return (
				<a
					href={item.url}
					tabIndex={item.url ? "" : 0}
					role="menuitem"
					onClick={(e) => onMenuItemClick(e, item, i)}
					target={item.target}
					onMouseEnter={(e) => onMenuItemMouseEnter(i)}
					onKeyDown={(e) => onKeyDown(e, item, i)}
					className={item.styleClass}
				>
					{content}
				</a>
			);
		}
	};

	const items =
		props.items &&
		props.items.map((item, i) => {
			let active = state.activeIndex === i;
			let styleClass = classNames(
				item.badgeStyleClass,
				{ "layout-root-menuitem": props.root },
				{ "active-menuitem": active }
			);
			let tooltip = props.root && (
				<div className="layout-menu-tooltip">
					<div className="layout-menu-tooltip-arrow"></div>
					<div className="layout-menu-tooltip-text">{t(item.label)}</div>
				</div>
			);

			return (
				<li className={styleClass} key={i} role="none">
					{item.items && props.root === true && <div className="arrow"></div>}
					{props.root && (
						<div>
							<span className="layout-menuitem-text">{t(item.label)}</span>
						</div>
					)}
					{renderLink(item, i)}
					{tooltip}
					<CSSTransition
						classNames="layout-submenu"
						timeout={{ enter: 400, exit: 400 }}
						in={active}
					>
						<AppSubmenu
							items={item.items}
							onMenuItemClick={props.onMenuItemClick}
							layoutMode={props.layoutMode}
							menuActive={props.menuActive}
						/>
					</CSSTransition>
				</li>
			);
		});

	return items ? (
		<ul role="menu" className={props.className}>
			{items}
		</ul>
	) : null;
};
AppSubmenu.defaultProps = {
	className: null,
	items: null,
	onMenuItemClick: null,
	onRootItemClick: null,
	root: false,
	layoutMode: null,
	menuActive: false,
};

AppSubmenu.propTypes = {
	className: PropTypes.string,
	items: PropTypes.array,
	onMenuItemClick: PropTypes.func,
	onRootItemClick: PropTypes.func,
	root: PropTypes.bool,
	layoutMode: PropTypes.string,
	menuActive: PropTypes.bool,
};

export class AppMenu extends Component {
	static defaultProps = {
		model: null,
		onMenuItemClick: null,
		onRootMenuItemClick: null,
		layoutMode: null,
		active: false,
	};

	static propTypes = {
		model: PropTypes.array,
		layoutMode: PropTypes.string,
		onMenuItemClick: PropTypes.func,
		onRootMenuItemClick: PropTypes.func,
		active: PropTypes.bool,
	};

	render() {
		return (
			<AppSubmenu
				items={this.props.model}
				className="layout-menu"
				menuActive={this.props.active}
				onRootItemClick={this.props.onRootMenuItemClick}
				onMenuItemClick={this.props.onMenuItemClick}
				root={true}
				layoutMode={this.props.layoutMode}
			/>
		);
	}
}
