import React, { useState, useCallback, useEffect } from "react";
import history from "../Service/history";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DefMembershipActions from "../Redux/actions/defMembership-actions";
import PlanGroupActions from "../Redux/transition/plan-group/plan-group.reducer";
import BusinessActions from "../Redux/actions/business-actions"
import DocumentActions from '../Redux/transition/document-upload/document-upload.reducer';
import PaymentActions from "../Redux/transition/payswitch/payswitch.reducer"
import { ActionButtons } from "./ActionButtons";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import Loading from "./Loading";
import { componentRoutes } from "../Route/ComponentRoutes";
import { useLocation } from 'react-router-dom';
import OnboardingNotification from "../Components/OnboardingNotification";

const Breadcrumbs = (props) => {
	const { t } = useTranslation("common");
	const location = useLocation();
	const pathnames = location.pathname.split("/").filter((x) => x);

	const findPath = (crumb) => {
		function hasNumbers(pathname) {
			// Define a regular expression to match any digit (\d)
			const digitRegex = /\d/;

			// Use the test method to check if the pathname contains a digit
			return digitRegex.test(pathname);
		}
		if (hasNumbers(location.pathname)) {
			const newLocationPath = location.pathname.replace(/\/\d+/g, '/:id');
			return componentRoutes.find(element => element.path === newLocationPath);
		}
		else {
			return componentRoutes.find(element => element.path === location.pathname);
		}
	}

	const newPath = findPath()?.breadcrumb
	const updatedPath = t(newPath)
	const resultArray = updatedPath?.split("/").filter((x) => x);
	const [customerId, setCustomerId] = useState("")
	const [planId, setPlanId] = useState("")
	const [productId, setProductId] = useState("");
	const [openNotification, setOpenNotification] = useState(false);
	const [subscriberObject, setSubscriberObject] = useState({})

	const defMembership = useSelector((state) => state.defMembership.defMembership)
	const business = useSelector((state) => state.businesses.business)
	const planGroupsGraphed = useSelector((state) => state.planGroups.planGroupsGraphed)
	const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
	const verificationStatus = useSelector((state) => state.documents.verifStatus);
	const paymentCredentials = useSelector((state) => state.payswitch.paymentCredentials);
	const businessCustomerRecord = useSelector((state) => state.businessCustomers.businessCustomerRecord)
	const plan = useSelector((state) => state.plans.plan)
	const fetchingPaymentCredentials = useSelector((state) => state.payswitch.fetchingCredentials);
	const subscriber = useSelector((state) => state.subscribers.subscriber)


	const dispatch = useDispatch()
	const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])
	const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
	const getBusiness = useCallback((businessId) => { dispatch(BusinessActions.businessRequest(businessId)) }, [dispatch])
	const getBusinessVerificationStatus = useCallback((businessId) => dispatch(DocumentActions.businessVerificationStatusRequest(businessId)), [dispatch]);
	const getPaymentCredentials = useCallback((businessId) => { dispatch(PaymentActions.paymentCredentialRequest(businessId)) }, [dispatch]);

	//Fetch breadcrumbs from session storage
	const getAppBreadcrumb = () => {
		let prevCrumbs = sessionStorage.getItem('crumbs');
		if (!prevCrumbs) {
			return prevCrumbs = [];
		}
		return prevCrumbs?.split(',');
	};

	function simulateSet(array) {
		const result = [];
		// Loop through the array using array.map
		array.forEach((value) => {
			// Check if the value is already in the result array
			const index = result.indexOf(value);
			if (index !== -1) {
				// If yes, remove the value from its previous index
				result.splice(index, 1);
			}
			// Push the value to the end of the result array
			result.push(value);
		});
		return result.slice(-1);
	}

	const updateBreadcrumb = (crumb) => {
		let savedCrumbs = crumb;
		if (crumb.length > 8) {
			savedCrumbs = crumb.slice((crumb.length - 8))
		}
		const finalCrumbs = simulateSet(savedCrumbs).join();
		sessionStorage.setItem('crumbs', finalCrumbs)
	};

	const [visible, setVisible] = useState(false)
	const [visiblePlan, setVisiblePlan] = useState(false)
	const [state, setState] = useState({
		visible: false,
		visiblePlan: false,
		hide: true,
		hidePlan: true,
	})

	const paymentSaved = paymentCredentials?.value

	useEffect(() => {
		if (!defMembership) {
			getMembership()
		}
		if (defMembership) {
			getBusiness(defMembership.business.id)
			getBusinessVerificationStatus(defMembership.business.id);
			getActivePlanGroups(defMembership.business.id)
			getPaymentCredentials(defMembership.business.id)
		}
		history.listen(location => {
			if (location.pathname === '/error' || location.pathname === '/denied' || location.pathname === '/not-found') {
				return updateBreadcrumb([]);
			}
			const newCrumb = [...getAppBreadcrumb(), location.pathname];
			updateBreadcrumb(newCrumb);
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	useEffect(() => {
		if (!business && defMembership) {
			getBusiness(defMembership.business.id)
		}
		if (!planGroupsGraphed && defMembership) {
			getActivePlanGroups(defMembership.business.id)
		}
		if (!verificationStatus && defMembership) {
			getBusinessVerificationStatus(defMembership.business.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [business, planGroupsGraphed, defMembership, verificationStatus])

	useEffect(() => {
		let loading = fetchingVerifStatus;
		setState(state => { return { ...state, fetchingVerifRecord: loading } });

		if (verificationStatus) {
			setState(state => { return { ...state, verifStatus: verificationStatus } })
		}
		if (businessCustomerRecord) {
			setCustomerId(businessCustomerRecord.id)
		}
		if (subscriber) {
			setSubscriberObject(subscriber.businessCustomer)
		}
		if (plan) {
			setPlanId(plan.id)
			setProductId(plan.groupId)
		}
	}, [fetchingVerifStatus, verificationStatus, businessCustomerRecord, plan, customerId, subscriber])

	//Toggle not Owner dialogue visibility
	const toggleVerifPendingDialogue = () => {
		setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible } })
	}

	//Toggle not Owner dialogue visibility
	const toggleNotOwnerDialogue = () => {
		setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible } })
	}
	//Toggle not Saved dialogue visibility
	const toggleNotSavedDialogue = () => {
		setState((state) => { return { ...state, notSavedVisible: !state.notSavedVisible } })
	}

	const toggleSetOpenDialog = () => {
		setOpenNotification(!openNotification);
	};

	//Dialog Footer for verif alert**
	const renderVerifFooter = (component) => {
		return (
			<div>
				<Button label={t("common.ok")} style={{ backgroundColor: "#d0021b" }} icon="pi pi-check" autoFocus
					onClick={component === 'not_owner' ? toggleNotOwnerDialogue : toggleVerifPendingDialogue}
				/>
			</div>
		);
	}
	const notSavedFooter = () => {
		return (
			<div>
				<Button label={t("common.ok")} icon="pi pi-check" autoFocus
					onClick={toggleNotSavedDialogue}
				/>
			</div>
		);
	}

	const toogleInviteSubscriber = () => {
		if (fetchingVerifStatus) {
			return <Loading />
		}
		else {
			if (business.verified && paymentSaved) {
				setVisible(true)

			}
			else if (!business.verified) {
				if (verificationStatus && !fetchingVerifStatus) {
					return toggleVerifPendingDialogue();
				}
				if (!verificationStatus && !fetchingVerifStatus) {
					switch (defMembership.role) {
						case "OWNER":
							return <OnboardingNotification visible={true} onHide={toggleSetOpenDialog} />
						default:
							toggleNotOwnerDialogue();
					}
				}
			}
			else if (business.verified && !paymentSaved) {

				switch (defMembership.role) {
					case "OWNER":
						history.push({ pathname: "/settings", state: { 'index': 1 } });
						break;
					default:
						toggleNotSavedDialogue();
				}
			}
		}
	}
	const tooglePlan = () => {
		setVisiblePlan(true)
	}
	const onHide = () => {
		setVisible(false)
	}
	const onHidePlan = () => {
		setVisiblePlan(false)
	}


	const toogleShareBusiness = () => {
		if (fetchingVerifStatus || fetchingPaymentCredentials) {
			return <Loading />;
		} else {
			if (!business.verified) {
				if (state.verifStatus && !state.fetchingVerifRecord) { return toggleVerifPendingDialogue(); } if (!state.verifStatus && !state.fetchingVerifRecord) {
					switch (defMembership.role) {
						case "OWNER":
							setOpenNotification(true)
							break;
						default:
							toggleNotOwnerDialogue();
					}
				}
			}
		}
	};


	return (
		<>
			<OnboardingNotification visible={openNotification} onHide={toggleSetOpenDialog} />
			<Dialog
				header={t("idoc.id_verif_pending_header")} visible={state.verifPendingVisible}
				onHide={toggleVerifPendingDialogue} style={{ width: "360px" }} footer={renderVerifFooter('verif_pending')}
			>
				<span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#ffb304", fontWeight: "bolder" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")} style={{ width: "360px" }} visible={state.notOwnerVisible}
				onHide={toggleNotOwnerDialogue} footer={renderVerifFooter('not_owner')}
			>
				<p>{t("idoc.not_owner_text")}</p>
			</Dialog>
			<Dialog
				header={t("idoc.not_owner_header")} visible={state.notSavedVisible}
				onHide={toggleNotSavedDialogue} style={{ width: "360px" }} footer={notSavedFooter}
			>
				<p>{t("idoc.not_saved_viewer")}</p>
			</Dialog>

			<div className='route-bar route-bar-div'>
				<div className='route-bar-breadcrumb'>
					{/* Link back to any previous steps of the breadcrumb. */}
					<div style={{ display: "flex" }}>
						{location.pathname === "/" ? null : <Link to="/"><i className="pi pi-home" /></Link>}
						{resultArray.map((value, index) => {
							const customerIndex = resultArray[1]
							const last = index === resultArray.length - 1;
							const to = `/${pathnames.slice(0, index + 1).join("/")}`;
							const noPath = () => {
								if (to === "/subscription" || to === "/reports" || to === "/money") {
									return true;
								} else {
									return false
								}
							}

							const checkForCustomerRecord = ["subscriptions", "subscribers"]
							const checkForPlanRecord = ["plan"]
							const containsValue = checkForCustomerRecord.some(value => pathnames.includes(value))
							const containsPlanValue = checkForPlanRecord.some(value => pathnames.includes(value))

							const handleProductNav = (value) => {
								value = value.trim().toLowerCase()
								if (value === resultArray[0].toLowerCase()) {
									history.push(`/products`)
								} else {
									history.push(`/products/detail/${productId}`)
								}
							};
							const handleSubscriberNav = (value) => {
								value = value.trim().toLowerCase()
								if (value === resultArray[0].toLowerCase()) {
									history.push(`/subscribers`)
								} else {
									history.push(`/subscribers/subscriber/${subscriberObject.id}`)
								}
							};
							return <div key={index}>
								{(() => {
									if (last || noPath()) {
										return (
											<span key={to}>&nbsp;&gt; {t(value)}</span>
										);
									} else if (containsValue && customerIndex === "Subscriber") {
										return (
											<span>
												&nbsp;&gt;	<span style={{ cursor: "pointer", color: "#E91E63" }} onClick={() => handleSubscriberNav(value)}>
													{t(value)}
												</span>
											</span>
										);
									} else if (planId && containsPlanValue) {

										return (
											<span>
												&nbsp;&gt; 	<span style={{ cursor: "pointer", color: "#E91E63" }} onClick={() => handleProductNav(value)}>
													{t(value)}
												</span>
											</span>
										);
									}
									else {
										return (
											<span>
												<span>
													&nbsp;&gt; <Link to={to}>{t(value)}</Link>
												</span>
											</span>
										);
									}
								})()}
							</div>
						}
						)}
					</div>
				</div>
			</div>
			<hr style={{ color: "#D9D9D9", margin: "0px 25px 0px 25px" }} />
			<div>{ActionButtons(visible, defMembership, toogleInviteSubscriber, onHide, onHidePlan, tooglePlan, visiblePlan, business, paymentCredentials, toogleShareBusiness)}</div>
		</>
	);
};
export default Breadcrumbs;