import { call, put } from "redux-saga/effects";
import * as api from "../../../Service/api";
import SubscriberActions from "./subscriber.reducer";

export function* getSubscriber(action) {
	const { subscriberId } = action;
	// make the call to the api

	const response = yield call(api.getSubscriber, subscriberId);

	// success?
	if (response.ok) {
		response.data = mapDateFields(response.data);
		yield put(SubscriberActions.subscriberSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberFailure(response.data));
	}
}

export function* getSubscribers(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.getSubscribers, businessId, options);
	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberAllSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberAllFailure(response.data));
	}
}
export function* searchSubscribers(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.searchSubscribers, businessId, options);
	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscribersSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscribersFailure(response.data));
	}
}
export function* countStatusSubscribers(action) {
	const { businessId, query } = action;
	// make the call to the api
	const response = yield call(api.countStatusSubscribers, businessId, query);
	//success?
	if (response.ok) {
		yield put(SubscriberActions.countStatusSubscribersSuccess(response.data));
	} else {
		yield put(SubscriberActions.countStatusSubscribersFailure(response.data));
	}
}

export function* countSearchSubscribers(action) {
	const { query, businessId, options } = action;
	// make the call to the api
	console.log();
	const response = yield call(api.getCountSearchSubscribers, query, businessId, options);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.countSearchSubscribersSuccess(response.data));
	} else {
		yield put(SubscriberActions.countSearchSubscribersFailure(response.data));
	}
}
export function* countDateSearchSubscribers(action) {
	const { query, businessId, startDate, endDate } = action;
	// make the call to the api

	const response = yield call(api.getCountDateSearchSubscribers, query, businessId, startDate, endDate);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.countDateSearchSubscribersSuccess(response.data));
	} else {
		yield put(SubscriberActions.countDateSearchSubscribersFailure(response.data));
	}
}
export function* getCountSubscribers(action) {
	const { businessId } = action;
	// make the call to the api

	const response = yield call(api.getCountSubscribers, businessId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberCountAllSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberCountAllFailure(response.data));
	}
}

export function* filterSubscribers(action) {
	const { query, businessId, options } = action;
	// make the call to the api
	const response = yield call(api.filterSubscribers, query, businessId, options);

	// success?
	if (response.ok) {

		yield put(SubscriberActions.subscriberFilterSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberFilterFailure(response.data));
	}
}
export function* countSubscribers(action) {
	const { query, filters } = action;
	// make the call to the api

	const response = yield call(api.countSubscribers, query, filters);

	// success?
	if (response.ok) {

		yield put(SubscriberActions.subscriberCountSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberCountFailure(response.data));
	}
}

export function* dateSearchSubscribers(action) {
	const { query, businessId, startDate, endDate, options } = action;
	// make the call to the api

	const response = yield call(
		api.dateFilterSubscribers, query, businessId, startDate, endDate, options);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberDateSearchSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberDateSearchFailure(response.data));
	}
}

export function* getPlanSubscribers(action) {
	const { planId } = action;
	// make the call to the api

	const response = yield call(api.getPlanSubscribers, planId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberPlanSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberPlanFailure(response.data));
	}
}

export function* updateSubscriber(action) {
	const { subscriber } = action;
	// make the call to the api
	const idIsNotNull = !!subscriber.id;

	const response = yield call(
		idIsNotNull ? api.updateSubscriber : api.createSubscriber,
		subscriber
	);

	// success?
	if (response.ok) {
		response.data = mapDateFields(response.data);
		yield put(SubscriberActions.subscriberUpdateSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberUpdateFailure(response.data));
	}
}

export function* deleteSubscriber(action) {
	const { subscriberId } = action;
	// make the call to the api

	const response = yield call(api.deleteSubscriber, subscriberId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberDeleteSuccess());
	} else {
		yield put(SubscriberActions.subscriberDeleteFailure(response.data));
	}
}

export function* rescheduleSubscriber(action) {
	const { subscriberId } = action;
	// make the call to the api

	const response = yield call(api.rescheduleSubscriber, subscriberId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberRescheduleSuccess());
	} else {
		yield put(SubscriberActions.subscriberRescheduleFailure(response.data));
	}
}

export function* checkExclusivity(action) {
	const { newPlanId, msisdn } = action;
	// make the call to the api

	const response = yield call(api.checkExclusivity, newPlanId, msisdn);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberExclusivitySuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberExclusivityFailure(response.data));
	}
}

export function* getSubscriberMissedSchedules(action) {
	const { subscriberId, missedByDate } = action;
	// make the call to the api

	const response = yield call(api.getSubscriberMissedSchedules, subscriberId, missedByDate);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscriberMissedSchedulesSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberMissedSchedulesFailure(response.data));
	}
}
export function* cancelSubscripition(action) {
	const { subscriptionId, membershipId } = action;
	// make the call to the api

	const response = yield call(api.cancelSubscription, subscriptionId, membershipId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.cancelSubscriptionSuccess(response.data));
	} else {
		yield put(SubscriberActions.cancelSubscriptionFailure(response.data));
	}
}

export function* pauseSubscription(action) {
	const { subscriptionId, membershipId } = action;
	// make the call to the api

	const response = yield call(api.pauseSubscription, subscriptionId, membershipId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.pauseSubscriptionSuccess(response.data));
	} else {
		yield put(SubscriberActions.pauseSubscriptionFailure(response.data));
	}
}
export function* resumeSubscription(action) {
	const { id, startFrom, membershipId } = action;
	// make the call to the api

	const response = yield call(api.resumeSubscription, id, startFrom, membershipId);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.resumeSubscriptionSuccess(response.data));
	} else {
		yield put(SubscriberActions.resumeSubscriptionFailure(response.data));
	}
}

export function* subscribersBulkCancel(action) {
	const { businessId, membershipId, ids } = action;
	// make the call to the api

	const response = yield call(api.subscribersBulkCancel, businessId, membershipId, ids);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscribersBulkCancelSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscribersBulkCancelFailure(response.data));
	}

} export function* subscribersBulkPause(action) {
	const { businessId, membershipId, ids } = action;
	// make the call to the api

	const response = yield call(api.subscribersBulkPause, businessId, membershipId, ids);

	// success?
	if (response.ok) {
		yield put(SubscriberActions.subscribersBulkPauseSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscribersBulkPauseFailure(response.data));
	}
}

export function* subscriptionReschedule(action) {
	const { subscriberId, startFrom, membershipId } = action

	const response = yield call(api.rescheduleSubscription, subscriberId, startFrom, membershipId)

	if (response.ok) {
		yield put(SubscriberActions.rescheduleSubscriptionSuccess(response.data));
	} else {
		yield put(SubscriberActions.rescheduleSubscriptionFailure(response.data));
	}
}
export function* getSubscriberRevenue(action) {
	const { subscriberId } = action

	const response = yield call(api.getSubscriberRevenue, subscriberId)

	if (response.ok) {
		yield put(SubscriberActions.subscriberRevenueSuccess(response.data));
	} else {
		yield put(SubscriberActions.subscriberRevenueFailure(response.data));
	}
}

function mapDateFields(data) {
	if (data.joinedOn) {
		data.joinedOn = new Date(data.joinedOn);
	}
	if (data.lastBilledOn) {
		data.lastBilledOn = new Date(data.lastBilledOn);
	}
	return data;
}
