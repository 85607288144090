import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
//getPaymentScheduleConsole
const { Types, Creators } = createActions({
	paymentScheduleRequest: ["paymentScheduleId"],
	paymentScheduleRangeRequest: [
		"businessId",
		"startDate",
		"endDate",
		"options",
	],
	paymentScheduleSummaryRequest: [
		"businessId",
		"startDate",
		"endDate",
	],
	WeeklyPaymentScheduleSummaryRequest: [
		"businessId",
		"startDate",
		"endDate",
	],
	threeMonthsPaymentScheduleSummaryRequest: [
		"businessId",
		"startDate",
		"endDate",
	],
	sixMonthsPaymentScheduleSummaryRequest: [
		"businessId",
		"startDate",
		"endDate",
	],
	twelveMonthsPaymentScheduleSummaryRequest: [
		"businessId",
		"startDate",
		"endDate",
	],
	paymentScheduleConsoleRequest: ["query"],
	paymentScheduleProjectionRequest: ["businessId", "startDate", "endDate"],
	paymentScheduleAllRequest: ["subscriberId", "options"],
	paymentScheduleMissedRequest: ["subscriberId", "options"],
	paymentSchedulePendingRequest: ["subscriberId", "options"],
	paymentScheduleUpdateRequest: ["paymentSchedule"],
	paymentScheduleDeleteRequest: ["paymentScheduleId"],
	paymentScheduleNotifyRequest: ["paymentScheduleId"],
	dailyChartsRequest: ["businessId", "startDate", "endDate"],
	weeklyChartsRequest: ["businessId", "startDate", "endDate"],
	threeMonthsChartsRequest: ["businessId", "startDate", "endDate"],
	sixMonthsChartsRequest: ["businessId", "startDate", "endDate"],
	twelveMonthsChartsRequest: ["businessId", "startDate", "endDate"],
	missedPaymentSchedulesCountRequest: ["subscriberId", "options"],

	paymentScheduleSuccess: ["paymentSchedule"],
	paymentScheduleRangeSuccess: ["paymentScheduleRange"],
	paymentScheduleConsoleSuccess: ["queryResponse"],
	paymentScheduleSummarySuccess: ["paymentScheduleSummary"],
	paymentScheduleProjectionSuccess: ["projection"],
	paymentScheduleAllSuccess: ["paymentSchedules"],
	paymentScheduleMissedSuccess: ["paymentSchedulesMissed"],
	paymentSchedulePendingSuccess: ["paymentSchedulesPending"],
	paymentScheduleUpdateSuccess: ["paymentSchedule"],
	paymentScheduleDeleteSuccess: [],
	paymentScheduleNotifySuccess: ["paymentSchedule"],
	dailyChartsSuccess: ["dailyCharts"],
	weeklyChartsSuccess: ["weeklyCharts"],
	threeMonthsChartsSuccess: ["threeMonthsCharts"],
	sixMonthsChartsSuccess: ["sixMonthsCharts"],
	twelveMonthsChartsSuccess: ["twelveMonthsCharts"],
	weeklyPaymentScheduleSummarySuccess: ["weeklyPaymentSummary"],
	threeMonthsPaymentScheduleSummarySuccess: ["threeMonthsPaymentSummary"],
	sixMonthsPaymentScheduleSummarySuccess: ["sixMonthsPaymentSummary"],
	twelveMonthsPaymentScheduleSummarySuccess: ["twelveMonthsPaymentSummary"],
	missedPaymentSchedulesCountSuccess: ["count"],

	paymentScheduleFailure: ["error"],
	paymentScheduleRangeFailure: ["error"],
	paymentScheduleConsoleFailure: ["error"],
	paymentScheduleSummaryFailure: ["error"],
	paymentScheduleProjectionFailure: ["error"],
	paymentScheduleAllFailure: ["error"],
	paymentScheduleMissedFailure: ["error"],
	paymentSchedulePendingFailure: ["error"],
	paymentScheduleUpdateFailure: ["error"],
	paymentScheduleDeleteFailure: ["error"],
	paymentScheduleNotifyFailure: ["error"],
	dailyChartsFailure: ["error"],
	weeklyChartsFailure: ["error"],
	threeMonthsChartsFailure: ["error"],
	sixMonthsChartsFailure: ["error"],
	twelveMonthsChartsFailure: ["error"],
	weeklyPaymentScheduleSummaryFailure: ["error"],
	threeMonthsPaymentScheduleSummaryFailure: ["error"],
	sixMonthsPaymentScheduleSummaryFailure: ["error"],
	twelveMonthsPaymentScheduleSummaryFailure: ["error"],
	missedPaymentSchedulesCountFailure: ["error"],

});

export const PaymentScheduleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingOne: null,
	fetchingAll: null,
	fetchingMissed: null,
	fetchingPending: null,
	fetchingConsole: null,
	fetchingRange: null,
	fetchingProjection: null,
	fetchingSummary: null,
	updating: null,
	notifying: null,
	deleting: null,
	fetchingDailyCharts: null,
	fetchingWeeklyCharts: null,
	fetchingThreeMonthsCharts: null,
	fetchingSixMonthsCharts: null,
	fetchingTwelveMonthsCharts: null,
	fetchingWeeklySummary: null,
	fetchingThreeMonthsSummary: null,
	fetchingSixMonthsSummary: null,
	fetchingTwelveMonthsSummary: null,
	fetchingMissedPaymentScheduleCount: null,

	paymentSchedule: null,
	paymentSchedules: null,
	paymentSchedulesMissed: null,
	paymentSchedulesPending: null,
	paymentScheduleConsole: null,
	paymentScheduleRange: null,
	paymentScheduleSummary: null,
	projection: null,
	dailyCharts: null,
	weeklyCharts: null,
	threeMonthsCharts: null,
	sixMonthsCharts: null,
	twelveMonthsCharts: null,
	weeklyPaymentSummary: null,
	threeMonthsPaymentSummary: null,
	sixMonthsPaymentSummary: null,
	twelveMonthsPaymentSummary: null,
	missedPaymentSchedulesCount: null,

	errorOne: null,
	errorAll: null,
	errorMissed: null,
	errorPending: null,
	errorRange: null,
	errorConsole: null,
	errorProjection: null,
	errorSummary: null,
	errorUpdating: null,
	errorDeleting: null,
	errorNotifying: null,
	errorFetchingDailyCharts: null,
	errorFetchingWeeklyCharts: null,
	errorFetchingThreeMonthsCharts: null,
	errorFetchingSixMonthsCharts: null,
	errorFetchingTwelveMonthsCharts: null,
	errorFetchingWeeklySummary: null,
	errorFetchingThreeMonthsSummary: null,
	errorFetchingSixMonthsSummary: null,
	errorFetchingTwelveMonthsSummary: null,
	errorFetchingMissedPaymentSchedulesCount: null
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
	return { ...state, fetchingOne: true, paymentSchedule: null };
};

// request the data from an api
export const allRequest = (state) => {
	return { ...state, fetchingAll: true, paymentSchedules: null };
};
// request the data from an api
export const missedRequest = (state) => {
	return { ...state, fetchingMissed: true, paymentSchedulesMissed: null };
};
// request the data from an api
export const pendingRequest = (state) => {
	return { ...state, fetchingPending: true, paymentSchedulesPending: null };
};

export const consoleRequest = (state) => {
	return { ...state, fetchingConsole: true, paymentScheduleConsole: null };
};

// request the range of schedules from an api
export const rangeRequest = (state) => {
	return { ...state, fetchingRange: true, paymentScheduleRange: null };
};

// request the projection from an api
export const projectionRequest = (state) => {
	return { ...state, fetchingProjection: true, projection: null };
};

// request the summary from an api
export const summaryRequest = (state) => {
	return { ...state, fetchingSummary: true, paymentScheduleSummary: null };
};
// request the weekly payment summary from an api
export const weeklySummaryRequest = (state) => {
	return { ...state, fetchingWeeklySummary: true, weeklyPaymentSummary: null };
};

// request the 3 months payment summary from an api
export const threeMonthsSummaryRequest = (state) => {
	return { ...state, fetchingThreeMonthsSummary: true, threeMonthsPaymentSummary: null };
};

// request the 6 months payment summary from an api
export const sixMonthsSummaryRequest = (state) => {
	return { ...state, fetchingSixMonthsSummary: true, sixMonthsPaymentSummary: null };
};

// request the 3 months payment summary from an api
export const twelveMonthsSummaryRequest = (state) => {
	return { ...state, fetchingTwelveMonthsSummary: true, twelveMonthsPaymentSummary: null };
};

// request to update from an api
export const notifyRequest = (state) => {
	return { ...state, notifying: true };
};

// request to update from an api
export const updateRequest = (state) => {
	return { ...state, updating: true };
};
// request to de    }ete from an api
export const deleteRequest = (state) => {
	return { ...state, deleting: true };
};

//request to fetch daily charts from api
export const getDailyChartsRequest = (state) => {
	return { ...state, fetchingDailyCharts: true }
}

//request to fetch weekly charts from api
export const getWeeklyChartsRequest = (state) => {
	return { ...state, fetchingWeeklyCharts: true }
}

//request to fetch 3 months charts from api
export const getThreeMonthsChartsRequest = (state) => {
	return { ...state, fetchingThreeMonthsCharts: true }
}

//request to fetch 6 months charts from api
export const getSixMonthsChartsRequest = (state) => {
	return { ...state, fetchingSixMonthsCharts: true }
}

//request to fetch 12 months charts from api
export const getTwelveMonthsChartsRequest = (state) => {
	return { ...state, fetchingTwelveMonthsCharts: true }
}

//request to fetch missed payments schedule count
export const missedPaymentSchedulesCount = (state) => {
	return { ...state, fetchingMissedPaymentScheduleCount: true, missedPaymentSchedulesCount: null, errorFetchingMissedPaymentSchedulesCount: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
	const { paymentSchedule } = action;
	return { ...state, fetchingOne: false, errorOne: null, paymentSchedule };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
	const { paymentSchedules } = action;
	return { ...state, fetchingAll: false, errorAll: null, paymentSchedules };
};
// successful api lookup for missed entities
export const missedSuccess = (state, action) => {
	const { paymentSchedulesMissed } = action;
	return { ...state, fetchingMissed: false, errorMissed: null, paymentSchedulesMissed };
};
// successful api lookup for pending entities
export const pendingSuccess = (state, action) => {
	const { paymentSchedulesPending } = action;
	return { ...state, fetchingPending: false, errorPending: null, paymentSchedulesPending };
};
export const consoleSuccess = (state, action) => {
	const { paymentScheduleConsole } = action;
	return { ...state, fetchingConsole: false, errorConsole: null, paymentScheduleConsole };
};

// successful api lookup for range of schedules
export const rangeSuccess = (state, action) => {
	const { paymentScheduleRange } = action;
	return {
		...state,
		fetchingRange: false,
		errorRange: null,
		paymentScheduleRange,
	};
};

// successful api lookup the projections for a schedule range
export const projectionSuccess = (state, action) => {
	const { projection } = action;
	return {
		...state,
		fetchingProjection: false,
		errorProjection: null,
		projection,
	};
};

// successful api lookup for summary of schedules
export const summarySuccess = (state, action) => {
	const { paymentScheduleSummary } = action;
	return {
		...state,
		fetchingSummary: false,
		errorSummary: null,
		paymentScheduleSummary,
	};
};

// successful api lookup for weekly summary of schedules
export const weeklySummarySuccess = (state, action) => {
	const { weeklyPaymentSummary } = action;
	return {
		...state,
		fetchingWeeklySummary: false,
		errorFetchingWeeklySummary: null,
		weeklyPaymentSummary,
	};
};

// successful api lookup for three months summary of schedules
export const threeMonthsSummarySuccess = (state, action) => {
	const { threeMonthsPaymentSummary } = action;
	return {
		...state,
		fetchingThreeMonthsSummary: false,
		errorFetchingThreeMonthsSummary: null,
		threeMonthsPaymentSummary,
	};
};

// successful api lookup for six months summary of schedules
export const sixMonthsSummarySuccess = (state, action) => {
	const { sixMonthsPaymentSummary } = action;
	return {
		...state,
		fetchingSixMonthsSummary: false,
		errorFetchingSixMonthsSummary: null,
		sixMonthsPaymentSummary,
	};
};

// successful api lookup for twelve months summary of schedules
export const twelveMonthsSummarySuccess = (state, action) => {
	const { twelveMonthsPaymentSummary } = action;
	return {
		...state,
		fetchingTwelveMonthsSummary: false,
		errorFetchingTwelveMonthsSummary: null,
		twelveMonthsPaymentSummary,
	};
};

// successful api update
export const updateSuccess = (state, action) => {
	const { paymentSchedule } = action;
	return { ...state, updating: false, errorUpdating: null, paymentSchedule };
};

// successful api update
export const notifySuccess = (state, action) => {
	const { paymentSchedule } = action;
	return { ...state, notifying: false, errorUpdating: null, paymentSchedule };
};

// successful api delete
export const deleteSuccess = (state) => {
	return {
		...state,
		deleting: false,
		errorDeleting: null,
		paymentSchedule: null,
	};
};

// successful api fetching of daily charts
export const getDailyChartsSuccess = (state, action) => {
	const { dailyCharts } = action
	return {
		...state,
		fetchingDailyCharts: false,
		errorFetchingDailyCharts: null,
		dailyCharts,
	};
};

// successful api fetching of weekly charts
export const getWeeklyChartsSuccess = (state, action) => {
	const { weeklyCharts } = action
	return {
		...state,
		fetchingWeeklyCharts: false,
		errorFetchingWeeklyCharts: null,
		weeklyCharts,
	};
};

// successful api fetching of three months charts
export const getThreeMonthsChartsSuccess = (state, action) => {
	const { threeMonthsCharts } = action
	return {
		...state,
		fetchingThreeMonthsCharts: false,
		errorFetchingThreeMonthsCharts: null,
		threeMonthsCharts,
	};
};

// successful api fetching of six months charts
export const getSixMonthsChartsSuccess = (state, action) => {
	const { sixMonthsCharts } = action
	return {
		...state,
		fetchingSixMonthsCharts: false,
		errorFetchingSixMonthsCharts: null,
		sixMonthsCharts,
	};
};

// successful api fetching of twelve months charts
export const getTwelveMonthsChartsSuccess = (state, action) => {
	const { twelveMonthsCharts } = action
	return {
		...state,
		fetchingTwelveMonthsCharts: false,
		errorFetchingTwelveMonthsCharts: null,
		twelveMonthsCharts,
	};
};

//successful api fetching of missed payments schedule count
export const missedPaymentSchedulesCountSuccess = (state, action) => {
	const { count } = action
	return { ...state, fetchingMissedPaymentScheduleCount: false, missedPaymentSchedulesCount: count, errorFetchingMissedPaymentSchedulesCount: null }
}


// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingOne: false,
		errorOne: error,
		paymentSchedule: null,
	};
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingAll: false,
		errorAll: error,
		paymentSchedules: null,
	};
};
// Something went wrong fetching missed entities.
export const missedFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingMissed: false,
		errorMissed: error,
		paymentSchedulesMissed: null,
	};
};
// Something went wrong fetching pending entities.
export const pendingFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingPending: false,
		errorPending: error,
		paymentSchedulesPending: null,
	};
};

export const consoleFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingConsole: false,
		errorConsole: error,
		paymentScheduleConsole: null,
	};
};

// Something went wrong fetching range of schedules
export const rangeFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingRange: false,
		errorRange: error,
		paymentScheduleRange: null,
	};
};

// Something went wrong fetching range of schedules
export const projectionFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingProjection: false,
		errorProjection: error,
		projection: null,
	};
};

// Something went wrong fetching summary of schedules
export const summaryFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingSummary: false,
		errorSummary: error,
		paymentScheduleSummary: null,
	};
};
// Something went wrong fetching weekly summary of schedules
export const weeklySummaryFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingWeeklySummary: false,
		errorFetchingWeeklySummary: error,
		weeklyPaymentSummary: null,
	};
};

// Something went wrong fetching 3 months summary of schedules
export const threeMonthsSummaryFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingThreeMonthsSummary: false,
		errorFetchingThreeMonthsSummary: error,
		threeMonthsPaymentSummary: null,
	};
};

// Something went wrong fetching 6 months summary of schedules
export const sixMonthsSummaryFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingSixMonthsSummary: false,
		errorFetchingSixMonthsSummary: error,
		sixMonthsPaymentSummary: null,
	};
};

// Something went wrong fetching 12 months summary of schedules
export const twelveMonthsSummaryFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingTwelveMonthsSummary: false,
		errorFetchingTwelveMonthsSummary: error,
		twelveMonthsPaymentSummary: null,
	};
};

// Something went wrong updating.
export const updateFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		updating: false,
		errorUpdating: error,
		paymentSchedule: state.paymentSchedule,
	};
};
export const notifyFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		notifying: false,
		errorNotifying: error,
		paymentSchedule: state.paymentSchedule,
	};
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		deleting: false,
		errorDeleting: error,
		paymentSchedule: state.paymentSchedule,
	};
};

// Something went wrong fetching daily charts.
export const getDailyChartsFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingDailyCharts: false,
		errorFetchingDailyCharts: error,
		dailyCharts: false,
	};
};

// Something went wrong fetching weekly charts.
export const getWeeklyChartsFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingWeeklyCharts: false,
		errorFetchingWeeklyCharts: error,
		weeklyCharts: false,
	};
};

// Something went wrong fetching 3 months charts.
export const getThreeMonthsChartsFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingThreeMonthsCharts: false,
		errorFetchingThreeMonthsCharts: error,
		threeMonthsCharts: false,
	};
};

// Something went wrong fetching 6 months charts.
export const getSixMonthsChartsFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingSixMonthsCharts: false,
		errorFetchingSixMonthsCharts: error,
		sixMonthsCharts: false,
	};
};

// Something went wrong fetching 12 months charts.
export const getTwelveMonthsChartsFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingTwelveMonthsCharts: false,
		errorFetchingTwelveMonthsCharts: error,
		twelveMonthsCharts: false,
	};
};

//successful api fetching of missed payments schedule count
export const missedPaymentSchedulesCountFailure = (state, action) => {
	const { error } = action
	return { ...state, fetchingMissedPaymentScheduleCount: false, missedPaymentSchedulesCount: null, errorFetchingMissedPaymentSchedulesCount: error }
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PAYMENT_SCHEDULE_REQUEST]: request,
	[Types.PAYMENT_SCHEDULE_CONSOLE_REQUEST]: consoleRequest,
	[Types.PAYMENT_SCHEDULE_RANGE_REQUEST]: rangeRequest,
	[Types.PAYMENT_SCHEDULE_PROJECTION_REQUEST]: projectionRequest,
	[Types.PAYMENT_SCHEDULE_SUMMARY_REQUEST]: summaryRequest,
	[Types.PAYMENT_SCHEDULE_ALL_REQUEST]: allRequest,
	[Types.PAYMENT_SCHEDULE_MISSED_REQUEST]: missedRequest,
	[Types.PAYMENT_SCHEDULE_PENDING_REQUEST]: pendingRequest,
	[Types.PAYMENT_SCHEDULE_UPDATE_REQUEST]: updateRequest,
	[Types.PAYMENT_SCHEDULE_DELETE_REQUEST]: deleteRequest,
	[Types.PAYMENT_SCHEDULE_NOTIFY_REQUEST]: notifyRequest,
	[Types.DAILY_CHARTS_REQUEST]: getDailyChartsRequest,
	[Types.WEEKLY_CHARTS_REQUEST]: getWeeklyChartsRequest,
	[Types.THREE_MONTHS_CHARTS_REQUEST]: getThreeMonthsChartsRequest,
	[Types.SIX_MONTHS_CHARTS_REQUEST]: getSixMonthsChartsRequest,
	[Types.TWELVE_MONTHS_CHARTS_REQUEST]: getTwelveMonthsChartsRequest,
	[Types.WEEKLY_PAYMENT_SCHEDULE_SUMMARY_REQUEST]: weeklySummaryRequest,
	[Types.THREE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST]: threeMonthsSummaryRequest,
	[Types.SIX_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST]: threeMonthsSummaryRequest,
	[Types.TWELVE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_REQUEST]: threeMonthsSummaryRequest,
	[Types.MISSED_PAYMENT_SCHEDULES_COUNT_REQUEST]: missedPaymentSchedulesCount,

	[Types.PAYMENT_SCHEDULE_SUCCESS]: success,
	[Types.PAYMENT_SCHEDULE_CONSOLE_SUCCESS]: consoleSuccess,
	[Types.PAYMENT_SCHEDULE_ALL_SUCCESS]: allSuccess,
	[Types.PAYMENT_SCHEDULE_MISSED_SUCCESS]: missedSuccess,
	[Types.PAYMENT_SCHEDULE_PENDING_SUCCESS]: pendingSuccess,
	[Types.PAYMENT_SCHEDULE_RANGE_SUCCESS]: rangeSuccess,
	[Types.PAYMENT_SCHEDULE_PROJECTION_SUCCESS]: projectionSuccess,
	[Types.PAYMENT_SCHEDULE_SUMMARY_SUCCESS]: summarySuccess,
	[Types.PAYMENT_SCHEDULE_UPDATE_SUCCESS]: updateSuccess,
	[Types.PAYMENT_SCHEDULE_DELETE_SUCCESS]: deleteSuccess,
	[Types.PAYMENT_SCHEDULE_NOTIFY_SUCCESS]: notifySuccess,
	[Types.DAILY_CHARTS_SUCCESS]: getDailyChartsSuccess,
	[Types.WEEKLY_CHARTS_SUCCESS]: getWeeklyChartsSuccess,
	[Types.THREE_MONTHS_CHARTS_SUCCESS]: getThreeMonthsChartsSuccess,
	[Types.SIX_MONTHS_CHARTS_SUCCESS]: getSixMonthsChartsSuccess,
	[Types.TWELVE_MONTHS_CHARTS_SUCCESS]: getTwelveMonthsChartsSuccess,
	[Types.WEEKLY_PAYMENT_SCHEDULE_SUMMARY_SUCCESS]: weeklySummarySuccess,
	[Types.THREE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_SUCCESS]: threeMonthsSummarySuccess,
	[Types.SIX_MONTHS_PAYMENT_SCHEDULE_SUMMARY_SUCCESS]: sixMonthsSummarySuccess,
	[Types.TWELVE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_SUCCESS]: twelveMonthsSummarySuccess,
	[Types.MISSED_PAYMENT_SCHEDULES_COUNT_SUCCESS]: missedPaymentSchedulesCountSuccess,

	[Types.PAYMENT_SCHEDULE_FAILURE]: failure,
	[Types.PAYMENT_SCHEDULE_ALL_FAILURE]: allFailure,
	[Types.PAYMENT_SCHEDULE_MISSED_FAILURE]: missedFailure,
	[Types.PAYMENT_SCHEDULE_PENDING_FAILURE]: pendingFailure,
	[Types.PAYMENT_SCHEDULE_CONSOLE_FAILURE]: consoleFailure,
	[Types.PAYMENT_SCHEDULE_RANGE_FAILURE]: rangeFailure,
	[Types.PAYMENT_SCHEDULE_PROJECTION_FAILURE]: projectionFailure,
	[Types.PAYMENT_SCHEDULE_SUMMARY_FAILURE]: summaryFailure,
	[Types.PAYMENT_SCHEDULE_UPDATE_FAILURE]: updateFailure,
	[Types.PAYMENT_SCHEDULE_DELETE_FAILURE]: deleteFailure,
	[Types.PAYMENT_SCHEDULE_NOTIFY_FAILURE]: notifyFailure,
	[Types.DAILY_CHARTS_FAILURE]: getDailyChartsFailure,
	[Types.WEEKLY_CHARTS_FAILURE]: getWeeklyChartsFailure,
	[Types.THREE_MONTHS_CHARTS_FAILURE]: getThreeMonthsChartsFailure,
	[Types.SIX_MONTHS_CHARTS_FAILURE]: getSixMonthsChartsFailure,
	[Types.TWELVE_MONTHS_CHARTS_FAILURE]: getTwelveMonthsChartsFailure,
	[Types.WEEKLY_PAYMENT_SCHEDULE_SUMMARY_FAILURE]: weeklySummaryFailure,
	[Types.THREE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_FAILURE]: threeMonthsSummaryFailure,
	[Types.SIX_MONTHS_PAYMENT_SCHEDULE_SUMMARY_FAILURE]: sixMonthsSummaryFailure,
	[Types.TWELVE_MONTHS_PAYMENT_SCHEDULE_SUMMARY_FAILURE]: twelveMonthsSummaryFailure,
	[Types.MISSED_PAYMENT_SCHEDULES_COUNT_FAILURE]: missedPaymentSchedulesCountFailure,

});
