/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//Redux imports
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
//Component import
import Loading from "../../../Components/Loading";
import Summary from "../index";
//Prime react import
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Carousel } from "primereact";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";
//other imports
import "../../../Styles/pages/transactions.scss";
import ProductAccountsTable from "./ProductAccountsTable";
import AddProductAccountDialog from "./AddProductAccountDialog";

function ProductAccount() {
    const history = useHistory();
    const toast = useRef(null);
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const [favouritedWallets, setFavouritedWallets] = useState(null);
    const [removeFavorite, setRemoveFavorite] = useState(false);
    const [favData, setFavData] = useState({});

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroups = useSelector((state) => state.planGroups.planGroups);
    const planGroupsCount = useSelector((state) => state.planGroups.count);
    const fetchingPlanGroupsCount = useSelector((state) => state.planGroups.fetchingPendingCount);
    const fetchingPlanGroups = useSelector((state) => state.planGroups.fetchingAll);
    const fetchingWallet = useSelector((state) => state.wallet.fetchingOne);
    const fetchingPendingCount = useSelector((state) => state.settlements.fetchingStatusCount);
    const favouritingWallet = useSelector((state) => state.wallet.favouritingWallet);
    const unfavouritingWallet = useSelector((state) => state.wallet.unfavouritingWallet);
    const favouriteWallets = useSelector((state) => state.wallet.favouriteWallets);
    const unfavouriteWallet = useSelector((state) => state.wallet.unfavouriteWallet);
    const errorFavouriteWallet = useSelector((state) => state.wallet.errorFavouriteWallet);
    const errorUnfavouriteWallet = useSelector((state) => state.wallet.errorUnfavouriteWallet);
    const favouriteWallet = useSelector((state) => state.wallet.favouriteWallet);
    const fetchingFavouriteWallets = useSelector((state) => state.wallet.fetchingFavouriteWallets);
    const fetchingWalletStatistics = useSelector((state) => state.wallet.fetchingWalletStatistics);
    const walletStatistics = useSelector((state) => state.wallet.walletStatistics);
    const filterPlanGroups = useSelector((state) => state.planGroups.filterPlanGroups);
    const filteringPlanGroups = useSelector((state) => state.planGroups.filteringPlanGroups);

    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    const getPlanGroups = useCallback(
        (businessId, options) => {
            dispatch(PlanGroupActions.planGroupAllRequest(businessId, options));
        },
        [dispatch]
    );
    const getPlanGroupsFilter = useCallback(
        (businessId, name, options) => {
            dispatch(PlanGroupActions.planGroupsFilterRequest(businessId, name, options));
        },
        [dispatch]
    );
    const getPlanGroupsCount = useCallback(
        (businessId) => {
            dispatch(PlanGroupActions.planGroupAllCountRequest(businessId));
        },
        [dispatch]
    );
    const getWallet = useCallback(
        (businessId) => {
            dispatch(WalletActions.walletRequest(businessId));
        },
        [dispatch]
    );
    const getWalletStatistics = useCallback(
        (businessId, options) => {
            dispatch(WalletActions.walletStatisticsRequest(businessId, options));
        },
        [dispatch]
    );
    const getSettlementsStatusCount = useCallback(
        (businessId) => {
            dispatch(SettlementActions.settlementStatusCountRequest(businessId, "PENDING"));
        },
        [dispatch]
    );
    const getFavouriteWallets = useCallback(
        (businessId) => {
            dispatch(WalletActions.favouriteWalletsRequest(businessId));
        },
        [dispatch]
    );
    const getFavouriteWallet = useCallback(
        (id) => {
            dispatch(WalletActions.favouriteWalletRequest(id));
        },
        [dispatch]
    );
    const getUnfavouriteWallet = useCallback(
        (id) => {
            dispatch(WalletActions.unfavouriteWalletRequest(id));
        },
        [dispatch]
    );

    const [state, setState] = useState({
        planGroups: null,
        filterPlanGroups: null,
        planGroupsGraphed: null,
        planGroupsCount: 0,
        size: 15,
        first: 0,
        sort: "id,desc",
        favouriting: null,
        favouritingId: null,
        unfavouritingId: null,
        unfavouriting: null,
        favouriteWallets: null,
        favouritingWallet: false,
        unfavouritingWallet: false,
        isNewWallet: false,
        newWallet: {},
        success: false,
        requesting: null,
        newFav: null,
        addNewFavVisible: false,
        callFav: false,
        input: "",
        submitInput: false,
        isFiltered: false,
        name: "",
        fetchingWalletStatistics: false,
        walletStatistics: null
    });
    const [walletName, setWalletName] = useState('')

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getPlanGroupsCount(defMembership.business.id);
            getWallet(defMembership.business.id);
            getWalletStatistics(defMembership.business.id, {});
            getSettlementsStatusCount(defMembership.business.id);
            getFavouriteWallets(defMembership.business.id);
            getPlanGroups(defMembership.business.id);
        }
    }, []);

    useEffect(() => {
        if (defMembership) {
            getPlanGroups(defMembership.business.id);
        }
    }, [defMembership]);

    useEffect(() => {
        if (defMembership) {
            getPlanGroups(defMembership.business.id, state.query);
        }
    }, [state.query, defMembership]);

    useEffect(() => {
        if (defMembership) {
            getWalletStatistics(defMembership.business.id);
        }
    }, [defMembership]);

    useEffect(() => {
        if (planGroups) {
            setState((state) => ({ ...state, planGroups: planGroups }));
        }
        if (walletStatistics) {
            setState((state) => ({ ...state, walletStatistics: walletStatistics }));
        }
        if (planGroupsCount) {
            setState((state) => {
                return { ...state, planGroupsCount: planGroupsCount };
            });
        }
        if (filterPlanGroups) {
            setState((state) => ({ ...state, filterPlanGroups: filterPlanGroups }));
        }
        if (favouriteWallets) {
            setState((state) => ({ ...state, favouriteWallets: favouriteWallets }));
        }
        function cleanup() { }
        return cleanup();
    }, [planGroups, planGroupsCount, unfavouriteWallet, errorUnfavouriteWallet, state.favouriting, state.unfavouriting, walletStatistics]);

    useEffect(() => {
        if (!favouritingWallet && state.callFav) {
            if (errorFavouriteWallet) {
                toast.current.show({ severity: "error", summary: "Error Message", detail: t("product_account.err_add_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    code: "added",
                    summary: t("successful"),
                    detail: `${t("product_account.save_fav")}`,
                    life: 3000,
                });
            }
            setState((prev) => {
                return { ...prev, callFav: false, newFav: null };
            });
        }
        if (!unfavouritingWallet && state.loadUnFav) {
            if (!errorUnfavouriteWallet) {
                showRemoveSuccess()
            } else {
                toast.current.show({ severity: "error", summary: "Error Message", detail: t("product_account.err_removed_fav") });
            }
            setState((prev) => {
                return { ...prev, loadUnFav: false };
            });
        }
    }, [unfavouritingWallet, getFavouriteWallets, errorUnfavouriteWallet, favouritingWallet, errorFavouriteWallet, state.newFav, state.callFav, state.loadUnFav]);

    useEffect(() => {
        if (errorFavouriteWallet && state.favouritingWallet && favouritingWallet) {
            getFavouriteWallets(defMembership.business.id);
            showAddedError();
        }
        if (unfavouritingWallet && state.unfavouritingWallet && errorUnfavouriteWallet) {
            getFavouriteWallets(defMembership.business.id);
            showRemoveError();
        }
    }, [
        errorFavouriteWallet,
        favouritingWallet,
        state.favouritingWallet,
        favouriteWallet,
        unfavouritingWallet,
        state.unfavouritingWallet,
        errorUnfavouriteWallet,
        unfavouriteWallet,
        unfavouriteWallet,
    ]);

    const loading = fetchingPendingCount || fetchingWallet || fetchingPlanGroups || fetchingPlanGroupsCount || fetchingFavouriteWallets || filteringPlanGroups || fetchingWalletStatistics;

    const showWarn = () => {
        toast.current.show({ severity: "warn", summary: t("product_account.warning_summary"), detail: t("product_account.warning_detail"), life: 3000 });
    };
    const showRemoveError = () => {
        toast.current.show({ severity: "error", summary: t("product_account.error_summary"), detail: t("product_account.error_message"), life: 3000 });
    };
    const showRemoveSuccess = () => {
        toast.current.show({ severity: "success", code: "removedFav", summary: t("product_account.success"), detail: t("product_account.delete_detail"), life: 1500 });
    };
    const showAddedSuccess = () => {
        toast.current.show({ severity: "success", code: "added", summary: t("product_account.success"), detail: t("product_account.added_detail"), life: 3000 });
    };
    const showAddedError = () => {
        toast.current.show({ severity: "success", summary: t("product_account.error_summary"), detail: t("product_account.error_added"), life: 3000 });
    };

    const routeTo = (data) => {
        if (data?.available < 0.5) {
            showWarn();
        } else {
            history.push({ pathname: "/money/send", search: "walletId=" + data.groupId });
        }
    };

    const showAddingFav = (name) => {
        toast.current.show({
            severity: "info",
            summary: t("product_account.add"),
            detail: t("product_account.saving_fav", { name }),
            life: 3000,
        });
    };

    const showRemovingFav = (name) => {
        toast.current.show({
            severity: "info",
            summary: t("product_account.remove"),
            detail: t("product_account.removing_fav", { name }),
            life: 3000,
        });
    };
    const showErrorFav = () => {
        toast.current.show({ severity: "error", summary: "Error Message", detail: t("product_account.select_error") });
    };

    const addFav = () => {
        setState((prev) => {
            return { ...prev, addNewFavVisible: true };
        });
    };

    const AddProductAccountsCard = ({ onClick, t }) => {
        return (
            <div onClick={onClick} className="fav fav-add" id="add_wallet_card">
                <i className="icon pi-left pi pi-plus"></i>
                <p className="fav-info-text-num"> {t("product_account.add_fav_account")} </p>
            </div>
        );
    };

    const removeFavActions = (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button label={t("common.no")} icon="pi pi-times" className="p-button-text" id="remove_no_btn"
                onClick={() => setRemoveFavorite(false)} />
            <Button label={t("common.yes")} icon="pi pi-check" className="p-button-text" id="remove_yes_btn"
                onClick={() => {
                    if (favouriteWallets.find(({ groupId }) => groupId === parseInt(favData?.groupId)) === -1) {
                        showErrorFav()
                    } else {
                        getUnfavouriteWallet(favData.data.groupId)
                        showRemovingFav(favData.data.name);
                        setState((state) => ({
                            ...state, loadUnFav: true, unfavouritingId: favData.data.groupId,
                            favouritingWallet: false, unfavouritingWallet: true
                        }));
                        setRemoveFavorite(false)
                    }
                }} />
        </div>
    )

    function truncateString(str, num) {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str?.length <= num) {
            return str;
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str?.slice(0, num) + "...";
    }

    let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    arr = arr.map((indexes, index) => {
        if (!!favouriteWallets && favouriteWallets.length !== 0 && favouriteWallets.length > index) {
            return favouriteWallets[indexes];
        } else {
            return 0;
        }
    });

    const responsiveOptions = [
        {
            breakpoint: "480px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "1451px",
            numVisible: 3,
            numScroll: 3,
        },
        {
            breakpoint: "678px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "1161px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "1858px",
            numVisible: 4,
            numScroll: 4,
        },
    ];

    const walletData = walletStatistics?.content
    const walletsData = () => {
        const data = state.name ? filterPlanGroups : walletData;
        if (data === null) {
            return [];
        }
        else {
            const allWallets = data?.map((walletData, key) => {
                const { name, available, balance, pending } = walletData;
                let arr = {
                    ...walletData,
                    name: name,
                    available: available,
                    balance: balance,
                    pending: pending,
                    key
                };
                return arr;
            });
            return allWallets;
        }
    };

    const route = (data) => {
        history.push({ pathname: "/money/product_accounts/withdrawalsList", state: data, search: "walletId=" + data.groupId });
    };

    return loading ? (
        <Loading />
    ) : (
        <div style={{ padding: "1rem" }}>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        getFavouriteWallets(defMembership.business.id);
                        getWalletStatistics(defMembership.business.id);
                        getPlanGroups(defMembership.business.id)
                    }
                }}
            />
            <Summary />

            <Dialog
                className="tier_dialog" id="tier_dialog"
                visible={removeFavorite}
                style={{ width: "auto" }}
                onHide={() => setRemoveFavorite(false)}
                header={t("subscriber.confirmation_header")}
                footer={removeFavActions}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <i style={{ color: "#000", fontSize: "20px", margin: "1.2rem 0.7rem 0rem 0.2rem" }} className="pi pi-exclamation-triangle" />
                    <h6 style={{ padding: "0rem 0.8rem 0rem 0rem" }}>{`${t("product_account.remove_favourite")}${"?"}`}</h6>
                </div>
            </Dialog>

            <AddProductAccountDialog {...{ getFavouriteWallet, showAddingFav, setState, state }} />
            <h6 className="title-tile ml-0 mt-6 mb-2">{t("product_account.favorite_product_accounts")}</h6>
            <div className="carousel-demo">
                <div className="card card-background">
                    <Carousel
                        responsiveOptions={responsiveOptions}
                        numVisible={5}
                        numScroll={2}
                        value={arr}
                        itemTemplate={(walletStatistics, i) => {
                            if (fetchingFavouriteWallets) {
                                return (
                                    <div className="fav">
                                        <ProgressSpinner className="spinner-content" style={{ display: "flex", justifyContent: "center", width: "60px", height: "90px" }} />
                                    </div>
                                );
                            }
                            if (walletStatistics) {
                                return (
                                    <>
                                        <div className="fav-card">
                                            <div className="inner-card card-details">
                                                <Button
                                                    icon="pi pi-times"
                                                    className="fav-button-cancel"
                                                    id="times_icon"
                                                    onClick={(e) => {
                                                        setFavData((favData) => { return { ...favData, data: walletStatistics } })
                                                        setRemoveFavorite(true);
                                                    }}
                                                    rounded
                                                    outlined
                                                    severity="danger"
                                                    aria-label="Cancel"
                                                />
                                                <h4 className="mb-4 favourite-name">{truncateString(walletStatistics.name, 35)}</h4>

                                                <h6 className="mb-2 fav-card-amount balance  grid w-full">
                                                    <span className="balance_label">{t("Balance")}{":"}</span>
                                                    <div style={{ marginLeft: "0.5rem" }}>
                                                        {defMembership?.business?.currency} {walletStatistics.balance}
                                                    </div>
                                                </h6>
                                                <h6 className="mb-1 fav-card-amount available mt-0 grid w-full">
                                                    <span className="available_label">{t("Available")}{":"}</span>
                                                    <div style={{ marginLeft: "0.5rem" }}>
                                                        {defMembership?.business?.currency} {walletStatistics.available}
                                                    </div>
                                                </h6>
                                                <h6 className="mt-0 mb-1 fav-card-amount pending grid w-full">
                                                    <span className="pending_label" >{t("Pending")}{":"}</span>
                                                    <div style={{ marginLeft: "0.5rem" }}>{walletStatistics.pending !== null ? `${defMembership?.business?.currency} ${walletStatistics.pendingWthdlAmount?.toFixed(2)}` : "--"}</div>
                                                </h6>

                                                <div className="card-buttons action-buttons">
                                                    <Button
                                                        icon="pi pi-list"
                                                        className=" p-button-rounded mr-2 p-button-sm wallet-button"
                                                        id="wallet-button"
                                                        onClick={() => route(walletStatistics)}
                                                        tooltip={t("product_account.list_icon")}
                                                        tooltipOptions={{
                                                            position: "bottom",
                                                            mouseTrack: true,
                                                            mouseTrackTop: 15,
                                                        }}
                                                    />
                                                    <Button
                                                        icon="pi pi-send"
                                                        type="button"
                                                        className="p-button-rounded wallet-button"
                                                        id="wallet-button"
                                                        onClick={() => {
                                                            routeTo(walletStatistics);
                                                        }}
                                                        tooltip={t("product_account.send_icon")}
                                                        tooltipOptions={{
                                                            position: "bottom",
                                                            mouseTrack: true,
                                                            mouseTrackTop: 15,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            } else {
                                return <AddProductAccountsCard onClick={addFav} key={i} t={t} />;
                            }
                        }}
                    ></Carousel>
                </div>
            </div>

            <ProductAccountsTable
                {...{
                    getPlanGroupsFilter, planGroups, walletsData, unfavouritingWallet, favouritingWallet,
                    favouritedWallets, loading, showWarn, getUnfavouriteWallet, getFavouriteWallet, showRemovingFav,
                    setFavouritedWallets, getWallet, defMembership, fetchingPendingCount, fetchingWallet,
                    fetchingPlanGroups, fetchingPlanGroupsCount, getFavouriteWallets, state, setState, showRemoveSuccess,
                    showAddedSuccess, walletStatistics, walletName, setWalletName
                }}
            />
        </div>
    );
}
export default ProductAccount;