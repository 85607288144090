import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { DefMembersipTypes } from "../actions/defMembership-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  defMembership: null,
  error: null,
  fetching: false,
  updating: false,
});

/* ------------- Reducers ------------- */

// we're attempting to account
export const request = (state) => {
  return { ...state, fetching: true, defMembership: null };
};

// we've successfully logged in
export const success = (state, data) => {
  const { defMembership } = data;
  return { ...state, fetching: false, error: null, defMembership };
};

// we've had a problem getting the account
export const failure = (state, { error }) => {
  return {
    ...state,
    fetching: false,
    updating: false,
    defMembership: null,
    error,
  };
};

// we're attempting to reset the account
export const defMembershipResetSuccess = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [DefMembersipTypes.DEF_MEMBERSHIP_REQUEST]: request,
  [DefMembersipTypes.DEF_MEMBERSHIP_SUCCESS]: success,
  [DefMembersipTypes.DEF_MEMBERSHIP_FAILURE]: failure,
  [DefMembersipTypes.DEF_MEMBERSHIP_RESET_SUCCESS]: defMembershipResetSuccess,
});
