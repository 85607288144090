import { call, put } from "redux-saga/effects";
import EnterpriseActions from "./enterprise.reducer"
import * as api from "../../../Service/api";

export function* getEnterprise(action) {
	const { businessId } = action;
	// make the call to the api
	const response = yield call(api.getCredentials, businessId);
console.log(response);
	// success?
	if (response.ok) {
		console.log(response.data);
		yield put(EnterpriseActions.enterpriseSuccess(response.data));
	} else {
		yield put(EnterpriseActions.enterpriseFailure(response.data));
	}
}


export function* generateEnterprise(action) {
	const { businessId } = action;
	// make the call to the api
	const response = yield call(api.generateRequest, businessId);

	// success?
	if (response.ok) {
		yield put(EnterpriseActions.generateSuccess(response.data));
	} else {
		yield put(EnterpriseActions.generateFailure(response.data));
	}
}