import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import "../../Styles/pages/settings.scss"
import { Card } from "primereact/card";
import PaySwitchActions from "../../Redux/transition/payswitch/payswitch.reducer";
import prodKeyImage from "../../assets/images/Prod Key Image.png"
import merchantIdImage from "../../assets/images/PaySwitch Merchant ID.png"
import Loading from '../../Components/Loading';

function Payments({ onSave }) {

    const dispatch = useDispatch()
    const createCredentials = useCallback((businessId, credentials) => { dispatch(PaySwitchActions.paySwitchCredentialCreateRequest(businessId, credentials)) }, [dispatch]);
    const getCredentials = useCallback((businessId) => { dispatch(PaySwitchActions.paySwitchCredentialRequest(businessId)) }, [dispatch]);

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const paymentCredentials = useSelector((state) => state.payswitch.credentials);
    const fetchingPaymentCredentials = useSelector((state) => state.payswitch.fetchingCredentials);
    const creating = useSelector((state) => state.payswitch.creating);
    const errorCreating = useSelector((state) => state.payswitch.errorCreating);

    const op = useRef(null);
    const op1 = useRef(null);
    const toast = useRef(null);

    const { t } = useTranslation("common");
    const [create, setCreate] = useState(false)
    const [save, setSave] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [editTokens, setEditTokens] = useState(false)


    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: t("business.payment_success"), detail: t("business.payment_success_info"), life: 3000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: t("business.payment_error"), detail: t("business.payment_error_info"), life: 3000 });
    }

    const handleChange = (newValue) => {
        setSave(newValue);
        // Pass the updated state to the parent component
        onSave(newValue);
    };

    useEffect(() => {
        getCredentials(defMembership.business.id)
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])


    useEffect(() => {
        if (create && !creating && paymentCredentials) {
            showSuccess()
            setCreate(false)
        }
        if (create && !creating && errorCreating) {
            showError()
            setCreate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, creating, errorCreating, paymentCredentials])



    const { control, reset, setValue, watch, formState: { errors }, handleSubmit, register
    } = useForm({
        defaultValues: {
            apiUser: !paymentCredentials || editTokens ? "" : "**********",
            apiKey: !paymentCredentials || editTokens ? "" : "**********",
            passCode: !paymentCredentials || editTokens ? "" : "**********",
            merchantId: !paymentCredentials ? "" : paymentCredentials.merchantId,
        }
    });

    const onSubmit = (data) => {
        const credentials = {
            ...data
        }
        createCredentials(defMembership.business.id, credentials)
        setCreate(true)
    }

    return (
        <>{fetchingPaymentCredentials ? <Loading /> :
            <div className='payment-main' style={{ fontFamily: "Roboto" }}>
                <Card>
                    <OverlayPanel ref={op}>
                        <div className="">
                            <div className='mb-3 mt-2'>
                                <div className='instruction1-header mb-1'>{t("business.payment_teller1_info1")}</div>
                                <div>
                                    <div className='instruction1 mb-1'>{t("business.payment_teller1_info2")}</div>
                                    <div className='instruction1'>{t("business.payment_teller1_info3")} <span className='instruction1-inner'>{t("business.payment_teller1_info3_inner")}</span> {t("business.payment_teller1_info4")}</div>
                                </div>
                            </div>
                            <img src={merchantIdImage} alt={t("business.payment_op1")} />
                        </div>
                    </OverlayPanel>
                    <OverlayPanel ref={op1}>
                        <div className="">
                            <div className='mb-3 mt-2'>
                                <div className='instruction2-header mb-1'>{t("business.payment_teller2_info1")}</div>
                                <div>
                                    <div className='instruction2 mb-1'>{t("business.payment_teller2_info2")}</div>
                                    <div className='instruction2 mb-1'>{t("business.payment_teller2_info3")}  <span className='instruction1-inner'>{t("business.payment_teller2_info3_inner")}</span> {t("business.payment_teller2_info4")}</div>
                                    <div className='instruction2 mb-1'>{t("business.payment_teller2_info5")} <span className='instruction1-inner'>{t("business.payment_teller2_info5_inner")}</span></div>
                                    <div className='instruction2'>{t("business.payment_teller2_info6")} <span className='instruction1-inner'>{t("business.payment_teller2_info6_inner")}</span> {t("business.payment_teller2_info7")}</div>
                                </div>
                            </div>
                            <img src={prodKeyImage} alt={t("business.payment_op2")} />
                        </div>
                    </OverlayPanel>
                    <Toast ref={toast} onRemove={(message) => {
                        if (message.severity === 'success') {
                            getCredentials(defMembership.business.id)
                            reset()
                            handleChange(!save)
                            setClicked(false)
                        }
                    }} />
                    <div className='payment_heading1'>{t("business.payment_heading1")}</div>
                    <div className='payment_heading'>{t("business.payment_heading")}</div>
                    <div className='payment-added-alert col-12'><span className='payment-added-alert1'>{t("business.existing_payment1")}</span> <br /> <span className='payment-added-alert2'>{t("business.existing_payment2")}</span> </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="wizard-forms-wrapper p-grid p-nogutter settings-edit-form">
                        <div className="card payment_card">
                            <div >
                                <div className='grid mb-3' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='col-2' >
                                        <label
                                            htmlFor="merchantId"
                                            className={"label"}
                                            style={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}>
                                            {t("business.payment_merchantId")}
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                    <div className='col-9 inputs-width' style={{ marginTop: "1.3rem", maxWidth:!errors.merchantId ? '759px' : "660px" }}>
                                        {((paymentCredentials && clicked) || !paymentCredentials) ? <div>
                                            <Controller
                                                name={"merchantId"}
                                                control={control}
                                                rules={{ required: t("business.payment_merchantId_required") }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        {...field}
                                                        {...register("merchantId")}
                                                        type='text'
                                                        placeholder={t("business.payment_placeHolder")}
                                                        disabled={paymentCredentials && !clicked}
                                                        className={classNames({ "p-invalid": fieldState.invalid }, "payment_input")} />
                                                )}
                                            />
                                        </div> : <div className='merchant'>{paymentCredentials?.merchantId}</div>}
                                        <div style={{ marginTop: "0.3rem" }}><i className="pi pi-info-circle info-icon" style={{ color: "#9747ff", fontSize: "12px" }} onClick={(e) => { op.current.toggle(e) }}></i> <span className='payment_token'>{t("business.payment_merchant_info")}</span></div>
                                    </div>
                                    {(paymentCredentials && !clicked) ? <div className='col-1 merchant-edit'><Button
                                        className=""
                                        id='merchantEdit-btn'
                                        label="Edit"
                                        type='button'
                                        text
                                        severity='info'
                                        onClick={() => {setClicked(true); handleChange(false)}}
                                        loading={creating}
                                    /></div> : errors.merchantId && <div className='wizard-forms error_message'><i className="pi pi-times-circle"></i> <span>{t("business.payment_merchantId_required")}</span> </div>}
                                </div>
                            </div>

                            <hr style={{ marginRight: "3rem" }} />
                            <div className='inner-payment-card'>
                                <div className='payment-credential'>{t("business.payment_credential")}</div>
                                <div style={{ marginTop: "0.3rem" }}><i className="pi pi-info-circle info-icon" style={{ color: "#9747ff", fontSize: "12px" }} onClick={(e) => { op1.current.toggle(e) }}></i> <span className='payment_token'>{t("business.payment_token_info")}</span></div>
                                <div className='grid mt-3 mb-2'>
                                    <div className='payment_alert col-12'><span className='payment-alert1'>{t("business.payment_alert1")}</span> <span className='payment-alert2'>{t("business.payment_alert2")}</span> <span className='payment-alert1'>{t("business.payment_alert3")}</span> <br /> <span className='payment-alert2'>{t("business.payment_alert4")}</span> </div>
                                </div>
                                <div className='grid mb-3' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='col-2' >
                                        <label
                                            htmlFor="apiUser"
                                            className={"label"}
                                            style={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}>
                                            {t("business.payment_userName")}
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                    <div className='col-9 inputs-width' style={{ maxWidth: '759px' }}>
                                        <div>
                                            <Controller
                                                name={"apiUser"}
                                                control={control}
                                                rules={{ required: t("business.payment_merchantId_required") }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        {...field}
                                                        {...register("apiUser")}
                                                        type='password'
                                                        placeholder={t("business.payment_placeHolder")}
                                                        disabled={paymentCredentials && !editTokens}
                                                        className={classNames({ "p-invalid": fieldState.invalid }, "payment_input")} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {errors.apiUser && <div className='wizard-forms error_message'><i className="pi pi-times-circle"></i> <span>{t("business.payment_userName_required")}</span> </div>}
                                    <div className='col-2'></div>
                                </div>
                                <div className='grid mb-1' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='col-2' >
                                        <label
                                            htmlFor="apiKey"
                                            className={"label"}
                                            style={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}>
                                            {t("business.payment_apiKey")}
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                    <div className='col-9 inputs-width' style={{ maxWidth: '759px' }}>
                                        <div>
                                            <Controller
                                                name={"apiKey"}
                                                control={control}
                                                rules={{ required: t("business.payment_apiKey_required") }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        {...field}
                                                        {...register("apiKey")}
                                                        type='password'
                                                        placeholder={t("business.payment_placeHolder")}
                                                        disabled={paymentCredentials && !editTokens}
                                                        className={classNames({ "p-invalid": fieldState.invalid }, "payment_input")} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {errors.apiKey && <div className='wizard-forms error_message'><i className="pi pi-times-circle"></i> <span>{t("business.payment_apiKey_required")}</span> </div>}
                                    <div className='col-2'></div>
                                </div>
                                <div className='grid mb-3' style={{ display: "flex", alignItems: "center" }}>
                                    <div className='col-2' >
                                        <label
                                            htmlFor="passCode"
                                            className={"label"}
                                            style={{ color: "#000000", fontFamily: "Roboto", fontSize: "13px" }}>
                                            {t("business.payment_passcode")}
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                    <div className='col-9 inputs-width' style={{ maxWidth: '759px' }}>
                                        <div>
                                            <Controller
                                                name={"passCode"}
                                                control={control}
                                                rules={{ required: t("business.payment_passcode_required") }}
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        {...field}
                                                        {...register("passCode")}
                                                        type='password'
                                                        placeholder={t("business.payment_placeHolder")}
                                                        disabled={paymentCredentials && !editTokens}
                                                        className={classNames({ "p-invalid": fieldState.invalid }, "payment_input")} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {errors.passCode && <div className='wizard-forms error_message'><i className="pi pi-times-circle"></i> <span>{t("business.payment_passcode_required")}</span> </div>}
                                    {(paymentCredentials && !editTokens) && <div className='col-1 inner-edit'>
                                        <Button
                                            className=""
                                            id='innerEdit-btn'
                                            label="Edit"
                                            type='button'
                                            text
                                            severity='info'
                                            onClick={() => {
                                                setEditTokens(true)
                                                setValue("apiUser", "")
                                                setValue("apiKey", "")
                                                setValue("passCode", "")
                                                handleChange(false)
                                            }}
                                            loading={creating}


                                        />
                                    </div>}
                                </div>

                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <div className='cancel'>
                                {((paymentCredentials && clicked && !editTokens) || (paymentCredentials && editTokens)) && <Button
                                    className="cancel-button p-button"
                                    id='edit_butto'
                                    label="Cancel"
                                    onClick={() => {
                                        setEditTokens(false)
                                        setClicked(false)
                                        setValue("apiUser", "**********")
                                        setValue("apiKey", "**********")
                                        setValue("passCode", "**********")
                                    }}
                                    type='button'
                                    loading={creating}
                                />}
                            </div>
                            <div>

                                {((paymentCredentials && clicked && !editTokens)
                                    || !paymentCredentials || (paymentCredentials && editTokens &&
                                        (watch("apiUser")?.trim() !== "" && watch("apiKey")?.trim() !== "" && watch("passCode")?.trim() !== ""))
                                )
                                    && <Button
                                        className="save-button p-button-info"
                                        id='edit_button'
                                        label="Save"
                                        type='submit'
                                        loading={creating}
                                    />}
                            </div>
                        </div>
                    </form>
                </Card>

            </div>
        }</>
    )
}

export default Payments