import { call, put } from 'redux-saga/effects'
import BusinessActions from '../actions/business-actions'
import * as api from "../../Service/api";

export function* getBusiness(action) {
    const { businessId } = action
    // make the call to the api
    const response = yield call(api.getBusiness, businessId)

    // success?
    if (response.ok) {
        yield put(BusinessActions.businessSuccess(response.data))
    } else {
        yield put(BusinessActions.businessFailure(response.data))
    }
}

export function* getBusinesses(action) {
    const { options } = action
    // make the call to the api
    const apiCall = call(api.getBusinesses, options)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        yield put(BusinessActions.businessAllSuccess(response.data))
    } else {
        yield put(BusinessActions.businessAllFailure(response.data))
    }
}
export function* getBusinessSummary(action) {
    const { businessId } = action
    //make the call to the api
    const response = yield call(api.getBusinessSummary, businessId)
    // success?
    if (response.ok) {
        yield put(BusinessActions.businessSummarySuccess(response.data))
    } else {
        yield put(BusinessActions.businessSummaryFailure(response.data))
    }
}

export function* createBusiness(action) {
    const { business } = action
    // make the call to the api
    // const idIsNotNull = !!business.id
    // const apiCall = call( api.createBusiness, business)
    const response = yield call(api.createBusiness, business)

    // success?
    if (response.ok) {
        yield put(BusinessActions.businessCreateSuccess(response.data))
    } else {
        yield put(BusinessActions.businessCreateFailure(response.data))
    }
}
export function* updateBusiness(action) {
    const { businessId, business } = action
    // make the call to the api
    // const idIsNotNull = !!business.id
    // const apiCall = call(api.updateBusiness, business)
    const response = yield call(api.updateBusiness, businessId, business)

    // success?
    if (response.ok) {
        yield put(BusinessActions.businessUpdateSuccess(response.data))
    } else {
        console.log(response);
        yield put(BusinessActions.businessUpdateFailure(response.data))
    }
}

export function* deleteBusiness(action) {
    const { businessId } = action
    // make the call to the api
    const apiCall = call(api.deleteBusiness, businessId)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        yield put(BusinessActions.businessDeleteSuccess())
    } else {
        yield put(BusinessActions.businessDeleteFailure(response.data))
    }
}

export function* getQrCode(action) {
    const { businessId } = action
    // make the call to the api
    const response = yield call(api.getBusinessQr, businessId)

    //success?
    if (response.ok) {
        yield put(BusinessActions.businessQrCodeSuccess(response.data))
    } else {
        yield put(BusinessActions.businessQrCodeFailure(response.data))
    }
}
export function* getIndustries(action) {
    const { industry } = action
    //make the call to the api
    const response = yield call(api.getIndustries, industry)

    //success?
    if (response.ok) {
        yield put(BusinessActions.businessIndustriesSuccess(response.data))
    } else {
        yield put(BusinessActions.businessIndustriesFailure(response.data))
    }
}