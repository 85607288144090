import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	planRequest: ["planId"],
	planAllRequest: ["businessId", "options"],
	planCreateRequest: ["plan"],
	firstTierCreateRequest: ["firstPlan"],
	planUpdateRequest: ["planId", "plan"],
	planPriceChangeRequest: ["planId", "amount"],
	planDeleteRequest: ["planId"],
	planArchiveRequest: ["planId", "membershipId"],
	planPerformanceRequest: ["tierId"],
	planMigrateRequest: ["migratedData"],
	planPaymentMissedRequest: ["planId", "options"],
	planPaymentMissedCountRequest: ["planId", "options"],
	planPaymentPendingRequest: ["planId", "options"],
	planPaymentPendingCountRequest: ["planId", "options"],
	planPaymentCollectedRequest: ["planId", "options"],
	planPaymentCollectedCountRequest: ["planId", "options"],

	planSuccess: ["plan"],
	planAllSuccess: ["plans"],
	planCreateSuccess: ["plan"],
	firstTierCreateSuccess: ["firstPlan"],
	planUpdateSuccess: ["plan"],
	planPriceChangeSuccess: ["plan"],
	planDeleteSuccess: [],
	planArchiveSuccess: [],
	planPerformanceSuccess: ["performance"],
	planMigrateSuccess: ["migrationData"],
	planPaymentMissedSuccess: ["paymentMissed"],
	planPaymentMissedCountSuccess: ["paymentMissedCount"],
	planPaymentPendingSuccess: ["paymentPending"],
	planPaymentPendingCountSuccess: ["paymentPendingCount"],
	planPaymentCollectedSuccess: ["paymentCollected"],
	planPaymentCollectedCountSuccess: ["paymentCollectedCount"],

	planFailure: ["error"],
	planAllFailure: ["error"],
	planCreateFailure: ["error"],
	firstTierCreateFailure: ["error"],
	planUpdateFailure: ["error"],
	planPriceChangeFailure: ["error"],
	planDeleteFailure: ["error"],
	planArchiveFailure: ["error"],
	planPerformanceFailure: ["error"],
	planMigrateFailure: ["error"],
	planPaymentMissedFailure: ["error"],
	planPaymentMissedCountFailure: ["error"],
	planPaymentPendingFailure: ["error"],
	planPaymentPendingCountFailure: ["error"],
	planPaymentCollectedFailure: ["error"],
	planPaymentCollectedCountFailure: ["error"],

});

export const PlanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingOne: false,
	fetchingAll: false,
	creating: false,
	creatingFirst: false,
	updating: false,
	changingPrice: false,
	deleting: false,
	archiving: false,
	fetchPerformance: false,
	fetchingMissed: false,
	fetchingMissedCount: false,
	fetchingPending: false,
	fetchingPendingCount: false,
	fetchingCollected: false,
	fetchingCollectedCount: false,

	plan: null,
	plans: null,
	firstTier: false,
	performance: null,
	archiveSuccess: false,
	changingSuccess: false,
	migrating: false,
	migrateSuccess:null,
	missed: null,
	missedCount: null,
	pending: null,
	pendingCount: null,
	collected: null,
	collectedCount: null,

	errorOne: null,
	errorAll: null,
	errorFirstTier: null,
	errorCreating: null,
	errorUpdating: null,
	errorChangingPrice: null,
	errorDeleting: null,
	errorArchiving: null,
	errorPerformance: null,
	errorMigrating: null,
	errorMissed: null,
	errorMissedCount: null,
	errorPending: null,
	errorPendingCount: null,
	errorCollected: null,
	errorCollectedCount: null,
});

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
	return { ...state, fetchingOne: true, plan: null };
};

// request the data from an api
export const allRequest = (state) => {
	return { ...state, fetchingAll: true, plans: null };
};
// request the data from frontend
export const firstTierRequest = (state) => {
	return { ...state, creatingFirst: true, firstTier: null };
};

// request to create from an api
export const createRequest = (state) => {
	return { ...state, creating: true };
};

// request to update from an api
export const updateRequest = (state) => {
	return { ...state, updating: true };
};

// request to update from an api
export const changingPriceRequest = (state) => {
	return { ...state, changingPrice: true, changingSuccess: false, errorChangingPrice: null };
};

// request to delete from an api
export const deleteRequest = (state) => {
	return { ...state, deleting: true };
};
// request to archive from an api
export const archiveRequest = (state) => {
	return { ...state, archiving: true, archiveSuccess: false, errorArchiving: null };
};
export const planPerformanceRequest = (state) => {
	return { ...state, fetchingPerformance: true, performance: null, errorPerformance: null };
};
export const missedRequest = (state) => {
	return { ...state, fetchingMissed: true, missed: null, errorMissed: null };
};

export const missedCountRequest = (state) => {
	return { ...state, fetchingMissedCount: true, missedCount: null, errorMissedCount: null };
};

export const pendingRequest = (state) => {
	return { ...state, fetchingPending: true, pending: null, errorPending: null };
};

export const pendingCountRequest = (state) => {
	return { ...state, fetchingPendingCount: true, pendingCount: null, errorPendingCount: null };
};

export const CollectedRequest = (state) => {
	return { ...state, fetchingCollected: true, collected: null, errorCollected: null };
};

export const CollectedCountRequest = (state) => {
	return { ...state, fetchingCollectedCount: true, collectedCount: null, errorCollectedCount: null };
};

// request to migrate from an api
export const migrateRequest = (state, action) => {
	const {migrationData} = action
	return { ...state, migrating: true, migrateSuccess:migrationData, errorMigrating:null };
};

// successful api lookup for single entity
export const success = (state, action) => {
	const { plan } = action;
	return { ...state, fetchingOne: false, errorOne: null, plan };
};
// successful api lookup for all entities
export const allSuccess = (state, action) => {
	const { plans } = action;
	return { ...state, fetchingAll: false, errorAll: null, plans };
};
export const firstTierSuccess = (state, action) => {
	const { firstPlan } = action;
	return { ...state, creatingTier: false, errorFirstTier: null, firstPlan };
};
// successful api create
export const createSuccess = (state, action) => {
	const { plan } = action;
	return { ...state, creating: false, errorCreating: null, plan };
};
// successful api update
export const updateSuccess = (state, action) => {
	const { plan } = action;
	return { ...state, updating: false, errorUpdating: null, plan };
};

// successful api change
export const changePriceSuccess = (state,) => {
	return { ...state, changingPrice: false, errorChangingPrice: null, changingSuccess: true };
};

// successful api delete
export const deleteSuccess = (state) => {
	return { ...state, deleting: false, errorDeleting: null, plan: null };
};
// successful api archive
export const archiveSuccess = (state) => {
	return { ...state, archiving: false, errorArchiving: null, archiveSuccess: true };
};

export const planPerformanceSuccess = (state, action) => {
	const { performance } = action;
	return { ...state, fetchingPerformance: false, performance, errorPerformance: null };
};

// successful api migrate
export const migrateSuccess = (state) => {
	return { ...state, migrating: false, errorMigrating: null, migrateSuccess:true };
};

export const missedSuccess = (state, action) => {
	const { paymentMissed } = action
	return { ...state, fetchingMissed: false, errorMissed: null, missed: paymentMissed };
};

export const missedCountSuccess = (state, action) => {
	const { paymentMissedCount } = action
	return { ...state, fetchingMissedCount: false, errorMissedCount: null, missedCount: paymentMissedCount };
};

export const pendingSuccess = (state, action) => {
	const { paymentPending } = action
	return { ...state, fetchingPending: false, errorPending: null, pending: paymentPending };
};

export const pendingCountSuccess = (state, action) => {
	const { paymentPendingCount } = action
	return { ...state, fetchingPendingCount: false, errorPendingCount: null, pendingCount: paymentPendingCount };
};

export const CollectedSuccess = (state, action) => {
	const { paymentCollected } = action
	return { ...state, fetchingCollected: false, errorCollected: null, collected: paymentCollected };
};

export const CollectedCountSuccess = (state, action) => {
	const { paymentCollectedCount } = action
	return { ...state, fetchingCollectedCount: false, errorCollectedCount: null, collectedCount: paymentCollectedCount };
};
// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingOne: false, errorOne: error, plan: null };
};
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingAll: false, errorAll: error, plans: null };
};
export const firstTierFailure = (state, action) => {
	const { error } = action;
	return { ...state, creatingFirst: false, errorFirstTier: error, firstPlan: null };
};
// Something went wrong creating.
export const createFailure = (state, action) => {
	const { error } = action;
	return { ...state, creating: false, errorCreating: error, plan: null };
};
// Something went wrong updating.
export const updateFailure = (state, action) => {
	const { error } = action;
	return { ...state, updating: false, errorUpdating: error, plan: state.plan };
};
// Something went wrong updating.
export const changePriceFailure = (state, action) => {
	const { error } = action;
	return { ...state, changingPrice: false, errorChangingPrice: error, changingSuccess: false };
};
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
	const { error } = action;
	return { ...state, deleting: false, errorDeleting: error, plan: state.plan };
};
// Something went wrong archiving.
export const archiveFailure = (state, action) => {
	const { error } = action;
	return { ...state, archiving: false, errorArchiving: error, archiveSuccess: false };
};
export const planPerformanceFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingPerformance: false, performance: null, errorPerformance: error };
};

// Something went wrong migrating.
export const migrateFailure = (state, action) => {
	const { error } = action;
	return { ...state, migrating: false, errorMigrating: error, migrateSuccess:false };
};

export const missedFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingMissed: false,
		errorMissed: error,
		missed: null,
	};
};

export const missedCountFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingMissedCount: false,
		errorMissedCount: error,
		missedCount: null,
	};
};

export const pendingFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingPending: false,
		errorPending: error,
		pending: null,
	};
};

export const pendingCountFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingPendingCount: false,
		errorPendingCount: error,
		pendingCount: null,
	};
};

export const CollectedFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingCollected: false,
		errorCollected: error,
		collected: null,
	};
};
export const CollectedCountFailure = (state, action) => {
	const { error } = action;
	return {
		...state,
		fetchingCollectedCount: false,
		errorCollectedCount: error,
		collectedCount: null,
	};
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PLAN_REQUEST]: request,
	[Types.PLAN_ALL_REQUEST]: allRequest,
	[Types.FIRST_TIER_CREATE_REQUEST]: firstTierRequest,
	[Types.PLAN_CREATE_REQUEST]: createRequest,
	[Types.PLAN_UPDATE_REQUEST]: updateRequest,
	[Types.PLAN_PRICE_CHANGE_REQUEST]: changingPriceRequest,
	[Types.PLAN_DELETE_REQUEST]: deleteRequest,
	[Types.PLAN_ARCHIVE_REQUEST]: archiveRequest,
	[Types.PLAN_PERFORMANCE_REQUEST]: planPerformanceRequest,
	[Types.PLAN_MIGRATE_REQUEST]: migrateRequest,
	[Types.PLAN_PAYMENT_MISSED_REQUEST]: missedRequest,
	[Types.PLAN_PAYMENT_MISSED_COUNT_REQUEST]: missedCountRequest,
	[Types.PLAN_PAYMENT_PENDING_REQUEST]: pendingRequest,
	[Types.PLAN_PAYMENT_PENDING_COUNT_REQUEST]: pendingCountRequest,
	[Types.PLAN_PAYMENT_COLLECTED_REQUEST]: CollectedRequest,
	[Types.PLAN_PAYMENT_COLLECTED_COUNT_REQUEST]: CollectedCountRequest,

	[Types.PLAN_SUCCESS]: success,
	[Types.PLAN_ALL_SUCCESS]: allSuccess,
	[Types.FIRST_TIER_CREATE_SUCCESS]: firstTierSuccess,
	[Types.PLAN_CREATE_SUCCESS]: createSuccess,
	[Types.PLAN_UPDATE_SUCCESS]: updateSuccess,
	[Types.PLAN_PRICE_CHANGE_SUCCESS]: changePriceSuccess,
	[Types.PLAN_DELETE_SUCCESS]: deleteSuccess,
	[Types.PLAN_ARCHIVE_SUCCESS]: archiveSuccess,
	[Types.PLAN_PERFORMANCE_SUCCESS]: planPerformanceSuccess,
	[Types.PLAN_MIGRATE_SUCCESS]: migrateSuccess,
	[Types.PLAN_PAYMENT_MISSED_SUCCESS]: missedSuccess,
	[Types.PLAN_PAYMENT_MISSED_COUNT_SUCCESS]: missedCountSuccess,
	[Types.PLAN_PAYMENT_PENDING_SUCCESS]: pendingSuccess,
	[Types.PLAN_PAYMENT_PENDING_COUNT_SUCCESS]: pendingCountSuccess,
	[Types.PLAN_PAYMENT_COLLECTED_SUCCESS]: CollectedSuccess,
	[Types.PLAN_PAYMENT_COLLECTED_COUNT_SUCCESS]: CollectedCountSuccess,

	[Types.PLAN_FAILURE]: failure,
	[Types.PLAN_ALL_FAILURE]: allFailure,
	[Types.FIRST_TIER_CREATE_FAILURE]: firstTierFailure,
	[Types.PLAN_CREATE_FAILURE]: createFailure,
	[Types.PLAN_UPDATE_FAILURE]: updateFailure,
	[Types.PLAN_PRICE_CHANGE_FAILURE]: changePriceFailure,
	[Types.PLAN_DELETE_FAILURE]: deleteFailure,
	[Types.PLAN_ARCHIVE_FAILURE]: archiveFailure,
	[Types.PLAN_PERFORMANCE_FAILURE]: planPerformanceFailure,
	[Types.PLAN_MIGRATE_FAILURE]: migrateFailure,
	[Types.PLAN_PAYMENT_MISSED_FAILURE]: missedFailure,
	[Types.PLAN_PAYMENT_MISSED_COUNT_FAILURE]: missedCountFailure,
	[Types.PLAN_PAYMENT_PENDING_FAILURE]: pendingFailure,
	[Types.PLAN_PAYMENT_PENDING_COUNT_FAILURE]: pendingCountFailure,
	[Types.PLAN_PAYMENT_COLLECTED_FAILURE]: CollectedFailure,
	[Types.PLAN_PAYMENT_COLLECTED_COUNT_FAILURE]: CollectedCountFailure,

});
