import { call, put } from "redux-saga/effects";
import PlanReportActions from "./plan-report.reducer";
import * as api from "../../../Service/api";
export function* getPlanReport(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.getPlanReport, businessId, options);
	// success?
	if (response.ok) {
		yield put(PlanReportActions.planReportSuccess(response.data));
	} else {
		yield put(PlanReportActions.planReportFailure(response.data));
	}
}
export function* getPlanReportChart(action) {
	const { businessId, planGroupType, options } = action;
	// make the call to the api
	const response = yield call(api.getPlanReportChart, businessId, planGroupType, options);
	// success?
	if (response.ok) {
		yield put(PlanReportActions.planReportChartSuccess(response.data));
	} else {
		yield put(PlanReportActions.planReportChartFailure(response.data));
	}
}
export function* getTransactionReport(action) {
	const { businessId, options } = action;
	// make the call to the api
	const response = yield call(api.getTransactionReport, businessId, options);
	// success?
	if (response.ok) {
		yield put(PlanReportActions.transactionReportSuccess(response.data));
	} else {
		yield put(PlanReportActions.transactionReportFailure(response.data));
	}
}
export function* getTransactionReportChart(action) {
	const { businessId, planGroupType, options } = action;
	// make the call to the api
	const response = yield call(api.getTransactionReportChart, businessId, planGroupType, options);
	// success?
	if (response.ok) {
		yield put(PlanReportActions.transactionReportChartSuccess(response.data));
	} else {
		yield put(PlanReportActions.transactionReportChartFailure(response.data));
	}
}
