import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { SignupTypes } from "../actions/signup-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    updating: null,
    signup: null,
    business: null,
    error: null,
    errorUpdating: null
})

/* ------------- Reducers ------------- */

// request to update from an api
export const updateRequest = (state) => { return { ...state, updating: true , business:null} }

// successful api update
export const updateSuccess = (state, action) => {
    const { business } = action
    return { ...state, updating: false, errorUpdating: null, business }
}

// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action
    return { ...state, updating: false, errorUpdating: error, business: null }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [SignupTypes.SIGNUP_UPDATE_REQUEST]: updateRequest,
    [SignupTypes.SIGNUP_UPDATE_SUCCESS]: updateSuccess,
    [SignupTypes.SIGNUP_UPDATE_FAILURE]: updateFailure
})
