import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    payoutChannelRequest: ['payoutChannelId'],
    payoutChannelAllRequest: ['businessId', 'options'],
    payoutChannelSavedRequest: ['businessId', 'options'],
    payoutChannelFilteredRequest: ['businessId', 'query', 'options'],
    payoutChannelFavouriteRequest: ['businessId', 'options'],
    payoutChannelSetFavouriteRequest: ['payoutChannelId'],
    payoutChannelSetUnfavouriteRequest: ['payoutChannelId'],
    payoutChannelUpdateRequest: ['payoutChannel'],
    payoutChannelDeleteRequest: ['payoutChannelId'],
    payoutChannelArchiveRequest: ['payoutChannelId', 'username'],

    payoutChannelSuccess: ['payoutChannel'],
    payoutChannelAllSuccess: ['payoutChannels'],
    payoutChannelSavedSuccess: ['payoutChannelsSaved'],
    payoutChannelFilteredSuccess: ['filteredPayoutChannels'],
    payoutChannelFavouriteSuccess: ['payoutChannelsFavourite'],
    payoutChannelSetFavouriteSuccess: ['payoutChannelFavourite'],
    payoutChannelSetUnfavouriteSuccess: ['payoutChannelUnFavourite'],
    payoutChannelUpdateSuccess: ['payoutChannel'],
    payoutChannelDeleteSuccess: [],
    payoutChannelArchiveSuccess: [],

    payoutChannelFailure: ['error'],
    payoutChannelAllFailure: ['error'],
    payoutChannelSavedFailure: ['error'],
    payoutChannelFilteredFailure: ['error'],
    payoutChannelFavouriteFailure: ['error'],
    payoutChannelSetFavouriteFailure: ['error'],
    payoutChannelSetUnfavouriteFailure: ['error'],
    payoutChannelUpdateFailure: ['error'],
    payoutChannelDeleteFailure: ['error'],
    payoutChannelArchiveFailure: ['error']
})

export const PayoutChannelTypes = Types
export default Creators
