import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";
import "../../../Styles/pages/report.scss"
import "../../../Styles/pages/dashboard.scss";
import "../../../Styles/pages/subscriptionDetail.scss"
import BusinessCustomersActions from '../../../Redux/transition/customers/business-customer.reducer';
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import PlanReportActions from "../../../Redux/transition/plan-report/plan-report.reducer";
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import moment from 'moment';
import { SummarySection } from '../components/SummaryCards';
import ChartsReport from '../ChartReport';
import { ActionBar } from '../components/actionBar';
import logo from "../../../assets/images/LOGO-4.png"

const SubscriptionSummary = () => {
    const { t } = useTranslation("common");
    const [selectedType, setSelectedType] = useState({ name: t("dashboard.products_dashboard"), code: 'plan' });
    const [period, setPeriod] = useState({ name: "Lifetime", code: 'MONTHLY', title: "lifetime" });
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [filteredCustomer, setFilteredCustomer] = useState(null);
    const [duration, setDuration] = useState('');
    const [dates, setDates] = useState(null);
    const [options, setOptions] = useState([]);
    const componentRef = useRef(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const [summary, setSummary] = useState(false)

    const [state, setState] = useState({
        enterpriseCustomers: null,
        businessCustomers: [],
        businessCustomerReports: {},
        invitesData: [],
        paymentsData: [],
        subscriptionsData: [],
        invitationInfo: {},
        paymentsInfo: {},
        subscriptionInfo: {},
        options: null,
        date: null,
        planReports: [],
        planCharts: [],
        planInvitesData: [],
        planSubscriptionsData: [],
        planPaymentsData: [],
        input: '',
        submitInput: false,
        selectedCustomer: null,
        filteredCustomers: [],
    });
    //redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const businessCustomers = useSelector((state) => state.businessCustomers.businessCustomers);
    const customerReports = useSelector((state) => state.businessCustomers.customerReports)
    const fetchingCustomerReports = useSelector((state) => state.businessCustomers.fetchingCustomerReports)
    const customerCharts = useSelector((state) => state.businessCustomers.customerCharts)
    const fetchingCustomerCharts = useSelector((state) => state.businessCustomers.fetchingCustomerCharts)
    const planReports = useSelector((state) => state.planReport.planReport)
    const fetchingReports = useSelector((state) => state.planReport.fetching)
    const planCharts = useSelector((state) => state.planReport.planReportChart)
    const fetchingPlanCharts = useSelector((state) => state.planReport.fetchingChart)
    const activePlanGroups = useSelector((state) => state.planGroups.planGroupsGraphedActive)
    const filteredCustomers = useSelector((state) => state.businessCustomers.filteredCustomers);
    const fetchingCustomers = useSelector((state) => state.businessCustomers.fetchingFilteredCustomers);

    //redux Dispatch actions
    const dispatch = useDispatch();
    const getMembership = () => dispatch(DefMembershipActions.defMembershipRequest())
    const getCustomerReports = useCallback((businessId, options) => { dispatch(BusinessCustomersActions.customerReportRequest(businessId, options)) }, [dispatch])
    const getBusinessCustomerCharts = useCallback((businessId, duration, options) => { dispatch(BusinessCustomersActions.customerChartsRequest(businessId, duration, options)) }, [dispatch])
    const getPlanReportChart = useCallback((businessId, planGroupType, options) => { dispatch(PlanReportActions.planReportChartRequest(businessId, planGroupType, options)) }, [dispatch])
    const getPlanReport = useCallback((businessId, options) => { dispatch(PlanReportActions.planReportRequest(businessId, options)) }, [dispatch])
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const getFilteredCustomers = useCallback((businessId, query) => dispatch(BusinessCustomersActions.businessCustomerSearchRequest(businessId, query)), [dispatch]);
    let doc = []
    const dropdownItems = [
        { name: t("dashboard.products_dashboard"), code: 'plan' },
        { name: t("business_customers.customers"), code: 'customer' },
    ];
    const periods = [
        { name: "Lifetime", code: 'MONTHLY', title: "lifetime" },
        { name: t("dashboard.duration"), code: 'DAILY' },
        { name: t("dashboard.weekly_duration"), code: 'WEEKLY' },
        { name: t("dashboard.3_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.6_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.12_months_duration"), code: 'MONTHLY' },
    ];
    const summaryOptions = [
        ...options
    ];

    const loading = fetchingReports || fetchingPlanCharts || fetchingCustomerCharts || fetchingCustomerReports || fetchingCustomers
    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getActivePlanGroups(defMembership.business.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (activePlanGroups) {
            let planGroupAssigned = Object.assign({}, activePlanGroups);
            planGroupAssigned.groups.map((item, ind) => {
                const {
                    group,
                } = item;

                doc.push({
                    ...group
                });
                return null;
            });
        }
        if (doc) {
            setOptions(doc)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlanGroups])
    useEffect(() => {
        if (filteredCustomers)
            setFilteredCustomer(filteredCustomers)

    }, [filteredCustomers])
    useEffect(() => {
        if (businessCustomers) {
            setState((state) => {
                return {
                    ...state,
                    businessCustomers,
                    loading: false,
                };
            });
        }
        if (customerReports) {
            setState((state) => {
                return {
                    ...state, invitationInfo: customerReports.invitations, paymentsInfo: customerReports.payments,
                    subscriptionInfo: customerReports.subscriptions
                }
            })
        }
        if (planReports) {
            setState((state) => { return { ...state, planReports } })
        }
        if (customerCharts) {
            setState((state) => {
                return {
                    ...state, invitesData: customerCharts.invitationSeries, paymentsData: customerCharts.paymentSeries,
                    subscriptionsData: customerCharts.subscriptionSeries
                }
            })
        }
        if (filteredCustomers) {
            setState((state) => { return { ...state, filteredCustomers: filteredCustomers } })
        }
        if (planCharts) {
            setState((state) => {
                return {
                    ...state, planInvitesData: planCharts.invitationSeries, planPaymentsData: planCharts.paymentSeries,
                    planSubscriptionsData: planCharts.subscriptionSeries
                }
            })
        }
        if (!selectedCustomer) {
            setState((state) => ({ ...state, selectedCustomer: null }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessCustomers, customerCharts, customerReports, planCharts, planReports, filteredCustomers, selectedCustomer,]);
    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = moment().subtract(1, 'months').format("YYYY-MM-DD")
        const joinedDate = moment(defMembership.joinedOn).format("YYYY-MM-DD")
        let customEndDate;

        const priorDate = new Date(new Date().setDate(today.getDate() - 30));
        const priorWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 84);
        const last3Months = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        const last6Months = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
        const last12Months = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate())

        const endDates = moment(today).format().split("T").shift()
        const dailyStartDate = moment(priorDate).format().split("T").shift()
        const weeklyStartDates = moment(priorWeeks).format().split("T").shift()
        const last3MonthsStartDate = moment(last3Months).format().split("T").shift()
        const last6MonthsStartDate = moment(last6Months).format().split("T").shift()
        const last12MonthsStartDate = moment(last12Months).format().split("T").shift()

        if (period.code === "MONTHLY" && period.title === "lifetime") {
            customEndDate = joinedDate
            setDuration(t("dashboard.lifetime_duration"))
        }
        else if (period.code === "DAILY") {
            customEndDate = dailyStartDate
            setDuration(t("dashboard.duration"))
        }
        else if (period.code === "WEEKLY") {
            customEndDate = weeklyStartDates
            setDuration(t("dashboard.weekly_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.3_months_duration"))) {
            customEndDate = last3MonthsStartDate
            setDuration(t("dashboard.3_months_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.6_months_duration"))) {
            customEndDate = last6MonthsStartDate
            setDuration(t("dashboard.6_months_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.12_months_duration"))) {
            customEndDate = last12MonthsStartDate
            setDuration(t("dashboard.12_months_duration"))
        }
        else {
            customEndDate = oneMonthAgo
            setDuration(t("dashboard.30_days_duration"))
        }
        const startDate = dates && moment(dates[0]).format('YYYY-MM-DD')
        const endDate = dates && moment(dates[1]).format('YYYY-MM-DD')
        
        if (defMembership) {
            switch (selectedType.code) {
                case 'customer':
                    if (!state.selectedCustomer && (!startDate || !endDate) && period.title === "lifetime") {
                        const customDateFilter = { startDate: period.title === "lifetime" ? joinedDate : customEndDate, endDate: endDates }
                        getCustomerReports(defMembership.business.id, customDateFilter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, customDateFilter)
                    }
                    else if (!state.selectedCustomer && (!startDate || !endDate) && period.title !== "lifetime") {
                        const customDateFilter = { startDate: customEndDate, endDate: endDates }
                        getCustomerReports(defMembership.business.id, customDateFilter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, customDateFilter)
                    }
                    else if (state.selectedCustomer && !dates && period.title === "lifetime") {
                        const customerFilter = { bcId: state.selectedCustomer?.id, startDate: joinedDate, endDate: endDates }
                        getCustomerReports(defMembership.business.id, customerFilter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, customerFilter)
                    }
                    else if (state.selectedCustomer && !dates) {
                        const customerFilter = { bcId: state.selectedCustomer?.id, }
                        const customDateFilter = { startDate: customEndDate, endDate: endDates }
                        getCustomerReports(defMembership.business.id, customDateFilter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, customerFilter)
                    }
                    else if (!state.selectedCustomer && (startDate && endDate !== "Invalid date")) {
                        const filter = { startDate, endDate }
                        getCustomerReports(defMembership.business.id, filter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, filter)
                    }
                    else if (state.selectedCustomer && (startDate && endDate !== "Invalid date")) {
                        const customerFilter = { bcId: state.selectedCustomer?.id, startDate, endDate }
                        getCustomerReports(defMembership.business.id, customerFilter)
                        getBusinessCustomerCharts(defMembership.business.id, period.code, customerFilter)
                    }
                    break;
                default:
                    if (!selectedOption && (!startDate || !endDate) && period.title === "lifetime") {
                        const customDateFilter = { startDate: period.title === "lifetime" ? joinedDate : customEndDate, endDate: endDates }
                        getPlanReportChart(defMembership.business.id, period.code, customDateFilter)
                        getPlanReport(defMembership.business.id, customDateFilter)
                    }
                    else if (!selectedOption && (!startDate || !endDate) && period.title !== "lifetime") {
                        const customDateFilter = { startDate: customEndDate, endDate: endDates }
                        getPlanReportChart(defMembership.business.id, period.code, customDateFilter)
                        getPlanReport(defMembership.business.id, customDateFilter)
                    }
                    else if (selectedOption && !dates && period.title === "lifetime") {
                        const planFilter = { planGroupId: selectedOption?.id, startDate: joinedDate, endDate: endDates }
                        getPlanReportChart(defMembership.business.id, period.code, planFilter)
                        getPlanReport(defMembership.business.id, planFilter)
                    }
                    else if (selectedOption && !dates) {
                        const planFilter = { planGroupId: selectedOption?.id, }
                        const customDateFilter = { startDate: customEndDate, endDate: endDates }
                        getPlanReportChart(defMembership.business.id, period.code, planFilter)
                        getPlanReport(defMembership.business.id, customDateFilter)
                    }
                    else if (!selectedOption && (startDate && endDate !== "Invalid date")) {
                        const filter = { startDate, endDate }
                        getPlanReportChart(defMembership.business.id, period.code, filter)
                        getPlanReport(defMembership.business.id, filter)
                    }
                    else if (selectedOption && (startDate && endDate !== "Invalid date")) {
                        const planFilter = { planGroupId: selectedOption?.id, startDate, endDate }
                        getPlanReport(defMembership.business.id, planFilter)
                        getPlanReportChart(defMembership.business.id, period.code, planFilter)
                    }
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.submitInput, period, selectedType, dates, selectedOption, state.selectedCustomer, selectedCustomer])
    const myData = selectedType.code === 'customer' ? state?.invitesData : state?.planInvitesData
    const myData2 = selectedType.code === 'customer' ? state?.paymentsData : state?.planPaymentsData
    const myData3 = selectedType.code === 'customer' ? state?.subscriptionsData : state?.planSubscriptionsData
    const invitationDates = myData.map((date) => {
        return (date.monthDisplayName)
    })
    const acceptedInvites = myData.map((number) => {
        return (number.accepted)
    })
    const pendingInvites = myData.map((number) => {
        return (number.pending)
    })
    const paymentDates = myData2.map((date) => {
        return (date.monthDisplayName)
    })
    const collectedPayments = myData2.map((number) => {
        return (number.collected)
    })
    const pendingPayments = myData2.map((number) => {
        return (number.pending)
    })
    const missedPayments = myData2.map((number) => {
        return (number.missed)
    })
    const subscriptionDates = myData3.map((date) => {
        return (date.monthDisplayName)
    })
    const newSubscriptions = myData3.map((number) => {
        return (number.fresh)
    })
    const activeSubscriptions = myData3.map((number) => {
        return (number.active)
    })
    const pausedSubscriptions = myData3.map((number) => {
        return (number.paused)
    })
    const cancelledSubscriptions = myData3.map((number) => {
        return (number.cancelled)
    })
    const completedSubscriptions = myData3.map((number) => {
        return (number.completed)
    })

    const numFor = Intl.NumberFormat("en-US")

    const collected_amount = planReports?.payments?.collected !== null ? planReports?.payments?.collected?.toFixed(2) : 0
    const paid = numFor.format(collected_amount);

    const missed_amount = planReports?.payments?.missed !== null ? planReports?.payments?.missed?.toFixed(2) : 0
    const missed = numFor.format(missed_amount);

    const pending_amount = planReports?.payments?.pending !== null ? planReports?.payments?.pending?.toFixed(2) : 0
    const pending = numFor.format(pending_amount);

    const collected_amount_customer = state.paymentsInfo?.collected !== null ? state.paymentsInfo?.collected?.toFixed(2) : 0
    const paid_amount = numFor.format(collected_amount_customer)

    const missed_amount_customer = state.paymentsInfo?.missed !== null ? state.paymentsInfo?.missed?.toFixed(2) : 0
    const missed_amounts = numFor.format(missed_amount_customer)

    const pending_amount_customer = state.paymentsInfo?.pending !== null ? state.paymentsInfo?.pending?.toFixed(2) : 0
    const pending_amounts = numFor.format(pending_amount_customer)

    const invitationData = {
        labels: invitationDates,
        datasets: [
            {
                label: t("invitation.accepted_invitations"),
                borderColor: '#66BB6A',
                backgroundColor: '#66BB6A',
                data: acceptedInvites
            },
            {
                label: t("invitation.pending_invitations"),
                borderColor: '#FFB300',
                backgroundColor: '#FFB300',
                data: pendingInvites
            }
        ]
    };
    const subscriptionsData = {
        labels: subscriptionDates,
        datasets: [
            {
                label: t("summary_reports.new"),
                borderColor: '#66BB6A',
                backgroundColor: '#66BB6A',
                data: newSubscriptions
            },
            {
                label: t("summary_reports.active"),
                borderColor: '#256029',
                backgroundColor: '#256029',
                data: activeSubscriptions
            },
            {
                label: t("summary_reports.completed"),
                borderColor: '#CAE7CB',
                backgroundColor: '#CAE7CB',
                data: completedSubscriptions
            },
            {
                label: t("summary_reports.paused"),
                borderColor: '#FCBA04',
                backgroundColor: '#FCBA04',
                data: pausedSubscriptions
            },
            {
                label: t("summary_reports.cancelled"),
                borderColor: '#FB2F30',
                backgroundColor: '#FB2F30',
                data: cancelledSubscriptions
            }
        ]
    };
    const paymentsData = {
        labels: paymentDates,
        datasets: [
            {
                label: t("business_customers.paid"),
                borderColor: '#66BB6A',
                backgroundColor: '#66BB6A',
                data: collectedPayments
            },
            {
                label: t("subscriber.pending_amount"),
                borderColor: '#FCBA04',
                backgroundColor: '#FCBA04',
                data: pendingPayments
            },
            {
                label: t("business_customers.missed"),
                borderColor: '#FB2F30',
                backgroundColor: '#FB2F30',
                data: missedPayments
            }
        ]
    };
    const invitationContent = [
        { title: t("summary_reports.accepted_invitations"), subDesc: t("subscriber.invitations"), style: "accepted-invitations", value: selectedType.code === "plan" ? planReports?.invitations.accepted : state.invitationInfo?.accepted, cardInfo: "The total number of accepted invitations" },
        { title: t("summary_reports.pending_invitations"), subDesc: t("subscriber.invitations"), style: "pending-invitations", value: selectedType.code === "plan" ? planReports?.invitations.pending : state.invitationInfo?.pending, cardInfo: "The total number of pending invitations" },
        { title: t("summary_reports.expired_invitations"), subDesc: t("subscriber.invitations"), style: "expired-invitations", value: selectedType.code === "plan" ? planReports?.invitations.expired : state.invitationInfo?.expired, cardInfo: "The total number of expired invitations" }
    ]
    const subscriptionContent = [
        { title: t("summary_reports.new_subscriptions"), subDesc: t("subscriber.title"), style: "new-subscriptions", value: selectedType.code === "plan" ? planReports?.subscriptions.fresh : state.subscriptionInfo?.fresh, cardInfo: t("summary_reports.new_info") },
        { title: t("summary_reports.active_subscriptions"), subDesc: t("subscriber.title"), style: "active-subscriptions", value: selectedType.code === "plan" ? planReports?.subscriptions.active : state.subscriptionInfo?.active, cardInfo: t("summary_reports.active_info") },
        { title: t("summary_reports.completed_subscriptions"), subDesc: t("subscriber.title"), style: "completed-subscriptions", value: selectedType.code === "plan" ? planReports?.subscriptions.completed : state.subscriptionInfo?.completed, cardInfo: t("summary_reports.completed_info") },
        { title: t("summary_reports.paused_subscriptions"), subDesc: t("subscriber.title"), style: "paused-subscriptions", value: selectedType.code === "plan" ? planReports?.subscriptions.paused : state.subscriptionInfo?.paused, cardInfo: t("summary_reports.paused_info") },
        { title: t("summary_reports.cancelled_subscriptions"), subDesc: t("subscriber.title"), style: "cancelled-subscriptions", value: selectedType.code === "plan" ? planReports?.subscriptions.cancelled : state.subscriptionInfo?.cancelled, cardInfo: t("summary_reports.cancelled_info") }
    ]
    const paymentContent = [
        { title: t("dashboard.collected_payments"), subDesc: t("business_customers.payments"), style: "collected-payments", value: selectedType.code === "plan" ? paid : paid_amount, cardInfo: t("summary_reports.collected_payments") },
        { title: t("dashboard.pending_payments"), subDesc: t("business_customers.payments"), style: "pending-payments", value: selectedType.code === "plan" ? pending : pending_amounts, cardInfo: t("summary_reports.pending_payments") },
        { title: t("dashboard.missed_payments"), subDesc: t("business_customers.payments"), style: "missed-payments", value: selectedType.code === "plan" ? missed : missed_amounts, cardInfo: t("summary_reports.missed_payments") }
    ]
    const searchCustomers = (event) => {
        getFilteredCustomers(defMembership.business.id, event.query.toLowerCase());
        setFilteredCustomer(filteredCustomers)
    };

    const onSummaryUpdate = () => {
        setSummary(true)
    }

    const OptionType = () => {
        if (selectedType.code === "plan" && !selectedOption) {
            return <p>{t("summary_reports.all_product_accounts")}</p>
        } else if (selectedType.code === "customer" && !selectedCustomer) {
            return <p>{t("summary_reports.all_customers")}</p>
        } else if (selectedType.code === "plan" && selectedOption?.name) {
            return <div>{selectedOption?.name}</div>
        } else if (selectedType.code === "customer" && selectedCustomer?.name !== null) {
            return <div>{selectedCustomer?.name}</div>
        } else
            return <div></div>
    }
    return (
        <>
            <div>
                <h6 className="wallet-titles" style={{ marginLeft: "1.5em" }} >{t("summary_reports.subs_summary_title")}</h6>
                <ActionBar selectedType={selectedType} setSelectedType={setSelectedType} dropdownItems={dropdownItems} selectedOption={selectedOption}
                    summaryOptions={summaryOptions} setSelectedOption={setSelectedOption} selectedCustomer={selectedCustomer} searchCustomers={searchCustomers} filteredCustomer={filteredCustomer}
                    setSelectedCustomer={setSelectedCustomer} periods={periods} period={period} setDates={setDates} setPeriod={setPeriod}
                    dates={dates} state={state} setState={setState} placeholder={t("summary_reports.choose_product")} summaryOption={true}
                    reactToPrintContent={reactToPrintContent} summary={summary} onClick={onSummaryUpdate} />

                <div className="print section-to-print" ref={componentRef}>
                    <div className="hide-on-screen" >
                        <div className='summary_title'><span className="summary">{t("summary_reports.transactions_subs_title")}</span></div>
                        <div style={{ margin: "0rem 0rem 1rem 1.5rem" }}>
                            <div className="retrieved_date"><span className="retrieved_date">{moment(selectedOption?.createdDate).format("ll, HH:mm")}</span></div>
                            <div style={{ display: "flex", flexDirection: "row", paddingTop: "0rem", margin: "0" }}>
                                <span className='generation_txt'>{t("payments.generation_txt")}</span>
                                <img src={logo} alt="babylon-layout" className="logo" />
                            </div>
                        </div>

                        <div className='table_div'>
                            <div className='labels_main_div'>
                                <div className='col-4 label'>
                                    {selectedType.code === "plan" ?
                                        <p>{t("summary_reports.plan_name")}</p> :
                                        <p>{t("summary_reports.customer_name")}</p>
                                    }
                                </div>
                                <div className='col-4 label'>
                                    <p>{t("summary_reports.report_period")}</p>
                                </div>
                                <div className='col-4 label'>
                                    <p>{t("summary_reports.filters")}</p>
                                </div>
                            </div>

                            <div className='info_div'>
                                <div className='col-4'>
                                    <OptionType />
                                </div>
                                <div className='col-4'>
                                    {dates === null ? <div>{period.name}</div> : <div>{dates && moment(dates[0]).format('ll,HH:mm -- ')}
                                        {dates && moment(dates[1]).format('ll,HH:mm')}</div>}
                                </div>

                                <div className='col-4'>
                                    {selectedType?.code === 'plan' ? <div>{t("summary_reports.product_account")}</div> : <div>{t("summary_reports.customer")}</div>}
                                    {dates === null && <div>{t("summary_reports.duration")}</div>}
                                    {dates === null ? <div>{" "}</div> : <div>{t("summary_reports.custom_date")}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SummarySection header={t("subscriber.invitations")} summary={invitationContent} loading={loading} />
                    <SummarySection header={t("subscriber.title")} summary={subscriptionContent} loading={loading} />
                    <SummarySection header={t("subscriber.payments")} summary={paymentContent} loading={loading} />
                    <br />
                    <div className='charts_main_div'>
                        <ChartsReport {...{ invitationData, paymentsData, subscriptionsData, duration, loading }} />
                    </div>
                </div>
            </div >
        </>
    )
}
export default SubscriptionSummary;