import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import PaymentTrackActions from "../Redux/transition/payment-track/payment-track.reducer";
import moment from "moment";
import { classNames } from "primereact/utils";
import "../Styles/pages/customers.scss"
import "../Styles/pages/subscriptionDetail.scss"
import "../Styles/components/sti.css"
import "../Styles/pages/inviteSubscriber.scss"
import "../Styles/pages/invitation.scss"

const MarkAsPaid = (props) => {
    const { displayDialog, onHide, scheduleId, scheduledDate, scheduledFor, callbackFunctions, isFlexTrue } = props
    const { t } = useTranslation("common");

    const toast = useRef(null);
    const [state, setState] = useState({
        marking: true,
    });

    //Redux State from Store
    const marking = useSelector((state) => state.paymentTracks.markingExternally)
    const errorMark = useSelector((state) => state.paymentTracks.errorMark)
    const defMembership = useSelector((state) => state.defMembership.defMembership)

    //Redux Dispatch Actions
    const dispatch = useDispatch()
    const markAsPaid = useCallback((scheduleId, membershipId, schedule) => { dispatch(PaymentTrackActions.paymentMarkExternallyRequest(scheduleId, membershipId, schedule)) }, [dispatch])

    useEffect(() => {
        if (!state.marking && !marking && errorMark) {
            reject()
        }
        if (!state.marking && !marking && !errorMark) {
            accept()
            setState((state) => { return { ...state, marking: true } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.marking, marking, errorMark])


    // toast for when there is a success marking a payment
    const accept = () => {
        toast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.marked'), life: 3000 });
    }

    // toast for when there is an error marking a payment
    const reject = () => {
        toast.current.show({ severity: 'error', summary: t('subscriber.error'), detail: t('subscriber.error_mark'), life: 3000 });
    }

    const formik = useFormik({
        initialValues: {
            dateReceived: "",
            paymentReference: "",
            amountReceived: "",
            paymentNotes: ""
        },
        validate: (data) => {
            let errors = {};
            if (!data.dateReceived) {
                errors.dateReceived = t("subscriber.date_error");
            }
            if (!data.amountReceived && isFlexTrue) {
                errors.amountReceived = t("transaction.flex_amount_error");
            }
            return errors;
        },
        onSubmit: (data) => {
            const scheduleInfo = {
                receivedOn: moment(data.dateReceived).format("YYYY-MM-DD"),
                reference: data.paymentReference,
                amount: data.amountReceived,
                notes: data.paymentNotes,
            }
            markAsPaid(scheduleId, defMembership.id, scheduleInfo)
            setState((state) => ({ ...state, marking: false }))
        },
    })
    const date = moment(scheduledDate).format("LL")
    const scheduledForDate = moment(scheduledFor).format("LL")

    useEffect(() => {
        if (scheduledDate) {
            formik.setFieldValue("dateReceived", date)
        }
        if (scheduledFor) {
            formik.setFieldValue("dateReceived", scheduledForDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledDate, scheduledFor])


    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const resetForm = () => {
        formik.resetForm();
    }

    let today = new Date();
    return (
        <>
            <Toast ref={toast} onRemove={(message) => {
                if (message.severity === 'success') {
                    onHide()
                    resetForm()
                    callbackFunctions()
                }
            }} />

            <div className="transactions_card" >
                < Dialog header={t('subscriber.mark_title')} visible={displayDialog}
                    onHide={onHide} className="tier_dialog" id="tier_dialog" style={{ width: '350px' }} >
                    <div>
                        <form onSubmit={formik.handleSubmit} className="p-fluid" style={{ display: 'flex', flexDirection: 'column', padding: "7px" }}>
                            <div style={{ padding: "10px" }}>
                                <div className="title" style={{ marginBottom: "5px" }}>{t("subscriber.date_marked")}<span style={{ color: "red" }}>*</span></div>
                                <div className="date-content" style={{ marginBottom: "5px" }}>
                                    <div style={{ marginBottom: "5px" }}>
                                        <div style={{ width: "100%", height: "3rem" }}>
                                            <Calendar
                                                style={{ width: "100%" }}
                                                name="dateReceived"
                                                id="dateReceived"
                                                value={formik.values.dateReceived}
                                                dateFormat='MM d, yy'
                                                maxDate={today}
                                                readOnlyInput
                                                inputMode=""
                                                onChange={formik.handleChange}
                                                showIcon
                                                placeholder={scheduledDate ? date : scheduledForDate}
                                                className="p-datepicker-header invite-calender datepicker"
                                            />
                                            <label
                                                htmlFor="dateReceived"
                                                className={classNames({
                                                    "p-error": isFormFieldValid("dateReceived"),
                                                })}
                                            ></label>
                                        </div>
                                    </div>
                                    {getFormErrorMessage("dateReceived")}
                                </div>
                                {
                                    isFlexTrue &&
                                    <div>
                                        <div className="title" style={{ marginBottom: "5px", marginTop: "0.9rem" }}>{t("subscriber.amount_received")}<span style={{ color: "red" }}>*</span></div>
                                        <div style={{ marginBottom: "5px" }}>
                                            <div style={{ width: "100%" }}>
                                                <InputText
                                                    id="amountReceived"
                                                    name="amountReceived"
                                                    value={formik.values.amountReceived}
                                                    onChange={formik.handleChange}
                                                    style={{ width: "100%", height: "3rem", padding: "11.2px" }}
                                                    placeholder={t("subscriber.amount_received_label")}
                                                />
                                                <label
                                                    htmlFor="amountReceived"
                                                    className={classNames({
                                                        "p-error": isFormFieldValid("amountReceived"),
                                                    })}
                                                ></label>
                                            </div>
                                        </div>
                                        {getFormErrorMessage("amountReceived")}
                                    </div>
                                }

                                <div>
                                    <div className="title" style={{ marginBottom: "5px", marginTop: "0.9rem" }}>{t("subscriber.notes")}</div>
                                    <div>
                                        <InputTextarea
                                            name="paymentNotes"
                                            id="paymentNotes"
                                            value={formik.values.paymentNotes}
                                            style={{ width: "100%" }}
                                            onChange={formik.handleChange}
                                            rows={5}
                                            cols={30}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                            <Button
                                type="submit"
                                loading={marking}
                                className="p-mt-2" style={{ fontFamily: "Roboto", marginLeft: 'auto', marginRight: '0', marginTop: "1rem", width: 'max-content', backgroundColor: "#d0021b" }}
                            >
                                {t('subscriber.markAsPaid')}
                            </Button>
                        </form>
                    </div>
                </Dialog>
            </div>
        </>
    );
};

export default MarkAsPaid;