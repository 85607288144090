/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import EnterpriseTypes from '../../Redux/transition/enterprise/enterprise.reducer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from "../../Components/Loading";
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next";

function Api() {
  const { t } = useTranslation("common");
  const [state, setState] = useState({
    enterprise: [],
    show: false,
    generate: false,
    loading: false,
    loading2: false,
  })
  // Redux State from Store

  const defMembership = useSelector((state) => state.defMembership.defMembership);
  const enterprise = useSelector((state) => state.enterprise.enterprise);
  const fetchingEnterprise = useSelector((state) => state.enterprise.fetchingEnterprise);
  const generating = useSelector((state) => state.enterprise.generating);
  // const document = useSelector((state) => state.documents.documents);


  //Redux Dispatch Action
  const dispatch = useDispatch();
  const getDefMembership = useCallback(() => { DefMembershipActions.defMembersipRequest() }, [])
  const getCredentials = useCallback(businessId => { dispatch(EnterpriseTypes.enterpriseRequest(businessId)) }, [dispatch]);
  const generate = useCallback((businessId) => { dispatch(EnterpriseTypes.generateRequest(businessId)) }, [dispatch]);
  // const getDocuments = useCallback((countryId, type) => dispatch(DocumentTypes.documentRequest(countryId,type), [dispatch]))

  useEffect(() => {
    if (!defMembership) {
      getDefMembership();

    }

    if (defMembership) {
      getCredentials(defMembership.business.id)
    }

  }, [])

  useEffect(() => {
    if (!generating && state.loading2) {
      setState((state) => ({ ...state, loading2: false }))
    }
    setState(() => ({ ...state, enterprise: [enterprise] }))

  }, [fetchingEnterprise, state.loading2, generating])

  if (!enterprise) {
    return <Loading />
  }

  const generatePassword = () => {
    generate(defMembership.business.id)
    setState((state) => ({ ...state, loading2: true }))
  }

  return (

    <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
      <Button label={t("api_settings.generate")} style={{ marginBottom: '1rem' }} onClick={() => generatePassword()} loading={generating} />
      <DataTable value={state.enterprise} showGridlines responsiveLayout="scroll">
        <Column field="clientId" header={t("api_settings.client_id")}></Column>
        <Column style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} field="clientSecret" header={t("api_settings.password")}
          body={(rowData) => {
            return <> <p style={{ width: '80%', display: 'flex', wordBreak: 'break-all' }}> {state.show ? rowData.clientSecret : "**************"} </p> <br />
              <Button icon={state.show ? 'pi pi-eye-slash' : "pi pi-eye"} className="p-button-rounded p-button-text p-button-info " onClick={() => setState(() => ({ ...state, show: !state.show }))} ></Button> </>
          }}
        ></Column>
        <Column field="businessKey" header={t("api_settings.business_key")}></Column>
      </DataTable>

    </div>

  )
}

export default Api
