import { call, put } from 'redux-saga/effects'

import AccountActions from '../actions/account-actions'
import { getAccount, updateAccount, resendVerificationEmail } from '../../Service/api'

// attempts to account
export function* getAccountData(api) {
    const response = yield call(getAccount)

    // success?
    if (response.ok) {
        if (!response.data || !response.data.login) {
            console.log('Account - FAIL')
            yield put(AccountActions.accountFailure('WRONG'))
            return
        }
        console.log('Account - OK')
        yield put(AccountActions.accountSuccess(response.data))

    } else {
        console.log('Account - FAIL')
        yield put(AccountActions.accountFailure('WRONG'))
    }
}

// attempts to update account settings
export function* updateAccountData(action) {
    const { account } = action
    const apiCall = call(updateAccount, account)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        console.log('AccountUpdate - OK')
        yield put(AccountActions.accountUpdateSuccess(response.data))
    } else {
        console.log('AccountUpdate - FAIL')
        yield put(AccountActions.accountUpdateFailure('WRONG'))
    }

}

export function* resendEmailVerification(action) {
    const {user} = action
    const response = yield call(resendVerificationEmail, user)

    // success?
    if (response.ok) {
		yield put(AccountActions.emailVerificationResendSuccess(response.data));
	} else {
		yield put(AccountActions.emailVerificationResendFailure(response.data));
	}
}
