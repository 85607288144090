import React, { useState, useEffect, useCallback, } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomDataTable from '../../../Components/CustomDataTable';
import { Card } from 'primereact/card';
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from 'primereact/button';
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";
import "../../../Styles/pages/customers.scss";
import Loading from "../../../Components/Loading";

const WithdrawalList = () => {

    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(0);

    // Redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroup = useSelector((state) => state.planGroups.planGroup);
    const settlement = useSelector((state) => state.settlements.planSettlements);
    const fetchingOnePlanGroup = useSelector((state) => state.planGroups.fetchingOne);
    const fetchingOne = useSelector((state) => state.settlements.fetchingOne);
    const fetchingPlanSettlements = useSelector((state) => state.settlements.fetchingPlanSettlements);
    const walletStatistics = useSelector((state) => state.wallet.walletStatistics);

    //Redux dispatch actions
    const dispatch = useDispatch();
    const getList = useCallback((businessId, filter, options) => dispatch(SettlementActions.planSettlementsRequest(businessId, filter, options)), [dispatch])
    const getPlanGroup = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupRequest(planId, options)), [dispatch])
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const getWalletStatistics = useCallback((businessId, options) => { dispatch(WalletActions.walletStatisticsRequest(businessId, options)); }, [dispatch]);

    const [state, setState] = useState({
        planGroup: {},
        withdrawals: [],
        settlement: [],
        pending: [],
        processed: [],
        reversed: [],
        settlementList: [],
        walletStatisticsObject: [],
        loading: true,
        count: null,
        size: 15,
        page: 0,
        first: 0,
        sort: 'requestedOn,desc',
    })

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('walletId');

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getPlanGroup(id);
            getWalletStatistics(defMembership.business.id, {})
        }
        getList(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (planGroup) {
            setState(state => { return { ...state, planGroup: planGroup } });
        }
        if (settlement) {
            setState((state) => { return { ...state, settlement } })
        }
        if (walletStatistics) {
            setState((state) => { return { ...state, walletStatisticsObject: walletStatistics.content } })
        }
    }, [planGroup, settlement, walletStatistics]);

    const pendingArray = state.settlement?.filter(item => item.status === 'PENDING');
    const processedArray = state.settlement?.filter(item => item.status === "PROCESSED");
    const reversedArray = state.settlement?.filter(item => item.status === "REVERSED");

    if (fetchingOnePlanGroup || fetchingOne || fetchingPlanSettlements) {
        return <Loading />;
    }
    const productAccountBalances = state.walletStatisticsObject.find(({ groupId }) => groupId === parseInt(id)) || null

    const statusTemplate = (rowData) => {
        if (rowData.status === 0) {
            return "";
        } else {
            if (rowData.status === "PROCESSED") {
                return <div className="statuses">
                    <span className={`status-outline status-active status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                </div>
            }
            else if (rowData.status === "PENDING") {
                return <div className="statuses">
                    <span className={`status-outline status-pending status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                </div>
            }
            else if (rowData.status === "REVERSED") {
                return <div style={{ display: "flex", flexDirection: "column", width: "100%" }} className="status">
                    <div className="statuses" style={{ height: "20px" }}>
                        <span className={`status-outline status-reversed status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                    </div>
                    <span style={{ fontSize: "10px", height: "auto", marginTop: "1rem" }} className="reversalReason reversed_status">
                        {rowData.reversalReason}
                    </span>
                </div>
            }
        }
    }

    const activityTemplate = (rowData) => {
        if (rowData !== null) {
            return <div>
                <div style={{ display: "flex", textAlign: "left", flexDirection: "column", width: "15rem", height: "4rem" }}>
                    <p style={{ marginBottom: "2px" }} className="trans-normal-text reference">{rowData.subReference === null ? "--" : rowData.subReference}</p>

                    {rowData.status === "PENDING" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span className="requested_on_header">{t("transaction.requested_on")}</span>
                        <span id="processed_on">{moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
                    </span> : null}
                    {rowData.status === "PROCESSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span className="processed_on_header">{t("transaction.processed_on")}</span>
                        <span id="processed_on">{moment(rowData.processedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
                    </span> : null}
                    {rowData.status === "REVERSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span className="processed_on_header">{t("transaction.reversed_on")}</span>
                        <span id="processed_on">{moment(rowData.reversedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
                    </span> : null}
                </div>
            </div>
        }
        return "--"
    }

    const customerBodyTemplate = (rowData) => {
        return <div style={{ display: "flex", flexDirection: "row" }} onClick={() => { console.log(rowData); }}>
            <div className="channel-logo">
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <div>
                        <span severity="info" id="channel_type">
                            {rowData.channel ? rowData.channel.type : "--"}
                        </span>
                    </div>
                    <div>
                        {
                            <img alt="channel logo" src={rowData.channel?.systemBank.logoUrl} width='50rem' height='40rem' />
                        }
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "0.5rem", textAlign: "start" }}>
                {rowData.channel ?
                    <span className="account_name">
                        {rowData.channel !== null ? rowData.channel.channelName : "--"}</span> : null
                }
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    {rowData.channel ?
                        <span className="account_number">
                            {rowData.channel !== null && rowData.channel.channelName === null ?
                                <span style={{ fontWeight: "bold", color: "#000" }}>{rowData.channel.identifier}</span> : rowData.channel.identifier}
                        </span> : null
                    }
                </div>
                <div className="channel-info_div">
                    <div className="channel-info" >
                        <span id="channel_name">
                            {rowData.channel ? rowData.channel.systemBank.name : "--"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    }

    const requestedByTemplate = (rowData) => {
        return <div className="customerBodyTemplate">
            {rowData.requestedByName ?
                <span className="requested-info">{rowData.requestedByName === (null || '') ? rowData.requestedByLogin : rowData.requestedByName}</span> : "--"
            }
            {rowData.requestedByName ?
                <span className="requested_normal-info">{rowData.requestedByName !== null ? rowData.requestedByLogin : "--"}</span> : "--"
            }
        </div>
    }

    const amountTemplate = (rowData) => {
        const amountPaid = `${rowData.amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        const fee = `${rowData.fee.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        if (rowData.amount) {
            return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span className="trans-normal-text">
                    {`${defMembership.business.country.currency} ${amountPaid}`}
                </span>
                <span className="fee" style={{ marginTop: "0.2rem" }}>
                    {t("transaction.fee")}	{`: ${defMembership.business.country.currency} ${fee}`}
                </span>
            </div>
        } else {
            return "--"
        }
    }

    const headerStyle = {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        color: "#666678",
        textTransform: "capitalize"
    }

    const withdrawalsTableColumns = [
        {
            field: "status", body: statusTemplate, header: t("transaction.status"), headerClassName: "status-header", sort: true

        },
        {
            field: "activity", header: t("transaction.withdrawal_activity_header"),
            body: activityTemplate, headerClassName: "activity-header", sort: true
        },
        {
            field: "requestedBy", body: requestedByTemplate, header: t("transaction.requested_by"),
            headerStyle: headerStyle
        },
        {
            field: "recipientAccount", body: customerBodyTemplate, header: t("transaction.recipient"),
            headerStyle: headerStyle
        },
        { field: "requested", body: amountTemplate, header: t("transaction.amount"), sort: true, headerStyle: headerStyle }
    ]

    const tabs = [
        {
            label: "Processed",
            columns: withdrawalsTableColumns, value: processedArray
        },
        {
            label: "Pending",
            columns: withdrawalsTableColumns, value: pendingArray
        },
        {
            label: "Reversed",
            columns: withdrawalsTableColumns, value: reversedArray
        }
    ];

    return (
        <div>
            <Card className="transaction_card">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {state.planGroup.favourite ?
                                <Button disabled icon="pi pi-star-fill" className="p-button-rounded wallet-favorite" id="wallet-favorite" /> :
                                <Button disabled icon="pi pi-star" className="p-button-rounded p-button-outlined wallet-unfavorite" id="wallet-unfavorite" />
                            }
                            <div style={{ fontSize: "20px", fontWeight: "800", marginLeft: "1rem" }}>{state.planGroup?.name}</div>
                        </div>
                        {state.planGroup?.description ? <div style={{ marginTop: '1rem' }}>{state.planGroup?.description}</div> : null}
                        {state.planGroup.exclusive ? <span className={`planCard-badge exclusive_tag`}>{t("product.exclusive")}</span> :
                            <span className={`planCard-badge non-exclusive_tag`}>{t("product.non_exclusive")}</span>}
                        <div className="grid" style={{ margin: "1rem 0rem 0rem 0rem", display: "flex", flexDirection: "row" }}>
                            {(state.planGroup.status === "ACTIVE") ? <div className="active_status">{state.planGroup.status}</div> : <span className="archive_status">{state.planGroup.status}</span>}
                            <div> <span className="createdOn_des">{t("transaction.created_on")}</span>{":"}{" "}<span>{moment(state.planGroup?.createdOn).format("D MMM, YYYY")}</span></div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div style={{ marginTop: "0.6rem" }}>
                            <span style={{ fontWeight: "bold" }}>{t("Balance")}</span>{":"} {defMembership.business?.currency} {productAccountBalances?.balance}
                        </div>
                        <div style={{ marginTop: "1.2rem" }}>
                            <span className="availableTransaction">{t("AVAILABLE")}</span>{":"} {defMembership.business?.currency} {productAccountBalances?.available}
                        </div>
                        <div style={{ marginTop: "1.2rem" }}>
                            <span className="pendingTransaction">{t("PENDING")}</span>{":"} {defMembership.business?.currency} {productAccountBalances?.pendingWthdlAmount}
                        </div>
                        <div className="grid" style={{ marginTop: "1rem" }}>
                            {state.planGroup.recentDebit && state.planGroup.lastDebited ? <span className="last_debited">{t("transaction.last_debited")}{":"} </span> : null}
                            {state.planGroup.recentDebit ? <div style={{ marginLeft: "0.5rem" }}>{defMembership.business?.currency}{" "}{state.planGroup.recentDebit?.toFixed(2)}</div> : null}
                            {state.planGroup.lastDebited ?
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ padding: "0rem 0.5rem 0rem 0.5rem" }}>
                                        {t("on")} {" "} {moment(state.planGroup.lastDebited).format("D MMM, YYYY")}<div />
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </Card >
            <CustomDataTable
                value={tabs[activeTab].value}
                rows={10}
                paginator
                columns={tabs[activeTab].columns}
                tabs={tabs.map(({ label, i }) => ({ label, i }))}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setState={setState}
                title={t("transaction.withdrawal_table_title")}
                children={null}
            />
        </div >
    )
}
export default WithdrawalList;