import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PlanActions from "../../Redux/transition/plan/plan.reducer";

function PriceChange(props) {

    const { visible, onHide, toggleState, oldPrice, id, defMembership } = props

    const changingPrice = useSelector((state) => state.plans.changingPrice)
    const changingSuccess = useSelector((state) => state.plans.changingSuccess)
    const errorChanging = useSelector((state) => state.plans.errorChangingPrice)

    const dispatch = useDispatch()
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const changePlanPrice = useCallback((planId, amount) => { dispatch(PlanActions.planPriceChangeRequest(planId, amount)) }, [dispatch])

    const toast = useRef(null);
    const { t } = useTranslation("common");
    const [state, setState] = useState({
        success: false,
        setDisplayBasic: false,
        visible: false,
    })

    useEffect(() => {
        if (!changingPrice && state.success && changingSuccess) {
            showSuccess();
            setState((state) => { return { ...state, success: false, } });
        }
        if (!changingPrice && state.success && errorChanging) {
            showError();
            setState((state) => { return { ...state, success: false, } });
        }
        if (visible) {
            setState((state) => { return { ...state, visible: visible } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changingPrice, state.success, changingSuccess, errorChanging, visible])

    const showSuccess = (data) => {
        toast.current.show({ severity: 'success', code: "priceChange", summary: t("product.success_summary"), detail: t('product.price_change_successful'), life: 3000 });
    }

    const showError = (data) => {
        toast.current.show({ severity: 'error', summary: t("product.error_summary"), detail: t("product.archive_error"), life: 3000 });
    }

    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = t("products.validate_error");
            }
            return errors;
        },

        onSubmit: (data) => {
            const planData = {
                amount: data.amount
            };
            changePlanPrice(id, planData);
            setState({ ...state, success: true, });
        },
    });

    const isFormFieldValid = (amount) =>
        !!(formik.touched[amount] && formik.errors[amount]);

    const getFormErrorMessage = (amount) => {
        return (
            isFormFieldValid(amount) && (
                <small className="p-error">{formik.errors[amount]}</small>
            )
        );
    };

    return (
        <>
            <Dialog
                header={t("product.change_price")}
                visible={toggleState}
                style={{ width: "30vw", }}
                onHide={() => { onHide() }}
                className="tier_dialog" id="tier_dialog"
            >
                <Toast
                    className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
                    ref={toast}
                    onRemove={(message) => {
                        if (message.code === 'priceChange') {
                            onHide()
                            getActivePlanGroups(defMembership.business.id)
                            setState({ ...state, success: false, });
                            formik.resetForm();
                        }
                    }}
                />

                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="p-field">
                        <span>{t("products.old_amount")} {`(${defMembership.business.currency})`}</span>
                        <span className="p-float-label">
                            <InputText
                                id="old_amount"
                                name="old_amount"
                                disabled
                                value={oldPrice}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("name"),
                                })}
                            />
                        </span>
                        {getFormErrorMessage("name")}
                    </div><br />
                    <div className="p-field">
                        <span className="p-float-label mt-3">
                            <InputText
                                id="amount"
                                name="amount"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                            />
                            <label
                                htmlFor="amount"
                                className={classNames({
                                    "p-error": isFormFieldValid("amount"),
                                })}
                            >
                                {t("products.new_amount")} {`(${defMembership.business.currency})`}
                            </label>
                        </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "10px", marginBottom: "1rem", fontFamily: "Roboto" }}>
                        <Button
                            type="submit"
                            disabled={changingPrice}
                            loading={changingPrice}
                            label={t("products.save_product")}
                            style={{ backgroundColor: "#d2132b", fontWeight: "bold", width: "115px", alignItems: "end" }}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    )
}
export default PriceChange;