import { call, put } from "redux-saga/effects";
import * as api from "../../../Service/api";
import PaymentTrackActions from "./payment-track.reducer";
import PaymentScheduleActions from "../payment-schedule/payment-schedule.reducer";


export function* getPaymentTrack(action) {
	const { paymentTrackId } = action;
	// make the call to the api
	const response = yield call(api.getPaymentTrack, paymentTrackId);
	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.paymentTrackSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTrackFailure(response.data));
	}
}

export function* getPaymentTrackRevenue(action) {
	const { businessId, startDate, endDate } = action;
	// make the call to the api
	const response = yield call(
		api.getPaymentTrackRevenue,
		businessId,
		startDate,
		endDate
	);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.paymentTrackRevenueSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTrackRevenueFailure(response.data));
	}
}

export function* getPaymentTracks(action) {
	const { subscriberId, options } = action;
	// make the call to the api
	const response = yield call(api.getPaymentTracks, subscriberId, options);
	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.paymentTrackAllSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTrackAllFailure(response.data));
	}
}

export function* getPaymentTrackRange(action) {
	const { businessId, startDate, endDate, options } = action;
	// make the call to the api
	const response = yield call(
		api.getPaymentTrackRange,
		businessId,
		startDate,
		endDate,
		options
	);

	// success?
	if (response.ok) {
		yield put(PaymentScheduleActions.paymentTrackRangeSuccess(response.data));
	} else {
		yield put(PaymentScheduleActions.paymentTrackRangeFailure(response.data));
	}
}

// export function* updatePaymentTrack(action) {
// 	const { paymentTrack } = action;
// 	// make the call to the api
// 	const idIsNotNull = !!paymentTrack.id;
// 	const response = yield call(
// 		idIsNotNull ? api.updatePaymentTrack : api.createPaymentTrack,
// 		paymentTrack
// 	);

// 	// success?
// 	if (response.ok) {
// 		yield put(PaymentTrackActions.paymentTrackUpdateRequest(response.data));
// 	} else {
// 		yield put(PaymentTrackActions.paymentTrackUpdateFailure(response.data));
// 	}
// }

export function* deletePaymentTrack(action) {
	const { paymentTrackId } = action;
	// make the call to the api
	const response = yield call(api.deletePaymentTrack, paymentTrackId);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.paymentTrackDeleteSuccess());
	} else {
		yield put(PaymentTrackActions.paymentTrackDeleteFailure(response.data));
	}
}

export function* getPaymentTrackGroup(action) {
	const { planGroupId, options } = action;
	// make the call to the api
	const response = yield call(api.getPaymentTrackGroup, planGroupId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.PaymentTrackGroupSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTrackGroupFailure(response.data));
	}
}
export function* getPaymentTrackGroupCount(action) {
	const { planGroupId, options } = action;
	// make the call to the api
	const response = yield call(api.getPaymentTrackGroupCount, planGroupId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.PaymentTrackGroupCountSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTrackGroupCountFailure(response.data));
	}
}
export function* markPaymentExternally(action) {
	const { scheduleId, membershipId, schedule } = action;
	// make the call to the api
	const response = yield call(api.paymentMarkExternally, scheduleId, membershipId, schedule);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.PaymentMarkExternallySuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentMarkExternallyFailure(response.data));
	}
}

export function* getSubsExternalPayments(action) {
	const { businessId, subId, options } = action;
	// make the call to the api
	const response = yield call(api.subscriptionsExternalPayments, businessId, subId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.subscriberExternalPaymentsSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.subscriberExternalPaymentsFailure(response.data));
	}
}
export function* getGroupExternalPayments(action) {
	const { businessId, groupId, options } = action;
	// make the call to the api
	const response = yield call(api.planGroupExternalPayments, businessId, groupId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.planGroupExternalPaymentsSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.planGroupExternalPaymentsFailure(response.data));
	}
}

export function* getCollectedPaymentTracks(action) {
	const { businessId, filter, options } = action;
	// make the call to the api
	const response = yield call(api.getCollectedPaymentTrack, businessId, filter, options);
	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.collectedPaymentTrackSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.collectedPaymentTrackFailure(response.data));
	}
}
export function* getMissedPaymentTracks(action) {
	const { businessId, filter, options } = action;
	// make the call to the api
	const response = yield call(api.getMissedPaymentTrack, businessId, filter, options);
	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.missedPaymentTrackSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.missedPaymentTrackFailure(response.data));
	}
}
export function* getExternalPaymentTracks(action) {
	const { businessId, filter, options } = action;
	// make the call to the api
	const response = yield call(api.getExternalPaymentTrack, businessId, filter, options);
	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.externalPaymentTrackSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.externalPaymentTrackFailure(response.data));
	}
}

export function* getPlanExternalPayments(action) {
	const { businessId, planId, options } = action;
	// make the call to the api
	const response = yield call(api.planExternalPayments, businessId, planId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.planExternalPaymentsSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.planExternalPaymentsFailure(response.data));
	}
}
export function* getBusinessCustomerExternalPayments(action) {
	const { businessId, bcId, options } = action;
	// make the call to the api
	const response = yield call(api.businessCustomerExternalPayments, businessId, bcId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.businessCustomerExternalPaymentsSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.businessCustomerExternalPaymentsFailure(response.data));
	}
}
export function* getPaymentTracksCount(action) {
	const { subscriberId, options } = action;
	// make the call to the api
	const response = yield call(api.countPaymentTracks, subscriberId, options);

	// success?
	if (response.ok) {
		yield put(PaymentTrackActions.paymentTracksCountSuccess(response.data));
	} else {
		yield put(PaymentTrackActions.paymentTracksCountFailure(response.data));
	}
}
