import { connect } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import MembershipActions from "../../Redux/transition/membership/membership.reducer";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useParams } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import moment from "moment";

let mount = false;
const Colleagues = (props) => {
	const {
		memberships,
		getMembership,
		defMembership,
		fetchingAllMembership,
		location,
	} = props;

	const { t } = useTranslation("common");
	let { id } = useParams();
	// const [state, setState] = useState({
	// 	tabMenuItems: [
	// 		{
	// 			label: "Successful Transactions",
	// 			id: 1,
	// 			className: "tab-success",
	// 		},
	// 		{ label: "Failed Transactions", id: 2, className: "tab" },
	// 	],
	// 	activeTab: null,
	// });
	useEffect(() => {
		// let mounted = true;

		if (!defMembership) {
			getMembership();
		}

		function cleanup() { }
		return cleanup();
	}, [defMembership, getMembership]);

	if (defMembership && !mount && id) {
		mount = true;
		getMembership(defMembership.business.id);
	}
	const formatTime = (time) => {
		if (moment(time).isValid()) {
			return moment(time).format("MMMM Do YYYY");
		}
	};
	const displayDetails = (details) => {
		const { user, role, business, joinedOn } = details;
		const { name, email, imageUrl } = user;
		const displayAvartar = (url) => {
			if (url) {
				return <Avatar image={url} size="xlarge" shape="circle" />;
			} else {
				return (
					<Avatar
						label={name[0]}
						className="p-mr-2"
						size="xlarge"
						shape="circle"
						style={{ backgroundColor: "#666", color: "#ffffff" }}
					/>
				);
			}
		};
		return (
			<div className="p-col-12">
				<div className="car-details">
					<div>
						<div className="p-grid">
							<div className="p-col-12">
								{displayAvartar(imageUrl)}
								{t("Name")}: <b>{name}</b>
							</div>

							<div className="p-col-12">
								{t("Email")}: <b>{email}</b>
							</div>
							<div className="p-col-12">
								{`${t("Business")} ${t("Name")}`}: <b>{business.name}</b>
							</div>
							<div className="p-col-12">
								{t("Role")}: <b>{role}</b>
							</div>
							<div className="p-col-12">
								{t("Joined-On")}: <b>{formatTime(joinedOn)}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	if (
		!defMembership ||
		fetchingAllMembership ||
		(!location.state && !memberships)
	) {
		return <Loading />;
	} else if (location.state) {
		return displayDetails(location.state);
	} else if (memberships && memberships.length !== 0) {
		return displayDetails(
			memberships.filter((membership) => id !== membership.id)[0]
		);
	} else {
		return <Loading />;
	}
};
const mapStateToProps = (state) => {
	return {
		account: state.account.account,
		fetchingAccount: state.account.loading,
		errorAccount: state.account.error,
		defMembership: state.defMembership.defMembership,
		fetchingDefMembership: state.defMembership.loading,
		errorDefMembership: state.defMembership.error,
		memberships: state.memberships.memberships,
		fetchingAllMembership: state.memberships.fetchingAll,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getDefMembership: () =>
			dispatch(DefMembershipActions.defMembershipRequest()),
		getMembership: (businessId) =>
			dispatch(MembershipActions.membershipAllRequest(businessId)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Colleagues);
