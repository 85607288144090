import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Column } from "primereact/column";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import CustomDataTable from "../../Components/CustomDataTable";
import { Card } from "primereact/card";
import CustomerEdit from "./edit";
import "../../Styles/pages/customers.scss"
import { OverlayPanel } from 'primereact/overlaypanel';
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber";
import "../../Styles/pages/inviteSubscriber.scss";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

const Customers = ({ header }) => {
    const { t } = useTranslation("common");
    const history = useHistory();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [query, setQuery] = useState("");
    const [showInviteSubscriber, setShowInviteSubscriber] = useState(false);
    const [latest, setLatest] = useState(null);
    const [customerNumber, setCustomerNumber] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [confirmPause, setConfirmPause] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [pausedBulk, setPausedBulk] = useState(false);
    const [cancelBulk, setCancelBulk] = useState(false);
    const [pausedSingle, setPausedSingle] = useState(false);
    const [cancelSingle, setCancelSingle] = useState(false);
    const [alreadyPaused, setAlreadyPaused] = useState(false);
    const [alreadyCancelled, setAlreadyCancelled] = useState(false)
    const cancelToast = useRef(null)
    const pauseToast = useRef(null)
    const overlayPanelMoreSubs = useRef(null);
    const overlayPanelLatest = useRef(null);
    const toast = useRef(null);
    const [visibleInvite, setVisibleInvite] = useState(false);
    const [state, setState] = useState({
        enterpriseCustomers: null,
        count: null,
        page: 0,
        sort: "addedOn,desc",
        size: 20,
        loading: true,
        visible: false,
        businessCustomers: null,
        filteredEnterpriseCustomersObjects: null,
        firstName: "",
        lastName: "",
        customerNum: "",
        email: "",
        firstNameError: false,
        lastNameError: false,
        data: null,
        updating: false,
        success: false,
        id: null,
        business: null,
        customers: null,
        isFiltered: false,
        customerId: null,
        query: null,
        initialTier: null,
        selectedCustomersIds: [],
        customerNumber: null,
        pausingBulk: false,
        customerActiveSubs: null,
        customerCancelledSubs: null,
        customerPausedSubs: null,
        customerCompletedSubs: null,
    });

    const routeChange = (pathname, state) => {
        history.push({
            pathname,
            state,
        });
    };

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessCustomers = useSelector((state) => state.businessCustomers.businessCustomers);
    const filteredCustomers = useSelector((state) => state.businessCustomers.filteredCustomers);
    const fetchingBusinessCustomerSummarySearch = useSelector((state) => state.businessCustomers.fetchingBusinessCustomerSummarySearch);
    const fetchingBusinessCustomerSummary = useSelector((state) => state.businessCustomers.fetchingBusinessCustomerSummary);
    const businessCustomersSummarySearch = useSelector((state) => state.businessCustomers.businessCustomerSummarySearch);
    const businessCustomersSummary = useSelector((state) => state.businessCustomers.businessCustomerSummary);
    const customerReceivedPayments = useSelector((state) => state.businessCustomers.customerReceivedPayments)
    const fetchingCustomerReceivedPayment = useSelector((state) => state.businessCustomers.fetchingCustomerReceivedPayments)
    const pausingBulkSubscriptions = useSelector((state) => state.businessCustomers.pausingBulkBusinessCustomers)
    const pausingSingleSubscriptions = useSelector((state) => state.businessCustomers.pausingBusinessCustomersSinglePause)
    const pausedBulkSubscriptions = useSelector((state) => state.businessCustomers.businessCustomersBulkPause)
    const pausedSingleSubscriptions = useSelector((state) => state.businessCustomers.businessCustomersSinglePause)
    const cancelledBulkSubscriptions = useSelector((state) => state.businessCustomers.businessCustomersBulkCancel)
    const cancelledSingleSubscriptions = useSelector((state) => state.businessCustomers.businessCustomersSingleCancel)
    const cancellingBulkSubscriptions = useSelector((state) => state.businessCustomers.cancellingBulkBusinessCustomers)
    const cancellingSingleSubscriptions = useSelector((state) => state.businessCustomers.cancellingSingleBusinessCustomer)

    const dispatch = useDispatch();
    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    const getSummaryCustomerSearch = useCallback((businessId, query) => dispatch(BusinessCustomersActions.businessCustomerSummarySearchRequest(businessId, query)), [dispatch]);
    const getSummaryCustomers = useCallback((businessId, query) => dispatch(BusinessCustomersActions.businessCustomerSummaryRequest(businessId, query)), [dispatch]);
    const getCustomerSubscription = useCallback((businessCustomerId,) => dispatch(BusinessCustomersActions.customerReceivedPaymentRequest(businessCustomerId)), [dispatch]);
    const pauseBulkCustomersSubscriptions = useCallback((membershipId, ids) => { dispatch(BusinessCustomersActions.businessCustomersBulkPauseRequest(membershipId, ids)) }, [dispatch])
    const cancelBulkCustomersSubscriptions = useCallback((membershipId, ids) => { dispatch(BusinessCustomersActions.businessCustomersBulkCancelRequest(membershipId, ids)) }, [dispatch])
    const pauseSingleCustomersSubscriptions = useCallback((businessCustomerId, membershipId) => { dispatch(BusinessCustomersActions.businessCustomersSinglePauseRequest(businessCustomerId, membershipId)) }, [dispatch])
    const cancelSingleCustomersSubscriptions = useCallback((businessCustomerId, membershipId) => { dispatch(BusinessCustomersActions.businessCustomersSingleCancelRequest(businessCustomerId, membershipId)) }, [dispatch])

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getSummaryCustomers(defMembership.business.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currency = defMembership?.business.currency
    const singleCustomer = state.selectedCustomersIds.length === 1;
    const singularCustomer = selectedCustomers.length === 1;

    const showOverlayMoreSubsPanel = (event) => {
        overlayPanelMoreSubs.current.toggle(event);
    };

    const showOverlayLatestPanel = (event, rowData) => {
        setLatest(rowData)
        overlayPanelLatest.current.toggle(event);
    };

    const navigateToSubsDetails = (subsId) => {
        history.push(`/subscribers/subscriber/subscription/${subsId}`);
    };

    const filteredItemsUI = customerReceivedPayments?.map(item => (
        <div key={item.id} style={{ display: "flex" }} >
            <span className={`status-outline status-${(item.status ? item.status.toLowerCase() : '')} status-content-stat`}>{item.status === "COMPLETE" ?
                t("business_customers.completed") : item.status}</span>
            <p className="item-name hover:underline" onClick={() => navigateToSubsDetails(item.subscriber.id)}>{item.subscriber.plan.name}</p>
        </div>
    ));

    const pauseSuccess = () => {
        const pausedBulkText = pausedBulkSubscriptions !== null && pausedBulkSubscriptions !== undefined
            ? `${pausedBulkSubscriptions} ${t('subscriber.paused')}`
            : '';
        const pausedSingleText = pausedSingleSubscriptions !== null && pausedSingleSubscriptions !== undefined
            ? `${pausedSingleSubscriptions} ${t('subscriber.paused')}`
            : '';

        const detailText = singularCustomer ? pausedSingleText : pausedBulkText;

        pauseToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: detailText, life: 3000 });
    }
    const cancelSuccess = () => {
        const cancelledBulkText = cancelledBulkSubscriptions !== null && cancelledBulkSubscriptions !== undefined
            ? `${cancelledBulkSubscriptions} ${t('subscriber.cancelled')}`
            : '';
        const cancelledSingleText = cancelledSingleSubscriptions !== null && cancelledSingleSubscriptions !== undefined
            ? `${cancelledSingleSubscriptions} ${t('subscriber.cancelled')}`
            : '';

        const detailText = singularCustomer ? cancelledSingleText : cancelledBulkText;

        pauseToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: detailText, life: 3000 });
    }



    const handlePauseBulk = () => {
        const customerId = selectedCustomers.map(customer => customer.businessCustomer.id)
        setState(state => ({ ...state, selectedCustomersIds: customerId }))
        setConfirmPause(true)
    }

    const handleCancelBulk = () => {
        const customerId = selectedCustomers.map(customer => customer.businessCustomer.id)
        setState(state => ({ ...state, selectedCustomersIds: customerId }))
        setConfirmCancel(true)
    }

    useEffect(() => {
        if (businessCustomersSummary) {
            setState((state) => {
                return {
                    ...state,
                    businessCustomers: businessCustomersSummary,
                    loading: false,
                };
            });
        }
        if (businessCustomersSummarySearch) {
            setState((state) => {
                return { ...state, filteredEnterpriseCustomersObjects: businessCustomersSummarySearch };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessCustomers, filteredCustomers, businessCustomersSummary, businessCustomersSummarySearch]);

    useEffect(() => {
        if (!query)
            setState((state) => ({ ...state, query: null }))
    }, [query])
    useEffect(() => {
        if (cancelledBulkSubscriptions !== null && cancelBulk) {
            setCancelBulk(false)
            setConfirmCancel(false)
            cancelSuccess()
        }
        if (cancelledSingleSubscriptions !== null && cancelSingle) {
            setCancelSingle(false)
            setConfirmCancel(false)
            cancelSuccess()
        }
        if (pausedBulkSubscriptions !== null && pausedBulk) {
            setConfirmPause(false)
            setPausedBulk(false)
            pauseSuccess()
        }
        if (pausedSingleSubscriptions !== null && pausedSingle) {
            setConfirmPause(false)
            setPausedSingle(false)
            pauseSuccess()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancellingBulkSubscriptions, cancelledBulkSubscriptions, cancelBulk, pausedBulkSubscriptions, pausedBulk, pausedSingle, pausedSingleSubscriptions, cancellingSingleSubscriptions, cancelledSingleSubscriptions, cancelSingle])

    if (fetchingBusinessCustomerSummary || fetchingBusinessCustomerSummarySearch) {
        return <Loading />;
    }

    const toggleInvite = () => {
        setCustomerNumber(null);
        setVisibleInvite(false)
    }

    const pauseSubscriptionActions = (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
                label={t('common.no')}
                icon="pi pi-times"
                className="p-button-text"
                id="pause_no_btn"
                onClick={() => { setConfirmPause(false) }}
            />
            <Button
                label={t('common.yes')}
                icon="pi pi-check"
                loading={singleCustomer ? pausingSingleSubscriptions : pausingBulkSubscriptions}
                id="pause_yes_btn"
                onClick={() => {
                    if (singleCustomer) {
                        pauseSingleCustomersSubscriptions(state.selectedCustomersIds, defMembership.id)
                        setPausedSingle(true)
                    } else {
                        pauseBulkCustomersSubscriptions(defMembership.id, state.selectedCustomersIds)
                        setPausedBulk(true)
                    }
                }}
                autoFocus
            />
        </div>
    );
    const cancelSubscriptionActions = (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
                label={t('common.no')}
                icon="pi pi-times"
                className="p-button-text" id="cancelSubs_no_btn"
                onClick={() => { setConfirmCancel(false) }}
            />
            <Button
                label={t('common.yes')}
                icon="pi pi-check"
                loading={singleCustomer ? cancellingSingleSubscriptions : cancellingBulkSubscriptions}
                id="cancelSubs_yes_btn"
                onClick={() => {
                    if (singleCustomer) {
                        cancelSingleCustomersSubscriptions(state.selectedCustomersIds, defMembership.id)
                        setCancelSingle(true)
                    } else {
                        cancelBulkCustomersSubscriptions(defMembership.id, state.selectedCustomersIds)
                        setCancelBulk(true)
                    }

                }}
                autoFocus
            />
        </div>
    );
    const okButton = () => {
        return (
            <div>
                <Button
                    label={t('common.ok')}
                    icon="pi pi-check"
                    onClick={() => { alreadyPaused ? setAlreadyPaused(false) : setAlreadyCancelled(false) }}
                    className="p-button-text" id="pause_yes_btn"
                />
            </div>
        )
    }

    const actionBodyTemplate = (rowData) => (
        <div className="car-buttons customer_table_action_button">
            <Button
                icon="pi pi-plus-circle"
                style={{ cursor: "pointer" }}
                tooltip={t("business_customers.invite")}
                tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                }}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    setShowInviteSubscriber(true);
                    setVisibleInvite(true)
                    setCustomerNumber(rowData.businessCustomer.phoneNo)
                }}
            />
            <Button
                icon="pi pi-pencil"
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                style={{ cursor: "pointer" }}
                tooltip={t("business_customers.edit_customer")}
                tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                }}
                onClick={() => {
                    setDisplayBasic(true);
                    setState((state) => ({
                        ...state,
                        data: rowData,
                        id: rowData.id,
                        firstName: rowData.businessCustomer.firstName,
                        lastName: rowData.businessCustomer.lastName,
                        customerNum: rowData.businessCustomer.customerNum,
                        email: rowData.businessCustomer.email,
                    }));
                }}
            />

        </div>
    );
    const onClickSearchBtn = () => {
        setState((state) => {
            return {
                ...state,
                isFiltered: true,
            };
        });
        getSummaryCustomerSearch(defMembership.business.id, query.toLowerCase());
    };
    const onClickCancelBtn = () => {
        setState((state) => {
            return {
                ...state,
                isFiltered: false,
            };
        });
        setQuery("");
        getSummaryCustomers(defMembership.business.id);
    };

    const formatDate = (date) => {
        return moment(date).format("MMMM DD YYYY");
    };

    const customerInfoTemplate = (rowData) => {
        if (rowData) {
            return <div className="customer_info">
                <span className="customerReference">{rowData.businessCustomer ? rowData.businessCustomer?.customerNum : <div>{null}</div>}</span>
                <span className="customer_name">{rowData.businessCustomer ? rowData.businessCustomer?.name : <div>{null}</div>}</span>
                <span className="customer_data">{rowData.businessCustomer ? rowData.businessCustomer?.phoneNo : <div>{null}</div>}</span>
                <span className="customer_data">{rowData.businessCustomer ? rowData.businessCustomer?.email : <div>{null}</div>}</span>
            </div>
        }
    }
    const customerIdTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {
                    rowData.id ?
                        <div className="customer_main">
                            <span className="customerId" onClick={() => {
                                setState((state) => { return { ...state, customerId: rowData.businessCustomer.id } })
                                if (rowData.businessCustomer.id) routeChange(`/subscribers/subscriber/${rowData.businessCustomer.id}`, rowData);
                            }}>{rowData.businessCustomer.reference}</span>
                            <span className="createdOnLabel">{t('business_customers.created_on')}</span>
                            <span className="createdOn">{formatDate(rowData.businessCustomer.createdDate)}</span>
                        </div> : "--"
                }
            </div>
        }
    }

    const subscriptionBodyTemplate = (rowData) => {
        const totalSubs = (rowData.activeSubs + rowData.completedSubs + rowData.cancelledSubs + rowData.pausedSubs)
        if (rowData) {
            return <div>
                {rowData.recentSub !== null ? <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {(rowData.recentSub?.status ?
                            <span className={`status-outline status-${(rowData.recentSub?.status ? rowData.recentSub?.status.toLowerCase() : '')} status-content-stat`}>
                                {rowData.recentSub?.status === "COMPLETE" ? t("business_customers.completed") : rowData.recentSub?.status}</span> : null
                        )}
                        <div className="subscription-status-column">
                            <div>
                                {totalSubs > 1 && <div style={{ display: "flex", flexDirection: "row" }} onClick={(e) => {
                                    e.stopPropagation()
                                    showOverlayMoreSubsPanel(e, customerReceivedPayments);
                                    getCustomerSubscription(rowData.id)
                                }}>
                                    <p className="more-subs">{`${totalSubs} ${t('more_subs')}`}</p>
                                    <i className="pi pi-arrow-up-right arrow-subs"></i>
                                </div>}
                                <OverlayPanel showCloseIcon className="subsOverlay" ref={overlayPanelMoreSubs} >
                                    <div style={{ marginTop: "0.5rem", cursor: "pointer" }}>
                                        {fetchingCustomerReceivedPayment ? (<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>) : (<span> {filteredItemsUI} </span>)}
                                    </div>
                                </OverlayPanel>
                            </div>
                        </div>
                    </div>


                    <div className="subscription-column">
                        <span className="subscriptionReference" onClick={() => {
                            navigateToSubsDetails(rowData.recentSub.id)
                        }}>
                            {rowData.recentSub?.subscriberReference}</span>
                        <span className="plan-name">{rowData.recentSub?.plan.name}</span>
                        <span className="plan-amount">{rowData.recentSub?.plan.cost && !rowData.recentSub?.plan.flex ? `${currency} ${rowData.recentSub?.plan.cost.toFixed(2)}` : rowData.recentSub?.plan.flex ? t("business_customers.flex") : null}</span>
                        <span className="plan-billingPeriod">{rowData.recentSub?.plan.billingPeriod ? t(`product.${rowData.recentSub?.plan.billingPeriod.toLowerCase()}`) : null}</span>
                    </div>
                </div> : null}
            </div>
        }
    }
    const paymentBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="payment-column-main">
                <OverlayPanel showCloseIcon className="subsOverlay" ref={overlayPanelLatest} >
                    <div className="latest-info">
                        <span className="latest-name">{latest?.lastPayment?.plan?.name}</span>
                        <span className="latest-date">{formatDate(latest?.lastPayment?.receivedAt)}</span>
                        <span className="latest-amount">{latest ? `${currency} ${latest.lastPayment.amount.toFixed(2)}` : "--"}</span>
                    </div>
                </OverlayPanel>
                <div className="payment-column-1">
                    <div style={{ display: "flex", flexDirection: 'row', marginBottom: "2px" }}>
                        <span className="total-collected-count">{`${rowData.collectedCount} ${t('business_customers.collected_payment')}`}</span>
                        {rowData.collectedCount > 0 ? <div style={{ display: "flex", flexDirection: "row" }} onClick={(e) => {
                            e.stopPropagation()
                            showOverlayLatestPanel(e, rowData);
                        }}>
                            <span className="latest" >{`${t('latest')}`}</span>
                            <i className="pi pi-arrow-up-right arrow-latest"></i>
                        </div> : null}
                    </div>
                    <div>
                        <div style={{ marginBottom: "2px" }}>
                            <span className="total-collected">{currency && rowData.collected ? `${currency} ${rowData.collected?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "GHS 0.00"}</span>
                            <span className="collected-label">{`${t('business_customers.collected')}`}</span>
                        </div>
                        <div style={{ marginBottom: "2px" }}>
                            <span className="revenue">{rowData.lastPayment?.business.currency ? `${currency} ${rowData.revenue?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "GHS 0.00"}</span>
                            <span className="revenue-label">{`${t('business_customers.revenue')}`}</span>
                        </div>
                        <div style={{ marginBottom: "2px" }}>
                            <span className="missed-payment">
                                {rowData.missedCount}
                            </span>
                            <span className="missed-payment-label">{`${t('business_customers.missed_payments')}`}</span>
                        </div>
                    </div>
                </div>

                {(rowData.recentSub?.status === 'ACTIVE') && <div className="payment-column-2">
                    <span className="next-billing">{t('business_customers.next_billing')}</span>
                    <span className="scheduled-date">{formatDate(rowData.recentSub?.nextBillingDate)}</span>
                    <span className="scheduled-cost">{rowData.nextScheduled?.cost && !rowData.recentSub?.plan.flex ? `${currency} ${rowData.nextScheduled.cost?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : null}</span>
                    <span className="scheduled-plan-name">{rowData.nextScheduled?.subscription?.plan.name}</span>
                </div>}
            </div>
        }
    }
    let columns = [
        {
            selectionMode: "multiple",
            style: { border: "0px", borderBottom: "1px solid #e0e0e0", padding: "0px", alignContent: "center", width: "45px" },
            bodyClassName: "activity-select",
            headerClassName: "select-header check-box-header",
        },
        {
            field: "customerId",
            header: t("business_customers.customer_id"),
            body: customerIdTemplate,
            sortable: false,
            headerClassName: "customer_headers customer_id_header"
        },
        {
            field: "customerInfo",
            header: t("business_customers.customer_info"),
            body: customerInfoTemplate,
            sortable: false,
            headerClassName: "customer_headers customer_info_header",
        },
        {
            field: "subscriptionInfo",
            header: t("business_customers.subscription_info"),
            body: subscriptionBodyTemplate,
            sortable: false,
            headerClassName: "customer_headers subscription_Info ",
        },
        {
            field: "payments",
            header: t("business_customers.payments"),
            body: paymentBodyTemplate,
            sortable: false,
            headerClassName: "customer_headers payments",
        },
    ];
    const closeDisplayBasic = () => {
        setDisplayBasic(false)
    }

    const isSelected = selectedCustomers.length > 0
    const count = selectedCustomers.length;
    const removeCustomers = () => {
        setSelectedCustomers([]);
    }

    const handleSearch = (e) => {
        const inputValue = e.target.value;

        // eslint-disable-next-line
        const pattern = /^[a-zA-Z0-9\s\-\/]*$/;

        if (pattern.test(inputValue)) {
            setQuery(inputValue)
        }
    };

    return (
        <>
            {displayBasic && <CustomerEdit displayBasic={displayBasic} onHide={closeDisplayBasic} id={state.id} customerId={state.customerId} state={state} setState={setState}
                rowData={state.data} />}
            {!businessCustomers?.business?.enterprise ? (
                <div>
                    <Toast ref={toast} />
                    <Card className="search action_bar_cards" style={{ marginBottom: "2rem" }}>
                        {isSelected ?
                            (<div style={{ display: "flex" }}>
                                <div className="activity-actions-count">
                                    <span className="activity-actions-count-number count-style">
                                        {isSelected &&
                                            (selectedCustomers.length === 1 ? `${count} ${t("business_customers.selected_title_singular")}` : `${count} ${t("business_customers.selected_title")}`)}
                                        {!isSelected && `${t("business_customers.title")}`}
                                    </span>
                                </div>
                                {(defMembership.role === "OWNER" || defMembership.role === "ADMIN") &&
                                    (<div className="activity-actions-btns" >
                                        <Button disabled={pausingBulkSubscriptions || pausingSingleSubscriptions || fetchingCustomerReceivedPayment} className="activity-actions-count-btn p-button-outlined p-button-info ml-1 mr-3" onClick={() => handlePauseBulk()} >{pausingBulkSubscriptions ? t("subscriber.pausing") : t("subscriber.pause_btn")} </Button>
                                        <Button disabled={cancellingBulkSubscriptions || cancellingSingleSubscriptions || fetchingCustomerReceivedPayment} className="activity-actions-count-btn p-button-outlined p-button-danger mr-3" onClick={() => handleCancelBulk()} >{cancellingBulkSubscriptions ? t("subscriber.cancelling") : t("subscriber.cancel_btn")}</Button>
                                        <Button label={t("subscriber.cancel_selection_btn")} icon="pi pi-times" onClick={removeCustomers} className=" activity-actions-count-btn p-button-info p-button-text cancel_selection_btn" />
                                    </div>)}
                            </div>
                            ) :
                            (<div className="p-grid p-fluid" style={{ display: "flex" }}>
                                <InputText
                                    type="text"
                                    id="customers_search_input"
                                    placeholder={t("business_customers.label")}
                                    value={query}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            onClickSearchBtn();
                                        }
                                    }}
                                    onChange={handleSearch}>
                                </InputText>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    <Button type="submit"
                                        onClick={(e) => {
                                            if (query) {
                                                onClickSearchBtn();
                                            }
                                        }} icon="pi pi-search" className="p-button-info p-button-text buttons search-but" />
                                    {query &&
                                        <Button type="submit" onClick={onClickCancelBtn} icon="pi pi-times" className="p-button-danger p-button-text buttons" />
                                    }
                                </div>
                            </div>)}
                    </Card>
                    <CustomDataTable columns={columns} selection={selectedCustomers} onSelectionChange={(e) => {
                        setSelectedCustomers(e.value)
                    }} value={state.isFiltered ? state.filteredEnterpriseCustomersObjects : state.businessCustomers} rows={10} rowClassName="row-selection-disabled" paginator>
                        {(defMembership?.role !== "VIEWER") && (
                            <Column headerClassName="datatable-header-default" className={`datatable-cell-default`} body={actionBodyTemplate} style={{ minWidth: "1.5rem" }}></Column>
                        )}
                    </CustomDataTable>
                </div>
            ) : (
                <div>{t("business_customers.no_display_message")}</div>
            )}
            {showInviteSubscriber && (
                <InviteSubscriber
                    visible={state.visible}
                    header={t("business_customers.invite")}
                    toggleVisible={visibleInvite}
                    onHide={toggleInvite}
                    phoneNo={customerNumber}
                />
            )}
            <Toast ref={pauseToast}
                onRemove={() => {
                    getSummaryCustomers(defMembership.business.id)
                    setSelectedCustomers([])
                }} />
            <Toast ref={cancelToast}
                onRemove={() => {
                    getSummaryCustomers(defMembership.business.id)
                    setSelectedCustomers([])
                }}
            />
            <Dialog
                visible={alreadyPaused}
                onHide={() => setAlreadyPaused(false)}
                header={t("subscriber.already_paused")}
                footer={okButton}
            >
                <h6 className="block-span">{t("subscriber.already_paused_text")} </h6>
            </Dialog>
            <Dialog
                visible={alreadyCancelled}
                onHide={() => setAlreadyCancelled(false)}
                header={t("subscriber.already_cancelled")}
                footer={okButton}
            >
                <h6 className="block-span">{t("subscriber.already_cancelled_text")} </h6>
            </Dialog>
            <Dialog
                visible={confirmCancel}
                style={{ width: "auto" }}
                onHide={() => setConfirmCancel(false)}
                header={t("subscriber.confirmation_header")}
                footer={cancelSubscriptionActions}
            >
                <h6>
                    {singleCustomer ? (<div style={{ width: "100%" }}>
                        <span className="inline-span">{t("business_customers.cancel_confirmation_message_span_1")} </span>
                        <span className="inline-span" style={{ color: "#BF2600" }}>{t("business_customers.cancel_confirmation_message_span_2")} </span>
                        <span className="inline-span">{t("for")} </span>
                        <span className="inline-span" style={{ color: "#4C9AFF" }}>{t("business_customers.cancel_confirmation_message_span_3")} </span>
                        <span className="block-span">{t("business_customers.cancel_confirmation_message_span_4")} </span>
                    </div>)
                        : (<div style={{ width: "100%" }}>
                            <span className="inline-span">{t("business_customers.cancel_confirmation_message(s)_span_1")} </span>
                            <span className="inline-span" style={{ color: "#BF2600" }}>{t("business_customers.cancel_confirmation_message(s)_span_2")} </span>
                            <span className="inline-span">{t("for")} </span>
                            <span className="inline-span" style={{ color: "#4C9AFF" }}>{t("business_customers.cancel_confirmation_message(s)_span_3")} </span>
                            <span className="block-span">{t("business_customers.cancel_confirmation_message(s)_span_4")} </span>
                        </div>)}
                </h6>

            </Dialog>
            <Dialog
                visible={confirmPause}
                style={{ width: "auto" }}
                onHide={() => setConfirmPause(false)}
                header={t("subscriber.confirmation_header")}
                footer={pauseSubscriptionActions}
            >
                <h6>{singleCustomer ? (<div style={{ width: "100%" }}>
                    <span className="inline-span">{t("business_customers.pause_confirmation_message_span_1")} </span>
                    <span className="inline-span" style={{ color: "#BF2600" }}>{t("business_customers.pause_confirmation_message_span_2")} </span>
                    <span className="inline-span">{t("for")} </span>
                    <span className="inline-span" style={{ color: "#4C9AFF" }}>{t("business_customers.pause_confirmation_message_span_3")} </span>
                    <span className="block-span">{t("business_customers.pause_confirmation_message_span_4")} </span>
                </div>)
                    : (<div style={{ width: "100%" }}>
                        <span className="inline-span">{t("business_customers.pause_confirmation_message(s)_span_1")} </span>
                        <span className="inline-span" style={{ color: "#BF2600" }}>{t("business_customers.pause_confirmation_message(s)_span_2")} </span>
                        <span className="inline-span">{t("for")} </span>
                        <span className="inline-span" style={{ color: "#4C9AFF" }}>{t("business_customers.pause_confirmation_message(s)_span_3")} </span>
                        <span className="block-span">{t("business_customers.pause_confirmation_message(s)_span_4")} </span>
                    </div>)}</h6>
            </Dialog>
        </>
    );
};

export default Customers;
