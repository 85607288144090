import React, { useEffect } from "react";
import { Route, withRouter, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import App from "../Layout/App";
import Login from "../Pages/Login";
import Error from "../Pages/Error";
import NotFound from "../Pages/NotFound";
import NoAccess from "../Pages/NoAccess";
import ImplicitCallback from "../Pages/Authentication";
import ConfigureBusiness from "../Pages/Business/Configure";
import { componentRoutes, permittedRoleList, lockedRoutes, enterpriseLockedRoutes, notAdminEntLockedRoutes } from "./ComponentRoutes";
import Authenticate from "../Pages/Authentication/Authenticate";
import EmailVerification from "../Pages/Authentication/EmailVerification";

function AppWrapper(props) {
	const currentUser = useSelector((state) => state.defMembership.defMembership)

	useEffect(() => {
		if (props.location) {
			window.scrollTo(0, 0);
		}
	}, [props.location]);

	const validPath = (routeArray) => {
		const pathArray = [];
		routeArray.forEach(item => pathArray.push(item.path));
		return routeArray.find(route => matchPath(props.location.pathname, route));
	};

	const findRoute = (path) => {
		const found = lockedRoutes.find(element => element.path === path)
		return found ? found : null;
	}

	const findRole = permittedRoleList.includes(currentUser?.role)
	const isBusinessEnterprise = currentUser?.business?.enterprise

	switch (true) {
		case (props.location.pathname === "/"):
			return <Route path="/" component={Login} exact />;
		case (props.location.pathname === "/configure"):
			return <Route path="/configure" component={ConfigureBusiness} exact />;
		case (props.location.pathname === "/verification"):
			return <Route path="/verification" component={EmailVerification} exact />;
		case (props.location.pathname === "/login"):
			return <Route path="/login" component={Authenticate} exact />;
		case (props.location.pathname === "/error"):
			return <Route path="/error" component={Error} />;
		case (props.location.pathname === "/implicit/callback"):
			return <Route path="/implicit/callback" component={ImplicitCallback} />;
		default:
			if (!validPath(componentRoutes)) {
				return <NotFound />;
			} else if (!findRole && findRoute(props.location.pathname)) {
				return <NoAccess />;
			} else if (isBusinessEnterprise && enterpriseLockedRoutes.some(route => route.path === props.location.pathname)) {
				return <NoAccess />;
			} else if (isBusinessEnterprise && !findRole && notAdminEntLockedRoutes.some(route => route.path === props.location.pathname)) {
				return <NoAccess />;
			} else if (!isBusinessEnterprise && (props.location.pathname === '/subscriptions/activity' || props.location.pathname === '/subscriptions/invitations')) {
				return <NoAccess />;
			} else if (isBusinessEnterprise && findRole && (props.location.pathname === '/invitations')) {
				return <NotFound />;
			} else {
				return <App props={props} />;
			}
	}
}
export default withRouter(AppWrapper);