/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReportActions from "../../Redux/transition/report/report.reducer";
import { Toast } from "primereact/toast";
import "../../Styles/dataTable.scss";
import "../../Styles/pages/transactionReport.scss";
import CustomDataTable from "../../Components/CustomDataTable";
import PayoutChannelActions from "../../Redux/actions/payoutchannel-actions";
import MembershipActions from "../../Redux/transition/membership/membership.reducer";
import BankActions from "../../Redux/actions/bank-actions";
import Loading from "../../Components/Loading";
import { referenceTemplate, requestedByTemplate, channelBodyTemplate, CustomerBodyTemplate, DateFilterTemplate, RequestedOnBodyTemplate, AmountTemplate, BalanceFilterTemplate, AccountFilterTemplate, ChannelFilterTemplate, RequestedByFilterTemplate, StatusFilterTemplate, StatusTemplate, ReferenceFilterTemplate } from "./Templates";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";


const WithdrawalsCustom = () => {
    const { t } = useTranslation("common");
    const [state, setState] = useState({
        sort: "id,desc",
        count: null,
        size: 20,
        first: 0,
        page: 0,
        plan: {},
        transaction: [],
        planGroupsGraphed: [],
        planGroups: [],
        filteredName: [],
        filteredEmail: [],
        filteredCustomers: [],
        channels: [],
        filteredNums: [],
        filteredAccountName: [],
        payoutChannels: [],
        payoutChannelAll: [],
        banks: [],
        memberships: [],
        filteredMemberships: [],
        requestedByIds: [],
        channelName: "",
        sysBankIds: [],
        identifiers: [],

        channelTypes: [
            { label: t("subscriber.Bank"), value: "BANK" },
            { label: t("subscriber.Mobile"), value: "MOBILE" },
        ],
    });

    const dateComparisonOperators = [
        { value: "EQUALS", label: t('report.date_on') },
        { value: "LESS_THAN", label: t('report.date_before') },
        { value: "GREATER_THAN", label: t('report.date_after') },
    ];

    const comparisonOperators = [
        { value: "EQUALS", label: t('report.equals') },
        { value: "NOT_EQUALS", label: t('report.not_equals') },
        { value: "LESS_THAN", label: t('report.less') },
        { value: "GREATER_THAN", label: t('report.greater') },
        { value: "LESS_THAN_EQUAL_TO", label: t('report.less_or') },
        { value: "GREATER_THAN_EQUAL_TO", label: t('report.greater_or') },
    ];
    const dateTypes = [
        { label: t("report.requestedOn"), value: "requestedOn" },
        { label: t("report.processedOn"), value: "processedOn" },
    ];
    const [filter, setFilter] = useState({
        requestedByIds: [],
        sysBankIds: [],
        channelIds: [],
        statuses: [],
    })
    const setFilterFun = (newBody) => {
        setFilter((body) => ({
            ...body,
            ...newBody,
        }));
    }
    const setFilterSubFun = (sub, newBody) => {
        setFilter((prevFilter) => {
            const updatedFilter = {
                ...prevFilter,
                [sub]: {
                    ...prevFilter[sub],
                    ...newBody,
                }
            };
            return updatedFilter;
        });
    }
    const setBodyFun = (newBody = {}) => {
        const { requestedByIds = [], channelIds = [] } = filter
        setBody((body) => ({
            transaction: {
                ...body.transaction,
                ...filter,
                requestedByIds: requestedByIds.map(({ id }) => id),
                channelIds: channelIds.map(({ id }) => id),
                ...newBody,
            },
        }));
        setTrigger(!trigger);
    };

    const [body, setBody] = useState({
        transaction: {},
    });
    const [filters, setFilters] = useState({})
    const [addedStatus, setAddedStatus] = useState(false)
    const [addedReference, setAddedReference] = useState(false)
    const [addedRequested, setAddedRequested] = useState(false)
    const [addedRecipient, setAddedRecipient] = useState(false)
    const [addedChannelType, setAddedChannelType] = useState(false)
    const [addedSystemBank, setAddedSystemBank] = useState(false)
    const [addedAmount, setAddedAmount] = useState(false)
    const [addedAmountOperator, setAddedAmountOperator] = useState(false)
    const [addedActivity, setAddedActivity] = useState(false)
    const [identifiers, setIdentifiers] = useState(false)
    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            filters: [{}],
        },
    });
    const filterSubscribersItems = [
        { label: t("subscriber.Pending"), value: "PENDING" },
        { label: t("subscriber.Completed"), value: "PROCESSED" },
        { label: t("subscriber.reversed"), value: "REVERSED" },
    ];

    const [trigger, setTrigger] = useState(false);
    const toast = useRef(null);
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessId = defMembership.business.id;
    const payoutChannelsAll = useSelector((state) => state.payoutChannels.payoutChannels);
    const banks = useSelector((state) => state.banks.banks);
    const memberships = useSelector((state) => state.memberships.memberships);
    const { fetchingTransactionCustomReport, transactionCustomReport } = useSelector((state) => state.report);


    const dispatch = useDispatch();
    const transactionCustomReportRequest = useCallback((body) => dispatch(ReportActions.transactionCustomReportRequest(businessId, body)), [dispatch]);
    const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
    const getAllPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelAllRequest(businessId, options)), [dispatch]);
    const getAllBanks = useCallback((countryId) => dispatch(BankActions.bankAllRequest(countryId)), [dispatch]);
    const getMemberships = useCallback((businessId) => dispatch(MembershipActions.membershipAllRequest(businessId)), [dispatch]);

    useEffect(() => {
        getSavedPayoutChannels(businessId)
        getAllPayoutChannels(businessId)
        getAllBanks(defMembership.business.country.id)
        transactionCustomReportRequest(body);
        getMemberships(businessId)
    }, [trigger]);

    useEffect(() => {
        if (banks) {
            setState({
                ...state,
                channels: [...banks.map(({ name, id, type }) => ({ label: name, value: id, type }))]
                    .sort((a, b) => (a.label < b.label ? -1 : 1))
                ,
            })
        }
        if (transactionCustomReport) {
            setState((state) => { return { ...state, payoutChannels: transactionCustomReport } })
        } if (transactionCustomReport) {
            setState((state) => { return { ...state, transaction: transactionCustomReport } })
        } if (memberships) {
            setState((state) => { return { ...state, memberships: memberships.map(({ user }) => user) } })
        }
        if (payoutChannelsAll) {
            setState((state) => { return { ...state, payoutChannelAll: payoutChannelsAll } })
        }
    }, [banks, payoutChannelsAll, transactionCustomReport, memberships])

    // Save object to local storage
    const saveObjectToLocal = (obj) => {
        localStorage.setItem('filterKey', JSON.stringify(obj));
    };

    // Retrieve object from local storage
    const getStoredObject = () => {
        const storedObject = localStorage.getItem('filterKey');
        return storedObject ? JSON.parse(storedObject) : null;
    };

    // Effect to save object to local storage when the object changes
    useEffect(() => {
        saveObjectToLocal(filters);
    }, [filters]);

    useEffect(() => {
        if (filter.statuses?.length === 0) {
            setAddedStatus(false)
        }
    }, [filter.statuses])

    // Effect to retrieve object from local storage during mount
    useEffect(() => {
        const storedObject = getStoredObject();
        if (fetchingTransactionCustomReport) {
            if (storedObject) {
                setFilters(storedObject);
            }
        }
    }, [fetchingTransactionCustomReport]);

    // Function to update a specific key-value pair
    const updateKeyValuePair = (key, value) => {
        setFilters((prevObject) => ({ ...prevObject, [key]: value }));
    };

    // Function to remove a specific key-value pair
    const removeKeyValuePair = (key) => {
        setFilters((prevObject) => {
            const updatedObject = { ...prevObject };
            delete updatedObject[key];
            return updatedObject;
        });
    };

    const filterClearStatus = (options) => {
        return <Button type="button" onClick={(e) => {
            if (addedStatus) {
                setFilterFun({ statuses: [] });
                setBodyFun({ statuses: [] });
                removeKeyValuePair('statusFilter');
                setAddedStatus(false)
            }
        }}
            severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span> </Button>;
    };

    const filterClearReference = (options) => {
        return <Button type="button" onClick={() => {
            if (addedReference) {
                setFilterFun({
                    reference: ""
                });
                setBodyFun({
                    reference: ""
                });
                removeKeyValuePair('referenceFilter')
                setAddedReference(false)
            }
        }}
            severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearRequested = (options) => {
        return <Button type="button" onClick={() => {
            if (addedRequested) {
                setBodyFun({
                    requestedByIds: []
                });
                setFilterFun({
                    requestedByIds: []
                });
                removeKeyValuePair('requestedFilter')
                setAddedRequested(false)
            }
        }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };
    const filterClearChannel = (options) => {
        return <Button type="button" onClick={() => {
            if (addedChannelType && addedSystemBank)
                setBodyFun({
                    type: undefined,
                    sysBankIds: []
                });
            setFilterFun({
                type: undefined,
                sysBankIds: []
            });
            removeKeyValuePair('channelFilter')
            setAddedChannelType(false)
            setAddedSystemBank(false)
        }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };
    const filterClearRecipient = (options) => {
        return <Button type="button" onClick={() => {
            if (addedRecipient)
                setBodyFun({
                    channelIds: []
                });
            setFilterFun({
                channelIds: []
            })
            removeKeyValuePair('recipientFilter')
            setAddedRecipient(false)
        }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearCollected = (options) => {
        return <Button type="button" onClick={() => {
            if (addedAmountOperator && addedAmount)
                setBodyFun({
                    requested: undefined
                });
            setFilterFun({
                requested: undefined
            });
            removeKeyValuePair('amountFilter')
            setAddedAmount(false)
        }} severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;
    };

    const filterClearStartedOn = (options) => {
        return <Button type="button" onClick={() => {
            if (addedActivity) {
                setBodyFun({
                    requestedOn: undefined,
                    processedOn: undefined,
                });
                removeKeyValuePair('activityFilter')
                setAddedActivity(false)
                setValue("filters", [{}])
            }

        }
        }
            severity="secondary"><span className="subs_clear">{t("subscriber.clear_button")}</span></Button>;

    };


    const tabs = {
        label: "transaction.title",
        columns: [
            {
                field: 'status', body: (rowData) => <StatusTemplate {...{ rowData, t }} />, header: t("transaction.status"),
                filterField: "status",
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                headerClassName: filters?.statusFilter && "statusFilter",
                filterMenuClassName: "filterBox",
                filter: true,
                filterElement: () => <StatusFilterTemplate {...{ filterSubscribersItems, t, filter, setFilterFun, setAddedStatus }} />,
                onFilterApplyClick: () => {

                    if (addedStatus) {
                        updateKeyValuePair('statusFilter', true)
                        setBodyFun();
                    }
                    if (!addedStatus && filters?.statusFilter) {
                        setFilterFun({ statuses: [] });
                        setBodyFun({ statuses: [] });
                        removeKeyValuePair('statusFilter');
                        setAddedStatus(false)
                    }
                },
                filterClear: filterClearStatus,
            },
            {
                field: "reference", header: t("transaction.reference"), body: referenceTemplate,
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMenuClassName: "filterBox",
                headerClassName: filters?.referenceFilter && "referenceFilter",
                filter: true,
                onFilterApplyClick: (e) => {
                    if (addedReference) {
                        updateKeyValuePair('referenceFilter', true)
                        setBodyFun();
                    }
                    if (!addedReference && filters?.referenceFilter) {
                        setBodyFun()
                    }
                },
                filterElement: () => <ReferenceFilterTemplate {...{ t, filter, state, setState, setFilterFun, setAddedReference }} />,
                filterClear: filterClearReference,
            },
            {
                field: "requestedBy", body: requestedByTemplate, header: t("transaction.requested_by"),
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMenuClassName: "filterBox",
                headerClassName: filters?.requestedFilter && "requestedFilter",
                filter: true,
                filterElement: () => <RequestedByFilterTemplate {...{ state, setState, t, filter, setFilterFun, setAddedRequested }} />,
                onFilterApplyClick: (e) => {
                    if (addedRequested) {
                        updateKeyValuePair('requestedFilter', true)
                        setBodyFun();
                    }
                },
                filterClear: filterClearRequested
            },
            {
                field: "channel", body: channelBodyTemplate,
                header: t("transaction.channel"),
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMenuClassName: "filterBox",
                headerClassName: filters?.channelFilter && "channelFilter",
                filter: true,
                filterElement: () => <ChannelFilterTemplate {...{ state, t, filter, setFilterFun, setAddedChannelType, setAddedSystemBank }} />,
                onFilterApplyClick: (e) => {
                    if (addedChannelType && addedSystemBank) {
                        updateKeyValuePair('channelFilter', true)
                        setBodyFun();
                    }
                },
                filterClear: filterClearChannel
            },
            {
                field: "RecipientAccount", body: (rowData) => <CustomerBodyTemplate {...{ rowData, setIdentifiers }} />, header: t("transaction.recipient"),
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMenuClassName: "filterBox",
                filter: true,
                headerClassName: filters?.recipientFilter && "recipientFilter",
                filterElement: () => <AccountFilterTemplate {...{ state, setState, t, filter, setFilterFun, setAddedRecipient, identifiers }} />,
                onFilterApplyClick: (e) => {
                    if (addedRecipient) {
                        updateKeyValuePair('recipientFilter', true)
                        setBodyFun();
                    }
                },
                filterClear: filterClearRecipient
            },
            {
                field: "requested", body: (rowData) => <AmountTemplate  {...{ rowData, defMembership }} />, header: t("transaction.amount"), sort: true,
                filter: true,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showFilterMenuOptions: false,
                filterElement: () => <BalanceFilterTemplate {...{ defMembership, comparisonOperators, t, filter, setFilterSubFun, setAddedAmount, setAddedAmountOperator }} />,
                filterMenuClassName: "filterBox",
                headerClassName: filters?.amountFilter && "amountFilter",
                onFilterApplyClick: () => {
                    if (addedAmount && addedAmountOperator) {
                        updateKeyValuePair('amountFilter', true)
                        setBodyFun();
                    }
                },
                filterClear: filterClearCollected
            },
            {
                field: "requestedOn", body: (rowData) => <RequestedOnBodyTemplate {...{ rowData, t }} />, header: t("transaction.activity"), sort: true,
                filter: true,
                filterElement: () => <DateFilterTemplate {...{ control, setBodyFun, dateTypes, t, comparisonOperators, dateComparisonOperators, handleSubmit, setAddedActivity, updateKeyValuePair }} />,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showFilterMenuOptions: false,
                showApplyButton: false,
                filterMenuClassName: "filterBox",
                headerClassName: filters?.activityFilter && "activityFilter",
                filterClear: filterClearStartedOn
            },
        ],
        value: state.transaction,
        count: state.transaction.length,
    };

    if (fetchingTransactionCustomReport) {
        return <Loading />;
    }
    return (
        <div className="transaction" id="transaction">
            <Toast ref={toast} />
            <h6 className="title-tile">{t("report.transaction_title")}</h6>

            <CustomDataTable
                value={state.transaction}
                paginator={true}
                columns={tabs.columns} setState={setState}
                tableClassName="tier_detail_data_table custom_report withdrawal_table" 
                tableId="withdrawal_table" 
                />
        </div>
    );
};

export default WithdrawalsCustom;