import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	paySwitchCredentialRequest: ["businessId"],
	paySwitchCredentialCreateRequest: ["businessId", "credentials"],
	paymentCredentialRequest: ["businessId"],
	

	paySwitchCredentialSuccess: ["credentials"],
	paySwitchCredentialCreateSuccess: ["credentials"],
	paymentCredentialSuccess: ["paymentCredentials"],

	paySwitchCredentialFailure: ["error"],
	paySwitchCredentialCreateFailure: ["error"],
	paymentCredentialFailure: ["error"],

});

export const PaySwitchTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingCredentials: false,
	creating: false,
	fetchingPaymentCredentials: false,

	credentials: null,
	paymentCredentials: null,
	

	errorCredentials: null,
	errorCreating: null,
	errorPaymentCredentials: null,
	
});

/* ------------- Reducers ------------- */

// request the data from an api
export const credentialsRequest = (state) => {
	return { ...state, fetchingCredentials: true, credentials: null, errorCredentials: null };
};


// request to create from an api
export const createRequest = (state) => {
	return { ...state, creating: true, errorCreating:null, credentials:null };
};

export const paymentCredentialsRequest = (state) => {
	return { ...state, fetchingPaymentCredentials: true, paymentCredentials: null, errorPaymentCredentials: null };
};



// successful api lookup for single entity
export const success = (state, action) => {
	const { credentials } = action;
	return { ...state, fetchingCredentials: false, errorCredentials: null, credentials };
};


// successful api create
export const createSuccess = (state, action) => {
	const { credentials } = action;
	return { ...state, creating: false, errorCreating: null, credentials };
};

export const paymentSuccess = (state, action) => {
	const { paymentCredentials } = action;
	return { ...state, fetchingPaymentCredentials: false, errorPaymentCredentials: null, paymentCredentials };
};


// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingCredentials: false, errorCredentials: error, credentials: null };
};


// Something went wrong creating.
export const createFailure = (state, action) => {
	const { error } = action;
	return { ...state, creating: false, errorCreating: error, credentials: null };
};

export const paymentFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingPaymentCredentials: false, errorPaymentCredentials: error, paymentCredentials: null };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PAY_SWITCH_CREDENTIAL_REQUEST]: credentialsRequest,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_REQUEST]: createRequest,
	[Types.PAYMENT_CREDENTIAL_REQUEST]: paymentCredentialsRequest,


	[Types.PAY_SWITCH_CREDENTIAL_SUCCESS]: success,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_SUCCESS]: createSuccess,
	[Types.PAYMENT_CREDENTIAL_SUCCESS]: paymentSuccess,

	[Types.PAY_SWITCH_CREDENTIAL_FAILURE]: failure,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_FAILURE]: createFailure,
	[Types.PAYMENT_CREDENTIAL_FAILURE]: failure,

});
