import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable';
import { AccountTypes } from '../actions/account-actions';

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    account: null,
    error: null,
    fetching: false,
    updating: false,
    resendEmailVerification:false,
    resendEmailVerificationSuccess:false,
    resendEmailVerificationError:false
})

/* ------------- Reducers ------------- */

// we're attempting to account
export const request = (state) => { return { ...state, fetching: true, account: null } }

export const resendRequest = (state) => { return { ...state, resendEmailVerification: true, resendEmailVerificationSuccess: false, resendEmailVerificationError:null } }

// we've successfully logged in
export const success = (state, data) => {
    const { account } = data
    return { ...state, fetching: false, error: null, account }
}

export const resendSuccess = (state) => {
    
    return { ...state, resendEmailVerification: false, resendEmailVerificationSuccess: true, resendEmailVerificationError: null }
}

// we've had a problem getting the account
export const failure = (state, { error }) => { return { ...state, fetching: false, updating: false, account: null, error } }

export const resendFailure = (state, { error }) => { return { ...state, resendEmailVerification: false, resendEmailVerificationSuccess: false,  resendEmailVerificationError:error } }

// we're attempting to updating account settings
export const updateRequest = (state) => { return { ...state, updating: true } }

// we've successfully updated the account settings
export const updateSuccess = (state, data) => {
    const { account } = data
    return { ...state, error: null, updating: false, account }
}

// we've had a problem updating the account settings
export const updateFailure = (state, { error }) => {
    return { ...state, updating: false, error }
}

// we're attempting to reset the account
export const accountResetSuccess = state => INITIAL_STATE


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [AccountTypes.ACCOUNT_REQUEST]: request,
    [AccountTypes.ACCOUNT_SUCCESS]: success,
    [AccountTypes.ACCOUNT_FAILURE]: failure,
    [AccountTypes.ACCOUNT_UPDATE_REQUEST]: updateRequest,
    [AccountTypes.ACCOUNT_UPDATE_SUCCESS]: updateSuccess,
    [AccountTypes.ACCOUNT_UPDATE_FAILURE]: updateFailure,
    [AccountTypes.ACCOUNT_RESET_SUCCESS]: accountResetSuccess,
    [AccountTypes.EMAIL_VERIFICATION_RESEND]: resendRequest,
    [AccountTypes.EMAIL_VERIFICATION_RESEND_SUCCESS]: resendSuccess,
    [AccountTypes.EMAIL_VERIFICATION_RESEND_FAILURE]: resendFailure
})

/* ------------- Selectors ------------- */
// Is the current user logged in?
export const isLoggedIn = accountState => accountState.account !== null

export const getLogin = accountState => accountState.account !== null ? accountState.account.login : 'anonymousUser'