import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  profileRequest: [],
  profileSuccess: ["profile"],
  profileFailure: ["error"],
  profileUpdateRequest: ["profile"],
  profileUpdateSuccess: ["profile"],
  profileUpdateFailure: ["error"],
  profileResetSuccess: null,
});

export const ProfileTypes = Types;
export default Creators;
