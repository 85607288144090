import { call, put } from 'redux-saga/effects'
import * as api from "../../Service/api";
import BankActions from '../actions/bank-actions'

export function* getBank(action) {
    const { bankId } = action
    // make the call to the api
    const response = yield call(api.getBank, bankId)

    // success?
    if (response.ok) {
        yield put(BankActions.bankSuccess(response.data))
    } else {
        yield put(BankActions.bankFailure(response.data))
    }
}

export function* getBanks(action) {
    const { countryId } = action
    // make the call to the api
    const response = yield call(api.getBanks, countryId)

    // success?
    if (response.ok) {
        yield put(BankActions.bankAllSuccess(response.data))
    } else {
        yield put(BankActions.bankAllFailure(response.data))
    }
}

export function* getFilteredBanks(action) {
    const { businessId, query, options } = action
    // make the call to the api
    const response = yield call(api.filterBanks, businessId, query, options)
    // success?
    if (response.ok) {
        yield put(BankActions.bankFilterSuccess(response.data))
    } else {
        yield put(BankActions.bankFilterFailure(response.data))
    }
}
