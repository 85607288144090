import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";
import "../../../Styles/pages/report.scss"
import "../../../Styles/pages/dashboard.scss";
import "../../../Styles/pages/subscriptionDetail.scss"
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import PlanReportActions from "../../../Redux/transition/plan-report/plan-report.reducer";
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import moment from 'moment';
import { SummarySection } from '../components/SummaryCards';
import { ActionBar } from '../components/actionBar';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import logo from "../../../assets/images/LOGO-4.png"

const TransactionSummary = () => {
    const { t } = useTranslation("common");
    const [selectedType, setSelectedType] = useState({ name: t("dashboard.products_dashboard"), code: 'plan' });
    const [period, setPeriod] = useState({ name: "Lifetime", code: 'MONTHLY', title: "lifetime" });
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [duration, setDuration] = useState('');
    const [dates, setDates] = useState(null);
    const [options, setOptions] = useState([]);
    const componentRef = useRef(null);
    const [summary, setSummary] = useState(false);
    const [cleared, setCleared] = useState(false);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);
    const [state, setState] = useState({
        options: null,
        date: null,
        transactionReports: [],
        transactionReportsCharts: [],
    });

    //redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const transactionReport = useSelector((state) => state.planReport.transactionReport)
    const fetchingTransactionReport = useSelector((state) => state.planReport.fetchingTransactionReport)
    const transactionReportChart = useSelector((state) => state.planReport.transactionReportChart)
    const fetchingTransactionChartReport = useSelector((state) => state.planGroups.fetchingTransactionChartReport)
    // const activePlanGroups = useSelector((state) => state.planGroups.planGroupsGraphed)
    const activePlanGroups = useSelector((state) => state.planGroups.planGroupsGraphedActive)
    //redux Dispatch actions
    const dispatch = useDispatch();
    const getMembership = () => dispatch(DefMembershipActions.defMembershipRequest())
    const getTransactionReport = useCallback((businessId, options) => { dispatch(PlanReportActions.transactionReportRequest(businessId, options)) }, [dispatch])
    const getTransactionReportChart = useCallback((businessId, planGroupType, options) => { dispatch(PlanReportActions.transactionReportChartRequest(businessId, planGroupType, options)) }, [dispatch])
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])

    const onSummaryUpdate = () => {
        setSummary(true)
    }

    let doc = []
    const dropdownItems = [
        { name: t("dashboard.products_dashboard"), code: 'plan' },
    ];

    const periods = [
        { name: "Lifetime", code: 'MONTHLY', title: "lifetime" },
        { name: t("dashboard.duration"), code: 'DAILY' },
        { name: t("dashboard.weekly_duration"), code: 'WEEKLY' },
        { name: t("dashboard.3_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.6_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.12_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.custom"), code: 'CUSTOM' },
    ];

    const summaryOptions = [
        ...options
    ];

    const loading = fetchingTransactionChartReport || fetchingTransactionReport

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getActivePlanGroups(defMembership.business.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activePlanGroups) {
            let planGroupAssigned = Object.assign({}, activePlanGroups);
            planGroupAssigned.groups.map((item, ind) => {
                const {
                    group,
                } = item;
                doc.push({
                    ...group
                });
                return null;
            });
        }
        if (doc) {
            setOptions(doc)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlanGroups])

    useEffect(() => {
        if (transactionReport) {
            setState((state) => { return { ...state, transactionReports: transactionReport } })
        }
        if (transactionReportChart) {
            setState((state) => { return { ...state, transactionReportsCharts: transactionReportChart } })
        }
    }, [transactionReport, state.transactionReports, state.transactionReportsCharts, transactionReportChart])

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = moment().subtract(1, 'months').format("YYYY-MM-DD")
        const joinedDate = moment(defMembership.joinedOn).format("YYYY-MM-DD")
        let customEndDate;

        const priorDate = new Date(new Date().setDate(today.getDate() - 30));
        const priorWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 84);
        const last3Months = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        const last6Months = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
        const last12Months = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate())

        const endDates = moment(today).format().split("T").shift()
        const dailyStartDate = moment(priorDate).format().split("T").shift()
        const weeklyStartDates = moment(priorWeeks).format().split("T").shift()
        const last3MonthsStartDate = moment(last3Months).format().split("T").shift()
        const last6MonthsStartDate = moment(last6Months).format().split("T").shift()
        const last12MonthsStartDate = moment(last12Months).format().split("T").shift()

        if (period.code === "MONTHLY" && period.title === "lifetime") {
            customEndDate = joinedDate
            setDuration(t("dashboard.lifetime_duration"))
        }
        else if (period.code === "DAILY") {
            customEndDate = dailyStartDate
            setDuration(t("dashboard.duration"))
        }
        else if (period.code === "WEEKLY") {
            customEndDate = weeklyStartDates
            setDuration(t("dashboard.weekly_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.3_months_duration"))) {
            customEndDate = last3MonthsStartDate
            setDuration(t("dashboard.3_months_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.6_months_duration"))) {
            customEndDate = last6MonthsStartDate
            setDuration(t("dashboard.6_months_duration"))
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.12_months_duration"))) {
            customEndDate = last12MonthsStartDate
            setDuration(t("dashboard.12_months_duration"))
        }
        else {
            customEndDate = oneMonthAgo
            setDuration(t("dashboard.30_days_duration"))
        }
        const startDate = dates && moment(dates[0]).format('YYYY-MM-DD')
        const endDate = dates && moment(dates[1]).format('YYYY-MM-DD')

        if (defMembership) {
            if (!selectedOption && (!startDate || !endDate) && period.title === "lifetime") {
                const customDateFilter = { startDate: period.title === "lifetime" ? joinedDate : customEndDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, customDateFilter)
                getTransactionReport(defMembership.business.id, customDateFilter)
            }
           else if (!selectedOption && (!startDate || !endDate) && period.code === "CUSTOM" && cleared) {
                const customDateFilter = { startDate: period.code === "CUSTOM" ? joinedDate : customEndDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, customDateFilter)
                getTransactionReport(defMembership.business.id, customDateFilter)
                setCleared(false)
            }
            else if (!selectedOption && (!startDate || !endDate) && (period.title !== "lifetime" && period.code !== "CUSTOM")) {
                const customDateFilter = { startDate: customEndDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, customDateFilter)
                getTransactionReport(defMembership.business.id, customDateFilter)
            }
            else if (selectedOption && !dates && period.title === "lifetime") {
                const planFilter = { planGroupId: selectedOption?.id, startDate: joinedDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, planFilter)
                getTransactionReport(defMembership.business.id, planFilter)
            }
            else if (selectedOption && !dates && period.code === "CUSTOM" && cleared) {
                const planFilter = { planGroupId: selectedOption?.id, startDate: joinedDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, planFilter)
                getTransactionReport(defMembership.business.id, planFilter)
                setCleared(false)
            }
            else if (selectedOption && !dates) {
                const planFilter = { planGroupId: selectedOption?.id, }
                const customDateFilter = { startDate: customEndDate, endDate: endDates }
                getTransactionReportChart(defMembership.business.id, period.code, planFilter)
                getTransactionReport(defMembership.business.id, customDateFilter)
            }
            else if (!selectedOption && (startDate && endDate !== "Invalid date")) {
                const filter = { startDate, endDate }
                getTransactionReportChart(defMembership.business.id, period.code, filter)
                getTransactionReport(defMembership.business.id, filter)
            }
            else if (selectedOption && (startDate && endDate !== "Invalid date")) {
                const planFilter = { planGroupId: selectedOption?.id, startDate, endDate }
                getTransactionReport(defMembership.business.id, planFilter)
                getTransactionReportChart(defMembership.business.id, period.code, planFilter)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.submitInput, period, selectedType, dates, selectedOption, state.selectedCustomer, selectedCustomer])

    const statusDates = state.transactionReportsCharts?.settlementsAmountSeries?.map((date) => {
        return (date.monthDisplayName)
    })
    const processedTransactions = state.transactionReportsCharts?.settlementsAmountSeries?.map((date) => {
        return (date.processed)
    })
    const pendingTransactions = state.transactionReportsCharts?.settlementsAmountSeries?.map((date) => {
        return (date.pending)
    })
    const reversedTransactions = state.transactionReportsCharts?.settlementsAmountSeries?.map((date) => {
        return (date.reversed)
    })
    const amountDates = state.transactionReportsCharts?.settlementsCountSeries?.map((amount) => {
        return (amount.monthDisplayName)
    })
    const processedTransactionAmount = state.transactionReportsCharts?.settlementsCountSeries?.map((amount) => {
        return (amount.processed)
    })
    const pendingTransactionAmount = state.transactionReportsCharts?.settlementsCountSeries?.map((amount) => {
        return (amount.pending)
    })
    const reversedTransactionAmount = state.transactionReportsCharts?.settlementsCountSeries?.map((amount) => {
        return (amount.reversed)
    })
    const statusData = {
        labels: statusDates,
        datasets: [
            {
                label: t("summary_reports.processed_transactions"),
                borderColor: '#66BB6A',
                backgroundColor: '#66BB6A',
                data: processedTransactions
            },
            {
                label: t("summary_reports.pending_transactions"),
                borderColor: '#FFB300',
                backgroundColor: '#FFB300',
                data: pendingTransactions
            },
            {
                label: t("summary_reports.reversed_transactions"),
                borderColor: '#e74e69',
                backgroundColor: '#e74e69',
                data: reversedTransactions
            }
        ]
    };

    const amountsData = {
        labels: amountDates,
        datasets: [
            {
                label: t("summary_reports.processed_transactions"),
                borderColor: '#66BB6A',
                backgroundColor: '#66BB6A',
                data: processedTransactionAmount
            },
            {
                label: t("summary_reports.pending_transactions"),
                borderColor: '#FFB300',
                backgroundColor: '#FFB300',
                data: pendingTransactionAmount
            },
            {
                label: t("summary_reports.reversed_transactions"),
                borderColor: '#e74e69',
                backgroundColor: '#e74e69',
                data: reversedTransactionAmount
            }
        ]
    };

    const processedValue = !state.transactionReports?.transactionAmounts?.processed ? "GHS 0.00" : defMembership.business.country?.currency + " " + state.transactionReports?.transactionAmounts?.processed
    const pendingValue = !state.transactionReports?.transactionAmounts?.pending ? "GHS 0.00" : defMembership.business.country?.currency + " " + state.transactionReports?.transactionAmounts?.pending
    const reversedValue = !state.transactionReports?.transactionAmounts?.reversed ? "GHS 0.00" : defMembership.business.country?.currency + " " + state.transactionReports?.transactionAmounts?.reversed

    const transactionStatus = [
        { title: t("summary_reports.processed_transactions"), subDesc: t("withdrawals_reports.withdrawals"), style: "accepted-invitations", value: state.transactionReports?.transactionStatuses?.processed, cardInfo: "The total number of processed transactions" },
        { title: t("summary_reports.pending_transactions"), subDesc: t("withdrawals_reports.withdrawals"), style: "pending-invitations", value: state.transactionReports?.transactionStatuses?.pending, cardInfo: "The total number of pending transactions" },
        { title: t("summary_reports.reversed_transactions"), subDesc: t("withdrawals_reports.withdrawals"), style: "reversed-transactions", value: state.transactionReports?.transactionStatuses?.reversed, cardInfo: "The total number of reversed transactions" }
    ]
    const transactionAmount = [
        {
            title: t("summary_reports.processed_transactions_title"),
            subDesc: t("withdrawals_reports.withdrawals"), style: "accepted-invitations",
            value: processedValue,
            cardInfo: "The total amount of processed transactions"
        },
        {
            title: t("summary_reports.pending_transactions_title"),
            subDesc: t("withdrawals_reports.withdrawals"), style: "pending-invitations",
            value: pendingValue,
            cardInfo: "The total amount of pending transactions"
        },
        {
            title: t("summary_reports.reversed_transactions_title"),
            subDesc: t("withdrawals_reports.withdrawals"),
            style: "reversed-transactions",
            value: reversedValue,
            cardInfo: "The total amount of reversed transactions"
        }
    ]
    const transactionChannel = [
        {
            title: t("recipient.mobile_money"),
            subDesc: t("withdrawals_reports.withdrawals"),
            style: "mobile-channel_type",
            value: state.transactionReports?.transactionChannels?.mobile,
            cardInfo: "The total number of mobile transactions"
        },
        { title: t("common.banks"), subDesc: t("withdrawals_reports.withdrawals"), style: "bank-channel_type", value: state.transactionReports?.transactionChannels?.bank, cardInfo: "The total number of bank transactions" },
    ]
    const RenderChart = ({ chartType, chartName, chartData }) => {
        return (
            <div className="chart card sm:col-12 lg:col-5" style={{
                width: "45%", margin: "1.5%", marginLeft: "0", minWidth: "40rem"
            }}>
                <div className="chart-info">
                    <span className="centerText" style={{ fontFamily: "RobotoCondensed-Bold", fontSize: "12px" }}> {chartName}</span>
                    <span className="chart-duration">{duration}</span>
                </div>
                <div >{loading ? <div className="card flex justify-content-center">
                    <ProgressSpinner style={{ width: '80px', height: '80px' }} />
                </div> :
                    <Chart className="p-col-6 p-col-6" type={chartType} data={chartData} />
                }
                </div>
            </div>
        )
    }
    const data = [
        { type: "bar", title: t("summary_reports.status_title"), data: statusData },
        { type: "bar", title: t("withdrawals_reports.withdrawals"), data: amountsData }
    ]

    return (
        <>
            <div>
                <h6 className="wallet-titles" style={{ marginLeft: "1.5em" }} >{t("summary_reports.transactions_summary_title")}</h6>
                <ActionBar selectedType={selectedType} setSelectedType={setSelectedType} dropdownItems={dropdownItems} selectedOption={selectedOption}
                    summaryOptions={summaryOptions} setSelectedOption={setSelectedOption}
                    setSelectedCustomer={setSelectedCustomer} periods={periods} period={period} setDates={setDates} setPeriod={setPeriod}
                    dates={dates} state={state} setState={setState} placeholder={t("withdrawals_reports.placeholder")} summaryOption={false}
                    reactToPrintContent={reactToPrintContent} summary={summary} onClick={onSummaryUpdate} setCleared={setCleared} />

                <div className="print section-to-print" ref={componentRef}>
                    <div className="hide-on-screen">
                        <div className='summary_title'><span className="summary">{t("summary_reports.transactions_summary_title")}</span></div>
                        <div style={{ margin: "0rem 0rem 1rem 1.5rem" }}>
                            <div><span className="retrieved_date">{moment(selectedOption?.createdDate).format("ll, HH:mm")}</span></div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div> <span className='generation_txt'>{t("payments.generation_txt")}</span></div>
                                <img src={logo} alt="babylon-layout" className='logo' />
                            </div>
                        </div>

                        <div className='table_div'>
                            <div className='labels_main_div'>
                                <div className='col-6 label'>
                                    <p className=''>{t("summary_reports.plan_name")}</p>
                                </div>
                                <div className='col-6 label'>
                                    <p >{t("summary_reports.report_period")}</p>
                                </div>
                            </div>

                            <div className='info_div'>
                                <div className='col-6'>
                                    {selectedOption?.business.name === undefined ? <div>{t("summary_reports.all_product_accounts")}</div> :
                                        <div>{selectedOption?.business.name}</div>}
                                </div>

                                <div className='col-6'>
                                    {dates === null ? <div>{period.name}</div> : <div>{dates && moment(dates[0]).format('ll,HH:mm -- ')}
                                        {dates && moment(dates[1]).format('ll,HH:mm')}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SummarySection header={t("summary_reports.status_title")} summary={transactionStatus} loading={loading} />
                    <SummarySection header={t("summary_reports.amount_title")} summary={transactionAmount} loading={loading} />
                    <SummarySection header={t("summary_reports.channel_type")} summary={transactionChannel} loading={loading} />
                    <br />
                    <div className='charts_main_div'>
                        <h6 className="wallet-titles" style={{ marginBottom: "15px", marginLeft: "1.5em" }} >{t("subscriber.snapshot")}</h6>
                        <div className="grid p-fluid" style={{ marginLeft: "5px" }}> </div >
                        <div className="grid  p-fluid" style={{ marginLeft: "5px" }} >
                            <div className='col-12' style={{ display: "flex", flexWrap: "wrap" }}>
                                {data.map((item) => {
                                    return <RenderChart chartType={item.type} chartName={item.title} chartData={item.data} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionSummary