import React from "react";
import { Router } from "react-router-dom";
import AppWrapper from "./Route/AppWrapper";
import { Provider } from 'react-redux'
import { store, persistor } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from './Components/Loading';
import history from "./Service/history";

export default function AppRoot() {
	return (
		<Provider store={store}>
			<PersistGate loading={<Loading />} persistor={persistor}>
				<Router history={history}>
					<AppWrapper />
				</Router>
			</PersistGate>
		</Provider>
	);
}