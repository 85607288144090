import moment from "moment";

//const ISO_DATE_FORMAT = "YYYY-MM-DD\Thh:mm:ss.180+03:00"
export const formatTime = (millis, format) => {
	let time = moment(millis > 1000000000000 ? millis : millis * 1000);
	return time.format(format);
};

export const displayFormat = (time) => {
	
	return moment(time).format('MMMM Do YYYY, h:mm:ss a');
};

export const getLastMonthStart = () => {
	let time = moment()
		.subtract(1, "months")
		.date(1)
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getLastMonthEnd = () => {
	let time = moment()
		.subtract(1, "months")
		.endOf("month")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getThisMonthStart = () => {
	let time = moment()
		.startOf("month")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getThisMonthEnd = () => {
	let time = moment()
		.endOf("month")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getThisMonthCurrent = () => {
	let time = moment().format();
	return addPadding(time);
};
export const getThisWeekStart = () => {
	let time = moment()
		.startOf("week")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getThisWeekEnd = () => {
	let time = moment()
		.endOf("week")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};
export const getThisWeekCurrent = () => {
	let time = moment().format();
	return addPadding(time);
};

export const getNextMonthStart = () => {
	let time = moment()
		.add(1, "months")
		.date(1)
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

export const getNextMonthEnd = () => {
	var time = moment()
		.add(1, "months")
		.endOf("month")
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};
export const getToday = () => {
	var time = moment()
		.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		.format();
	return addPadding(time);
};

const addPadding = (input) => {
	let insert = ".000";
	let position = 19;
	var output = [input.slice(0, position), insert, input.slice(position)].join(
		""
	);
	return output;
};
