import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  defMembershipRequest: [],
  defMembershipSuccess: ["defMembership"],
  defMembershipFailure: ["error"],
  defMembershipResetSuccess: null,
  resetDefMembership: [],
});

export const DefMembersipTypes = Types;
export default Creators;
