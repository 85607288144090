import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  settlementRequest: ['settlementId'],
  // settlementAllRequest: ['walletId', 'options'],
  // settlementAllRequest: ['businessId', 'filter'],
  settlementAllRequest: ['businessId','filter'],
  // settlementAllCountRequest: ['businessId', 'filter'],
  settlementUpdateRequest: ['settlement'],
  settlementCountRequest: ['businessId', 'filter', 'options'],
  settlementStatusCountRequest: ['businessId', 'status'],
  settlementCreateRequest: ['settlementRequest'],
  settlementDeleteRequest: ['settlementId'],
  settlementSourcesRequest: ['settlementId'],
  settlementSourcesGroupRequest: ['planGroupId'],
  planSettlementsRequest: ['id'],
  structureSettlementRequest: ['countryId'],

  settlementSuccess: ['settlement'],
  // settlementAllSuccess: ['settlements'],
  settlementAllSuccess: ['settlements'],
  // settlementAllCountSuccess: ['settlementsCount'],
  settlementUpdateSuccess: ['settlement'],
  settlementCountSuccess: ['count'],
  settlementStatusCountSuccess: ['count'],
  settlementCreateSuccess: ['settlementRequest'],
  settlementDeleteSuccess: [],
  settlementSourcesSuccess: ['settlementSources'],
  settlementSourcesGroupSuccess: ['sourcePlanGroups'],
  planSettlementsSuccess: ['planSettlements'],
  structureSettlementSuccess: ['structureSettlement'],


  settlementFailure: ['error'],
  // settlementAllFailure: ['error'],
  settlementAllFailure: ['error'],
  // settlementAllCountFailure: ['error'],
  settlementUpdateFailure: ['error'],
  settlementCountFailure: ['error'],
  settlementStatusCountFailure: ['error'],
  settlementCreateFailure: ['error'],
  settlementDeleteFailure: ['error'],
  settlementSourcesFailure: ['error'],
  settlementSourcesGroupFailure: ['error'],
  planSettlementsFailure: ['error'],
  structureSettlementFailure: ['error']
})

export const SettlementTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingOne: false,
  fetchingAll: false,
  fetchingSettlementSources: false,
  fetchingSourcePlanGroups: false,
  fetchingCount: false,
  fetchingStatusCount: false,
  updating: false,
  fetchingPlanSettlements: false,
  fetchingStructureSettlement: false,

  count: null,
  statusCount: null,
  creating: null,
  deleting: null,
  settlement: null,
  settlementRequest: null,
  settlements: null,
  settlementSources: null,
  sourcePlanGroups: null,
  planSettlements: null,
  structureSettlement: null,

  errorOne: null,
  errorAll: null,
  errorUpdating: null,
  errorCount: null,
  errorStatusCount: null,
  errorCreating: null,
  errorDeleting: null,
  errorSettlementSources: null,
  errorSourcePlanGroups: null,
  errorFetchingPlanSettlements: null,
  errorStructureSettlement: null,
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => {
  return { ...state, fetchingOne: true, settlement: null }
}

// request the data from an api
export const allRequest = (state) => {
  return { ...state, fetchingAll: true, settlements: null }
}

// request the data from an api
export const sourcesRequest = (state) => {
  return { ...state, fetchingSettlementSources: true, settlementSources: null }
}

// request the data from an api
export const sourcesGroupRequest = (state) => {
  return { ...state, fetchingSourcePlanGroups: true, sourcePlanGroups: null }
}

// request to update from an api
export const updateRequest = (state) => {
  return { ...state, updating: true }
}

export const countRequest = (state) => {
  return { ...state, fetchingCount: true, count: null }
}

export const statusCountRequest = (state) => {
  return { ...state, fetchingStatusCount: true, statusCount: null }
}

// request to create from an api
export const createRequest = (state) => {
  return { ...state, creating: true, settlementRequest: null }
}

// request to delete from an api
export const deleteRequest = (state) => {
  return { ...state, deleting: true }
}

// request to fetch settlements related to a particular plan
export const planSettlementsRequest = (state) => {
  return { ...state, fetchingPlanSettlements: true }
}

//request to fetch settlements related to a particular settlement structure
export const structureSettlementRequest = (state) => {
  return { ...state, fetchingStructureSettlementRequest: true }
}


// successful api lookup for single entity
export const success = (state, action) => {
  const { settlement } = action;
  return { ...state, fetchingOne: false, errorOne: null, settlement }
}


// successful api lookup for all entities
export const allSuccess = (state, action) => {
  const { settlements } = action;
  return { ...state, fetchingOne: false, fetchingAll: false, errorOne: null, settlements }
}

export const countSuccess = (state, action) => {
  const { count } = action;
  return { ...state, fetchingCount: false, errorCount: null, count }
}

export const statusCountSuccess = (state, action) => {
  const { count } = action;
  return { ...state, fetchingStatusCount: false, errorStatusCount: null, statusCount: count }
}

// successful api lookup for all entities
export const sourcesSuccess = (state, action) => {
  const { settlementSources } = action;
  return { ...state, fetchingSetttlementSources: false, errorSettlementSources: null, settlementSources }
}


// successful api lookup for all entities
export const sourcesGroupSuccess = (state, action) => {
  const { sourcePlanGroups } = action;
  return { ...state, fetchingSourcePlanGroups: false, errorSourcePlanGroups: null, sourcePlanGroups }
}

// successful api update
export const updateSuccess = (state, action) => {
  const { settlement } = action;
  return { ...state, updating: false, errorUpdating: null, settlement }
}

// successful api post
export const createSuccess = (state, action) => {
  const { settlementRequest } = action;
  return { ...state, creating: false, errorCreating: null, settlementRequest }
}

// successful api delete
export const deleteSuccess = (state) => {
  return { ...state, deleting: false, errordeleting: null, settlement: null }
}

// successful fetching plan settlements from api 
export const planSettlementsSuccess = (state, action) => {
  const { planSettlements } = action
  return { ...state, fetchingPlanSettlements: false, errorFetchingPlanSettlements: null, planSettlements }
}

// successful fetching of structure settlements from api
export const structureSettlementSuccess = (state, action) => {
  const { structureSettlement } = action
  return { ...state, fetchingStructureSettlement: false, errorFetchingStructureSettlement: null, structureSettlement }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingOne: false, errorOne: error, settlement: null }
}

// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingAll: false, errorAll: error, settlements: null }
}

export const countFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingCount: false, errorCount: error, count: null }
}

export const statusCountFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingStatusCount: false, errorStatusCount: error, statusCount: null }
}

// Something went wrong fetching all entities.
export const sourcesFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchinSettlementSource: false, errorSettlementSources: error, settlementSources: null }
}

// Something went wrong fetching all entities.
export const sourcesGroupFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingSourcePlanGroups: false, errorSourcePlanGroups: error, sourcePlanGroups: null }
}


// Something went wrong updating.
export const updateFailure = (state, action) => {
  const { error } = action;
  return { ...state, updating: false, errorUpdating: error, settlement: state.settlement }
}

// Something went wrong posting.
export const createFailure = (state, action) => {
  const { error } = action;
  return { ...state, creating: false, errorCreating: error, settlementRequest: state.settlementRequest }
}

// Something went wrong deleting.
export const deleteFailure = (state, action) => {
  const { error } = action;
  return { ...state, deleting: false, errorDeleting: error, settlement: state.settlement }
}

// Something went wrong fetching plan settlements.
export const planSettlementsFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingPlanSettlements: false, errorFetchingPlanSettlements: error, planSettlements: null }
}

//Something went wrong fetching structure settlement
export const structureSettlementFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingStructureSettlement: false, errorFetchingStructureSettlement: error, structureSettlement: null }
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SETTLEMENT_REQUEST]: request,
  [Types.SETTLEMENT_ALL_REQUEST]: allRequest,
  [Types.SETTLEMENT_COUNT_REQUEST]: countRequest,
  [Types.SETTLEMENT_STATUS_COUNT_REQUEST]: statusCountRequest,
  [Types.SETTLEMENT_SOURCES_REQUEST]: sourcesRequest,
  [Types.SETTLEMENT_SOURCES_GROUP_REQUEST]: sourcesGroupRequest,
  [Types.SETTLEMENT_UPDATE_REQUEST]: updateRequest,
  [Types.SETTLEMENT_CREATE_REQUEST]: createRequest,
  [Types.SETTLEMENT_DELETE_REQUEST]: deleteRequest,
  [Types.PLAN_SETTLEMENTS_REQUEST]: planSettlementsRequest,
  [Types.STRUCTURE_SETTLEMENT_REQUEST]: structureSettlementRequest,

  [Types.SETTLEMENT_SUCCESS]: success,
  [Types.SETTLEMENT_ALL_SUCCESS]: allSuccess,
  [Types.SETTLEMENT_COUNT_SUCCESS]: countSuccess,
  [Types.SETTLEMENT_STATUS_COUNT_SUCCESS]: statusCountSuccess,
  [Types.SETTLEMENT_SOURCES_SUCCESS]: sourcesSuccess,
  [Types.SETTLEMENT_SOURCES_GROUP_SUCCESS]: sourcesGroupSuccess,
  [Types.SETTLEMENT_UPDATE_SUCCESS]: updateSuccess,
  [Types.SETTLEMENT_CREATE_SUCCESS]: createSuccess,
  [Types.SETTLEMENT_DELETE_SUCCESS]: deleteSuccess,
  [Types.PLAN_SETTLEMENTS_SUCCESS]: planSettlementsSuccess,
  [Types.STRUCTURE_SETTLEMENT_SUCCESS]: structureSettlementSuccess,

  [Types.SETTLEMENT_FAILURE]: failure,
  [Types.SETTLEMENT_ALL_FAILURE]: allFailure,
  [Types.SETTLEMENT_COUNT_FAILURE]: countFailure,
  [Types.SETTLEMENT_STATUS_COUNT_FAILURE]: statusCountFailure,
  [Types.SETTLEMENT_SOURCES_FAILURE]: sourcesFailure,
  [Types.SETTLEMENT_SOURCES_GROUP_FAILURE]: sourcesGroupFailure,
  [Types.SETTLEMENT_UPDATE_FAILURE]: updateFailure,
  [Types.SETTLEMENT_CREATE_FAILURE]: createFailure,
  [Types.SETTLEMENT_DELETE_FAILURE]: deleteFailure,
  [Types.PLAN_SETTLEMENTS_FAILURE]: planSettlementsFailure,
  [Types.STRUCTURE_SETTLEMENT_FAILURE]: [structureSettlementFailure]

})
