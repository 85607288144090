import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    bankBranchRequest: ['bankBranchId'],
    bankBranchAllRequest: ['bankId', 'options'],

    bankBranchSuccess: ['bankBranch'],
    bankBranchAllSuccess: ['bankBranches'],

    bankBranchFailure: ['error'],
    bankBranchAllFailure: ['error'],
})

export const BankBranchTypes = Types
export default Creators
