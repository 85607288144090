import React from 'react'
import { useSelector } from 'react-redux';
import { Card } from 'primereact/card';
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../../../../Styles/pages/subscriptionDetail.scss"

const DetailCard = (props) => {
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const { t } = useTranslation("common");
    const newDate = moment(props.subscribers?.nextBillingDate).format("LLL")
    const pausedDate = moment(props.subscribers?.pausedOn).format("LLL")
    const cancelledDate = moment(props.subscribers?.cancelledOn).format("LLL")
    const currency = props.defMembership?.business.country.currency
    const amount = (props.subscriber?.plan?.flex !== false && props.subscribers?.cost === null) ? (t("subscriber.flex_true"))
        : (currency + " " + Number.parseFloat(props.subscribers?.cost).toFixed(2))
    return (
        <div className="grid detail_card" style={{ marginBottom: "20px" }}>
            <Card className="col-12 md:col-8 lg:col-8">
                {props.subscribers && <>
                    <div>
                        <div className="grid subs_info_content">
                            <div className="col-12 md:col-6 lg:col-3 name-content">
                                <span className='customer-name'>
                                    {props.subscribers.businessCustomer?.name}
                                </span>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3 width" >
                                <span className='customer-info'>
                                    {props.subscribers.businessCustomer?.phoneNo}
                                </span>
                            </div>
                            <div>
                                <span className="dot"></span>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3" style={{ width: "auto" }}>
                                <span className='customer-info'>
                                    {props.subscribers.businessCustomer?.email}
                                </span>
                            </div>
                            <div className="col-12 md:col-6 lg:col-4" style={{ textAlign: "center", width: "auto" }}>
                                <span className="subs-customer-id">{t("subscriber.customer_id")}{":"}</span>
                                <span id="customer-id">
                                    {props.subscribers.businessCustomer?.customerNum}
                                </span>
                            </div>
                        </div>
                        <div className="grid plan-group-content" >
                            <div className="col-12 md:col-6 lg:col-3">
                                <span className='plan-tier-details'>
                                    {props.subscribers.plan && props.subscribers.plan.name}
                                </span>
                            </div>
                            <div className="col-12 md:col-6 lg:col-3 billing-content">
                                <span>{t(`product.${props.subscribers.plan?.billingPeriod.toLowerCase()}`)}</span>
                            </div>
                            {props.subscribers.plan?.deductions !== null &&
                                <div className="deduction">
                                    <span className="dot"></span>
                                </div>}
                            <div className="col-12 md:col-6 lg:col-3 width" >
                                <span className='subs-deduction'>
                                    {props.subscribers.plan?.deductions === null ?
                                        "" : <div> <span style={{ fontWeight: "bolder" }}>
                                            {props.subscribers.plan?.deductions}</span>
                                            <span> {t("subscriber.billing_cycles")}</span>
                                        </div>}
                                </span>
                            </div>
                        </div>
                        <div className="grid subs-buttons-info">
                            <div className="col-12 md:col-6 lg:col-3 status-badge">
                                <span
                                    className={`product-badge subs-status status-${(props.subscribers?.status ? props.subscribers?.status.toLowerCase() : '')}`}>
                                    {props.subscribers?.status}
                                </span>
                            </div>
                            <div className="subs-content">
                                <div className="ref-content">
                                    <button className="button-content">
                                        <i className="pi pi-key subs-icon" />
                                        {props.subscribers?.subscriberReference}
                                    </button>
                                </div>
                                <div className="subs-button-content" >
                                    <button className="button-content">
                                        <i className="pi pi-info-circle" />
                                        <span className="ded-policy-text">{t("subscriber.deduction_policy")}</span>
                                        <span className='plan-policy'>{props.subscribers.plan?.policy}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                <div className="grid subs-buttons-info">
                    <div className="col-12 md:col-6 lg:col-3 " >
                        <button className="deductions-content spacing">
                            {props.subscribers.plan?.deductions === null ?
                                (props.totalDeductions || 0) + "  " + t("subscriber.deductions") :
                                <b>{props.totalDeductions} {t("subscriber.cycle")} {props.subscribers.plan?.deductions} {t("subscriber.deductions")}</b>
                            }
                        </button>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4" >
                        <button className="total-received spacing"> <div>
                            <span>
                            {props.defMembership.business.country?.currency} {props.totalReceivedPayments !== null ? Number.parseFloat(props.totalReceivedPayments).toFixed(2) : "0.00"}
                            </span>  {t("subscriber.received")}
                        </div>
                        </button>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4" >
                        <button className="missed-content spacing">
                            <div>
                                <span>
                                    {props.totalMissedPayments || 0}
                                </span> {props.totalMissedPayments !== 1 ? t("subscriber.missed") : t("subscriber.missed_one")}
                            </div>
                        </button>
                    </div>
                </div>
                {
                    props.subscribers?.status === "ACTIVE" &&
                    <div className='alert'>
                        <span><i className="pi pi-info-circle"></i>
                            <span className='notice'>
                                {t("subscriber.notice")}
                            </span>: {amount} {t("subscriber.billing")} {newDate}
                        </span>
                    </div>
                }
                {
                    props.subscribers?.status === "PAUSED" ?
                        <div className='alert'>
                            <span><i className="pi pi-info-circle"></i>
                                <span className='notice'>
                                    {t("subscriber.notice")}
                                </span>:  <span className={`product-badge status-${(props.subscribers?.status ? props.subscribers?.status.toLowerCase() : '')}`}>{props.subscribers?.status}
                                </span>  {pausedDate} by {props.subscribers.pausedBy?.user.name}
                            </span>
                        </div> : ""
                }
                {
                    props.subscribers?.status === "CANCELLED" ?
                        <div className='alert'>
                            <span><i className="pi pi-info-circle"></i>
                                <span className='notice'>
                                    {t("subscriber.notice")}
                                </span>:  <span className={`product-badge status-${(props.subscribers?.status ? props.subscribers?.status.toLowerCase() : '')}`}>{props.subscribers?.status}
                                </span>  {cancelledDate} {t("common.by")} {props.subscribers.cancelledBy?.user.name}
                            </span>
                        </div> : ""
                }
            </Card>
            {defMembership.role !== "VIEWER" && <div id="side-menu">
                {props.subscribers?.status === "PAUSED" ?
                    <div className="col-12">
                        <span onClick={() => {
                            props.setConfirmResume(true)
                            props.setState((state) => { return { ...state, resumed: true } })
                        }}>
                            <i className="pi pi-refresh" >
                            </i>{t('subscriber.resume_label')}
                        </span>
                        <hr />
                    </div> : ""}

                {
                    props.subscribers?.status === "ACTIVE" &&
                    <div className="col-12">
                        <span onClick={() => {
                            props.setConfirmReschedule(true)
                        }}
                        >
                            <i className="pi pi-calendar" >
                            </i>{t('subscriber.reschedule_label')}
                        </span>
                        <hr />
                    </div>
                }

                {props.subscribers?.status === "ACTIVE" ?
                    <div className="col-12">
                        <span onClick={() => {
                            props.setConfirmPause(true)
                        }}
                        >
                            <i className="pi pi-pause" >
                            </i>{t('subscriber.pause_label')}
                        </span>
                        <hr />
                    </div> : ""}

                {props.subscribers?.status !== "CANCELLED" && props.subscribers?.status !== "EXPIRED" && props.subscribers?.status !== "COMPLETE" &&
                    <div className="col-12" >
                        < span onClick={() => {
                            props.setConfirmCancel(true)
                            props.setState((state) => { return { ...state, cancelled: true } })
                        }}>
                            <i className="pi pi-stop-circle" ></i>
                            {t('subscriber.cancel_label')}
                        </span>
                    </div>}
                <hr />
            </div>}
        </div >
    )
}
export default DetailCard