import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  subscriptionReportRequest: ['businessId', 'body'],
  transactionCustomReportRequest: ['businessId', 'body'],

  subscriptionReportSuccess: ['subscriptionReport'],
  transactionCustomReportSuccess: ['transactionCustomReport'],

  subscriptionReportFailure: ['error'],
  transactionCustomReportFailure: ['error'],
})

export const ReportTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetchingSubscriptionReport: false,


  subscriptionReport: null,


  errorSubscriptionReport: null,
  fetchingTransactionCustomReport: false,


  transactionCustomReport: null,


  errorTransactionCustomReport: null,

})

/* ------------- Reducers ------------- */
export const subscriptionReportRequest = (state) => {
  return { ...state, fetchingSubscriptionReport: true, subscriptionReport: null };
};

export const subscriptionReportSuccess = (state, action) => {
  const { subscriptionReport } = action;
  return { ...state, fetchingSubscriptionReport: false, errorSubscriptionReport: null, subscriptionReport }
}

export const subscriptionReportFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingSubscriptionReport: false, errorSubscriptionReport: error, subscriptionReport: null }
}
export const transactionCustomReportRequest = (state) => {
  return { ...state, fetchingTransactionCustomReport: true, transactionCustomReport: null };
};

export const transactionCustomReportSuccess = (state, action) => {
  const { transactionCustomReport } = action;
  return { ...state, fetchingTransactionCustomReport: false, errorTransactionCustomReport: null, transactionCustomReport }
}

export const transactionCustomReportFailure = (state, action) => {
  const { error } = action;
  return { ...state, fetchingTransactionCustomReport: false, errorTransactionCustomReport: error, transactionCustomReport: null }
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBSCRIPTION_REPORT_REQUEST]: subscriptionReportRequest,
  [Types.TRANSACTION_CUSTOM_REPORT_REQUEST]: transactionCustomReportRequest,

  [Types.SUBSCRIPTION_REPORT_SUCCESS]: subscriptionReportSuccess,
  [Types.TRANSACTION_CUSTOM_REPORT_SUCCESS]: transactionCustomReportSuccess,

  [Types.SUBSCRIPTION_REPORT_FAILURE]: subscriptionReportFailure,
  [Types.TRANSACTION_CUSTOM_REPORT_FAILURE]: transactionCustomReportFailure,
})
