// a library to wrap and simplify api calls
import apisauce from "apisauce";

// ------
// STEP 1
// ------
//
// Create and configure an apisauce-based api object.
//
const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL: process.env.REACT_APP_API_URL,
  // here are some default headers
  headers: {
    "Cache-Control": "no-cache",
  },
  // 60 second timeout...
  timeout: 60000,
});

// ------
// STEP 2
// ------
//
// Define some functions that call the api.  The goal is to provide
// a thin wrapper of the api layer providing nicer feeling functions
// rather than "get", "post" and friends.
//
// I generally don't like wrapping the output at this level because
// sometimes specific actions need to be take on `403` or `401`, etc.
//
// Since we can't hide from that, we embrace it by getting out of the
// way at this level.
//
const setAuthToken = (userAuth) => api.setHeader("Authorization", "Bearer " + userAuth);
const removeAuthToken = () => api.deleteHeader("Authorization");

const getAccount = () => api.get("api/v1/account");
const updateAccount = (account) => api.put("api/v1/account", account);
const getDefMembership = () => api.get("api/v1/memberships/account/default");
const getProfile = () => api.get("api/v1/account/profile");
const updateProfile = (profile) => api.put("api/v1/account/profile", profile);
const resendVerificationEmail = (user) => api.post("api/v1/account/resend/verification-email", user);

const getUser = (userId) => api.get("api/v1/users/" + userId);
const getUsers = (options) => api.get("api/v1/users", options);
const createUser = (user) => api.post("api/v1/users", user);
const updateUser = (user) => api.put("api/v1/users", user);
const deleteUser = (userId) => api.delete("api/v1/users/" + userId);

const getCountry = (countryId) => api.get("api/v1/countries/" + countryId);
const getCountries = (options) => api.get("api/v1/countries/graphed", options);
const createCountry = (country) => api.post("api/v1/countries", country);
const updateCountry = (country) => api.put("api/v1/countries", country);
const deleteCountry = (countryId) => api.delete("api/v1/countries/" + countryId);

const getBusiness = (businessId) => api.get("api/v1/businesses/" + businessId);
const getBusinesses = (options) => api.get("api/v1/businesses", options);
const getBusinessSummary = (businessId) => api.get(`api/v1/businesses/${businessId}/report`);
const createBusiness = (business) => api.post("api/v1/businesses", business);
const updateBusiness = (businessId, business) => api.put("api/v1/businesses/" + businessId, business);
const deleteBusiness = (businessId) => api.delete("api/v1/businesses/" + businessId);
const getBusinessQr = (businessId, qr) => api.get(`api/v1/businesses/${businessId}/barcode/qr.png`, qr, {
  headers: {
    'Accept': '*/*',
  },
  responseType: "arraybuffer"
});
const getIndustries = (industry) => api.get("api/v1/industries", industry)

const getPlan = (planId) => api.get("api/v1/plans/" + planId);
const getPlans = (businessId, options) => api.get("api/v1/plans?businessId.equals=" + businessId, options);
const createPlan = (plan) => api.post("api/v1/plans", plan);
const updatePlan = (planId, plan) => api.patch(`api/v1/plans/${planId}`, plan);
const changePlanPrice = (planId, amount) => api.put(`api/v1/plans/${planId}/change-amount`, amount);
const deletePlan = (planId) => api.delete("api/v1/plans/" + planId);
const archivePlan = (planId, membershipId) => api.patch(`api/v1/plans/archive/${planId}/${membershipId}`);
const planPerformance = (tierId) => api.get(`api/v1/plans/performance/${tierId}`);
const migratePlan = (migratedData) => api.post(`api/v1/plan-migrations`, migratedData);
const getPlanPaymentMissed = (planId, options) => api.get(`api/v1/payment-schedules/plan/${planId}/missed`, options);
const getPlanPaymentMissedCount = (planId) => api.get(`api/v1/payment-schedules/plan/${planId}/missed/count`);
const getPlanPaymentPending = (planId, options) => api.get(`api/v1/payment-schedules/plan/${planId}/pending`, options);
const getPlanPaymentPendingCount = (planId) => api.get(`api/v1/payment-schedules/plan/${planId}/pending/count`);
const getPlanPaymentCollected = (planId, options) => api.get("api/v1/payment-tracks?planId.equals=" + planId, options);
const getPlanPaymentCollectedCount = (planId) => api.get("api/v1/payment-tracks/count?planId.equals=" + planId);

const getCustomer = (customerId) => api.get("api/v1/customers/" + customerId);
const getCustomers = (options) => api.get("api/v1/customers", options);
const createCustomer = (customer) => api.post("api/v1/customers", customer);
const updateCustomer = (customer) => api.put("api/v1/customers", customer);
const deleteCustomer = (customerId) => api.delete("api/v1/customers/" + customerId);

const getSubscriber = (subscriberId) => api.get("api/v1/subscribers/" + subscriberId);
const getSubscribers = (businessId, options) => api.get(`api/v1/_search/subscribers/${businessId}?searchText`, options);
const getCountSubscribers = (businessId) => api.get("api/v1/subscribers/count?businessId.equals=" + businessId);
const getPlanSubscribers = (planId) => api.get("api/v1/subscribers?planId.equals=" + planId);
const createSubscriber = (subscriber) => api.post("api/v1/subscribers", subscriber);
const updateSubscriber = (subscriber) => api.put("api/v1/subscribers", subscriber);
const deleteSubscriber = (subscriberId) => api.delete("api/v1/subscribers/" + subscriberId);
const rescheduleSubscriber = (subscriberId) => api.patch("api/v1/subscribers/reschedule/" + subscriberId);
const checkExclusivity = (newPlanId, msisdn) => api.get("api/v1/subscribers/check-exclusivity/" + newPlanId + "/" + msisdn);
const substituteSubscribersFilter = (query, filters, options) => api.get("api/v1/_search/subscribers?query=" + query + "&filters=" + filters, options);
const searchSubscribers = (businessId) => api.get(`api/v1/_search/subscribers/${businessId}?searchText`);
const countStatusSubscribers = (businessId, query) => api.get(`api/v1/_search/subscribers/${businessId}/count?${query}`);
const filterSubscribers = (query, businessId, options) => api.get(`api/v1/_search/subscribers/${businessId}?searchText${query}`, options);
const getCountSearchSubscribers = (query, businessId) => api.get(`api/v1/_search/subscribers/${businessId}/count?searchText=${query}`);
const dateFilterSubscribers = (query, businessId, startDate, endDate, options) => api.get("api/v1/_search/subscribers/" + businessId + "/" + startDate + "/" + endDate + query, options);
const getCountDateSearchSubscribers = (query, businessId, startDate, endDate) => api.get(`api/v1/_search/subscribers/${businessId}/${startDate}/${endDate}/count${query}`);
const countSubscribers = (query, filters) => api.get("api/v1/_search/subscribers/count?query=" + query + "&filters=" + filters);
const getSubscriberMissedSchedules = (subscriberId, missedByDate) => api.get("api/v1/payment-schedules/subscriber/" + subscriberId + "/missed/" + missedByDate);
const subscribersBulkCancel = (businessId, membershipId, ids) => api.patch(`api/v1/subscribers/${businessId}/bulk/cancel/${membershipId}`, { ids, });
const subscribersBulkPause = (businessId, membershipId, ids) => api.patch(`api/v1/subscribers/${businessId}/bulk/pause/${membershipId}`, { ids, });
const businessCustomersBulkPause = (membershipId, ids) => api.patch(`api/v1/business-customers/subscriptions/bulk-pause/${membershipId}`, { ids })
const businessCustomersBulkCancel = (membershipId, ids) => api.patch(`api/v1/business-customers/subscriptions/bulk-cancel/${membershipId}`, { ids })
const businessCustomersSingleCancel = (businessCustomerId, membershipId) => api.patch(`api/v1/business-customers/${businessCustomerId}/subscriptions/cancel/${membershipId}`)
const businessCustomersSinglePause = (businessCustomerId, membershipId) => api.patch(`api/v1/business-customers/${businessCustomerId}/subscriptions/pause/${membershipId}`)
const customerReceivedPayments = (businessCustomerId, options) => api.get(`/api/v1/business-customers/${businessCustomerId}/subscriptions/summary`, options);
const businessCustomerMissedPayments = (businessCustomerId) => api.get(`/api/v1/business-customers/${businessCustomerId}/subscriptions/missed`);
const customerPendingPayments = (businessCustomerId) => api.get(`/api/v1/business-customers/${businessCustomerId}/subscriptions/pending`);
const getCustomerReport = (businessId, options) => api.get(`api/v1/businesses/${businessId}/business-customers/summary`, options);
const getCustomerCharts = (businessId, duration, options) => api.get(`api/v1/businesses/${businessId}/business-customers/${duration}/series`, options);

const getMembership = (membershipId) => api.get("api/v1/memberships/" + membershipId);
const getMemberships = (businessId, options) => api.get("api/v1/memberships?businessId.equals=" + businessId, options);
const createMembership = (membership) => api.post("api/v1/memberships", membership);
const signupBusiness = (signup) => api.post("api/v1/memberships/account/default", signup);
const updateMembership = (membership) => api.put("api/v1/memberships", membership);
const deleteMembership = (membershipId) => api.delete("api/v1/memberships/" + membershipId);

const getPaySwitchCredentials = (businessId) => api.get(`api/v1/providers/payswitch/credential/${businessId}`);
const createPaySwitchCredentials = (businessId, credentials) => api.post(`api/v1/providers/payswitch/credential/${businessId}`, credentials);
const getPaymentCredentials = (businessId) => api.get(`/api/v1/businesses/payment-credentials/${businessId}/configured`);
const generateVerificationCode = (generationRequest) => api.post("api/v1/self-signups", generationRequest);
const getMembershipInvite = (membershipInviteId) => api.get("api/v1/membership-invites/" + membershipInviteId);
const getMembershipInvites = (businessId, options) => api.get("api/v1/membership-invites?businessId.equals=" + businessId + "&status.equals=PENDING", options);
const createMembershipInvite = (membershipInvite) => api.post("api/v1/membership-invites", membershipInvite);
const updateMembershipInvite = (membershipInvite) => api.put("api/v1/membership-invites", membershipInvite);
const deleteMembershipInvite = (membershipInviteId) => api.delete("api/v1/membership-invites/" + membershipInviteId);
const resendMembershipInvite = (membershipInviteId) => api.patch(`api/v1/membership-invites/${membershipInviteId}/resend`);

const getSelfSignup = (selfSignupId) => api.get("api/v1/self-signups/" + selfSignupId);
const resendSelfSignup = (id) => api.patch("api/v1/self-signups/resend/" + id);
const resendBulkSelfSignup = (invitations) => api.patch("api/v1/self-signups/resend/", invitations);
const getSelfSignups = (businessId, options) => api.get(`api/v1/_search/self-signups/${businessId}`, options);
const searchInvitations = (businessId, filter, options) => api.get(`api/v1/_search/self-signups/${businessId}`, filter, options);
const getCountSelfSignups = (businessId) => api.get(`api/v1/_search/self-signups/${businessId}/count`);
const searchInvitationsCount = (businessId, filter) => api.get(`api/v1/_search/self-signups/${businessId}/count?`, filter);
const getSelfSignupsFilter = (businessId, filter, options) => api.get(`api/v1/self-signups?businessId.equals=${businessId} &${filter}`, options);
const deleteSelfSignup = (selfSignupId) => api.delete("api/v1/self-signups/" + selfSignupId);

const getPaymentSchedule = (paymentScheduleId) => api.get("api/v1/payment-schedules/" + paymentScheduleId);
const getPaymentSchedules = (subscriberId, options) => api.get("api/v1/payment-schedules?subscriptionId.equals=" + subscriberId, options);
const getPaymentScheduleRange = (businessId, startDate, endDate, options) => api.get("api/v1/payment-schedules/business/" + businessId + "/" + startDate + "/" + endDate, options);
const getPaymentScheduleSummary = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/report/${startDate}/${endDate}`);
const getWeeklyPaymentScheduleSummary = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/report/${startDate}/${endDate}`);
const get3MonthsPaymentScheduleSummary = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/report/${startDate}/${endDate}`);
const get6MonthsPaymentScheduleSummary = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/report/${startDate}/${endDate}`);
const get12MonthsPaymentScheduleSummary = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/report/${startDate}/${endDate}`);
const getPaymentScheduleProjection = (businessId, startDate, endDate) => api.get("api/v1/payment-schedules/projection/business/" + businessId + "/" + startDate + "/" + endDate);
const createPaymentSchedule = (paymentSchedule) => api.post("api/v1/payment-schedules", paymentSchedule);
const updatePaymentSchedule = (paymentSchedule) => api.put("api/v1/payment-schedules", paymentSchedule);
const deletePaymentSchedule = (paymentScheduleId) => api.delete("api/v1/payment-schedules/" + paymentScheduleId);
const notifyPaymentSchedule = (paymentScheduleId) => api.post("api/v1/payment-schedules/notify/" + paymentScheduleId);
const getSubscriptionMissedSchedule = (subscriberId, options) => api.get(`api/v1/payment-schedules?subscriptionId.equals=${subscriberId}&status.equals=EXPIRED`, options);
const getSubscriptionMissedScheduleCount = (subscriberId, options) => api.get(`api/v1/payment-schedules/count?subscriptionId.equals=${subscriberId}&status.equals=EXPIRED`, options);
const getSubscriptionPendingSchedule = (subscriberId, options) => api.get(`api/v1/payment-schedules?subscriptionId.equals=${subscriberId}&status.equals=PENDING`);
const cancelSubscription = (subscriptionId, membershipId) => api.patch(`api/v1/subscribers/${subscriptionId}/cancel/${membershipId}`);
const pauseSubscription = (subscriptionId, membershipId) => api.patch(`api/v1/subscribers/${subscriptionId}/pause/${membershipId}`);
const resumeSubscription = (id, startFrom, membershipId) => api.patch(`api/v1/subscribers/reschedule/${id}/${startFrom}/${membershipId}`);
const rescheduleSubscription = (subscriberId, startFrom, membershipId) => api.patch(`api/v1/subscribers/reschedule/${subscriberId}/${startFrom}/${membershipId}`);
const getSubscriberRevenue = (subscriberId) => api.get(`api/v1/subscribers/${subscriberId}/revenue`)

const getPaymentTrack = (paymentTrackId) => api.get(`api/v1/payment-tracks/${paymentTrackId}`);
const getPaymentTrackGroup = (planGroupId, options) => api.get("api/v1/payment-tracks?planGroupId.equals=" + planGroupId, options);
const getCollectedPaymentTrack = (businessId, filter, options) => api.get(`api/v1/_search/payment-tracks/${businessId}${filter}`, options);
const getMissedPaymentTrack = (businessId, filter, options) => api.get(`api/v1/_search/payment-schedules/${businessId}/missed${filter}`, options);
const getExternalPaymentTrack = (businessId, filter, options) => api.get(`api/v1/_search/payment-schedules/${businessId}/external${filter}`, options);
const getPaymentTrackGroupCount = (planGroupId, options) => api.get("api/v1/payment-tracks/count?planGroupId.equals=" + planGroupId, options);
const getPaymentTracks = (subscriberId, options) => api.get("api/v1/payment-tracks?subscriberId.equals=" + subscriberId, options);
const getPaymentTrackRange = (businessId, startDate, endDate, options) => api.get("api/v1/payment-tracks/business/" + businessId + "/" + startDate + "/" + endDate, options);
const getPaymentTrackRevenue = (businessId, startDate, endDate) => api.get("api/v1/payment-tracks/revenue/business/" + businessId + "/" + startDate + "/" + endDate);
const updatePaymentTrack = (paymentTrack) => api.put("api/v1/payment-tracks", paymentTrack);
const deletePaymentTrack = (paymentTrackId) => api.delete("api/v1/payment-tracks/" + paymentTrackId);
const paymentMarkExternally = (scheduleId, membershipId, schedule) => api.post(`api/v1/payment-schedules/${scheduleId}/mark-externally-paid/${membershipId}`, schedule);
const countPaymentTracks = (subscriberId, options) => api.get(`api/v1/payment-tracks/count?subscriberId.equals=${subscriberId}`, options);

const getSettlement = (settlementId) => api.get("api/v1/settlements/" + settlementId);
const getSettlementSources = (settlementId) => api.get("api/v1/settlements/sources/settlement/" + settlementId);
const getSettlementSourcesGroup = (planGroupId) => api.get("api/v1/settlements/sources/plan-group/" + planGroupId);
const getSettlements = (walletId, options) => api.get("api/v1/settlements?walletId.equals=" + walletId, options);
const getSettlementsCount = (walletId) => api.get("api/v1/settlements/count?walletId.equals=" + walletId);
const getAllSettlements = (businessId, filter) => api.get("api/v1/_search/settlements/" + businessId + filter);
const getAllSettlementsCount = (businessId, filter) => api.get(`api/v1/settlements/${businessId}/graph/count${filter}`);
const getSettlementsStatusCount = (businessId, status) => api.get("api/v1/settlements/count?walletId.equals=" + businessId + "&status.equals=" + status);
const createSettlement = (settlement) => api.post("api/v1/settlements", settlement);
const updateSettlement = (settlement) => api.put("api/v1/settlements", settlement);
const deleteSettlement = (settlement) => api.delete("api/v1/settlements/" + settlement);
const getPlanSettlements = (id) => api.get(`api/v1/settlements/sources/plan-group/${id}`);
const getStructureSettlement = (countryId) => api.get(`api/v1/settlements/config/${countryId}`)

const getPlanGroup = (planGroupId) => api.get("api/v1/plan-groups/" + planGroupId);
const getPlanGroups = (businessId, options) => api.get(`api/v1/plan-groups?businessId.equals=${businessId}`, options);
const getPlanGroupsFilter = (businessId, name, options) => api.get(`api/v1/plan-groups?businessId.equals=${businessId}&name.equals=${name}`, options);
const getPlanGroupsCount = (businessId, options) => api.get("/api/v1/plan-groups/count?businessId.equals=" + businessId, options);
const getPlanGroupsGraphed = (businessId, options) => api.get("api/v1/plan-groups/graphed/" + businessId, options);
const getPlanGroupsGraphedActive = (businessId) => api.get("api/v1/plan-groups/graphed/active/" + businessId);
const getPlanGroupsGraphedArchived = (businessId) => api.get("api/v1/plan-groups/graphed/archived/" + businessId);
const getPlanGroupTier = (planGroupId, options) => api.get(`api/v1/plans?groupId.equals=${planGroupId}`, options);
const getPlanBusinessPerformance = (businessId) => api.get(`api/v1/businesses/plans/${businessId}/performance`);
const getPlanGroupPerformance = (planGroupId, options) => api.get(`api/v1/plan-groups/performance/${planGroupId}`, options);
const getPlanGroupPlansSummary = (planGroupId, options) => api.get(`api/v1/plan-groups/plans/summary/${planGroupId}`, options);
const getPlanGroupCustomersSummary = (planGroupId, options) => api.get(`api/v1/plan-groups/customers/summary/${planGroupId}`, options);
const getPlanGroupDuplicate = (id, name) => api.post(`api/v1/plan-groups/copy`, { id, name });
const getPlanReport = (businessId, options) => api.get(`api/v1/businesses/${businessId}/plan-groups/summary`, options);
const getPlanReportChart = (businessId, type, options) => api.get(`api/v1/businesses/${businessId}/plan-groups/${type}/series`, options);
const enablePlanReport = (planGroupId, report) => api.post(`api/v1/plan-groups/reportable/${planGroupId}`, report);

const getPlanGroupPaymentMissed = (planGroupId, options) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/missed`, options);
const getPlanGroupPaymentMissedCount = (planGroupId) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/missed/count`);
const getPlanGroupPaymentPending = (planGroupId, options) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/pending`, options);
const getPlanGroupPaymentPendingCount = (planGroupId) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/pending/count`);
const getPlanGroupPaymentPaid = (planGroupId, options) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/paid`, options);
const getPlanGroupPaymentPaidCount = (planGroupId) => api.get(`api/v1/payment-schedules/plan-group/${planGroupId}/pending/count`);

const createPlanGroup = (planGroup) => api.post("api/v1/plan-groups", planGroup);
const updatePlanGroup = (planGroupId, planGroup) => api.put(`api/v1/plan-groups/${planGroupId}`, planGroup);
const deletePlanGroup = (planGroupId) => api.delete("api/v1/plan-groups/" + planGroupId);
const archivePlanGroup = (planGroupId, username) => api.patch("api/v1/plan-groups/archive/" + planGroupId + "/" + username);

const getPayoutChannel = (payoutChannelId) => api.get("api/v1/payout-channels/" + payoutChannelId);
const getPayoutChannels = (businessId, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE", options);
const getFilteredPayoutChannels = (businessId, query, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE&temporary.equals=false&identifier equals=" + query, options);
const getSavedPayoutChannels = (businessId, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE&temporary.equals=false&", options);
const getFavouriteChannels = (businessId, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE&temporary.equals=false&favourite.equals=true", options);
const setFavouritePayoutChannels = (channelId) => api.patch("api/v1/payout-channels/favourite/" + channelId);
const setUnfavouritePayoutChannels = (channelId) => api.patch("api/v1/payout-channels/unfavourite/" + channelId);
const filterBanks = (businessId, query, options) => api.get("api/v1/payout-channels?businessId.equals=" + businessId + "&status.equals=ACTIVE&temporary.equals=false&systemBankId.equals=" + query, options);

const createPayoutChannel = (payoutChannel) => api.post("api/v1/payout-channels", payoutChannel);
const updatePayoutChannel = (payoutChannel) => api.put("api/v1/payout-channels", payoutChannel);
const deletePayoutChannel = (payoutChannelId) => api.delete("api/v1/payout-channels/" + payoutChannelId);
const archivePayoutChannel = (payoutChannelId, username) => api.patch("api/v1/payout-channels/archive/" + payoutChannelId + "/" + username);

const getBank = (bankId) => api.get("api/v1/system-banks/" + bankId);
const getBanks = (countryId) => api.get("api/v1/system-banks?countryId.equals=" + countryId + "&active.equals=true");

const getBankBranch = (bankId) => api.get("api/v1/system-banks-branches/" + bankId);
const getBankBranches = (bankId, options) => api.get("api/v1/system-banks-branches?bankId.equals=" + bankId, options);

const getCredentials = (businessId) => api.get("api/v1/credential/" + businessId);
const generateRequest = (businessId) => api.patch("api/v1/credential/rotate/" + businessId);

const getDocuments = (countryId) => api.get("api/v1/identity-documents?countryId.equals=" + countryId + "&active.equals=true&appliesTo.equals=INDIVIDUAL");
const getOrganisationDocuments = (countryId) => api.get("api/v1/identity-documents?countryId.equals=" + countryId + "&active.equals=true&appliesTo.equals=ORGANISATION");
const createBusinessVerification = (business, date) => api.post("api/v1/identity-verif-requests", business);
const verifyDocument = (requestId, membershipId, documentId, referenceStr, file
) => api.post(`api/v1/identity-proofs/upload/${requestId}/${membershipId}/${documentId}?reference=${referenceStr ? referenceStr : ""}`, file, {
  headers: {
    "content-type": "multipart/form-data",
  },
}
);
const uploadLogo = (businessId, file) => api.post(`api/v1/businesses/logo/${businessId}/upload`, file, {
  headers: {
    "content-type": "multipart/form-data",
  },
})
const reviewDocument = (requestId) => api.patch("api/v1/identity-verif-requests/" + requestId + "/complete");
const previewDocument = (proofId) => api.get("api/v1/identity-proofs/" + proofId + "/image");
const businessVerificationRequest = (businessId) => api.get(`api/v1/identity-verif-requests/count?businessId.equals=${businessId}&status.equals=PENDING`);

const getBusinessCustomers = (businessId, options) => api.get(`api/v1/business-customers/_search/${businessId}/simple`, options);
const getExactFilteredBusinessCustomers = (businessId, query, options) => api.get(`api/v1/business-customers/_search/${businessId}/exact/${query}`, options)
const getFilteredBusinessCustomers = (businessId, query) => api.get(`api/v1/business-customers/_search/${businessId}/simple?searchText=${query}`);
const updateEnterpriseCustomers = (businessCustomerId, businessCustomer) => api.put(`api/v1/business-customers/update/simple/${businessCustomerId}`, businessCustomer);
const getBusinessCustomerSubscriptions = (businessCustomerId) => api.get(`api/v1/subscribers/business-customer/${businessCustomerId}`);
const getBusinessCustomerSubscriptionsCount = (businessCustomerId) => api.get(`api/v1/subscribers/business-customer/${businessCustomerId}/count`);
const getBusinessCustomerRecord = (businessCustomerId) => api.get(`api/v1/business-customers/${businessCustomerId}`);
const businessCustomerImport = (businessId, membershipId, file) => api.post(`api/v1/customer-imports/upload/${businessId}?membershipId=${membershipId}`, file, {
  headers: {
    "content-type": "multipart/form-data",
  },
});
const getBusinessCustomerImports = (businessId, options) => api.get("api/v1/customer-imports?businessId.equals=" + businessId, options);
const getBusinessCustomerImportsCount = (businessId) => api.get("api/v1/customer-imports/count?businessId.equals=" + businessId);
const getBusinessCustomerSummarySearch = (businessId, query) => api.get(`api/v1/business-customers/_search/${businessId}/summary?searchText=${query}`);
const getBusinessCustomerSummary = (businessId) => api.get(`api/v1/business-customers/_search/${businessId}/summary`);
const applyBusinessCustomerImport = (id) => api.put(`api/v1/customer-imports/apply/${id}`);
const getBusinessCustomerEntriesList = (importId) => api.get(`api/v1/customer-import-entries/imports/${importId}`);
const successfulCustomerPayments = (bcId, options) => api.get(`api/v1/payment-tracks/business-customer/${bcId}`, options);
const expiredCustomerPayments = (bcId, options) => api.get(`api/v1/payment-schedules/business-customer/${bcId}/expired`, options);
const getBusinessCustomerSubscriptionAmounts = (bcId) => api.get(`api/v1/business-customers/${bcId}/subscriptions/amounts`);

const getDailyCharts = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/timeseries/daily/${startDate}/${endDate}`);
const getWeeklyCharts = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/timeseries/weekly/${startDate}/${endDate}`);
const get3MonthlyCharts = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/timeseries/monthly/${startDate}/${endDate}`);
const get6MonthlyCharts = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/timeseries/monthly/${startDate}/${endDate}`);
const get12MonthlyCharts = (businessId, startDate, endDate) => api.get(`api/v1/businesses/${businessId}/timeseries/monthly/${startDate}/${endDate}`);

const getWallet = (walletId) => api.get("api/v1/wallets/" + walletId);
const getGlobalWallet = (businessId) => api.get(`api/v1/wallets/business/${businessId}/status`);
const getWalletStatistics = (businessId, options) => api.get(`api/v1/wallets/statistics/business/${businessId}`, options);
const getFavouriteWallets = (businessId) => api.get(`api/v1/plan-groups/favourites/${businessId}`);
const favouriteWallet = (id) => api.patch(`api/v1/plan-groups/favourite/${id}`);
const unfavouriteWallet = (id) => api.patch(`api/v1/plan-groups/unfavourite/${id}`);

const customerRecentPayments = (businessCustomerId, size) => api.get(`api/v1/payment-tracks/business-customer/${businessCustomerId}/recent/${size}`);
const customerMissedPayments = (businessCustomerId, size) => api.get(`api/v1/payment-schedules/business-customer/${businessCustomerId}/recent/missed/${size}`);
const customerSubscriptionActivity = (businessCustomerId, size) => api.get(`/api/v1/payment-schedules/business-customer/${businessCustomerId}/future/${size}`);

const getTransactionReport = (businessId, options) => api.get(`api/v1/businesses/${businessId}/plan-groups/transactions/summary`, options);
const getTransactionReportChart = (businessId, type, options) => api.get(`api/v1/businesses/${businessId}/plan-groups/${type}/transactions/series`, options);
const getTransactionCustomReport = (businessId, body) => api.post(`api/v1/businesses/${businessId}/settlements/report`, body);

const subscribeReport = (businessId, body) => api.post(`/api/v1/businesses/${businessId}/subscriptions/report`, body);

const subscriptionsExternalPayments = (businessId, subId, options) => api.get(`api/v1/payment-schedules/${businessId}/subscription/${subId}/external`, options);
const planGroupExternalPayments = (businessId, groupId, options) => api.get(`api/v1/payment-schedules/${businessId}/plan-group/${groupId}/external`, options);
const businessCustomerExternalPayments = (businessId, bcId, options) => api.get(`api/v1/payment-schedules/${businessId}/business-customer/${bcId}/external`, options);
const planExternalPayments = (businessId, planId, options) => api.get(`api/v1/payment-schedules/${businessId}/plan/${planId}/external`, options);
// ignite-jhipster-api-method-needle

// ------
// STEP 3
// ------
//
// Return back a collection of functions that we would consider our
// interface.  Most of the time it'll be just the list of all the
// methods in step 2.
//
// Notice we're not returning back the `api` created in step 1?  That's
// because it is scoped privately.  This is one way to create truly
// private scoped goodies in JavaScript.
//
export {
  // a list of the API functions from step 2
  createUser,
  updateUser,
  getUsers,
  getUser,
  deleteUser,
  createCountry,
  updateCountry,
  getCountries,
  getCountry,
  deleteCountry,
  createBusiness,
  updateBusiness,
  getBusinesses,
  getBusinessSummary,
  getBusiness,
  deleteBusiness,
  createPlan,
  updatePlan,
  getPlans,
  getPlan,
  deletePlan,
  changePlanPrice,
  archivePlan,
  planPerformance,
  migratePlan,
  getPlanPaymentMissed,
  getPlanPaymentMissedCount,
  getPlanPaymentPending,
  getPlanPaymentPendingCount,
  getPlanPaymentCollected,
  getPlanPaymentCollectedCount,
  createCustomer,
  updateCustomer,
  getCustomers,
  getCustomer,
  deleteCustomer,
  createSubscriber,
  updateSubscriber,
  getSubscribers,
  getSubscriber,
  getCountSubscribers,
  deleteSubscriber,
  getPlanSubscribers,
  checkExclusivity,
  searchSubscribers,
  substituteSubscribersFilter,
  filterSubscribers,
  searchInvitations,
  dateFilterSubscribers,
  countSubscribers,
  getSubscriberMissedSchedules,
  cancelSubscription,
  pauseSubscription,
  countStatusSubscribers,
  getCountSearchSubscribers,
  getCountDateSearchSubscribers,
  subscribersBulkCancel,
  subscribersBulkPause,
  resumeSubscription,
  customerPendingPayments,
  businessCustomerMissedPayments,
  customerReceivedPayments,
  rescheduleSubscription,
  getCustomerReport,
  getBusinessCustomerSummarySearch,
  getBusinessCustomerSummary,
  getCustomerCharts,
  createMembership,
  updateMembership,
  getMemberships,
  getMembership,
  deleteMembership,
  resendMembershipInvite,
  getPaySwitchCredentials,
  createPaySwitchCredentials,
  getPaymentCredentials,
  getBusinessCustomerSubscriptionAmounts,

  // ignite-jhipster-api-export-needle
  setAuthToken,
  removeAuthToken,
  getAccount,
  updateAccount,
  getDefMembership,
  signupBusiness,
  generateVerificationCode,
  getMembershipInvite,
  getMembershipInvites,
  createMembershipInvite,
  updateMembershipInvite,
  deleteMembershipInvite,
  getSelfSignup,
  resendSelfSignup,
  resendBulkSelfSignup,
  getSelfSignups,
  getSelfSignupsFilter,
  searchInvitationsCount,
  getCountSelfSignups,
  deleteSelfSignup,
  getProfile,
  updateProfile,
  resendVerificationEmail,
  getPaymentSchedule,
  getPaymentSchedules,
  getPaymentScheduleRange,
  getPaymentScheduleProjection,
  getPaymentScheduleSummary,
  createPaymentSchedule,
  updatePaymentSchedule,
  deletePaymentSchedule,
  notifyPaymentSchedule,
  getSubscriptionMissedSchedule,
  getSubscriptionPendingSchedule,
  getSubscriberRevenue,
  getPaymentTrack,
  getPaymentTrackGroup,
  getCollectedPaymentTrack,
  getMissedPaymentTrack,
  getExternalPaymentTrack,
  getPaymentTrackGroupCount,
  getPaymentTracks,
  getPaymentTrackRange,
  getPaymentTrackRevenue,
  updatePaymentTrack,
  deletePaymentTrack,
  rescheduleSubscriber,
  paymentMarkExternally,
  countPaymentTracks,
  getWallet,
  getGlobalWallet,
  getWalletStatistics,
  getFavouriteWallets,
  favouriteWallet,
  unfavouriteWallet,
  getSettlement,
  getSettlements,
  getAllSettlements,
  getSettlementSources,
  getSettlementsCount,
  getAllSettlementsCount,
  getSettlementSourcesGroup,
  getSettlementsStatusCount,
  createSettlement,
  updateSettlement,
  deleteSettlement,
  getPlanSettlements,
  getStructureSettlement,
  getPlanGroup,
  getPlanGroups,
  getPlanGroupsFilter,
  getPlanGroupsCount,
  getPlanGroupsGraphed,
  getPlanGroupsGraphedActive,
  getPlanGroupsGraphedArchived,
  createPlanGroup,
  updatePlanGroup,
  deletePlanGroup,
  archivePlanGroup,
  getPlanGroupTier,
  getPlanGroupPaymentMissed,
  getPlanGroupPaymentMissedCount,
  getPlanGroupPaymentPending,
  getPlanGroupPaymentPendingCount,
  getPlanGroupPaymentPaid,
  getPlanGroupPaymentPaidCount,
  getPlanGroupPerformance,
  getPlanBusinessPerformance,
  getPlanGroupPlansSummary,
  getPlanGroupCustomersSummary,
  getPlanGroupDuplicate,
  getPlanReport,
  getPlanReportChart,
  enablePlanReport,
  getPayoutChannel,
  getPayoutChannels,
  getSavedPayoutChannels,
  getFavouriteChannels,
  setFavouritePayoutChannels,
  setUnfavouritePayoutChannels,
  createPayoutChannel,
  updatePayoutChannel,
  deletePayoutChannel,
  archivePayoutChannel,
  getFilteredPayoutChannels,
  getBank,
  getBanks,
  getBankBranch,
  getBankBranches,
  filterBanks,
  getCredentials,
  generateRequest,
  getDocuments,
  getOrganisationDocuments,
  createBusinessVerification,
  verifyDocument,
  reviewDocument,
  previewDocument,
  businessVerificationRequest,
  getBusinessCustomers,
  getFilteredBusinessCustomers,
  getExactFilteredBusinessCustomers,
  updateEnterpriseCustomers,
  getBusinessCustomerSubscriptions,
  getBusinessCustomerRecord,
  getBusinessCustomerSubscriptionsCount,
  businessCustomerImport,
  getBusinessCustomerImports,
  getBusinessCustomerImportsCount,
  getBusinessCustomerEntriesList,
  getBusinessQr,
  getIndustries,
  applyBusinessCustomerImport,
  successfulCustomerPayments,
  expiredCustomerPayments,
  getDailyCharts,
  getWeeklyCharts,
  get3MonthlyCharts,
  get6MonthlyCharts,
  get12MonthlyCharts,
  getWeeklyPaymentScheduleSummary,
  get3MonthsPaymentScheduleSummary,
  get6MonthsPaymentScheduleSummary,
  get12MonthsPaymentScheduleSummary,
  customerRecentPayments,
  customerMissedPayments,
  customerSubscriptionActivity,
  getTransactionReportChart,
  getTransactionReport,
  subscribeReport,
  getTransactionCustomReport,
  subscriptionsExternalPayments,
  planGroupExternalPayments,
  businessCustomerExternalPayments,
  planExternalPayments,
  businessCustomersBulkPause,
  businessCustomersBulkCancel,
  businessCustomersSingleCancel,
  businessCustomersSinglePause,
  getSubscriptionMissedScheduleCount,
  uploadLogo
};
