import React from "react";
import { useSelector, } from "react-redux";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { DataView } from "primereact/dataview";
import defaultAvatar from "../../assets/images/default avatar.png"

const ColleaguesList = () => {
  const defMembership = useSelector((state) => state.defMembership.defMembership)
  const fetchingAllMembership = useSelector((state) => state.memberships.fetchingAll)
  const memberships = useSelector((state) => state.memberships.memberships)
  const { t } = useTranslation("common");
  const loading = !defMembership || fetchingAllMembership

  const renderGridItem = (data) => {
    return (
      <div className="col-12 md:col-6 xl:col-4 p-3 colleague-list-item">
        <div className="surface-card shadow-2 border-round p-4">
          <div className="flex border-bottom-1 surface-border pb-4">
            <img src={data.user.imageUrl || defaultAvatar} className="mr-3" alt="user pic" />
            <div className="flex flex-column align-items-start">
              <span className="name">{data.user.name}</span>
              <span className="title">{data.user.email}</span>
              <span className={`role ${data.role.toLowerCase()}`}>{data.role}</span>
            </div>
          </div>
          {defMembership.role.toLowerCase() === "owner" &&
            <div className="flex justify-content-between pt-4">
              <Button label={t('colleague.edit')} icon="pi pi-user-edit" className="p-button-outlined p-button-secondary w-6 mr-2" />
              <Button label={t('colleague.remove')} icon="pi pi-user-minus" className="p-button-outlined p-button-secondary w-6 ml-2" />
            </div>}
        </div>
      </div>
    );
  };

  return (<DataView
    value={memberships}
    itemTemplate={renderGridItem}
    paginatorPosition={"bottom"}
    paginator
    rows={10}
    layout="grid"
    className="colleague-list"
    loading={loading}
  />);
};
export default (ColleaguesList);