import { call, put } from 'redux-saga/effects'
import CountryActions from '../actions/country-actions'
import { getCountries, getCountry, updateCountry, deleteCountry, createCountry } from "../../Service/api";

export function* getCountryData(action) {
    const { countryId } = action
    // make the call to the api
    const response = yield call(getCountry, countryId)

    // success?
    if (response.ok) {
        console.log('Country - OK')
        yield put(CountryActions.countrySuccess(response.data))
    } else {
        console.log('Country - FAIL')
        yield put(CountryActions.countryFailure(response.data))
    }
}

export function* getCountriesData(action) {
    const { options } = action
    // make the call to the api
    const response = yield call(getCountries, options)

    // success?
    if (response.ok) {
        console.log('Countries - OK')
        yield put(CountryActions.countryAllSuccess(response.data))
    } else {
        console.log('Countries - FAIL')
        yield put(CountryActions.countryAllFailure(response.data))
    }
}

export function* updateCountryData(action) {
    const { country } = action
    // make the call to the api
    const idIsNotNull = !!country.id
    const apiCall = call(idIsNotNull ? updateCountry : createCountry, country)
    const response = yield call(apiCall)

    // success?
    if (response.ok) {
        console.log('Update Country - OK')
        yield put(CountryActions.countryUpdateSuccess(response.data))
    } else {
        console.log('Update Country - FAIL')
        yield put(CountryActions.countryUpdateFailure(response.data))
    }
}

export function* deleteCountryData(action) {
    const { countryId } = action
    // make the call to the api
    const response = yield call(deleteCountry, countryId)

    // success?
    if (response.ok) {
        console.log('Delete Country - OK')
        yield put(CountryActions.countryDeleteSuccess())
    } else {
        console.log('Delete Country - FAIL')
        yield put(CountryActions.countryDeleteFailure(response.data))
    }
}
