import { call, put } from "redux-saga/effects";
import PlanGroupActions from "./plan-group.reducer";
import * as api from "../../../Service/api";

export function* getPlanGroup(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroup, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupFailure(response.data));
  }
}

export function* getPlanGroups(action) {
  const { businessId, options } = action;
  // make the call to the api

  const response = yield call(api.getPlanGroups, businessId, options);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupAllSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupAllFailure(response.data));
  }
}
export function* getPlanGroupsFilter(action) {
  const { businessId, name, options } = action;
  const response = yield call(
    api.getPlanGroupsFilter,
    businessId,
    name,
    options
  );

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupsFilterSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupsFilterFailure(response.data));
  }
}

export function* getPlanGroupsCount(action) {
  const { businessId } = action;
  // make the call to the api

  const response = yield call(api.getPlanGroupsCount, businessId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupAllCountSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupAllCountFailure(response.data));
  }
}

export function* getPlanGroupsGraphed(action) {
  const { businessId, options } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupsGraphed, businessId, options);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupGraphedSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupGraphedFailure(response.data));
  }
}

export function* getPlanGroupsGraphedActive(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupsGraphedActive, businessId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupGraphedActiveSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupGraphedActiveFailure(response.data));
  }
}

export function* getPlanGroupsGraphedArchived(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupsGraphedArchived, businessId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupGraphedArchivedSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupGraphedArchivedFailure(response.data));
  }
}

export function* createPlanGroup(action) {
  const { planGroup } = action;
  // make the call to the api

  const response = yield call(api.createPlanGroup, planGroup);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupCreateSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupCreateFailure(response.data));
  }
}
export function* updatePlanGroup(action) {
  const { planGroupId, planGroup } = action;
  // make the call to the api
  // const idIsNotNull = !!planGroup.id;
  const response = yield call(api.updatePlanGroup, planGroupId, planGroup);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupUpdateSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupUpdateFailure(response.data));
  }
}

export function* deletePlanGroup(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.deletePlanGroup, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupDeleteSuccess());
  } else {
    yield put(PlanGroupActions.planGroupDeleteFailure(response.data));
  }
}

export function* archivePlanGroup(action) {
  const { planGroupId, username } = action;
  // make the call to the api
  const response = yield call(api.archivePlanGroup, planGroupId, username);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupArchiveSuccess());
  } else {
    yield put(PlanGroupActions.planGroupArchiveFailure(response.data));
  }
}

export function* getPlanGroupTier(action) {
  const { planGroupId, options } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupTier, planGroupId, options);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupTierSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupTierFailure(response.data));
  }
}

export function* getPlanGroupPaymentMissed(action) {
  const { planGroupId, options } = action;
  // make the call to the api
  const response = yield call(
    api.getPlanGroupPaymentMissed,
    planGroupId,
    options
  );

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPaymentMissedSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPaymentMissedFailure(response.data));
  }
}

export function* getPlanGroupPaymentMissedCount(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupPaymentMissedCount, planGroupId);

  // success?
  if (response.ok) {
    yield put(
      PlanGroupActions.planGroupPaymentMissedCountSuccess(response.data)
    );
  } else {
    yield put(
      PlanGroupActions.planGroupPaymentMissedCountFailure(response.data)
    );
  }
}

export function* getPlanGroupPaymentPending(action) {
  const { planGroupId, options } = action;
  // make the call to the api
  const response = yield call(
    api.getPlanGroupPaymentPending,
    planGroupId,
    options
  );

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPaymentPendingSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPaymentPendingFailure(response.data));
  }
}

export function* getPlanGroupPaymentPendingCount(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupPaymentPendingCount, planGroupId);

  // success?
  if (response.ok) {
    yield put(
      PlanGroupActions.planGroupPaymentPendingCountSuccess(response.data)
    );
  } else {
    yield put(
      PlanGroupActions.planGroupPaymentPendingCountFailure(response.data)
    );
  }
}

export function* getPlanGroupPaymentPaid(action) {
  const { planGroupId, options } = action;
  // make the call to the api
  const response = yield call(
    api.getPlanGroupPaymentPaid,
    planGroupId,
    options
  );

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPaymentPaidSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPaymentPaidFailure(response.data));
  }
}

export function* getPlanGroupPaymentPaidCount(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupPaymentPaidCount, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPaymentPaidCountSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPaymentPaidCountFailure(response.data));
  }
}
export function* getPlanGroupPerformance(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupPerformance, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPerformanceSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPerformanceFailure(response.data));
  }
}

export function* getPlanBusinessPerformance(action) {
  const { businessId } = action;
  // make the call to the api
  const response = yield call(api.getPlanBusinessPerformance, businessId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planBusinessPerformanceSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planBusinessPerformanceFailure(response.data));
  }
}
export function* getPlanGroupPlansSummary(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupPlansSummary, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupPlansSummarySuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupPlansSummaryFailure(response.data));
  }
}

export function* getPlanGroupCustomersSummary(action) {
  const { planGroupId } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupCustomersSummary, planGroupId);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupCustomersSummarySuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupCustomersSummaryFailure(response.data));
  }
}
export function* getPlanGroupDuplicate(action) {
  const { id, name } = action;
  // make the call to the api
  const response = yield call(api.getPlanGroupDuplicate, id, name);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.planGroupDuplicateSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planGroupDuplicateFailure(response.data));
  }
}

export function* enablePlanReport(action) {
  const { planGroupId, report } = action;
  // make the call to the api
  const response = yield call(api.enablePlanReport, planGroupId, report);

  // success?
  if (response.ok) {
    yield put(PlanGroupActions.enablePlanReportSuccess(response.data));
  } else {
    yield put(PlanGroupActions.planReportFailure(response.data));
  }
}
