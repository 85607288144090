import { call, put } from "redux-saga/effects";
import PaySwitchActions from "./payswitch.reducer";
import * as api from "../../../Service/api";

export function* getCredentials(action) {
	const { businessId } = action;
	// make the call to the api
	const response = yield call(api.getPaySwitchCredentials, businessId);

	// success?
	if (response.ok) {
		yield put(PaySwitchActions.paySwitchCredentialSuccess(response.data));
	} else {
		yield put(PaySwitchActions.paySwitchCredentialFailure(response.data));
	}
}



export function* createCredentials(action) {
	const {businessId, credentials } = action;
	// make the call to the api

	const response = yield call(api.createPaySwitchCredentials, businessId, credentials);

	// success?
	if (response.ok) {
		yield put(PaySwitchActions.paySwitchCredentialCreateSuccess(response.data));
	} else {
		yield put(PaySwitchActions.paySwitchCredentialCreateFailure(response.data));
	}
}

export function* getPaymentCredentials(action) {
	const { businessId } = action;
	// make the call to the api
	const response = yield call(api.getPaymentCredentials, businessId);

	// success?
	if (response.ok) {
		yield put(PaySwitchActions.paymentCredentialSuccess(response.data));
	} else {
		yield put(PaySwitchActions.paymentCredentialFailure(response.data));
	}
}