import DefMembershipActions from "../../Redux/actions/defMembership-actions";


export function defMembershipCall(dispatch, Selector, Callback) {
    const defMembership = Selector((state) => {
        return state.defMembership.defMembership;
    });
    const defFetching = Selector((state) => {
        return state.defMembership.fetching;
    });
    const defError = Selector((state) => {
        return state.defMembership.error;
    });
    const getMembership = Callback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    return { defMembership, getMembership, defFetching, defError };
}